export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGIN_OTP_REQUEST: 'USERS_LOGIN_OTP_REQUEST',
    LOGIN_OTP_SUCCESS: 'USERS_LOGIN_OTP_SUCCESS',
    LOGIN_OTP_FAILURE: 'USERS_LOGIN_OTP_FAILURE',
    SEND_OTP_REQUEST: 'USERS_SEND_OTP_REQUEST',
    SEND_OTP_SUCCESS: 'USERS_SEND_OTP_SUCCESS',
    SEND_OTP_FAILURE: 'USERS_SEND_OTP_FAILURE',

    INPROGRESS_REQUEST: 'INPROGRESS_REQUEST',
    INPROGRESS_SUCCESS: 'INPROGRESS_SUCCESS',
    INPROGRESS_FAILURE: 'INPROGRESS_FAILURE',

    CKYC_REQUEST: 'CKYC_REQUEST',
    CKYC_SUCCESS: 'CKYC_SUCCESS',
    CKYC_FAILURE: 'CKYC_FAILURE',

    DIGILOCKER_REQUEST: 'DIGILOCKER_REQUEST',
    DIGILOCKER_SUCCESS: 'DIGILOCKER_SUCCESS',
    DIGILOCKER_FAILURE: 'DIGILOCKER_FAILURE',

    PAN_REQUEST: 'PAN_REQUEST',
    PAN_SUCCESS: 'PAN_SUCCESS',
    PAN_FAILURE: 'PAN_FAILURE',

    PANPRO_REQUEST: 'PANPRO_REQUEST',
    PANPRO_SUCCESS: 'PANPRO_SUCCESS',
    PANPRO_FAILURE: 'PANPRO_FAILURE',

    BANK_SELFIE_REQUEST: 'BANK_SELFIE_REQUEST',
    BANK_SELFIE_SUCCESS: 'BANK_SELFIE_SUCCESS',
    BANK_SELFIE_FAILURE: 'BANK_SELFIE_FAILURE',

    PROFILE_REQUEST: 'PROFILE_REQUEST',
    PROFILE_SUCCESS: 'PROFILE_SUCCESS',
    PROFILE_FAILURE: 'PROFILE_FAILURE',

    UPLOADEDFILES_REQUEST: 'UPLOADEDFILES_REQUEST',
    UPLOADEDFILES_SUCCESS: 'UPLOADEDFILES_SUCCESS',
    UPLOADEDFILES_FAILURE: 'UPLOADEDFILES_FAILURE',

    CIBIL_REQUEST: 'CIBIL_REQUEST',
    CIBIL_SUCCESS: 'CIBIL_SUCCESS',
    CIBIL_FAILURE: 'CIBIL_FAILURE',

    CIBILSOFT_REQUEST: 'CIBILSOFT_REQUEST',
    CIBILSOFT_SUCCESS: 'CIBILSOFT_SUCCESS',
    CIBILSOFT_FAILURE: 'CIBISOFTL_FAILURE',
    
    ACTIVITY_REQUEST: 'ACTIVITY_REQUEST',
    ACTIVITY_SUCCESS: 'ACTIVITY_SUCCESS',
    ACTIVITY_FAILURE: 'ACTIVITY_FAILURE',

    POST_ACTIVITY_REQUEST: 'POST_ACTIVITY_REQUEST',
    POST_ACTIVITY_SUCCESS: 'POST_ACTIVITY_SUCCESS',
    POST_ACTIVITY_FAILURE: 'POST_ACTIVITY_FAILURE',

    INSUBMISSION_REQUEST: 'INSUBMISSION_REQUEST',
    INSUBMISSION_SUCCESS: 'INSUBMISSION_SUCCESS',
    INSUBMISSION_FAILURE: 'INSUBMISSION_FAILURE',

    INDISBURSED_REQUEST: 'INDISBURSED_REQUEST',
    INDISBURSED_SUCCESS: 'INDISBURSED_SUCCESS',
    INDISBURSED_FAILURE: 'INDISBURSED_FAILURE',

    PENDINGINDISBURSMENT_REQUEST: 'PENDINGINDISBURSMENT_REQUEST',
    PENDINGINDISBURSMENT_SUCCESS: 'PENDINGINDISBURSMENT_SUCCESS',
    PENDINGINDISBURSMENT_FAILURE: 'PENDINGINDISBURSMENT_FAILURE',

    INSANCTION_REQUEST: 'INSANCTION_REQUEST',
    INSANCTION_SUCCESS: 'INSANCTION_SUCCESS',
    INSANCTION_FAILURE: 'INSANCTION_FAILURE',

    INREJECTED_REQUEST: 'INREJECTED_REQUEST',
    INREJECTED_SUCCESS: 'INREJECTED_SUCCESS',
    INREJECTED_FAILURE: 'INREJECTED_FAILURE',

    INREVIEW_REQUEST: 'INREVIEW_REQUEST',
    INREVIEW_SUCCESS: 'INREVIEW_SUCCESS',
    INREVIEW_FAILURE: 'INREVIEW_FAILURE',

    DROPPED_REQUEST: 'DROPPED_REQUEST',
    DROPPED_SUCCESS: 'DROPPED_SUCCESS',
    DROPPED_FAILURE: 'DROPPED_FAILURE',

    READYTODISBURSE_REQUEST: 'READYTODISBURSE_REQUEST',
    READYTODISBURSE_SUCCESS: 'READYTODISBURSE_SUCCESS',
    READYTODISBURSE_FAILURE: 'READYTODISBURSE_FAILURE',

    ONHOLD_REQUEST: 'ONHOLD_REQUEST',
    ONHOLD_SUCCESS: 'ONHOLD_SUCCESS',
    ONHOLD_FAILURE: 'ONHOLD_FAILURE',

    DATEFILTER_REQUEST: 'DATEFILTER_REQUEST',
    DATEFILTER_SUCCESS: 'DATEFILTER_SUCCESS',
    DATEFILTER_FAILURE: 'DATEFILTER_FAILURE',

    HANDLEUSER_REQUEST: 'HANDLEUSER_REQUEST',
    HANDLEUSER_SUCCESS: 'HANDLEUSER_SUCCESS',
    HANDLEUSER_FAILURE: 'HANDLEUSER_FAILURE',

    HANDLECOAPPUSER_REQUEST: 'HANDLECOAPPUSER_REQUEST',
    HANDLECOAPPUSER_SUCCESS: 'HANDLECOAPPUSER_SUCCESS',
    HANDLECOAPPUSER_FAILURE: 'HANDLECOAPPUSER_FAILURE',


    EDITFORMDETAILS_REQUEST: 'EDITFORMDETAILS_REQUEST',
    EDITFORMDETAILS_SUCCESS: 'EDITFORMDETAILS_SUCCESS',
    EDITFORMDETAILS_FAILURE: 'EDITFORMDETAILS_FAILURE',

    DISBURSERDSTATUSCHANGE_REQUEST: 'DISBURSERDSTATUSCHANGE_REQUEST',
    DISBURSERDSTATUSCHANGE_SUCCESS: 'DISBURSERDSTATUSCHANGE_SUCCESS',
    DISBURSERDSTATUSCHANGE_FAILURE: 'DISBURSERDSTATUSCHANGE_FAILURE',

    DROPPEDSTATUSCHANGE_REQUEST: 'DROPPEDSTATUSCHANGE_REQUEST',
    DROPPEDSTATUSCHANGE_SUCCESS: 'DROPPEDSTATUSCHANGE_SUCCESS',
    DROPPEDSTATUSCHANGE_FAILURE: 'DROPPEDSTATUSCHANGE_FAILURE',

    UPDATESTATUSCHANGE_REQUEST: 'UPDATESTATUSCHANGE_REQUEST',
    UPDATESTATUSCHANGE_SUCCESS: 'UPDATESTATUSCHANGE_SUCCESS',
    UPDATESTATUSCHANGE_FAILURE: 'UPDATESTATUSCHANGE_FAILURE',

    REVIEWSTATUSCHANGE_REQUEST: 'REVIEWSTATUSCHANGE_REQUEST',
    REVIEWSTATUSCHANGE_SUCCESS: 'REVIEWSTATUSCHANGE_SUCCESS',
    REVIEWSTATUSCHANGE_FAILURE: 'REVIEWSTATUSCHANGE_FAILURE',

    UPDATEEDITFORM_REQUEST: 'UPDATEEDITFORM_REQUEST',
    UPDATEEDITFORM_SUCCESS: 'UPDATEEDITFORM_SUCCESS',
    UPDATEEDITFORM_FAILURE: 'UPDATEEDITFORM_FAILURE',

    HANDLEAPPROVEFORM_REQUEST: 'HANDLEAPPROVEFORM_REQUEST',
    HANDLEAPPROVEFORM_SUCCESS: 'HANDLEAPPROVEFORM_SUCCESS',
    HANDLEAPPROVEFORM_FAILURE: 'HANDLEAPPROVEFORM_FAILURE',

    HANDLEAPPROVEAPI_REQUEST: 'HANDLEAPPROVEAPI_REQUEST',
    HANDLEAPPROVEAPI_SUCCESS: 'HANDLEAPPROVEAPI_SUCCESS',
    HANDLEAPPROVEAPI_FAILURE: 'HANDLEAPPROVEAPI_FAILURE',

    HANDLECOLENDERFUND_REQUEST: 'HANDLECOLENDERFUND_REQUEST',
    HANDLECOLENDERFUND_SUCCESS: 'HANDLECOLENDERFUND_SUCCESS',
    HANDLECOLENDERFUND_FAILURE: 'HANDLECOLENDERFUND_FAILURE',

    FUNDCODE_REQUEST: 'FUNDCODE_REQUEST',
    FUNDCODE_SUCCESS: 'FUNDCODE_SUCCESS',
    FUNDCODE_FAILURE: 'FUNDCODE_FAILURE',
    
    HANDLEAPPROVEAPIFUND_REQUEST: 'HANDLEAPPROVEAPIFUND_REQUEST',
    HANDLEAPPROVEAPIFUND_SUCCESS: 'HANDLEAPPROVEAPIFUND_SUCCESS',
    HANDLEAPPROVEAPIFUND_FAILURE: 'HANDLEAPPROVEAPIFUND_FAILURE',

    GETAADHARFRONT_REQUEST: 'GETAADHARFRONT_REQUEST',
    GETAADHARFRONT_SUCCESS: 'GETAADHARFRONT_SUCCESS',
    GETAADHARFRONT_FAILURE: 'GETAADHARFRONT_FAILURE',

    GETAADHARBACK_REQUEST: 'GETAADHARFRONT_REQUEST',
    GETAADHARBACK_SUCCESS: 'GETAADHARFRONT_SUCCESS',
    GETAADHARBACK_FAILURE: 'GETAADHARFRONT_FAILURE',

    

    ADDCOAPPLICANT_REQUEST: 'ADDCOAPPLICANT_REQUEST',
    ADDCOAPPLICANT_SUCCESS: 'ADDCOAPPLICANT_SUCCESS',
    ADDCOAPPLICANT_FAILURE: 'ADDCOAPPLICANT_FAILURE',

    BANKSELFIE_REQUEST: 'BANKSELFIE_REQUEST',
    BANKSELFIE_SUCCESS: 'BANKSELFIE_SUCCESS',
    BANKSELFIE_FAILURE: 'BANKSELFIE_FAILURE',

    APPLICATIONDETAILS_REQUEST: 'APPLICATIONDETAILS_REQUEST',
    APPLICATIONDETAILS_SUCCESS: 'APPLICATIONDETAILS_SUCCESS',
    APPLICATIONDETAILS_FAILURE: 'APPLICATIONDETAILS_FAILURE',

    EMIURL_REQUEST: 'EMIURL_REQUEST',
    EMIURL_SUCCESS: 'EMIURL_SUCCESS',
    EMIURL_FAILURE: 'EMIURL_FAILURE',


    LOANS_REQUEST: 'LOANS_REQUEST',
    LOANS_SUCCESS: 'LOANS_SUCCESS',
    LOANS_FAILURE: 'LOANS_FAILURE',

    REPAYMENTS_REQUEST: 'REPAYMENTS_REQUEST',
    REPAYMENTS_SUCCESS: 'REPAYMENTS_SUCCESS',
    REPAYMENTS_FAILURE: 'REPAYMENTS_FAILURE',

    POST_INCOMEPROFILE_REQUEST: 'POST_INCOMEPROFILE_REQUEST',
    POST_INCOMEPROFILE_SUCCESS: 'POST_INCOMEPROFILE_SUCCESS',
    POST_INCOMEPROFILE_FAILURE: 'POST_INCOMEPROFILE_FAILURE',
    GET_INCOMEPROFILE_REQUEST: 'GET_INCOMEPROFILE_REQUEST',
    GET_INCOMEPROFILE_SUCCESS: 'GET_INCOMEPROFILE_SUCCESS',
    GET_INCOMEPROFILE_FAILURE: 'GET_INCOMEPROFILE_FAILURE',
    POSTFOIR_REQUEST: 'POSTFOIR_REQUEST',
    POSTFOIR_SUCCESS: 'POSTFOIR_SUCCESS',
    POSTFOIR_FAILURE: 'POSTFOIR_FAILURE',
    GETFOIR_REQUEST: 'GETFOIR_REQUEST',
    GETFOIR_SUCCESS: 'GETFOIR_SUCCESS',
    GETFOIR_FAILURE: 'GETFOIR_FAILURE',
    GET_INSTITUTE_REQUEST: 'GET_INSTITUTE_REQUEST',
    GET_INSTITUTE_SUCCESS: 'GET_INSTITUTE_SUCCESS',
    GET_INSTITUTE_FAILURE: 'GET_INSTITUTE_FAILURE',
    POST_INSTITUTE_REQUEST: 'POST_INSTITUTE_REQUEST',
    POST_INSTITUTE_SUCCESS: 'POST_INSTITUTE_SUCCESS',
    POST_INSTITUTE_FAILURE: 'POST_INSTITUTE_FAILURE',
    GET_PRODUCTS_REQUEST: 'GET_PRODUCTS_REQUEST',
    GET_PRODUCTS_SUCCESS: 'GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_FAILURE: 'GET_PRODUCTS_FAILURE',

    UPDATEBANKDETAILS_REQUEST: 'UPDATEBANKDETAILS_REQUEST',
    UPDATEBANKDETAILS_SUCCESS: 'UPDATEBANKDETAILS_SUCCESS',
    UPDATEBANKDETAILS_FAILURE: 'UPDATEBANKDETAILS_FAILURE',

    UPDATINGBANKDETAILS_REQUEST: 'UPDATINGBANKDETAILS_REQUEST',
    UPDATINGBANKDETAILS_SUCCESS: 'UPDATINGBANKDETAILS_SUCCESS',
    UPDATINGBANKDETAILS_FAILURE: 'UPDATINGBANKDETAILS_FAILURE',

    UPDATEINSTITUTEDETAILS_REQUEST: 'UPDATEINSTITUTEDETAILS_REQUEST',
    UPDATEINSTITUTEDETAILS_SUCCESS: 'UPDATEINSTITUTEDETAILS_SUCCESS',
    UPDATEINSTITUTEDETAILS_FAILURE: 'UPDATEINSTITUTEDETAILS_FAILURE',

    CALLBANKDETAILS_REQUEST: 'CALLBANKDETAILS_REQUEST',
    CALLBANKDETAILS_SUCCESS: 'CALLBANKDETAILS_SUCCESS',
    CALLBANKDETAILS_FAILURE: 'CALLBANKDETAILS_FAILURE',

    CALLINGBANKDETAILS_REQUEST: 'CALLINGBANKDETAILS_REQUEST',
    CALLINGBANKDETAILS_SUCCESS: 'CALLINGBANKDETAILS_SUCCESS',
    CALLINGBANKDETAILS_FAILURE: 'CALLINGBANKDETAILS_FAILURE',

    CALLREG_REQUEST: 'CALLREG_REQUEST',
    CALLREG_SUCCESS: 'CALLREG_SUCCESS',
    CALLREG_FAILURE: 'CALLREG_FAILURE',

    CREATEINST_REQUEST: 'CREATEINST_REQUEST',
    CREATEINST_SUCCESS: 'CREATEINST_SUCCESS',
    CREATEINST_FAILURE: 'CREATEINST_FAILURE',

    USERSEARCH_REQUEST: 'USERSEARCH_REQUEST',
    USERSEARCH_SUCCESS: 'USERSEARCH_SUCCESS',
    USERSEARCH_FAILURE: 'USERSEARCH_FAILURE',

    USERSEARCHDEF_REQUEST: 'USERSEARCHDEF_REQUEST',
    USERSEARCHDEF_SUCCESS: 'USERSEARCHDEF_SUCCESS',
    USERSEARCHDEF_FAILURE: 'USERSEARCHDEF_FAILURE',

    USERSEARCHINS_REQUEST: 'USERSEARCHINS_REQUEST',
    USERSEARCHINS_SUCCESS: 'USERSEARCHINS_SUCCESS',
    USERSEARCHINS_FAILURE: 'USERSEARCHINS_FAILURE',

    LOANDETAILS_REQUEST: 'LOANDETAILS_REQUEST',
    LOANDETAILS_SUCCESS: 'LOANDETAILS_SUCCESS',
    LOANDETAILS_FAILURE: 'LOANDETAILS_FAILURE',

    LOANCOUNT_REQUEST: 'LOANCOUNT_REQUEST',
    LOANCOUNT_SUCCESS: 'LOANCOUNT_SUCCESS',
    LOANCOUNT_FAILURE: 'LOANCOUNT_FAILURE',
    
    GET_NOTIFICATIONS_REQUEST: 'GET_NOTIFICATIONS_REQUEST',
    GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
    GET_NOTIFICATIONS_FAILURE: 'GET_NOTIFICATIONS_FAILURE',
    POST_GENERATE_RECEIPT_REQUEST: 'POST_GENERATE_RECEIPT_REQUEST',
    POST_GENERATE_RECEIPT_SUCCESS: 'POST_GENERATE_RECEIPT_SUCCESS',
    POST_GENERATE_RECEIPT_FAILURE: 'POST_GENERATE_RECEIPT_FAILURE',
    POST_REMINDER_REQUEST: 'POST_REMINDER_REQUEST',
    POST_REMINDER_SUCCESS: 'POST_REMINDER_SUCCESS',
    POST_REMINDER_FAILURE: 'POST_REMINDER_FAILURE',
    GET_REMINDER_REQUEST: 'GET_REMINDER_REQUEST',
    GET_REMINDER_SUCCESS: 'GET_REMINDER_SUCCESS',
    GET_REMINDER_FAILURE: 'GET_REMINDER_FAILURE',
    GET_EMIHISTORY_REQUEST: 'GET_EMIHISTORY_REQUEST',
    GET_EMIHISTORY_SUCCESS: 'GET_EMIHISTORY_SUCCESS',
    GET_EMIHISTORY_FAILURE: 'GET_EMIHISTORY_FAILURE',
    UPLOAD_REQUEST: 'UPLOAD_REQUEST',
    UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',
    UPLOAD_FAILURE: 'UPLOAD_FAILURE',

    CALLDETAILS_REQUEST: 'CALLDETAILS_REQUEST',
    CALLDETAILS_SUCCESS: 'CALLDETAILS_SUCCESS',
    CALLDETAILS_FAILURE: 'CALLDETAILS_FAILURE',

    GET_REPORTS_REQUEST: 'GET_REPORTS_REQUEST',
    GET_REPORTS_SUCCESS: 'GET_REPORTS_SUCCESS',
    GET_REPORTS_FAILURE: 'GET_REPORTS_FAILURE',
    DELETE_REMINDER_REQUEST: 'DELETE_REMINDER_REQUEST',
    DELETE_REMINDER_SUCCESS: 'DELETE_REMINDER_SUCCESS',
    DELETE_REMINDER_FAILURE: 'DELETE_REMINDER_FAILURE',

    INSTITUTES_REQUEST: 'INSTITUTES_REQUEST',
    INSTITUTES_SUCCESS: 'INSTITUTES_SUCCESS',
    INSTITUTES_FAILURE: 'INSTITUTES_FAILURE',
    CREATE_APPLICATION_REQUEST: 'CREATE_APPLICATION_REQUEST',
    CREATE_APPLICATION_SUCCESS: 'CREATE_APPLICATION_SUCCESS',
    CREATE_APPLICATION_FAILURE: 'CREATE_APPLICATION_FAILURE',

    PRODUCTS_REQUEST: 'PRODUCTS_REQUEST',
    PRODUCTS_SUCCESS: 'PRODUCTS_SUCCESS',
    PRODUCTS_FAILURE: 'PRODUCTS_FAILURE',

    FILTEREDUSERS_REQUEST: 'FILTEREDUSERS_REQUEST',
    FILTEREDUSERS_SUCCESS: 'FILTEREDUSERS_SUCCESS',
    FILTEREDUSERS_FAILURE: 'FILTEREDUSERS_FAILURE',

    OPEN_CUSTOMER_DASHBOARD_REQUEST: 'OPEN_CUSTOMER_DASHBOARD_REQUEST',
    OPEN_CUSTOMER_DASHBOARD_SUCCESS: 'OPEN_CUSTOMER_DASHBOARD_SUCCESS',
    OPEN_CUSTOMER_DASHBOARD_FAILURE: 'OPEN_CUSTOMER_DASHBOARD_FAILURE',

    LOGOUT: 'USERS_LOGOUT'
}