import { userConstants } from './../constants/user.constants';

let user = sessionStorage.getItem('user');
const initialState = user ? { user, isLoading: false } : {};

export function GETINSTITUTE(state = initialState, action) {
  
  console.log("inside GETINSTITUTE function", action)
  switch (action.type) {
    case userConstants.GET_INSTITUTE_REQUEST:
      return {
        user: action.user,
        isLoading : true

      };
    case userConstants.GET_INSTITUTE_SUCCESS:
      const {res} = action
        console.log("Action", action)
      return {
        res,
        isLoading : false
      };
    case userConstants.GET_INSTITUTE_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}
export function CREATEPRODUCT(state = initialState, action) {
  
  console.log("inside CREATEPRODUCT function", action)
  switch (action.type) {
    case userConstants.POST_INSTITUTE_REQUEST:
      return {
        user: action.user,
        isLoading : true

      };
    case userConstants.POST_INSTITUTE_SUCCESS:
      const {res} = action
        console.log("Action", action)
      return {
        res,
        isLoading : false
      };
    case userConstants.POST_INSTITUTE_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}
export function GETPRODUCTS(state = initialState, action) {
  
  console.log("inside GETPRODUCT function", action)
  switch (action.type) {
    case userConstants.GET_PRODUCTS_REQUEST:
      return {
        user: action.user,
        isLoading : true

      };
    case userConstants.GET_PRODUCTS_SUCCESS:
      const {res} = action
        console.log("Action", action)
      return {
        res,
        isLoading : false
      };
    case userConstants.GET_PRODUCTS_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}