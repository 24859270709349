import React from 'react'
import Sidebar from "../Sidebar";
import { useState, useEffect  } from 'react';
import axiosInstance from '../../helpers/axios';
import Topbar from "../Topbar";
import DatePicker from "react-multi-date-picker";
import calender from "../../assets/images/calender.png"
import download from "../../assets/images/download.png"
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';



export default function Legalreport() {
    const [isOpen,setIsOpen]=useState(true) 
    const [legaldata, setLegalData] = useState()
    const [LegalstartDate,setLegalStartDateFrom] = useState()
    const [LegalendDate,setLegalEndDateTo] = useState()
    const [isLoading, setIsLoading] = useState(false); 


    const user = sessionStorage.getItem('user');


    useEffect(() => {
        
        setIsLoading(true)
    
        axiosInstance.get('/analytics/legal-report', {
          headers: {
            Authorization: `Bearer ${user}`,
          },
         
        })
        .then((res) => {
           
          console.log(res?.data?.legalreports?.legalreport, 'datadfhgjhhj');
          const legaldatareport=res?.data?.legalreports?.legalreport
          setLegalData(legaldatareport)
          
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);

        })
        .catch((error) => {
          console.log(error);
        });
    
      }, []); 




      const fetchingclosedData = ()=>{

        setIsLoading(true)

        let queryParams = {}
        if(LegalstartDate && LegalendDate){
            
            const formattedStartDate = moment(LegalstartDate, 'DD MMM YYYY').format('DD-MM-YYYY')
            const formattedEndDate =moment(LegalendDate, 'DD MMM YYYY').format('DD-MM-YYYY')

            console.log(formattedStartDate , formattedEndDate,'datesssssss')
    
        queryParams.startDate = formattedStartDate;
        queryParams.endDate = formattedEndDate;
        }
        
       
    
        axiosInstance.get('/analytics/legal-report', {
          params: queryParams,
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
    
            setLegalData(res?.data?.legalreports?.legalreport);
        
            const loadingTimeout = setTimeout(() => {
                setIsLoading(false);
              }, 300);
         
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
       }


       function formatNumberWithCommas(number) {
        return number?.toLocaleString('en-IN'); 
      }






  return (
    <div style={{display:'flex',width:"100%",backgroundColor:'white',height:'130%'}}>
    <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
    <div style={{width:isOpen?'80%':'100%',marginLeft:isOpen?"20%":'0%',padding:'10px'}}>
    <Topbar/>



    <p style={{textAlign:"center",fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px'}}>Legal report</p>


    <div style={{ display: 'flex' }}>

<div style={{ height:'30px',width:'15em', borderRadius: '20px',marginRight:"60px",marginTop:'-2px'}}>
<DatePicker
style={{ height:'33px', BorderRadius: '5px',padding: '2px', backgroundRepeat: 'no-repeat',
backgroundPosition: '5px center',
backgroundSize: '15px 15px', backgroundImage: `url(${calender})`, paddingLeft: '30px',fontSize:"14px", border:'1px solid #ccc',fontWeight:"normal", border:'1px solid #ccc'}}
placeholder="Select date range"
allowClear={true}
format="DD MMM YYYY"
onChange={(dates, dateStrings) => {
 console.log("dateStrings:", dateStrings);

   
 setLegalStartDateFrom(dateStrings?.validatedValue[0])
 setLegalEndDateTo(dateStrings?.validatedValue[1])
}}
range
/>
</div>

<div > 
<button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium",paddingLeft:'10px'}}
onClick={() => fetchingclosedData()}
>
Search Results
</button>
</div>

</div>

  {isLoading ? (
                  <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%'}}>
                  <CircularProgress />
                </Box>
                ):(<>
    <table className='tables' style={{marginRight:'10px'}}>
        
  <thead className='table-heading'>
    <tr>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '8px%', fontFamily: "Inter-Medium", paddingLeft: '10px' }}>Loan id</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Cust name</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>inst name</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Mobile</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Loan amount</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '15px%', fontFamily: "Inter-Medium" }}>outstandingAmount</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Last payment date</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Legal date</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Emi paid</th>
      
      
     
    </tr>
  </thead>
  
  
  <tbody className='table-body'>
  {
    legaldata?.map((item, index) => (
       
      <tr className="table-row" key={index} style={{ lineHeight:"25px"}}>
       <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085', paddingLeft: '10px' }}>{item?.legacy_loan_id?item?.legacy_loan_id:'-'}</td>
       <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085', paddingLeft: '10px' }}>{item?.customer_name?item?.customer_name:'-'}</td>
       <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085', paddingLeft: '10px' }}>{item?.inst_name?item?.inst_name:'-'}</td>
       <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085', paddingLeft: '10px' }}>{item?.mobile?item?.mobile:'-'}</td>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>₹{item?.loan_amount ?formatNumberWithCommas(Math.round(item?.loan_amount)):'-'}</td>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>₹{item?.total_principle ?formatNumberWithCommas(Math.round(item?.total_principle)):'-'}</td>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.last_emi_payment_date ? item?.last_emi_payment_date  : '-'}</td>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.updated_at ?item?.updated_at  : '-'}</td> 
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.Emipaid ?item?.Emipaid  : '-'}/{item?.tenure ?item?.tenure  : '-'}</td> 

      </tr> 
    ))}
  </tbody>
</table></>)}

    </div>
    </div>
  )
}
