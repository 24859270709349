import { userConstants } from './../constants/user.constants';

let user = sessionStorage.getItem('user');
const initialState = user ? { user, isLoading: false } : {};

export function getEmiHistory(state = initialState, action) {
  
  console.log("inside emi history function", action)
  switch (action.type) {
    case userConstants.GET_EMIHISTORY_REQUEST:
      return {
        user: action.user,
        isLoading : true

      };
    case userConstants.GET_EMIHISTORY_SUCCESS:
      const {res} = action
        console.log("Action", action)
      return {
        res,
        isLoading : false
      };
    case userConstants.GET_EMIHISTORY_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}