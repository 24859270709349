import { styled } from "@mui/material/styles";


export const Homestyle = styled("div")(({ theme }) => ({
      '& .logo-nav':{
        width:"180px",
        height:"64px",
        marginLeft:"20%",
        marginRight:"auto",
      },
      '& .sideshdow':{
        background:" #FFFFFF",
boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.25)",
      },
      '& .paper-setting':{
        padding:"25px",
        textAlgin:"center",
        height:"100vh",
        
      },
      '& .searchiput':{
        fontFamily:"Inter-Regular !important ",
        fontSize:"16px",
        height:"48px !important ",
        '&::placeholder': {
          fontFamily:"Inter-Regular",
          fontSize:"16px",
         
        },
        '& .MuiOutlinedInput-root': {
          fontFamily:"Inter-Regular !important ",
          color:"#344054",
          height:"48px !important ",
          '& fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
      },
      '& .list-man-nav':{
        padding:"unset",
        '& .MuiListItem-root':{
          padding:"unset",
          width:"unset",
         
        },
      },
      '& .list-top':{
        marginTop:"14px",
        '& .List-main-inner':{
          width:"100%",
      
          display:"inline-flex",
          height:"40px",
          borderRadius:"6px",

        },
        '& .MuiListItem-root':{
          padding:"unset",
          width:"unset",
          paddingLeft:"12px",
        },
        '& .MuiButton-textPrimary':{
          textTransform: "capitalize",
          color:theme.palette.warning.main,
          background:theme.palette.warning.light,
        },
        '& .user-setting':{
          position: "relative",
          top: "3px",
        }

      },


      '& .list-top-2':{
        marginTop:"8px",
        '& .List-main-inner':{
          width:"100%",
          display:"inline-flex",
          height:"40px",
          borderRadius:"6px",

        },
        '& .MuiListItem-root':{
          padding:"unset",
          width:"unset",
          paddingLeft:"12px",
        },
        '& .MuiButton-textPrimary':{
          textTransform: "capitalize",
          color:theme.palette.primary.dark,
        },
        '& .MuiTypography-root':{
            fontFamily:"Inter-Medium"
        },
        '& .user-setting':{
          position: "relative",
          top: "3px",
        }

      },
      '& .nav-card':{
          background:theme.palette.warning.light,
          padding:"15px",
          marginTop:"130px",
      },
      '& .ring-text':{
        marginTop:"10px",
      },
      '& .click-here':{
        color:theme.palette.warning.main,
        borderBottom: "1px solid #D32028 ",
        cursor: "pointer",
      },
      '& .appbar':{
        boxShadow: "none",
        background: "transparent",
        borderBottom: "1px solid #D0D5DD",
        '& .MuiList-root':{
          display:"inline-flex",
        },
        '& .MuiListItem-root':{
          padding:"unset",
        },
        '& .backlft':{
          paddingLeft:"10px",
        },
        '& .MuiButton-outlinedPrimary':{
          fontSize:"14px",
          color:"#667085",
          fontFamily:"Inter-Medium",
          borderRadius:"8px",
          height:"40px",
          textTransform: "capitalize",
          border:"1px solid #667085",
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
          marginTop:"12px",
        },

            
      },
      '& .menuicon':{
        color:"#667085",
      },

      '& .nextbtn':{
        textTransform: "capitalize",
        width:"326px",
        height:" 50px",
        fontSize:"24px",
        fontFamily:"Outfit-Regular",
        borderRadius:"60px",
        textAlgin:"center",
        position:"relative",
        '& .next-arrow':{
          position:" absolute",
          right: "21px",
        },
      },
      '& .chose-file':{
        textTransform: "capitalize",
        width:"153px",
        height:" 44px",
        fontSize:"16px",
        fontFamily:"Outfit-Regular",
        color:theme.palette.primary.dark,
        background:"#F3F3F3",
        border: "1px solid #D0D5DD",
      
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
        "&:hover":{
          color:theme.palette.primary.dark,
          background:"#F3F3F3",
        },
      },
      '& .linkdco':{      
    textDecoration: "none",
      },


//  small down media started

[theme.breakpoints.down('sm')]: {
  '& .chose-file':{
    width:"100%",

  },
},
}));
