/* eslint-disable react/jsx-no-target-blank */
import React,{useState,useRef,useEffect} from 'react';
import {
  Table,
  Modal,
  ModalBody,
  Button,
  ModalHeader,
 Input,Label
} from 'reactstrap';
import * as moment from 'moment'
import * as collections from './collections';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import successful from '../../../assets/images/successful.png'
import attention from '../../../assets/images/attention.png'
import faceMatch from '../../../assets/images/faceMatch.png'
import alertIcon from '../../../assets/images/alertIcon.png'
import accountInfo from '../../../assets/images/accountInfo.png'
import enquiryInfo from '../../../assets/images/enquiryInfo.png'
import address from '../../../assets/images/address.png'
import AccountSum from '../../../assets/images/AccountSummary.png'
import employee from '../../../assets/images/employee.png'
import Enquiries from '../../../assets/images/Enquiries.png'
import customerInfo from '../../../assets/images/customerInfo.png'
import mail from '../../../assets/images/mail.png'
import phone from '../../../assets/images/phone.png'
import ScoringIcon from '../../../assets/images/ScoringIcon.png'
import monthwise from '../../../assets/images/monthwise.png'
import legend from '../../../assets/images/legend.png'
import ckyckImage from '../../../assets/images/ckyckImage.png'
import documentImage from '../../../assets/images/documentSample.png'
import viewBig from '../../../assets/images/viewBig.png'
import frame from '../../../assets/images/Frame.png'
import slider from '../../../assets/images/slider.png'
import cibilIcon from '../../../assets/images/cibilIcon.jpeg'
import bankImg from '../../../assets/images/bankImg.png'
import ViewFile from '../Files/viewFile.js'
import axiosInstance from '../../../helpers/axios.js';
import download from "../../../assets/images/download-button.png"

import {Chart as ChartJs,ArcElement,Tooltip,Legend,CategoryScale,
  LinearScale,
  BarElement,
  Title,
  } from 'chart.js';
import { Doughnut,Bar} from 'react-chartjs-2'
import './index.css'
import axios from 'axios';
ChartJs.register( CategoryScale,
  LinearScale,
  BarElement,
  Title,
   ArcElement,
  Tooltip,
  Legend);

export default function CreditProfile({cibilReport,profileDetails}) {

const ref=useRef(null)
 const optionsBar = {
  plugins: {
    
  },
 
  responsive: true,
  
  borderRadius:8,
  scales: {
    x: {
      stacked: true,
      grid:{
        drawOnChartArea:false
      },

    },
    y: {
      stacked: true,
      min:0,
       
      ticks: {
      stepSize: 1,
      },
    },
  },
};

const labels = cibilReport?.emiGraphCoordinates?.months?.map((item)=>{
  return(
  `${item.substr(0,2)}-${item.substr(5,7)}`
)
}
);

 const dataBar = {
  labels,
  datasets: [
    {
      label: 'On Time',
      data: cibilReport?.emiGraphCoordinates?.paid,
      backgroundColor: '#579AFD',
      barPercentage: 0.9,
      categoryPercentage: 1,
      
    },
    {
      label: 'Delayed',
      data: cibilReport?.emiGraphCoordinates?.unpaid,
      backgroundColor: '#FAA21E',
      barPercentage: 0.9,
      categoryPercentage: 1,
      
    },
   
  ],
};
  
  const [imageOpen, setImageOpen] = useState(false);
  const [showChart, setShowChart] = useState(false);
  
  const [open, setOpen] = useState('1');
  const [cibilOpen, setCibilOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [dob, setDob] = useState('');
  const [panId, setPanId] = useState('');
  const [email, setEmail] = useState('');
  const [currentPincode, setCurrentPincode] = useState('');
  const [currentState, setCurrentState] = useState('');
  const [currentCity, setCurrentCity] = useState('');
  const [currentAddress, setCurrentAddress] = useState('');
  
  console.log("profff",profileDetails)

  const user = sessionStorage.getItem("user")

  const toggle = () => {
    setCibilOpen(!cibilOpen);
    const urlProfile = `/summary/user?id=${profileDetails?.userId}&applicationId=${profileDetails?.applicationId}&status=${sessionStorage.getItem("status")}`;
    axiosInstance.get(urlProfile).then((res) => {
      setEmail(res?.data?.data?.email);
      setDob(res?.data?.data?.dateOfBirth);
      setFirstName(res?.data?.data?.firstName);
      setLastName(res?.data?.data?.lastName);
      setMobile(res?.data?.data?.mobile);
      setCurrentAddress(res?.data?.data?.currentAddress);
      setPanId(res?.data?.data?.panId);
      setCurrentPincode(res?.data?.data?.currentPincode);
      setCurrentCity(res?.data?.data?.currentCity);
      setCurrentState(res?.data?.data?.currentState);
    });
  };
  

 

const data = {
  labels: [],
  datasets: [
    {
      label: 'score',
      data:[cibilReport?.score,1000-cibilReport?.score],
      radius:130,
      backgroundColor: [cibilReport?.score>700?'#12B76A':cibilReport?.score>650&&cibilReport?.score<=700?'yellow':cibilReport?.score>575 &&cibilReport?.score<=650?'orange':'red','white'] ,
      borderColor:[ cibilReport?.score>700?'#12B76A':cibilReport?.score>650&&cibilReport?.score<=700?'yellow':cibilReport?.score>575 &&cibilReport?.score<=650?'orange':'red','#D0D0D0'] ,
      borderWidth: 1,
      circumference:180,
      rotation:270
    },
  ],

};
 
 
 const handleCibilDownload=()=>{

 }
   


  const prev = () => {
    requestAnimationFrame(() => {
      const scrollLeft = ref.current?.scrollLeft;
      const itemWidth = parseInt(
        getComputedStyle(ref.current?.children[0]).width
      );
      ref.current.scrollLeft = scrollLeft - itemWidth * 3;
    });
  };

  const next = () => {
    requestAnimationFrame(() => {
      const scrollLeft = ref.current?.scrollLeft;
      const itemWidth = parseInt(
        getComputedStyle(ref.current?.children[0]).width
      );
      ref.current.scrollLeft = scrollLeft + itemWidth * 3;
    });
  };
  function Balance(amount) {
    
    if (amount === undefined) {
      return " "; 
    }
    amount = parseFloat(amount.toString().replace(/,/g, ''));
      if (amount >= 10000000) {
        return (amount / 10000000).toFixed(2) + " cr";
      } else if (amount >= 100000) {
        return (amount / 100000).toFixed(2) + " L";
      } else {
        return amount?.toLocaleString('en-IN');
      }
    }
    const currentBalance = cibilReport?.currentBalance;
  const formattedCurrentBalance = Balance(currentBalance);
  
  const totalBalance = cibilReport?.totalBalance;
  const formattedTotalBalance = Balance(totalBalance);

  const [modal, setModal] = useState(false);
  const [cibilUrl,setCibilUrl] = useState(null);

  const toggle1 = () => {
    if(cibilReport?.cibilReportLink){
      const url = `/file/url-content?path=${cibilReport?.cibilReportLink}`
      axiosInstance
          .get(url, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          })
          .then((res)=>{
            setCibilUrl(res?.data?.data)
            }).catch((err)=>(console.log(err)))
    }

    setModal(!modal)
  };

  const reTriggerCibil = () =>{
   const cibilReTriggerUrl = `/admin/application/retrigger-cibil`

   const data = {
    applicationId:profileDetails?.applicationId,
    userId:profileDetails?.userId
   }

   axiosInstance.post(cibilReTriggerUrl, data, {
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-type': 'application/json',
    },
  }).then((res)=>{
    console.log("res",res)
  })
  }

  return (
    <>
      <Modal isOpen={cibilOpen}  size="lg" style={{height:'100%'}}>
        <ModalHeader toggle={toggle}>Cibil</ModalHeader>
        <ModalBody >
          <div style={{display:'flex',justifyContent:'space-between'}}>
            <div>
                <Label style={{lineHeight:'1em',fontFamily:'Inter-Medium'}}>
            First Name
            </Label>
            <Input
              placeholder="First Name"
              type="text"
              value={firstName}
            /> 
                </div>
              <div>
              <Label style={{lineHeight:'1em',fontFamily:'Inter-Medium'}}>
            Last Name
            </Label>
            <Input
              placeholder="Last Name"
              type="text"
              value={lastName}
            />    
              </div>
              <div>
                <Label style={{lineHeight:'1em',fontFamily:'Inter-Medium'}}>
            Date Of Birth
            </Label>
            <Input
              placeholder="Date Of Birth"
              type="text"
              value={dob}
            /> 
            </div>
          </div>
          <div style={{display:'flex',justifyContent:'space-between',marginTop:'10px'}}>
            <div>
            <Label style={{lineHeight:'1em',fontFamily:'Inter-Medium'}}>
            Email
            </Label>
            <Input
              placeholder="Email"
              type="email"
              value={email}
            /> 
                </div>
              <div>
              <Label style={{lineHeight:'1em',fontFamily:'Inter-Medium'}}>
            Phone Number
            </Label>
            <Input
              placeholder="Phone Number"
              type="Number"
              value={mobile}
            />    
              </div>
              <div>
                <Label style={{lineHeight:'1em',fontFamily:'Inter-Medium'}}>
            Pan Number
            </Label>
            <Input
              placeholder="Pan Number"
              type="text"
              value={panId}
            /> 
            </div>
          </div>
          <div style={{marginTop:'10px'}}>
            <Label style={{lineHeight:'1em',fontFamily:'Inter-Medium'}}>
              Address
              </Label>
              <Input
                placeholder="Address"
                type="textarea"
                value={currentAddress}
              /> 
          </div>
          <div style={{display:'flex',justifyContent:'space-between',marginTop:'10px'}}>
            <div>
            <Label style={{lineHeight:'1em',fontFamily:'Inter-Medium'}}>
              City
              </Label>
              <Input
                placeholder="City"
                type="text"
                value={currentCity}
              /> 
                  </div>
                <div>
                <Label style={{lineHeight:'1em',fontFamily:'Inter-Medium'}}>
              State
              </Label>
              <Input
                placeholder="State"
                type="text"
                value={currentState}
              />    
                </div>
                <div>
                  <Label style={{lineHeight:'1em',fontFamily:'Inter-Medium'}}>
              Pin Code
              </Label>
              <Input
                placeholder="Pin Code"
                type="text"
                value={currentPincode}
              /> 
            </div>
          </div>
          {/* <div style={{display:'flex',justifyContent:'space-between',marginTop:'10px',fontFamily:'Inter-Medium'}}>Reason For Failure:</div> */}
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',backgroundColor:'#D22129',padding:'10px',color:'white',borderRadius:'10px',height:'3em',width:'7em', margin: '0 auto',marginTop:'30px',fontSize:'16px',cursor:'pointer'}} onClick={()=>reTriggerCibil()}>
          Submit
          </div>
    </ModalBody>
      </Modal>
      
        <div style={{display:'block'}}>
      
        <div style={{display:'flex',justifyContent: 'space-between', }}>
        <Modal isOpen={modal}  size="xl" style={{height:'100%'}}>
        <ModalHeader toggle={toggle1}>Cibil</ModalHeader>
        <ModalBody >
        <iframe
            src={cibilUrl}
            width="100%"
            height="1000vh"
            style={{ border: 'none' }}
          ></iframe>
        </ModalBody>
        </Modal>
    
            <div style={{borderRadius:'1em',width:'80%',margin:'1em',boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit'}}><img style={{marginRight:'0.5em'}} src={bankImg}/>CIBIL Score Ranking</p>
                <p><div onClick={toggle1} style={{borderStyle:'solid',borderRadius:'5px',borderColor:'#f0f0f0',borderWidth:'1px',paddingLeft:'10px',paddingRight:'10px',cursor:'pointer',boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
                  <img style={{cursor:'pointer',height:'1em'}} src={download}/><span style={{fontWeight:'bold',marginLeft:'5px'}}>CIBIL Report</span></div></p>
                </div>
  
              </div>
               <div style={{padding:'1em',paddingLeft:'1em',paddingRight:'1em',marginTop:'2em'}}>
  
              <div style={{display:'block'}}>
                <div style={{display:'flex',justifyContent: 'space-around', }}>
                  <div style={{display:'block',marginTop:'-4em',}}>
                    <p style={{position:'absolute',marginLeft:'3em',marginTop:'2em',textAlign:'center',fontFamily:'Outfit',fontWeight:'bold',fontSize:"19px",}}>{cibilReport?.vintage}</p>
                  <div>
                   <Doughnut redraw={true} options={
                    {
                      responsive:true,
                      maintainAspectRatio:true
                    }
                   }  data={data}/> 
                  </div>
                   <div style={{lineHeight:'1.5em',marginTop:'-5em'}}>
                   <p style={{textAlign:'center',fontSize:'25px',fontWeight:'bold'}}><span style={{fontWeight:'bad',padding:'5px',borderRadius:'5px',}}>{cibilReport?.score}</span></p>
                  </div>
                    
                  </div>
                  <div style={{display:'block',paddingRight:'5%',}}>
                      <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div style={{display:'block',lineHeight:'1em',width:'10em'}}>
                            <p style={{color:'#667085'}}>Accounts</p>
                            <p style={{fontWeight:'bold'}}> {cibilReport?.activeNumberOfAccounts} / {cibilReport?.totalNumberOfAccounts}</p>
                            <p style={{fontSize:'11px',backgroundColor:'#F0F0F0',textAlign:'center',borderRadius:'1em'}}>Active/Total</p>
                        </div>
                        
                      {/* <div style={{display:'block', lineHeight:'1em',width:'8em'}}>
                            <p style={{color:'#667085',marginRight:'2em'}}>Overdue</p>
                            <p style={{fontWeight:'bold'}}>{cibilReport?.overdueAccount}/{cibilReport?.overdueAmount}</p>
                            <p style={{fontSize:'11px',backgroundColor:'#F0F0F0',textAlign:'center',borderRadius:'1em',width:'10em'}}>Count/Amount</p>
                      </div> */}
                        <div style={{display:'block',lineHeight:'1em',width:'10em'}}>
                            <p style={{color:'#667085',textAlign:'center',marginRight:'2em'}}>Balance</p>
                            <p style={{fontWeight:'bold',width:'10em',marginLeft:'2em'}}>{formattedCurrentBalance} / {formattedTotalBalance}</p>
                            <p style={{fontSize:'11px',backgroundColor:'#F0F0F0',marginLeft:'3em',borderRadius:'1em',textAlign:'center'}}>Current/Sanctioned</p>
                      </div>
                   </div>
                  <div style={{display:'block',lineHeight:'1em',width:'10em'}}>
                      <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div style={{display:'block',width:'8em'}}>
                            <p style={{color:'#667085',width:'8em'}}>Repayments P.M</p>
                            <p style={{fontWeight:'bold'}}>{cibilReport?.repaymentsPerMonth}</p>
                        </div>
                         <div style={{display:'block',lineHeight:'1em',marginLeft:'2em',width:'12em'}}>
                            <p style={{color:'#667085',marginLeft:'2em'}}>Deliquencies</p>
                            <p style={{fontWeight:'bold',color:'#D32028',marginLeft:'2em'}}>{cibilReport?.deliquencyCount?cibilReport?.deliquencyCount:'--'} / {cibilReport?.highestOverdueDays?cibilReport?.highestOverdueDays:'--'} / {cibilReport?.overdueCount?cibilReport?.overdueCount:'--'}</p>
                            <p style={{padding:'2px',fontSize:'11px',backgroundColor:'#F0F0F0',textAlign:'center',borderRadius:'1em',width:'12em',marginLeft:'3em'}}>Count / Highest Days / No. of Overdue Accounts</p>
                      </div>
                    
                   </div>
                </div>
                   
                </div>
                </div>
                <div style={{display:'flex',justifyContent: 'space-between',marginLeft:'4%',marginRight:'4%'}}>
                      <div style={{display:'block',lineHeight:'1.5em'}}>
                        <p>Overdue</p>
                        <p style={{textAlign:'center',color:cibilReport?.overdueAccount||cibilReport?.overdueAmount?"#d32028":"#12B76A",fontSize:"18px"}}>{cibilReport?.overdueAmount||cibilReport?.overdueAccount?<span> &#8377;{cibilReport?.overdueAmount?.toLocaleString('en-IN')} / {cibilReport?.overdueAccount}</span>:"NIL"}</p>
                      </div>
                      <div style={{display:'block',lineHeight:'1.5em'}}>
                        <p>Write Offs</p>
                        <p style={{textAlign:'center',color:cibilReport?.writeOffs?"#d32028":"#12B76A",fontSize:"18px"}}> {cibilReport?.writeOffs||cibilReport?.writeOffAmount?<span> &#8377;{cibilReport?.writeOffAmount?.toLocaleString('en-IN')} /  {cibilReport?.writeOffs} </span>:"NIL"}</p>
                      </div>
                      <div style={{display:'block',lineHeight:'1.5em'}}>
                        <p>Settlement</p>
                        <p style={{textAlign:'center',color:cibilReport?.settlementAmount||cibilReport?.settlements?"#d32028":"#12B76A",fontSize:"18px"}}>{cibilReport?.settlementAmount||cibilReport?.settlements?<span> &#8377;{cibilReport?.settlementAmount?.toLocaleString('en-IN')} /  {cibilReport?.settlements} </span> :"NIL"}</p>
                      </div>
                      <div style={{display:'block',lineHeight:'1.5em'}}>
                        <p>Suites</p>
                        <p style={{textAlign:'center',color:cibilReport?.suitAccounts?"#d32028":"#12B76A",fontSize:"18px"}}>{cibilReport?.suitAccounts?`${cibilReport?.suitAccounts}`:"NIL"}</p>
                      </div>
                       
                      
                </div>
  
                
                  
                </div>
               
                </div>
  
            </div>
            <div style={{borderRadius:'1em',width:'50%',margin:'1em',backgroundColor:'#ECF2FF',boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit',fontFamily:'Outfit'}}><img style={{marginRight:'0.5em'}} src={ScoringIcon}/>Scoring Factors <img style={{marginLeft:'5px'}}src={alertIcon}/></p>
                 
                </div>
  
              </div>
               <div style={{padding:'1em'}}>
               
  
                <div style={{display:'flex',justifyContent:'space-between',padding:'1em',overflowY: 'scroll',}}>
                  <div style={{display:'block',height:'20em'}} >
                   <ul >
                   { 
                   cibilReport?.reasonCodes?.map((item)=>{
                   return(
                   <li style={{paddingBottom:'2em',borderBottom:'2px dashed #AEBBD7'}}>{item}</li>
                   )
                  }
                  )
                   }
                    
                    
                  </ul>
                
                </div>
               
                
                </div>
          </div>
  
            </div>
        </div>
        <div style={{display:'flex',justifyContent: 'space-between',}}>
            <div style={{borderRadius:'1em',width:'80%',margin:'1em',boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit',}}><img style={{marginRight:'0.5em'}} src={AccountSum}/>Account Summary</p>
                
                </div>
  
              </div>
               <div style={{padding:'1em',paddingLeft:'2em',paddingRight:'2em',marginTop:'2em'}}>
                
                <div style={{display:'flex',justifyContent:'space-between'}}>
                   
                  <div style={{display:'block',}}>
                      <div style={{display:'flex',justifyContent:'space-between',borderBottom:'2px dashed #D0D0D0'}}>
                        <div style={{display:'block',width:'12em',lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Total Accounts</p>
                            <p style={{fontWeight:'bold',color:'#D32028',fontSize:'30px'}}> {cibilReport?.totalNumberOfAccounts}</p>
                           
                        </div>
                      <div style={{display:'block',width:'12em', lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Overdue Account</p>
                            <p style={{fontWeight:'bold',color:'#D32028',fontSize:'30px'}}>{cibilReport?.overdueAccount}</p>
                           
                      </div>
                      <div style={{display:'block',width:'12em', lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Zero Balance Account</p>
                            <p style={{fontWeight:'bold',color:'#D32028',fontSize:'30px'}}>{cibilReport?.activeNumberOfAccounts||cibilReport?.totalNumberOfAccounts?cibilReport.totalNumberOfAccounts-cibilReport.activeNumberOfAccounts:"--"}</p>
                             
                      </div>
                   </div>
                  <div style={{display:'block',lineHeight:'1em',marginTop:'1em'}}>
                      <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div style={{display:'block',width:'8em'}}>
                            <p style={{color:'#667085',width:'10em'}}>High C.R/Sanc. Amount</p>
                            <p style={{fontWeight:'bold',fontSize:'20px'}}>{cibilReport?.highCreditSanctionedAmount?cibilReport.highCreditSanctionedAmount:"--"}</p>
                        </div>
                      <div style={{display:'block',width:'8em',lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Current Balance</p>
                            <p style={{fontWeight:'bold',fontSize:'20px'}}>&#8377;{cibilReport?.currentBalance}</p>
                            
                      </div>
                      <div style={{display:'block',width:'12em',lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Overdue Balance</p>
                            <p style={{fontWeight:'bold',fontSize:'20px'}}> &#8377;{cibilReport?.overdueAmount?.toLocaleString("en-IN")}</p>
                           
                      </div>
                   </div>
                </div>
                  <div style={{display:'block' ,lineHeight:'1em'}}>
                      <div style={{display:'flex',width:'70%',justifyContent: 'space-between',}}>
                        <div style={{display:'block',width:'10em'}}>
                            <p style={{color:'#667085',width:'10em'}}>Date of Recent Account</p>
                            <p style={{fontWeight:'bold',fontSize:'20px'}}>{cibilReport?.recentAccountDate?moment(cibilReport.recentAccountDate).format('DD MMM YYYY'):"--"}</p>
                        </div>
                      <div style={{display:'block',width:'13em',lineHeight:'1em'}}>
                            <p style={{color:'#667085',width:'10em'}}>Date of Oldest Account</p>
                            <p style={{fontWeight:'bold',fontSize:'20px'}}>{cibilReport?.oldestAccountDate?moment(cibilReport.oldestAccountDate).format('DD MMM YYYY'):"--"}</p>
                           
                      </div>
                   </div>
                </div>
                </div>
                         
  
                
                  
                </div>
               
                </div>
  
            </div>
            <div style={{borderRadius:'1em',width:'50%',margin:'1em', boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit',}}><img style={{marginRight:'0.5em'}} src={Enquiries}/>Enquiries Summary</p>
                 
                </div>
  
              </div>
               <div style={{padding:'2em'}}>
               
  
                 <div style={{display:'block',lineHeight:'1em'}}>
                      <div style={{display:'flex',width:'100%',justifyContent: 'space-between',}}>
                        <div style={{display:'block',width:'8em'}}>
                            <p style={{color:'#667085'}}> Total Enquiries </p>
                            <p style={{fontWeight:'bold',color:'#d32028',fontSize:'30px'}}>{cibilReport?.enquiries?.length}</p>
                        </div>
                      <div style={{display:'block',width:'8em',lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Enquiries <small>( 30 days )</small></p>
                            <p style={{fontWeight:'bold',color:'#d32028',fontSize:'30px'}}>{cibilReport?.enquiriesForLast30Days?cibilReport?.enquiriesForLast30Days:"--"} </p>
                           
                      </div>
                   </div>
               
                
                </div>
                 <div style={{display:'block',lineHeight:'1em',marginTop:'2em'}}>
                      <div style={{display:'flex',width:'100%',justifyContent: 'space-between',}}>
                        <div style={{display:'block',width:'8em'}}>
                            <p style={{color:'#667085'}}>Enquiries <small>( 12 months )</small></p>
                            <p style={{fontWeight:'bold',color:'#d32028',fontSize:'30px'}}>{cibilReport?.enquiriesForLast12Months?cibilReport?.enquiriesForLast12Months:"--"} </p>
                        </div>
                      <div style={{display:'block',width:'8em'}}>
                            <p style={{color:'#667085'}}>Enquiries <small>( 24 months )</small></p>
                            <p style={{fontWeight:'bold',color:'#d32028',fontSize:'30px'}}> {cibilReport?.enquiriesForLast24Months?cibilReport?.enquiriesForLast24Months:"--"} </p>
                           
                      </div>
                   </div>
               
                
                </div>
          </div>
          
  
            </div>
         
         
          
        </div>
        
            <div style={{borderRadius:'1em',width:'99%',margin:'1em', boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit',}}><img style={{marginRight:'0.5em'}} src={customerInfo}/>Customer Information</p>
                 
                </div>
  
              </div>
               <div style={{padding:'1em'}}>
               
  
                <div style={{display:'flex',justifyContent:'space-between',padding:'1em' }}>
                 <div style={{display:'block' ,lineHeight:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                      <div style={{display:'flex',justifyContent: 'space-between',}}>
                        <div style={{display:'block'}}>
                            <p style={{color:'#667085'}}>Name</p>
                            <p style={{fontWeight:'bold',color:'#D32028'}}>{cibilReport?.name}</p>
                        </div>
                      <div style={{display:'block',lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>DOB </p>
                            <p style={{fontWeight:'bold',color:'#D32028'}}>{moment(cibilReport?.dob).format('DD-MMM-YYYY')}</p>
                           
                      </div>
                      <div style={{display:'block',lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Gender</p>
                            <p style={{fontWeight:'bold',color:'#D32028'}}>{cibilReport?.gender}</p>
                           
                      </div>
                      <div style={{display:'block',lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Date</p>
                            <p style={{fontWeight:'bold',color:'#D32028'}}>{cibilReport?.processesDateTime?moment(cibilReport?.processesDateTime?.substr(0,10)).format('DD-MMM-YYYY'):"--"}</p>
                           
                      </div>
                      <div style={{display:'block',lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Time</p>
                            <p style={{fontWeight:'bold',color:'#D32028'}}> {cibilReport?.processesDateTime?cibilReport?.processesDateTime.substr(11,cibilReport?.processesDateTime.length):"--"}</p>
                           
                      </div>
                   </div>
                </div>
                </div>
                 <div style={{display:'block' ,lineHeight:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                    
                        <div class="slides">
  
  
                  
                        { cibilReport?.ids?.map((item)=>{
                          return(
                            <div>
                             
                             <div style={{lineHeight:'1em',margin:'3em'}}>
                            <p style={{marginLeft:'2em'}}>{item.type}</p>
                            <p style={{color:'#D32028',marginLeft:'2em'}}>{item.id}</p>
                            </div>
                           </div>
                          )
                          }) }
                         
                  </div>
              
                </div>
               
              
          </div>
          
  
            </div>
  
            <div style={{display:'flex',justifyContent:'space-between'}}>
              <div style={{borderRadius:'1em',width:'50%',margin:'1em', boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit',}}><img style={{marginRight:'0.5em'}} src={phone}/>Contact Info</p>
                 
                </div>
  
              </div>
               <div style={{padding:'1em',overflowY: 'auto',height:'12em'}}>
               
  
               
                 <div style={{display:'block' ,lineHeight:'1em'}}>
                      <div style={{display:'flex',width:'100%',justifyContent: 'space-between',}}>
                       <div style={{display:'block',lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Mobile Number</p>
                            <p style={{fontWeight:'bold'}}>{cibilReport?cibilReport?.demographicData?cibilReport.demographicData?.telephones[0]:'Null':'Null'}</p>
                        </div>
                        <div style={{display:'block' ,lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Alternate Number 1</p>
                            <p style={{fontWeight:'bold'}}>{cibilReport?cibilReport?.demographicData?cibilReport.demographicData?.telephones[1]:'Null':'Null'} </p>
                        </div>
                        </div>
                      <div style={{display:'flex',width:'100%',justifyContent: 'space-between'}}>
                        <div style={{display:'block',lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Alternate Number 2</p>
                            <p style={{fontWeight:'bold'}}>{cibilReport?cibilReport?.demographicData?cibilReport.demographicData?.telephones[2]:'Null':'Null'}</p>
                        </div>
                        <div style={{display:'block',lineHeight:'1em'}}>
                            <p style={{color:'#667085'}}>Alternate Number 2</p>
                            <p style={{fontWeight:'bold'}}>{cibilReport?cibilReport?.demographicData?cibilReport.demographicData?.telephones[3]:'Null':'Null'}</p>
                        </div>
               
                
                </div>
           
               
                
                </div>
          </div>
          
  
            </div>
              <div style={{borderRadius:'1em',width:'50%',margin:'1em', boxShadow:'0px 0px 2px 2px  #f0f0f0'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #f0f0f0'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit',}}><img style={{marginRight:'0.5em'}} src={mail}/>Mail</p>
                </div>
  
              </div>
               <div style={{padding:'1em',overflowY: 'auto',height:'12em' }}>
               
  
               
                 {cibilReport?.demographicData?.emails.map((item)=>{
                 return(
                 <div style={{display:'block',padding:'10px' ,lineHeight:'1em',borderBottom:'1px dashed #C0C0C0',marginLeft:'1em'}}>
                     <p>{item}</p>
               </div>
                 )
                 })
                }
                 
              
               
                 
               
             
          </div>
          
  
            </div>
         
         
          
            </div>
  
  
          <Accordion defaultExpanded={true} style={{marginTop:'1em',marginBottom:'1em'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><img style={{marginRight:'1em'}}src={monthwise}/><span style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit'}}>Account Vs EMI History</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
               <div style={{display:'flex',justifyContent: 'center',}}>
              <img style={{width:'40em',marginTop:'1em',marginBottom:'2em'}} src={legend}/>
            </div>
            <div style={{display:'block',justifyContent: 'flex-start',}}>
                { cibilReport?.accounts?.map((item)=>{
               return(
             
              <ul style={{listStyle:'none',display:'flex',flexDirection: 'row',borderBottom:'1px dashed #D0D0D0',paddingLeft:'0px',paddingBottom:'1em',paddingTop:'1em',lineHeight:'1em'}}>
             
                <li title={item?.dateClosed?"Closed":"Active"}style={{width:'8em',borderRadius:'15px',textAlign:'center',padding:'4px',backgroundColor:item?.dateClosed?"#898989":"#ECFDF3",color:item?.dateClosed?"white":"#12B76A"}}>{item.accountType}</li>
                {item.paymentHistory1.map((item)=>{
                  
                   if(item.dpd===null )
                  {
                    return (
                    <collections.TooltipCredit title={`No Record`} placement="top" arrow>
                     <li  style={{backgroundColor:'#D0D0D0',width:'8px',borderRadius:'15em',marginLeft:'1%',height:'8px'}}></li>
                    </collections.TooltipCredit>
                   
                    )
                  }
                   else if(parseInt(item.dpd)===0 ||item.dpd==="XXX" || item.dpd==="STD")
                  {
  
                    return (
                       <collections.TooltipCredit title={`No Delay ,  ${item.month}`} placement="top" arrow>
                     <li  style={{backgroundColor:'#12B76A',width:'8px',borderRadius:'15em',marginLeft:'1%',height:'8px'}}></li>
                    </collections.TooltipCredit>
                    
                    )
                  }
                  else if((parseInt(item.dpd)>30)||item.dpd==="SMA"|| item.dpd==="SUB"|| item.dpd==="LSS")
                  {
                    return (
                      <collections.TooltipCredit title={item.dpd==="SMA"||item.dpd==="SUB"||item.dpd==="LSS"?`${item.month}, ${item.dpd}`:`${item.month},${parseInt(item.dpd)} days delay`} placement="top" arrow>
                     <li style={{backgroundColor:'red',width:'1vw',borderRadius:'15em',marginTop:'-0.2em',marginLeft:'1%',height:'1vw'}}></li>
                    </collections.TooltipCredit>
                   
                    )
                  }
                  else if(parseInt(item.dpd)<=30 && parseInt(item.dpd)>0)
                  {
                    return (
                      <collections.TooltipCredit title={`${item.month},${parseInt(item.dpd)} days delay`} placement="top" arrow>
                    <li  style={{backgroundColor:'orange',width:'12px',borderRadius:'15em',marginLeft:'1%',height:'12px'}}></li>
                    </collections.TooltipCredit>
                    )
                  }
                }
                )}
                {item.paymentHistory2.map((item)=>{
                   if(item.dpd===null  )
                  {
                    return (
                        <collections.TooltipCredit title={`No Record`} placement="top" arrow>
                          <li   style={{backgroundColor:'#D0D0D0',width:'8px',borderRadius:'15em',border:'1px solid #C0C0C0',marginLeft:'1%',height:'8px'}}></li>
                      </collections.TooltipCredit>
                  
                    )
                  }
                   else if(parseInt(item.dpd)===0 ||item.dpd==="XXX" || item.dpd==="STD")
                  {
  
                    return (
                      <collections.TooltipCredit title={`No Delay ,  ${item.month}`} placement="top" arrow>
                        <li  style={{backgroundColor:'#12B76A',width:'8px',borderRadius:'15em',marginLeft:'1%',height:'8px'}}></li>
                      </collections.TooltipCredit>
                    
                    )
                  }
                  else if((parseInt(item.dpd)>30)||item.dpd==="SMA"|| item.dpd==="SUB"|| item.dpd==="LSS"|| item.dpd==="DBT")
                  {
                    return (
                      <collections.TooltipCredit title={item.dpd==="SMA"||item.dpd==="SUB"||item.dpd==="LSS"||item.dpd==="DBT"?`${item.month}, ${item.dpd}`:`${item.month},${parseInt(item.dpd)} days delay`} placement="top" arrow>
                         <li style={{backgroundColor:'red',width:'1vw',borderRadius:'15em',marginTop:'-0.2em',marginLeft:'1%',height:'1vw'}}></li>
                      </collections.TooltipCredit>
                   
                    )
                  }
                  else if((parseInt(item.dpd)<=30 && parseInt(item.dpd)>0 ))
                  {
                    return (
                      <collections.TooltipCredit title={`${item.month},${parseInt(item.dpd)} days delay`} placement="top" arrow>
                        <li  style={{backgroundColor:'orange',width:'12px',borderRadius:'15em',marginLeft:'1%',height:'12px'}}></li>
                      </collections.TooltipCredit>
                    
                    )
                  }
                }
                )}
              </ul>
                 
               )
               }
               )
               }
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>      
      
       <Accordion style={{marginTop:'1em',marginBottom:'1em'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><img style={{width:'1.8em',height:'1.8em',marginRight:'1em'}} src={address}/><span style={{fontWeight:'bold',fontSize:'18px'}}>Address</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
             <div style={{ marginTop:'9em',marginLeft:'1em',position:'absolute'}}>
              <img onClick={()=>prev()} className="sliderBack" src={slider}/>
            </div>
               <div ref={ref}  style={{marginLeft:'2%',scrollBehavior:'smooth',padding:'1em',overflowX: 'hidden',display:'flex',whiteSpace:'nowrap',width:'94%'}}>
                
                {
                cibilReport?.demographicData?.addresses.map((item)=>{
                  return(
                <div style={{backgroundColor:'#fff5f5',borderRadius:'1em',width:'26em',margin:'1em', boxShadow:'0px 0px 2px 2px  #F9D8D6'}}>
              <div style={{display:'flex',justifyContent:'space-around',}}>
                <div style={{display:'flex',justifyContent: 'space-between',padding:'1em',width:'100%',borderBottom:'1px solid #F9D8D6'}}>
                <p style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit',color:'#D32028'}}>{item.category}</p>
                 
                </div>
  
              </div>
              
               <div style={{padding:'1em',height:'12em'}}>
              
                 <div style={{display:'block', borderBottom:'2px dashed #F9D8D6'}}>
                      <div style={{display:'flex',width:'100%',}}>
                        
                            <p style={{color:'#667085',width:'25em' ,textWrap:'wrap',overflowY: 'scroll',height:'4em',}}>{item.area} , {item.state}</p>
                            
                         
                      
                   </div>
               
                
                </div>
                 <div style={{display:'block' }}>
                      <div style={{display:'flex',width:'100%',justifyContent: 'space-between',marginTop:'0.5em'}}>
                        <div style={{display:'block',lineHeight:'1.5em'}}>
                            <p style={{color:'#667085'}}>Residence Code</p>
                            <p style={{fontWeight:'bold',color:'#D32028'}}>{item.residenceType}</p>
                        </div>
                      <div style={{display:'block',lineHeight:'1.5em'}}>
                            <p style={{color:'#667085',}}>Date Reported</p>
                            <p style={{fontWeight:'bold',color:'#D32028'}}>{item.reportedOn}</p>
                           
                      </div>
                   </div>
               
                
                </div>
          </div>
             
        </div>
                  )
                }) }
  
        </div>
  
            <div style={{ marginTop:'-13em',position:'absolute',right:'2em',}}>
              <img onClick={()=>next()} style={{cursor:'pointer',}} src={slider}/>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
         
      <Accordion style={{marginTop:'1em',marginBottom:'1em'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><img style={{marginRight:'1em'}} src={employee}/><span style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit'}}>Employment Info</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
              <Table>
              <thead style={{backgroundColor:'#f0f0f0', }} >
                <th style={{textAlign:'center'}} >Account Type </th>
                <th>Date Reported</th>
                <th>Income</th>
                <th>Occupation</th>
                <th>Net/Gross Income Indicator</th>
                <th>Monthly/Annual Income Indicator</th>
              </thead>
              <tbody >
           
                 <tr >
                  <td style={{textAlign:'center'}}>{cibilReport?.demographicData?.accountType}</td>
                  <td>{cibilReport?.demographicData?.dateReported?moment(cibilReport?.demographicData?.dateReported).format("DD MMM YYYY"):"--"}</td>
                  <td>{cibilReport?.demographicData?.income?cibilReport?.demographicData?.income:"--"}</td>
                  <td>{cibilReport?.demographicData?.employment?cibilReport?.demographicData?.employment:"--"}</td>
                  <td>NA</td>
                  <td>NA</td>
                </tr>
              
              </tbody>
            </Table>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{marginTop:'1em',marginBottom:'1em'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><img style={{marginRight:'1em'}}src={accountInfo}/><span style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit'}}>Account Info</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
             <div style={{overflowX: 'scroll',whiteSpace:'nowrap'}}>
             <Table  >
              <thead style={{backgroundColor:'#f0f0f0',}} >
                <th style={{textAlign:'center',padding:'1em'}} >Account Type </th>
                <th style={{textAlign:'center',padding:'1em'}}>Ownership</th>
                <th style={{textAlign:'center',padding:'1em'}}>Collateral Type</th>
                <th style={{textAlign:'center',padding:'1em'}}>Sanc. Amount</th>
                <th style={{textAlign:'center',padding:'1em'}}>Current Balance</th>
                <th style={{textAlign:'center',padding:'1em'}}>Overdue</th>
                <th style={{textAlign:'center',padding:'1em'}}>EMI</th>
                <th style={{textAlign:'center',padding:'1em'}}>PMT Freq.</th>
                <th style={{textAlign:'center',padding:'1em'}}>R.PMT Tenure</th>
                <th style={{textAlign:'center',padding:'1em'}}>Interest Rate</th>
                <th style={{textAlign:'center',padding:'1em'}}>Actual Payment</th>
                <th style={{textAlign:'center',padding:'1em'}}>High Credit</th>
                <th style={{textAlign:'center',padding:'1em'}}>Credit Limit</th>
                <th style={{textAlign:'center',padding:'1em'}}>Cash Limit</th>
                <th style={{textAlign:'center',padding:'1em'}}>Suites</th>
                <th style={{textAlign:'center',padding:'1em'}}>Remarks</th>
              </thead>
              <tbody >
              
                  {  cibilReport?.accounts?.map((item)=>{
              return(
                 <tr >
                  <td title={item?.dateClosed?"Closed":"Active"}style={{textAlign:'center',backgroundColor:item?.dateClosed?"#898989":"#ECFDF3",color:item?.dateClosed?"white":"#12B76A"}}>{item.accountType}</td>
                  <td style={{textAlign:'center'}}>{item?.ownershipType}</td>
                  <td style={{textAlign:'center'}}>{item?.collateralType?item?.collateralType:"NA"}</td>
                  <td style={{textAlign:'center'}}>{item?.sanctionedAmount}</td>
                  <td style={{textAlign:'center'}}>{item?.currentBalance}</td>
                  <td style={{textAlign:'center'}}>{item?.overdue?item?.overdue:"--"}</td>
                  <td style={{textAlign:'center'}}>{item?.emi?item?.emi:"--"}</td>
                  <td style={{textAlign:'center'}}>{item?.paymentFrequency?item?.paymentFrequency:"--"}</td>
                  <td style={{textAlign:'center'}}>{item?.repaymentTenure?item?.repaymentTenure:"--"}</td>
                  <td style={{textAlign:'center'}}>{item?.rateOfInterest?item?.rateOfInterest:"--"}</td>
                  <td style={{textAlign:'center'}}>{item?.actualPayment?item?.actualPayment:"--"}</td>
                  <td style={{textAlign:'center'}}>{item?.highCredit?item?.highCredit:"--"}</td>
                  <td style={{textAlign:'center'}}>{item?.creditLimit?item?.creditLimit:"--"}</td>
                  <td style={{textAlign:'center'}}>{item?.cashLimit?item?.cashLimit:"--"}</td>
                  <td style={{textAlign:'center'}}>{item?.suitFiledOrWilfulDefault?item?.suitFiledOrWilfulDefault:"--"}</td>
                  <td style={{textAlign:'center'}}>{item?.creditFacilityStatus?item?.creditFacilityStatus:"--"}</td>
                  
                </tr>
              )
              }
              )
             }
              
              </tbody>
            </Table>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{marginTop:'1em',marginBottom:'1em'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><img style={{marginRight:'1em'}}src={monthwise}/><span style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit'}}>Month-Wise EMI History ( last 3 years)</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography >
            <div className="wrapper">
                <div className="barWrapper">
                  <Bar className="bar" redraw={true} options={optionsBar} data={dataBar} />
                </div>
            </div>
           
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{marginTop:'1em',marginBottom:'1em'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><img style={{marginRight:'1em'}}src={enquiryInfo}/><span style={{fontSize:'18px',fontWeight:'bold',fontFamily:'Outfit'}}>Enquiry Info</span></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
               <Table bordered >
              <thead style={{backgroundColor:'#f0f0f0',}} >
                <th style={{textAlign:'center',padding:'1em'}} >Member </th>
                <th style={{textAlign:'center',padding:'1em'}}>Enquiry Date</th>
                <th style={{textAlign:'center',padding:'1em'}}>Enquiry Purpose</th>
                <th style={{textAlign:'center',padding:'1em'}}>Enquiry Amount</th>
               
              </thead>
              <tbody >
               {cibilReport?.enquiries?.map((item)=>{
                return(
                  <tr >
                  <td style={{textAlign:'center',padding:'1em'}}>{item.member}</td>
                  <td style={{textAlign:'center',padding:'1em'}}>{item.date}</td>
                  <td style={{textAlign:'center',padding:'1em'}}>{item.purpose}</td>
                  <td style={{textAlign:'center',padding:'1em'}}>{item.amount}</td>
                </tr>
                )
               }) }
              </tbody>
            </Table>
          </Typography>
        </AccordionDetails>
      </Accordion>
        </div>
      

    
    </>
  )
}