 
import {React,useState,useEffect} from 'react';
import Sidebar from '../Sidebar';
 
import Topbar from '../Topbar';
import {useLocation} from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux'
import {userActions} from '../../actions/user.actions';
import axiosInstance from '../../helpers/axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import user from '../../assets/images/user.png'
  import {
    Col,
    Table,
    Card,
    Form,
    Button,
    Input,
    CardBody,
    CardTitle,
    CardSubtitle,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from 'reactstrap';
  import jwt_decode from 'jwt-decode'
 

import submitActivity from '../../assets/images/submitActivity.png'
import send from '../../assets/images/send.png'
import './index.css'
export default function Activity() {
  const location=useLocation();
  const currentUser=sessionStorage.getItem('user');
  const dispatch=useDispatch()
  const decode=jwt_decode(currentUser)
  const getActivityState=useSelector((state)=>state?.ACTIVITY?.activityData)
  const [activityDetails,setActivityDetails]=useState(null)
  const urlAddActivity=`/audit`
  const getActivityUrl=`/audit?applicationId=${sessionStorage.getItem('activityAppId')}`

  useEffect(() => {
     const ref = document.getElementById('chatcontent');
      setTimeout(()=> {
        console.log("scroll")
        ref.scrollTop = ref.scrollHeight;
      }, 100);
      console.log(getActivityState)
      if(getActivityState?.res?.data?.data.length>0){
        setActivityDetails(getActivityState?.res?.data?.data)
      }
      else{
        dispatch(userActions.getActivity(getActivityUrl,currentUser))
      }
  }, [getActivityState])
  

  const [activityDescrition,setActivityDescription]=useState(null)


  async function handleSubmitActivity(){
    setActivityDescription(null)
    document.getElementById('descriptionBox').value=null
    const activityBody={
        "userId": sessionStorage.getItem('activityUserId'),
        "status": "Notes Created",
        "notes": activityDescrition,
        "applicationId": sessionStorage.getItem('activityAppId')
    }
     await dispatch(userActions.submitActivity(urlAddActivity,activityBody,currentUser))
     
  }
  const [isOpen,setIsOpen]=useState(true)
   
  return (
     <>
  
   <Card className="card" style={{width:"100%",height:'100%'}} >
 
          <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
          <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%',padding:'10px',height:'100vh'}}>
          <Topbar/>
          
            <div className="chatContainer">
            
              <div id="chatcontent" style={{overflowY: 'scroll',height:'85%'}}>
           {
            
          activityDetails?.map((item)=>{

                if(decode?.email!==item.doneBy)
                {

                  return(
                    <div className="receiver">
            
                <p style={{marginTop:'-4em',  }} ><img src={user} style={{marginRight:'1em'}}/><span style={{fontWeight:'bold',fontSize:'18px',marginRight:'1em'}} >{item.doneBy}</span><span style={{fontWeight:'bold',fontSize:'18px',marginRight:'1em',color:'#989898'}} >{item.createdAt} </span></p>
                <p><span style={{fontWeight:'bold'}}>Status :</span> {item.status}</p>
                  {item.notes}
                </div>
                  )
                
                }
                else{
                  
                  return(

                  <div className="sender">
                    <p style={{marginTop:'-4em',right:'4em'}} ><img src={user} style={{marginRight:'1em'}}/><span style={{fontWeight:'bold',fontSize:'18px',marginRight:'1em'}} >{item.doneBy}</span><span style={{fontWeight:'bold',fontSize:'18px',marginRight:'1em',color:'#989898'}} >{item.createdAt} </span></p>
                  <p><span style={{fontWeight:'bold'}}>Status : </span> {item.status}</p>

                   {item.notes}
                 </div>
                  )
                   
                  }
            })
            
          }
                   
                   </div>
          
           
          <div style={{borderRadius:'12px' ,marginTop:'1em',border:'1px solid #E7BABC',backgroundColor:'#FFEDEE',padding:'1em',display:'flex',justifyContent: 'space-between',}}>
            <Input id="descriptionBox" style={{width:'83%'}} onChange={(e)=>setActivityDescription(e.target.value)} type='text' placeholder="Log Activity Here"/>
            <div onClick={()=>handleSubmitActivity()} style={{cursor:'pointer'}} >
              <img style={{position:'absolute',padding:'0.8em'}} src={send}/>
              <img src={submitActivity}/>
            </div>
          </div>
    </div>
         </div>
  </Card>
  </>
    
  )
}
