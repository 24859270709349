import { userConstants } from './../constants/user.constants';

let user = sessionStorage.getItem('user');
const initialState = user ? { user, isLoading: false } : {};

export function Inprogress(state = initialState, action) {
  
  console.log("inside Inprogress function", action)
  switch (action.type) {
    case userConstants.INPROGRESS_REQUEST:
      return {
        
        user: action.user,
        isLoading : true

      };
      
    case userConstants.INPROGRESS_SUCCESS:
      const {res} = action
        console.log("Action", action)
      return {
        res,
        
        isLoading : false

      };
    case userConstants.INPROGRESS_FAILURE:
      return {
        
        isLoading : false
      };
    default:
      return {
        state
      }
  }
}