import { createTheme } from '@mui/material/styles';
import './index.css'

const theme = createTheme({
  palette: {
    primary: {
      main: '#D9480F',
      dark:"#344054",
      light:"#D0D5DD",
      contrastText:"#101828",
   
    },
    secondary:{
      main:"#667085",
    
    },
    warning:{
      main:"#D32028",
      light:"#FFEDEE",
    },
    grey:{
      main:"#E9E9E9",
    },
    
    error: {
      main: "#FF0000",
    },
  },

});


// theme.typography.h1 = {
//   fontSize: "104px",
//   fontFamily:"Gilroy-Bold",
//   lineHeight:"100%",
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '30px',
//   },
// };
// theme.typography.h2 = {
//   fontSize: "56px",
//   fontFamily:"Gilroy-Bold",
//   lineHeight:"64px",
//   [theme.breakpoints.down('sm')]: {
//     fontSize: '30px',
//   },
// };
// theme.typography.h3 = {
//   fontSize: "40px",
//   fontFamily:"Gilroy-Bold",
//   lineHeight:"44px",
//   [theme.breakpoints.down('xs')]: {
//     fontSize: '20px',
//   },
// };
theme.typography.h4 = {
  fontSize: "30px",
  fontFamily:"Inter-Medium",
  lineHeight:"24px",
  // [theme.breakpoints.down('md')]: {
  //   fontSize: '2.4rem',
  // },
};
theme.typography.h5 = {
  fontSize: "26px",
  fontFamily:"Inter-Bold",
  lineHeight:"38px",
  // [theme.breakpoints.down('md')]: {
  //   fontSize: '2.4rem',
  // },
};
theme.typography.h6 = {
  fontSize: "24px",
  fontFamily:"Inter-Bold",
  lineHeight:"24px",
  // [theme.breakpoints.down('md')]: {
  //   fontSize: '2.4rem',
  // },
};
theme.typography.body1 = {
  fontSize: "16px",
  fontFamily:"Inter-Bold",
  lineHeight:"40px",
  // [theme.breakpoints.down('md')]: {
  //   fontSize: '2.4rem',
  // },
};
theme.typography.body2 = {
  fontSize: "14px",
  fontFamily:"Inter-Medium",
  lineHeight:"22px",
  color:theme.palette.primary.contrastText,
  // [theme.breakpoints.down('md')]: {
  //   fontSize: '2.4rem',
  // },
};




export default theme;
