import React,{useRef,useEffect} from 'react';
import {useDispatch,useSelector} from 'react-redux';
import {userActions} from '../../../actions/user.actions'
import 'moment-timezone';
import moment from 'moment';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
 import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import createpayment from '../../../assets/images/createmanual.png'
import file_upload from '../../../assets/images/file_upload.png'
import './index.css'
import close from "../../../assets/images/close.png"
 
const style = {
   
   
  justifyContent: 'center',
  width: 500,
  bgcolor: 'background.paper',
  height:'auto',
  overflowY: 'scroll',
  boxShadow: 20,
  borderRadius:5,
  marginTop:'2em',
  marginBottom:'2em'
   
  
};



export default function ManualPayment({totalEmiAmount,selectedItems,chequeBounceCharges,overdueCharges}) {
  const [open, setOpen] = React.useState(false);
  const user=sessionStorage.getItem('user')
  const uploadState=useSelector((state)=>state?.uploadFile?.res?.data?.data)
  
  React.useEffect(() => {
    
    if(uploadState)
    {
      setProof(uploadState?.s3Url)
    }
  
    
  }, [uploadState])
  
  const handleOpenDialog=()=>{
      setOpen(true)
      
      
  }
  
    const dispatch=useDispatch();

  const uploadRef=useRef()
  const openProofUpload=()=>{
  uploadRef.current.click()
  
}

const [valueRadio, setValueRadio] = React.useState('A/C Transfer');

  const handleChange = (event) => {
    setValueRadio(event.target.value);
  };
const [proof,setProof]=React.useState(null)

const handleProofUpload=(event)=>{
   
 const body=new FormData();
 body.append("file",event.target.files[0]);
 body.append("type",'transaction_proof');
     
  
  const urlUpload='/file/upload'
  
  dispatch(userActions.uploadFile(urlUpload,body))
  


}

 
const [transactionId,setTransactionId]=React.useState(null)
const [receivedAmount,setReceivedAmount]=React.useState(null)
const [adjustmentAmount,setAdjustmentAmount]=React.useState(null)
const [selectedDate,setSelectedDate]=React.useState(null)
const [reasonCode,setReasonCode]=React.useState(null)

const handleClose = () => {
  setOpen(false);
  setReceivedAmount(null);
  setAdjustmentAmount(null);
  setTransactionId(null);
  setSelectedDate(null);
  setReasonCode(null);
  setValueRadio('A/C Transfer');
  setProof(null);
};
    const formattedDate = selectedDate?.toLocaleString('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    });
var date = new Date(formattedDate);
var year = date.getFullYear();
var month = ('0' + (date.getMonth() + 1)).slice(-2); 
var day = ('0' + date.getDate()).slice(-2);

var date = year + '-' + month + '-' + day;
console.log("data",date)
useEffect(() => {
  const adjustment = totalEmiAmount - (receivedAmount || 0);
  setAdjustmentAmount(adjustment);
}, [receivedAmount, totalEmiAmount]);

const generateReceipt=()=>{
  const urlGenerateReceipt='/loan-repayment/manual-repayment'
  const body={
    loanId: sessionStorage.getItem('loanid'),
    emiIds: selectedItems,
    transactionId: transactionId?.trim(),
    transactionProofUrl: proof,
    repaymentId: "string",
    paymentAmount: totalEmiAmount,
    paymentMode: valueRadio==="A/C Transfer"?3:valueRadio==="UPI"?2:1,
    paidAt: date ,
    manualPaymentReason:reasonCode,
    discountAmount: adjustmentAmount,
    overdueCharges: 0,
    checkBounceCharges: valueRadio==="Cheque"?chequeBounceCharges:0
  }
  console.log("data",body)
  dispatch(userActions.generateManualPaymentReceipt(urlGenerateReceipt,user,body))
}
const isSpanClickable = selectedItems.length>=1;
  return (
    <>
         <span onClick={isSpanClickable ? () => handleOpenDialog() : null} style={{cursor: isSpanClickable ? 'pointer' : 'not-allowed',border:'1px solid #D0D0D0',borderRadius: '10px', paddingRight:'10px',boxShadow:'0px 0px 1px 1px #F0F0F0',fontWeight:'bold',color:'#344054'}}>
           <img src={createpayment}  style={{paddingLeft:'15px'}}/> Create Manual Payment
        </span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{display:'flex',justifyContent:'center'}}
      >
        <Box sx={style}>
          <Typography style={{borderTopLeftRadius:'8px',borderTopRightRadius:'8px',backgroundColor:'#F0F0F0' ,borderBottom:'1px solid #D0D0D0'}}      >
           <p style={{ textAlign:'center',fontSize:'20px',paddingTop:'10px',fontWeight:'bold'}}> Manual Payment Update <img src={close} style={{marginLeft:'35%',cursor:'pointer'}} onClick={() => {setOpen(!open)}}/></p>
          
          </Typography>
         <div style={{padding:'1em'}}>
          <div style={{display:'block'}}>
          <p style={{fontWeight:'bold'}}>Total EMI Amount</p>
          <input disabled value={totalEmiAmount} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}} type="number" placeholder="&#8377;"/>
          </div>
          <div style={{display:'block' ,marginTop:'15px'}}>
          <p style={{fontWeight:'bold'}}>Category</p>
          <select onChange={(e)=>setReasonCode(e.target.value)}   style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}}  >
            <option value="1">Preclosure</option>
            <option value="2">Overdue</option>
            <option value="3">Single EMI payment</option>
            <option value="4">Multiple EMIs</option>
            <option value="5">Settlement</option>
            <option value="6">Refund By Institute</option>
          </select>
        
          </div>
          <div style={{display:'block',marginTop:'1em',marginBottom:'1em'}}>
           
        
          <RadioGroup  
          style={{display:'flex',flexDirection: 'row',justifyContent: 'space-between',}}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={valueRadio}
              onChange={handleChange}
            >
              <FormControlLabel value="A/C Transfer" control={<Radio />} label="A/C Transfer" />
              <FormControlLabel value="Cheque" control={<Radio />} label="Cheque" />
              <FormControlLabel value="UPI" control={<Radio />} label="UPI" />
           </RadioGroup>
           {valueRadio==="Cheque"?<div style={{ marginTop:'10px',display:'block'}}>
          <p style={{fontWeight:'bold'}}>Cheque Bounce Charges</p>
          <input disabled value={chequeBounceCharges} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}} type="number" placeholder="&#8377;"/>
          </div>:null}
            <div style={{marginTop:'1em'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                            components={[
                            'DesktopDateTimePicker',
                            ]}
                        >
                            <DemoItem label="Paid At ( Date and Time )" >
                            <DesktopDateTimePicker value={selectedDate} onChange={(newDate) => setSelectedDate(newDate)}/>
                            </DemoItem>
                        </DemoContainer>
          </LocalizationProvider>
          </div>
        
          </div>
          <div style={{display:'block'}}>
          <p style={{fontWeight:'bold'}}>Transaction Id / Cheque No.</p>
          <input  onChange={(e)=>setTransactionId(e.target.value)} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}} type="text"/>
          </div>
          <div style={{display:'block'}}>
          <p style={{fontWeight:'bold'}}>Upload Proof</p>
          <img onClick={()=>openProofUpload()}style={{cursor:'pointer',width:'100%'}} src={file_upload}/>
          <input ref={uploadRef} onChange={(e)=>handleProofUpload(e)} style={{visibility:'hidden'}} type="file" />
          <p style={{textAlign:'center',backgroundColor:'#FFCCCB',boxShadow:'0px 0px 2px 2px #F0F0F0'}}>{proof?<u>{proof.name}</u>:""}</p>
          </div>
          <div style={{display:'block'}}>
          <p  style={{fontWeight:'bold'}}>Received Amount</p>
          <input  onChange={(e)=>setReceivedAmount(e.target.value)} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}} type="number" placeholder="&#8377;"/>
          </div>
          <div style={{display:'block'}}>
          <p  style={{fontWeight:'bold'}}>Adjustment Amount</p>
          <input value={adjustmentAmount}  onChange={(e)=>setAdjustmentAmount(e.target.value)} style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'10px',padding:'8px'}} type="number" placeholder="&#8377;"/>
          </div>
          
        <div style={{display:'flex', justifyContent: 'center',backgroundColor:'#FFF2EA',marginTop:'1em'}}>
          <div>
            <p style={{fontFamily:'Outfit',color:'#d32028',textAlign:'center'}}>Adjustment Amount</p>
            <p style={{fontFamily:'Outfit',color:'#0D9857',textAlign:'center'}}>  {adjustmentAmount?`+ ${adjustmentAmount}`:null}</p>
            <p style={{fontSize:'15px',fontFamily:'Outfit', textAlign:'center'}}>( Will be adjusted in the next EMI )</p>

            <Button onClick={()=>generateReceipt()} style={{width:'100%',borderRadius:'1em',backgroundColor:'#D32028',color:'white',marginBottom:'1em'}}>Submit</Button>
          </div>
          
        </div>
        </div>
        </Box>
      </Modal>
    </>
  );
}