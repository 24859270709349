import {
  Col,
  Table,
  Card,
  Form,
  Button,
  Input,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import axios from 'axios';
import axiosInstance from '../../helpers/axios';
import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode'
//import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { useNavigate } from 'react-router-dom';
import EditForm from '../status/EditForm/EditForm';
//import MoveStatus from './MoveStatus';
import PaginationTable from '../status/PaginationTable';
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import Sort from "../../assets/images/sort.png";
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import './UserSearch.css'
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'

import {userActions} from '../../actions/user.actions';

// eslint-disable-next-line import/extensions
//import ComponentCard from '../ComponentCard';

//require('moment/locale/fr');
//require('moment/locale/de');
//require('moment/locale/en-gb');

const SearchTable = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const ApplicationStatusEnum = {
  InSubmission: 'In Submission',
  InReview: 'In Review',
  InSanction: 'In Sanction',
  InDisbursement: 'In Disbursement',
  PendingDisbursement: "Pending Disbursement",
  Disbursed: 'Disbursed',
  Rejected: 'Rejected',
  Dropped: 'Dropped',
  OnHold:'On Hold', ReadyToDisburse:'Ready to Disburse',
};
  const [status, setStatus] = useState('');
  const user = sessionStorage.getItem("user")
  //filter

  const [searchUser, setSearchUser] = useState("");
  const [filterInstituteName, setFilterInstituteName] = useState('');
  const [filterDateFrom, setFilterDateFrom] = useState('null');
  const [filterDateTo, setFilterDateTo] = useState(null);
  const valid = (current) => {
    return current.isAfter(filterDateFrom);
  };

  //table
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [userProfileId, setUserProfileId] = useState(null);
  
  //pagination
  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [totalFetchRows, setTotalFetchRows] = useState(null);

   //sorting
   const decode=jwt_decode(user)

  const [sort,setSort]=useState(-1)
  //onclick Search

  const handleUserApplication=(applItem)=>{
    console.log("appl",applItem)

 setTableData([])
    if(applItem.status==="Pending Disbursement")
    {
      
            const pendignUsers = `/disbursement/payouts?applicationId=${applItem.applicationId}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
            const path=`/status/Pending`
        dispatch(userActions.userSearch(pendignUsers,navigate,applItem.status, user),[])
        
    }
    else if(applItem.status==='In Sanction')
    {
        const sanctionUrl=`/integrations/applications?applicationId=${applItem.applicationId}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
        const path=`/status/Sanction`
        dispatch(userActions.userSearch(sanctionUrl,navigate,applItem.status, user),[])
    }
    else if(applItem.status==="Ready to Disburse")
    {
    
         const readyToDisburseUrl=`/integrations/applications?status=Ready%20to%20Disburse&applicationId=${applItem.applicationId}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
         const path=`/status/Disbursement`
         dispatch(userActions.userSearch(readyToDisburseUrl,navigate,applItem.status, user),[])

    }

    else if(applItem.status==="In Process")
    {
         const urlUsers = `/admin/application/in-progress?applicationId=${applItem.applicationId}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
         const path=`/status/Process`
         dispatch(userActions.userSearch(urlUsers,navigate,applItem.status, user),[])
        
    }
    else
    {

      const getAppUrl=`${process.env.REACT_APP_BASE_URL}/admin/application/id?applicationId=${applItem.applicationId}`;
      
      dispatch(userActions.userSearchdefault(getAppUrl,applItem.status,navigate,user))
      
    }
  }
  const handleInstituteApplication=(item)=>{
    console.log("clicked",item.status)
    if(item.status === "In Process" || item.status === "In Submission" || item.status === "In Review" || item.status === "In Sanction" || item.status === "In Disbursement" || item.status === "Ready to Disburse"){
      const urlUsers = `/admin/application/institute-search?applicationId=${item.applicationId}`;

      dispatch(userActions.userSearchInst(urlUsers,item.status,navigate,user))
    }
    else if(item.status === "Disbursed"){
      const urlUsers = `/admin/application/institute-search?applicationId=${item.applicationId}`;

      dispatch(userActions.userSearchInst(urlUsers,item.status,navigate,user))
    }
    else if(item.status === "Dropped"){
      const urlUsers = `/admin/application/institute-search?applicationId=${item.applicationId}`;

      dispatch(userActions.userSearchInst(urlUsers,item.status,navigate,user))
    }
    else if(item.status === "Rejected"){
      const urlUsers = `/admin/application/institute-search?applicationId=${item.applicationId}`;

      dispatch(userActions.userSearchInst(urlUsers,item.status,navigate,user))
    }
    else if(item.status === "On Hold"){
      const urlUsers = `/admin/application/institute-search?applicationId=${item.applicationId}`;

      dispatch(userActions.userSearchInst(urlUsers,item.status,navigate,user))
    }
   

  }

  const urlUsers = `${process.env.REACT_APP_BASE_URL}/users/basic-info?searchField=${searchUser?searchUser.length>=5?searchUser:undefined:undefined}`;
  const urlUsersId = `${process.env.REACT_APP_BASE_URL}/admin/application/search?applicationId=${searchUser}`;

  const handleSearch=()=>{
    if(searchUser.length >= 10 && searchUser.substring(0, 3) === "FM2"){
      axios.get(urlUsersId,{
        headers:{
          Authorization:`Bearer ${user}`
      }}).then((res) => {
        console.log("RES",res?.data?.data)
        if(res?.data?.data!==null)
        {
    
        
          setTableData(res?.data?.data);
         
          // setcurrentPage(res.data.data.pagination.currentPage);
          // setTotalFetchRows(res.data.data.pagination.totalRows);
          // setrowsPerPage(res.data.data.pagination.perPage);
        }
        
      }).catch((error)=>{
        alert(error?.response?.data?.message)
      })
    }else{
      axios.get(urlUsers,{
        headers:{
          Authorization:`Bearer ${user}`
      }}).then((res) => {
        
        if(res?.data?.data!==null)
        {
    
          
          setTableData(res?.data?.data);
         
          // setcurrentPage(res.data.data.pagination.currentPage);
          // setTotalFetchRows(res.data.data.pagination.totalRows);
          // setrowsPerPage(res.data.data.pagination.perPage);
        }
      }).catch((error)=>{
        alert(error?.response?.data?.message)
      })
    }
  
}


  const[toggleSort,setToggleSort]=useState(false);

  useEffect(() => {
    if(toggleSort)
    setSort(1)
    else
    setSort(-1)
    handleSearch();
  }, [toggleSort,urlUsers,urlUsersId]);


  
  //changepage
  const paginate = (pageNumber) => {
    setcurrentPage(pageNumber);
  };

  const [isOpen,setIsOpen]=useState(true)

  if (tableData) {
    return (
      <Card style={{display:'flex', flexDirection:'row',width:"100%",height:'100%'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%'}}>
          <Topbar/>
        
        <CardBody>
         <div style={{display:'flex'}}>
           <Input  style={{border:'1px solid #D0D5DD',width:'40%',height:'40px',borderRadius:'10px',marginTop:'20px',fontFamily: "Inter-Medium"}}type='text' onChange={(e)=>setSearchUser(e.target.value)} placeholder='Enter at least 5 characters of Applicant Name or Phone number'/> 
           </div>
          <div className='tables'style={{ marginTop: '30px' }}>
            <table>
              <thead className='table-heading'>
                <tr>
                  <th className="thead-text" style={{  fontSize: '1vw',fontFamily: "Inter-Medium",width:'15em' }} id="appId">
                  <span style={{marginLeft:'10px'}}></span>  Appl. Id
                  {/* <img style={{cursor:'pointer',width:'14px',fontFamily: "Inter-Medium"}} src={Sort} alt="sort"/> */}
                  </th>
                  <th className="thead-text" style={{  fontSize: '1vw',fontFamily: "Inter-Medium",width:'20em' }} id="appname">
                  <span style={{marginLeft:'10px'}}></span>   Appl. Name
                  </th>
                  <th className="thead-text" style={{  fontSize: '1vw',fontFamily: "Inter-Medium",width:'20em' }} id="insName">
                  <span style={{marginLeft:'10px'}}></span> Institute Name
                  </th>
                  <th className="thead-text" style={{  fontSize: '1vw',fontFamily: "Inter-Medium" ,width:'20em'}} id="coursename">
                    Course Fees
                  </th>
                  <th className="thead-text" style={{  fontSize: '1vw',fontFamily: "Inter-Medium",width:'20em' }} id="status">
                  <span style={{marginLeft:'10px'}}></span> Status
                  </th>
                  <th className="thead-text"  style={{  fontSize: '1vw',cursor:'pointer',fontFamily: "Inter-Medium",width:'25em' }} id="appfromdate">
                   Applied On
                  </th>
                 
                </tr>
              </thead>
              {Array.isArray(tableData) && tableData?.map((item) => {
              
                  return (
                    <tbody className='table-body' key={item.applicationId}>
                      <tr className='table-row'
                       onClick={decode.role === "institute_admin" || decode.role === "institute_user" ? () => handleUserApplication(item) :() => handleUserApplication(item)}
                        style={{ lineHeight: '28px' }}>
                        <td style={{cursor:'pointer',  fontSize: '1vw', fontWeight: '400', width: '10em',fontFamily: "Inter-Medium" }}>
                          {item.applicationId}
                        </td>
                        <td
                          style={{
                             fontSize: '1vw',
                            width: '18em',fontFamily: "Inter-Medium"
                          }}
                        >
                          <span style={{fontFamily: "Inter-Medium", fontSize: '1vw'}}>{item.name}</span>
                          {item.coapplicantName ? (
                            <span style={{fontFamily: "Inter-Medium", fontSize: '1vw'}}>
                              
                              | {item.coapplicantName} ( C )
                            </span>
                          ) : (
                            ''
                          )}
                        </td>
                        <td style={{  fontSize: '1vw', fontWeight: '400', width: '13em',fontFamily: "Inter-Medium", color: '#667085' }}>
                          {item.instituteName}
                        </td>
                        <td style={{  fontSize: '1vw', fontWeight: '400', width: '13em',fontFamily: "Inter-Medium", color: '#667085' }}>
                          {item.courseFees}
                        </td>
                        <td style={{  fontSize: '1vw', fontWeight: '400', width: '13em' ,fontFamily: "Inter-Medium", color: '#667085'}}>
                          {item.status}
                        </td>
                       
                        <td
                          title={item.appliedOn}
                          style={{ width: '10em', fontWeight: '400',  fontSize: '1vw',fontFamily: "Inter-Medium" }}
                        >
                          {moment(item.appliedOn).format('DD MMM YYYY')}
                        </td>
                       
                      </tr>
                      
                    </tbody>
                  );
              })}
            </table>
            </div>

            {totalFetchRows ? (
              <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter-Medium", fontSize: '0.9vw',marginLeft:'10px' ,marginBottom:'5px'}}>
                Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage}{' '}
                records
              </div>
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter-Medium", fontSize: '0.9vw' }}>No Records</div>
            )}
            <PaginationTable
              startPage="1"
              rowsPerPage={rowsPerPage}
              totalRows={totalFetchRows}
              paginate={paginate}
            />
          
        </CardBody>
        </div>
      </Card>
    );
  }
  return <div>....Loading</div>;
};

export default SearchTable;

