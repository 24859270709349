import React, { useState,useEffect } from 'react';
import addcall from "../../../assets/images/addcall.png"
import close from "../../../assets/images/close.png"
import add from "../../../assets/images/add.png"

import axios from 'axios';
import dayjs from 'dayjs';
import 'moment-timezone';
import moment from 'moment';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import{
    Modal,
    ModalBody,
    Label,
    ModalHeader,
    Form,
    FormGroup,
    Input,
  } from 'reactstrap';

export default function AddCall(){
    const [formStatusOpen, setStatusOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [name, setContactName] = useState('');
    const [phone, setContactNumber] = useState('');
    const [status, setStatus] = useState('1');
    const [remarks, setRemarks] = useState('');
    const [confirmationDialog, setConfirmationDialog] = useState(false);
    const [formLoader, setFormLoader] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('Hold On...');
    const user = sessionStorage.getItem("user")
    const loanId = sessionStorage.getItem("loanid")
    const parsedDate = moment(selectedDate);
    const adjustedDate = parsedDate.subtract(5, 'hours').subtract(30, 'minutes');
    const adjustedFormattedDate = adjustedDate.format('YYYY-MM-DD HH:mm:ss');
//    const dates =  moment(new Date(selectedDate)).format('yyyy/MM/DD HH:mm:ss')
  const today = dayjs();
   
    const handleUpdate = () => {
        setFormLoader(!formLoader);
      setStatusOpen(!formStatusOpen);
      setConfirmationDialog(!confirmationDialog);
        const formData = {
            name,
            phone,
            status,
            remarks,
            loanId,
            date: adjustedFormattedDate
        };
        const createUrl = `${process.env.REACT_APP_BASE_URL}/call-history/create`;
        axios
        .post(`${createUrl}`, formData, {
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-type': 'application/json',
          },
        })
        .then(
          (res) => {
            if (res.data.message === 'Successful') {
                setConfirmationMessage('Call History Added Successfully');
                setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
                setTimeout(() => setConfirmationDialog(false), 2000);
                setTimeout(() => window.location.reload(true), 500);
              }
  
         console.log("formatteddata",res);
          },
          (error) => {
            setConfirmationMessage(`Unauthorized --- Error Code ${error}`);
            setTimeout(() => setConfirmationDialog(!confirmationDialog), 3500);
             setTimeout(() => setConfirmationDialog(false), 3750);
             setTimeout(() => window.location.reload(true), 3000);

            console.log(error)
          },
        );
       
    };
    const popUpStatusForm = () => {
        setSelectedDate(dayjs());
        setStatusOpen(true);
      };
 
    
    return(
        <div>
            <p  onClick={() => popUpStatusForm()} style={{backgroundColor:'#D32028',cursor:'pointer',marginTop:"15px",borderRadius:'10px'}}><img src={addcall}/></p> 
            <Modal  isOpen={confirmationDialog}>
                <ModalHeader>Waiting For Confirmation</ModalHeader>
                <ModalBody>{confirmationMessage}</ModalBody>
            </Modal>
            {formStatusOpen && (
                <>
                <div style={{
                        
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 999,
                        }}>
                </div>
                <Modal style={{
                    
                    width:"500px",
                    
                    top:"-2%",
                    borderRadius:"10px",
                }} isOpen={formStatusOpen}>
                    <div style={{backgroundColor:'#E9E9E9',borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',paddingLeft:'20px',paddingRight:'20px',paddingTop:'10px'}}>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'20px',fontWeight:'600',color:'#101828'}}>Add Call</p>
                        <p style={{paddingBottom:'10px'}}><img src={close} style={{cursor:'pointer'}} onClick={() => setStatusOpen(!formStatusOpen)}/></p>
                    </div>
                    </div>
                <ModalBody>
                    <Form style={{padding:'10px'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                            components={[
                            'DesktopDateTimePicker',
                            ]}
                        >
                            <DemoItem label="Select Date and Time" >
                            <DesktopDateTimePicker defaultValue={today}
                            disablePast
                            views={['year', 'month', 'day', 'hours', 'minutes']} value={selectedDate} onChange={(newDate) => setSelectedDate(newDate)}/>
                            </DemoItem>
                        </DemoContainer>
                        </LocalizationProvider>
                   
    
                    <FormGroup style={{lineHeight:'1.5em',marginTop:'10px'}}>
                        <Label ><span style={{color:'#344054',fontWeight:'600',fontSize:'15px'}}>Contact Name</span></Label>
                        <Input type="text" placeholder="Contact Name" id="name" value={name} onChange={(e) => setContactName(e.target.value)} />
                    </FormGroup>
                    <FormGroup style={{lineHeight:'1.5em',marginTop:'20px'}}>
                        <Label><span style={{color:'#344054',fontWeight:'600',fontSize:'15px'}}>Contact Number</span></Label>
                        <Input type="number" placeholder="Contact Number" id="phone" value={phone} onChange={(e) => setContactNumber(e.target.value)}/>
                    </FormGroup>
                    <FormGroup style={{lineHeight:'1.5em',marginTop:'20px'}}>
                    <Label><span style={{color:'#344054',fontWeight:'600',fontSize:'15px'}}>Status</span></Label>
                    <Input type="select" id="status"   onChange={(e) => setStatus(e.target.value)}>
                    <option value="1">PTP</option>
                    <option value="2">RTP</option>
                    <option value="3">CB</option>
                    <option value="4">RNR</option>
                    <option value="5">Legal</option>
                    <option value="6">SW</option>
                    <option value="7">Disc</option>

                    </Input>
                    </FormGroup>
                    <FormGroup style={{lineHeight:'1.5em',marginTop:'20px'}}>
                    <Label><span style={{color:'#344054',fontWeight:'600',fontSize:'15px'}}>Remarks</span></Label>
                    <Input type="textarea" id="remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)}/>
                    </FormGroup>
                    </Form>
                </ModalBody>
                <div style={{backgroundColor:'#FFF3F3',padding:'30px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'10px'}}>
                    <button style={{width:'400px',backgroundColor:'#D32028',borderRadius:'20px',color:'white',fontWeight:'500',fontSize:'16px',fontFamily:'Inter-Medium'}} onClick={handleUpdate}>Update</button>
                </div>
                </Modal>
                </>
            )}
        </div>
    )
}