import React from 'react'
import Sidebar from "../Sidebar";
import { useState, useEffect  } from 'react';
import axiosInstance from '../../helpers/axios';
import Topbar from "../Topbar";
import DatePicker from "react-multi-date-picker";
import calender from "../../assets/images/calender.png"
import download from "../../assets/images/download.png"
import moment from 'moment';





export default function Closedloans() {
    const [isOpen,setIsOpen]=useState(true) 

    const [ClosedtableData, setclosedTabledata] = useState()
    const [Closeddata, setclosedData] = useState()
    const [ClosedstartDate,setClosedStartDateFrom] = useState()
    const [ClosedendDate,setclosedEndDateTo] = useState()
  
    const user = sessionStorage.getItem('user');



    useEffect(() => {
      
        const startDateFrom = moment().startOf('month');
        const endDateTo = moment();
    
      
        const formattedStartDate = startDateFrom.format('YYYY-MM-DD');
        const formattedEndDate = endDateTo.format('YYYY-MM-DD')+ " 23:59:59";
    
        console.log("Formatted Start Date:", formattedStartDate);
        console.log("Formatted End Date:", formattedEndDate);
    
        let queryParams = {};
    
        if (formattedStartDate && formattedEndDate) {
          queryParams.startDate = formattedStartDate;
          queryParams.endDate = formattedEndDate;
        }
    
        axiosInstance.get('/analytics/Closedloandetails', {
          headers: {
            Authorization: `Bearer ${user}`,
          },
          params: queryParams 
        })
        .then((res) => {
          setclosedData(res?.data?.emiDetails?.closedlonas);
          console.log(res?.data?.emiDetails?.closedlonas, 'data');
        })
        .catch((error) => {
          console.log(error);
        });
    
      }, []); 
    
    
      const fetchingclosedData = ()=>{
        
        let queryParams = {}
        if(ClosedstartDate && ClosedendDate){
          const formattedStartDate = moment(ClosedstartDate, 'DD MMM YYYY').format('YYYY-MM-DD');
          const formattedEndDate = moment(ClosedendDate, 'DD MMM YYYY').format('YYYY-MM-DD') + " 23:59:59";
    
        queryParams.startDate = formattedStartDate;
        queryParams.endDate = formattedEndDate;
        }
        
       
    
        axiosInstance.get('/analytics/Closedloandetails', {
          params: queryParams,
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
    
          setclosedTabledata(res?.data?.emiDetails?.closedlonas);
        
         
         
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
       }
       
       function formatNumberWithCommas(number) {
        return number?.toLocaleString('en-IN'); 
      }


      const handleDownloadList=()=>{
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = String(currentDate.getFullYear());
        const formattedDate = `${day}-${month}-${year}`;
        document.getElementById("downloadButton").disabled=true;
        document.getElementById("downloadButton").innerText="Wait....";
        document.getElementById("downloadButton").style.backgroundColor="#D22129";
        document.getElementById("downloadButton").style.color= "white";
        document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
        document.getElementById("downloadButton").style.fontSize= "15px";
        document.getElementById("downloadButton").style.width= "130px";
        document.getElementById("downloadButton").style.cursor= "pointer";
        document.getElementById("downloadButton").style.height= "34px";
        document.getElementById("downloadButton").style.borderRadius= "5px";
        document.getElementById("downloadButton").style.textAlign= "center";
        // document.getElementById("downloadButton").style.marginLeft="150px";
        document.getElementById("downloadButton").style.backgroundImage = "none"
    
    
        const downloadTemplateUrl=`${process.env.REACT_APP_BASE_URL}/excel/IrrCountInRange `
        
        let queryParams = {}

        
        if(ClosedstartDate && ClosedendDate){
          const formattedStartDate = moment(ClosedstartDate, 'DD MMM YYYY').format('YYYY-MM-DD');
          const formattedEndDate = moment(ClosedendDate, 'DD MMM YYYY').format('YYYY-MM-DD') + " 23:59:59";
    
        queryParams.startDate = formattedStartDate;
        queryParams.endDate = formattedEndDate;
        }




        const startDateFrom = moment().startOf('month');
        const endDateTo = moment();
    
        // Format the dates to the desired format
        const formattedStartDate = startDateFrom.format('YYYY-MM-DD');
        const formattedEndDate = endDateTo.format('YYYY-MM-DD')+ " 23:59:59";
        
        if (formattedStartDate && formattedEndDate) {
          queryParams.startDate = formattedStartDate;
          queryParams.endDate = formattedEndDate;
        }



        
        axiosInstance.get("/excel/closedloans", {
          params: queryParams,
          responseType: 'arraybuffer',
          headers: {
              Authorization: `Bearer ${user}`
          }
      }).then((res)=>{
        setTimeout(() => {
          document.getElementById("downloadButton").disabled=false
          document.getElementById("downloadButton").innerText="Export";
          document.getElementById("downloadButton").style.paddingLeft = "30px";
          document.getElementById("downloadButton").style.backgroundColor="#D22129";
          document.getElementById("downloadButton").style.color= "white";
          document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
          document.getElementById("downloadButton").style.fontSize= "15px";
          document.getElementById("downloadButton").style.width= "130px";
          document.getElementById("downloadButton").style.cursor= "pointer";
          document.getElementById("downloadButton").style.height= "34px";
          document.getElementById("downloadButton").style.borderRadius= "5px";
          document.getElementById("downloadButton").style.textAlign= "center";
          // document.getElementById("downloadButton").style.marginLeft= "30px";
          document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
          document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
          // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
          document.getElementById("downloadButton").style.backgroundSize  ='15px' 
          document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
    
        }, 2000);
        const blob = new Blob([res.data], { type: "application/xlsx", });
        const url= window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href =url
        link.download = `${formattedDate}.xlsx`;
    
        document.body.appendChild(link);
    
        link.click();
    
        document.body.removeChild(link);
      }
        
      ).catch((error)=>{
        alert("Error Downloading")
        document.getElementById("downloadButton").innerText="Error..";
        document.getElementById("downloadButton").style.backgroundColor="#D22129";
        document.getElementById("downloadButton").disabled=false
          document.getElementById("downloadButton").style.paddingLeft = "30px";
          document.getElementById("downloadButton").style.backgroundColor="#D22129";
          document.getElementById("downloadButton").style.color= "white";
          document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
          document.getElementById("downloadButton").style.fontSize= "15px";
          document.getElementById("downloadButton").style.width= "130px";
          document.getElementById("downloadButton").style.cursor= "pointer";
          document.getElementById("downloadButton").style.height= "34px";
          document.getElementById("downloadButton").style.borderRadius= "5px";
          document.getElementById("downloadButton").style.textAlign= "center";
          // document.getElementById("downloadButton").style.marginLeft= "30px";
          document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
          document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
          // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
          document.getElementById("downloadButton").style.backgroundSize  ='15px' 
          document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
        setTimeout(() => {
          document.getElementById("downloadButton").disabled=false
          document.getElementById("downloadButton").innerText="Export";
          // document.getElementById("downloadButton").style.paddingLeft = "30px";
          document.getElementById("downloadButton").style.backgroundColor="#D22129";
          document.getElementById("downloadButton").style.color= "white";
          document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
          document.getElementById("downloadButton").style.fontSize= "15px";
          document.getElementById("downloadButton").style.width= "130px";
          document.getElementById("downloadButton").style.cursor= "pointer";
          document.getElementById("downloadButton").style.height= "34px";
          document.getElementById("downloadButton").style.borderRadius= "5px";
          document.getElementById("downloadButton").style.textAlign= "center";
          // document.getElementById("downloadButton").style.marginLeft= "30px";
          document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
          document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
          // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
          document.getElementById("downloadButton").style.backgroundSize  ='20px' 
          document.getElementById("downloadButton").style.backgroundPosition  ='15px 10px'
        }, 1000);
      })
      } 
    


  return (
    <div style={{display:'flex',width:"100%",backgroundColor:'white',height:'130%'}}>
    <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
    <div style={{width:isOpen?'80%':'100%',marginLeft:isOpen?"20%":'0%',padding:'10px'}}>
    <Topbar/>



    <p style={{textAlign:"center",fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px'}}>Closed Loans</p>



<div style={{ display: 'flex' }}>

<div style={{ height:'30px',width:'15em', borderRadius: '20px',marginRight:"60px",marginTop:'-2px'}}>
<DatePicker
style={{ height:'33px', BorderRadius: '5px',padding: '2px', backgroundRepeat: 'no-repeat',
backgroundPosition: '5px center',
backgroundSize: '15px 15px', backgroundImage: `url(${calender})`, paddingLeft: '30px',fontSize:"14px", border:'1px solid #ccc',fontWeight:"normal", border:'1px solid #ccc'}}
placeholder="Select date range"
allowClear={true}
format="DD MMM YYYY"
onChange={(dates, dateStrings) => {
 console.log("dateStrings:", dateStrings);

   
 setClosedStartDateFrom(dateStrings?.validatedValue[0])
 setclosedEndDateTo(dateStrings?.validatedValue[1])
}}
range
/>
</div>

<div > 
<button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium",paddingLeft:'10px'}}
onClick={() => fetchingclosedData()}
>
Search Results
</button>
</div>

<div style={{marginLeft:'40px'}}>
<div style={{backgroundColor:'#D22129',display: 'flex', alignItems: 'center',width:'130px',height:'34px',borderStyle:'none',borderRadius:"5px",fontFamily:"Inter-Medium",paddingLeft:'10px',justifyContent:'space-around',cursor:'pointer',marginRight:'20px'}}
id="downloadButton"   onClick={() => handleDownloadList()} >

<img src={download} style={{height:'20px',marginLeft:'8px'}}/> 
<span style={{fontFamily:"Inter-Medium",color:'white',paddingRight:"15px"}}>Export</span>

</div></div>


</div>



<table className='tables' style={{marginRight:'10px'}}>
  <thead className='table-heading'>
    <tr>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '8px%', fontFamily: "Inter-Medium", paddingLeft: '10px' }}>Loan id</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>full name</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '15px%', fontFamily: "Inter-Medium" }}>Insti name</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Loan amt.</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Closed date</th>
      <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Last emi date</th>
     
    </tr>
  </thead>
  
  <tbody className='table-body'>
    {ClosedstartDate && ClosedendDate ?
    ClosedtableData?.map((item, index) => (
      <tr className="table-row" key={index} style={{ lineHeight:"25px"}}>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085', paddingLeft: '10px' }}>{item.legacy_loan_id?item.legacy_loan_id:'-'}</td>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item.full_name?item.full_name:'-'}</td>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item.name?item.name:'-'}</td>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>₹{item.loan_amount ?formatNumberWithCommas(Math.round(item.loan_amount)):'-'}</td>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item.closed_date ? new Date(item.closed_date).toISOString().split('T')[0] : '-'}</td>               
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item.last_emi_payment_date ? new Date(item.last_emi_payment_date).toISOString().split('T')[0] : '-'}</td>
      </tr>
    )):
    Closeddata?.map((item, index) => (
      <tr className="table-row" key={index} style={{ lineHeight:"25px"}}>
         <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085', paddingLeft: '10px' }}>{item.legacy_loan_id?item.legacy_loan_id:'-'}</td>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item.full_name?item.full_name:'-'}</td>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item.name?item.name:'-'}</td>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>₹{item.loan_amount ?formatNumberWithCommas(Math.round(item.loan_amount)):'-'}</td>
        <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item.closed_date ? new Date(item.closed_date).toISOString().split('T')[0] : '-'}</td>               
       <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item.last_emi_payment_date ? new Date(item.last_emi_payment_date).toISOString().split('T')[0] : '-'}</td>

      </tr> 
    ))}
  </tbody>
</table>

    </div>
    </div>
  )
}
