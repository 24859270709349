import React,{useRef,useState,useEffect} from 'react'
import {Card,Input,CustomInput} from 'reactstrap'; 
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from '../Sidebar';
import Topbar from "../Topbar";
import axiosInstance from '../../helpers/axios';
import DataElements from './DataElements';
import calender from "../../assets/images/calender.png"
import Datetime from 'react-datetime';
import edit from "../../assets/images/edit.png"
import add from "../../assets/images/add_icon.png"
import remove from "../../assets/images/delete_rm.png"
import close from "../../assets/images/close.png"
import {
Dropdown,
DropdownToggle,
DropdownMenu,
DropdownItem,
} from 'reactstrap';
import { set } from 'date-fns';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import {useDispatch,useSelector} from 'react-redux';
import { userActions } from '../../actions/user.actions';
const Rules = () => {
  const [openSources,setOpenSources]=useState(false)
  const [selectedSource, setSelectedSource] = useState(); 
  const user = sessionStorage.getItem("user")
  const [sources,setSources]=useState([])
  const [dataElements,setDataElements]=useState([])
  const [clicked,setClicked] = useState(null)
  const [selectedElement, setSelectedElement] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [name,setName] = useState(null);
  const [description,setDescription] = useState(null)
  const [id,setId] = useState(null)
  const [datatype,setDatatype] = useState(null)
  const [value1,setValue1] = useState(null)
  const [value2,setValue2] = useState(null)
  const [selectedOperatorText, setSelectedOperatorText] = useState("");
  const [tableData,setTableData] = useState([])
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedRuleIndex, setSelectedRuleIndex] = useState(null);
  const [ruleArray, setRuleArray] = useState([]);
  const [rules, setRules] = useState([{source:"",
    data:"",
    op:"",ruleId:"",outcome1:"",outcome2:""
  }]);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const sourceUrl = `/rules/sources`
  const getPolicy = `/rules/all-policies`
  useEffect(()=>{
    axiosInstance.get(sourceUrl, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
        setSources(res?.data?.data)
    })
    .catch((error) => {
      console.error("Error fetching filtered data:", error);
    });

    axiosInstance.get(getPolicy, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
        setTableData(res?.data?.data)
    })
    .catch((error) => {
      console.error("Error fetching filtered data:", error);
    });
    
  },[sourceUrl])
  
  const handleSourceHover = (sourceId) => {
    const dataElementsUrl = `/rules/data-elements?sourceId=${sourceId}`;
    axiosInstance
      .get(dataElementsUrl, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
        setDataElements(res?.data?.data)
      })
      .catch((error) => {
        console.error('Error fetching data elements:', error);
      });
      
  };
 
  const [selectedSourceId, setSelectedSourceId] = useState(null);

  const handleSourceSelect = (sourceKey) => {

    const selectedSourceId = sources[sourceKey];
    setSelectedSource(sourceKey);
    setSelectedSourceId(selectedSourceId);
    setOpenSources(false);
    setSelectedElement(null)
    const updatedRuleArray = ruleArray.map((item, index) => {
      if (index === selectedRuleIndex) {
        return {
          ...item,
          source: sourceKey,
        };
      }
      return item;
    });
    setRuleArray(updatedRuleArray);
  };
  const handleDataElementSelect = (elementId,id,type) => {

    const updatedRuleArray = ruleArray.map((item, index) => {
      if (index === selectedRuleIndex) {
        return {
          ...item,
          element: elementId,
          dataType: type,
        };
      }
      return item;
    });
    setRuleArray(updatedRuleArray);
    rules[currentRule].data=elementId
    setSelectedElement(elementId);
    setId(id)
    setDatatype(type)
  };
  const ruleUrl = `/rules/create`
  const toggleModal = () => {
    console.log(policyClicked)
    if(policyClicked === false){
      const emptyRuleIndex = rules.findIndex(rule => rule.ruleId === "");
      console.log(emptyRuleIndex)
      if (emptyRuleIndex !== -1) {
        const data = {
            source: selectedSourceId,
            name:`${selectedSource}${value1}`,
            description:`${selectedElement} ${selectedOperatorText} ${value1}`,
            dataElement: id,
            operator: selectedOperator,
            value1: value1,
            value2: 0,
            ifTrueAction: trueDropdownSelection[emptyRuleIndex] === 'A' ? 1 : trueDropdownSelection[emptyRuleIndex] === 'R' ? 0 : 0.5,
            ifFalseAction: falseDropdownSelection[emptyRuleIndex] === 'A' ? 1 : falseDropdownSelection[emptyRuleIndex] === 'R' ? 0 : 0.5,
        };
  
        axiosInstance.post(ruleUrl, data, {
            headers: {
                Authorization: `Bearer ${user}`
            }
        }).then((res) => {
            rules[emptyRuleIndex].ruleId = res?.data?.data;
            const newData = {
                rulesArray: rules.map(rule => ({
                    ruleId: rule.ruleId,
                })),
                name: "policy",
                description: "minimum"
            };
            setIsModalOpen(!isModalOpen);
        }).catch((error) => {
            alert(error?.response?.data?.message);
            console.log("error", error);
        });
    } 
   
    setIsModalOpen(!isModalOpen);
    }else{
      setIsModalOpen(!isModalOpen);
    }
      
  };

  const submit = ()=>{
    if (/\s/.test(name)) {
      alert("Spaces are not allowed in the Name");
      return; 
    }
    const data = {
      rulesArray: (Array.isArray(updatedRuleArray) && updatedRuleArray.length > 0) 
      ? updatedRuleArray.map(rule => rule.ruleId)
      : (Array.isArray(rules) && rules.length >= 1) 
        ? rules.map(rule => rule.ruleId) 
        : ruleArray.map(rule => rule.ruleId),
      name:name,
      description:description,
      stage:selectedStage === 'AfterConsent' ? 1 : selectedStage === 'InProcess' ? 2 : selectedStage === 'AfterSanction' ? 3:selectedStage,
      ...(selectedOption === 'Select' && { instituteId: currentInstituteId })
    }
    const policyUrl = `/rules/create-policy`

    axiosInstance.post(policyUrl,data,{
      headers:{
        Authorization:`Bearer ${user}`
    }
    }).then((res)=>{
      if(res?.data?.message === "Successful"){
        alert("Policy created Successful")
        setTimeout(() => window.location.reload(true), 1000);
      }
    }).catch((error)=>{
      alert(error?.response?.data?.message)
      console.log("error",error)
    })
  }
  const reset = ()=>{
    setSelectedElement(null)
    setSelectedOperator(null)
    setId(null)
    setSelectedOperatorText("")
    setRules([{source:"",
    data:"",
    op:""
  }])
  }
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
  if (/\S/.test(inputValue)) {
    setName(inputValue);
  }else{
      alert("Spaces Are not allowed in the Name")
    }
  };
  const [operatorClicked,setOperatorClicked]=useState(null) 

  const selectOperator = (selectedOperator,selectedOperatorText) =>{

    const updatedRuleArray = ruleArray.map((item, index) => {
      if (index === selectedRuleIndex) {
        return {
          ...item,
          operator: Number(selectedOperator),
        };
      }
      return item;
    });
    setRuleArray(updatedRuleArray);
    setOperatorClicked(selectedOperator)
    setSelectedOperator(selectedOperator);
    setSelectedOperatorText(selectedOperatorText);

  }
  const [isOpen,setIsOpen]=useState(true) 
  const [stageDropdownOpen, setStageDropdownOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState("Select Stage");
  const toggleStageDropdown = () => setStageDropdownOpen((prevState) => !prevState);

  const handleStageSelect = (stage) => setSelectedStage(stage);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);


   const [currentRule,setCurrentRule]=useState(rules.length-1)
  const handleAddRule = (index) =>{

    const data = {
      source:selectedSourceId,
      name:`${selectedSource}${value1}`,
      description:`${selectedElement} ${selectedOperatorText} ${value1}`,
      dataElement:id,
      operator:selectedOperator,
      value1:value1,
      value2:0,
      ifTrueAction: trueDropdownSelection[index] === 'A' ? 1 : trueDropdownSelection[index] === 'R' ? 0 : 0.5,
      ifFalseAction: falseDropdownSelection[index] === 'A' ? 1 : falseDropdownSelection[index] === 'R' ? 0 : 0.5,
  
  };
  if(!isSaveButtonClicked){
    axiosInstance.post(ruleUrl,data,{
      headers:{
        Authorization:`Bearer ${user}`
    }
    }).then((res)=>{
      // data.ruleId = res?.data?.data;
      rules[currentRule].ruleId=res?.data?.data
      rules[currentRule].outcome1=trueDropdownSelection[index]
      rules[currentRule].outcome2=falseDropdownSelection[index]

      // const updatedRules = [...rules];
      // updatedRules[currentRule].ruleId = res?.data?.data;
      // setRules(updatedRules);
  
      // setRules([...rules, {source:"", data:"", op:"", ruleId:""}]);
  
      // setRuleArray([...ruleArray, data]);
     
    }).catch((error)=>{
      if (policyClicked) {
        return null;
    } else {
        alert(error?.response?.data?.message);
    }
    console.log("error", error);    
      console.log("error",error)
    })
  }
  
  const newRules = [...rules, data];
  setRules(newRules);

  const newRuleArray = [...ruleArray, data];
  setRuleArray(newRuleArray);
    if(rules) {
      setRules([...rules ,{source:"",
      data:"",
      op:"",ruleId:"",outcome1:"",outcome2:""
    }])
    }
   
   
  }
  const handleDeleteRule = (index) => {

    const newItems = [...rules];
    newItems.splice(index , 1);
    setRules(newItems);
    const newRuleArray = [...ruleArray];
    newRuleArray.splice(index, 1);
    setRuleArray(newRuleArray);

    setCurrentRule(old => old - 1);
  }
const [policyClicked,setPolicyClicked] = useState(false)
const [selectedRule,setSelectedRule] = useState(null)
const [selectedPolicyName,setSelectedPolicyName] = useState(null)

  const handlePolicy = (policyId,name) => {
    setSelectedPolicyName(name)
    setPolicyClicked(true)
    const getPolicyUrl = `/rules/policy?policyId=${policyId}`;
  
    axiosInstance.get(getPolicyUrl, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((res) => {
      const policyData = res?.data?.data;
      if (policyData) {
        // const rulesArray = JSON.parse(policyData.rulesArray);
        const ruleIds = policyData.map((rule) => rule?.ruleId);
        const rules = ruleIds.map((ruleId) => {
          const getRulesUrl = `/rules/id?ruleId=${ruleId}`;
          return axiosInstance.get(getRulesUrl, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }).then((res) => res?.data?.data );
        });
        Promise.all(rules)
          .then((allRules) => {
            const elementRequests = allRules.map((item) => {
              const getElementUrl = `/rules/data-element?elementId=${item.dataElement}`;
              return axiosInstance.get(getElementUrl, {
                headers: {
                  Authorization: `Bearer ${user}`,
                },
              }).then((res) => {
                item.ifTrueAction=item.ifTrueAction
                item.ifFalseAction=item.ifFalseAction
                item.element = res?.data?.data[0].element
                item.dataType = res?.data?.data[0].datatype; 
                return item;
              });
            });
  
            Promise.all(elementRequests)
              .then((rulesWithData) => {
                setRuleArray(rulesWithData);
              })
              .catch((error) => {
                console.log(error)
                alert(error?.response?.data?.message);
              });
          })
          .catch((error) => {
            console.log(error)
            alert(error?.response?.data?.message);
          });
      }
    }).catch((error) => {
      console.log(error)
      alert(error?.response?.data?.message);
    });
  };
  
  const handleClose = () => {
    setPolicyClicked(false);
  };

const handleRuleClick = (index,item) => {
  setSelectedRule(item)
  setSelectedRuleIndex(index);
};
const [modal, setModal] = useState(false);
const [policyName, setPolicyName] = useState(false);

  const handleEdit = (name) => {
    setPolicyName(name)
    setModal(!modal);

  };
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [updatedRuleArray, setUpdatedRuleArray] = useState([]);

  const handleSubmit = (item,index) =>{
console.log(item)
console.log(trueDropdownSelection[index])
console.log(item?.ifTrueAction)
    setIsSaveButtonClicked(true);
    const data = {
      source:selectedSourceId?selectedSourceId:item?.source,
      name:selectedSource && value1 ? `${selectedSource}${value1}` : item?.name,
      description:selectedElement && selectedOperatorText && value1 ?`${selectedElement} ${selectedOperatorText} ${value1}`:item?.description,
      dataElement:id?id:item?.dataElement,
      operator:selectedOperator?selectedOperator:item?.operator,
      value1:value1?value1:item?.value1,
      value2:0,
      ifTrueAction:trueDropdownSelection[index]? trueDropdownSelection[index] === 'A' ? 1 : trueDropdownSelection[index] === 'R' ? 0 : 0.5:item?.ifTrueAction,
      ifFalseAction:falseDropdownSelection[index]? falseDropdownSelection[index] === 'A' ? 1 : falseDropdownSelection[index] === 'R' ? 0 : 0.5:item?.ifFalseAction,

  };
  if (selectedRule) {
    data.ruleId = selectedRule?.ruleId;
}
axiosInstance.post(ruleUrl,data,{
  headers:{
    Authorization:`Bearer ${user}`
}
}).then((res)=>{
  const newRuleData = { ruleId: res.data.data };
  const updatedRuleArray = ruleArray.map(item => {
    if (!item.ruleId) {
      return newRuleData;
    } else {
      return item;
    }
  });

  setUpdatedRuleArray(updatedRuleArray);})
  }
  const initializeDropdownState = (length) => {
    return Array.from({ length }, () => false);
  };
const rulesLength = policyClicked  ? ruleArray.length:rules.length
  const [trueDropdownOpen, setTrueDropdownOpen] = useState(initializeDropdownState(rulesLength));
  const [falseDropdownOpen, setFalseDropdownOpen] = useState(initializeDropdownState(rulesLength));

  useEffect(() => {
    setTrueDropdownOpen(initializeDropdownState(rulesLength));
    setFalseDropdownOpen(initializeDropdownState(rulesLength));
  }, [rulesLength]);

  const toggleTrueDropdown = (index) => {
    setTrueDropdownOpen(trueDropdownOpen.map((item, i) => (i === index ? !item : item)));
  };

  const toggleFalseDropdown = (index) => {
    setFalseDropdownOpen(falseDropdownOpen.map((item, i) => (i === index ? !item : item)));
  };
  const [trueDropdownSelection, setTrueDropdownSelection] = useState(Array(rulesLength).fill(''));
  const [falseDropdownSelection, setFalseDropdownSelection] = useState(Array(rulesLength).fill(''));
  const [modifiedTrueActions, setModifiedTrueActions] = useState({});
  const [modifiedFalseActions, setModifiedFalseActions] = useState({});

  const handleTrueDropdownSelect = (index, selection) => {
    const newSelection = [...trueDropdownSelection];
    newSelection[index] = selection === 'Approve' ? 'A' : selection === 'Manual' ? 'M' : 'R';
    setTrueDropdownSelection(newSelection);
    setModifiedTrueActions((prev) => ({
      ...prev,
      [index]: newSelection[index],
    }));
  };


  const handleFalseDropdownSelect = (index, selection) => {
    const newSelection = [...falseDropdownSelection];
    newSelection[index] = selection === 'Approve' ? 'A' : selection === 'Manual' ? 'M' : 'R';
    setFalseDropdownSelection(newSelection);
    setModifiedFalseActions((prev) => ({
      ...prev,
      [index]: newSelection[index],
    }));
  };

const [kycOpen, setKycOpen] = useState(true);

const handleKYC = () => {
  setKycOpen(!kycOpen);
};

const [borrowerOpen, setBorrowerOpen] = useState(true);

const handleBorrower = () => {
  setBorrowerOpen(!borrowerOpen);
};

const [demographicOpen, setDemographicOpen] = useState(true);

const handleDemographic = () => {
  setDemographicOpen(!demographicOpen);
};
const [financeOpen, setFinanceOpen] = useState(true);

const handleFinance = () => {
  setFinanceOpen(!financeOpen);
};
const dispatch=useDispatch();

const instituteState=useSelector((state)=>state?.GETINSTITUTE?.res?.data?.data)
const [instituteList,setInstituteList]=useState(null)

const getInsituteInfo=(text)=>{
  
  const urlGetInstitute=`/institute/info/name?name=${text}`;
    
  if(text.length>=4)
  {
   dispatch(userActions.getInstituteInfo(urlGetInstitute,user))
   setInstituteList(instituteState)
  }
  else{
    setInstituteList(null)
  }
}
const [currentInstituteId,setCurrentInstituteId]=useState(null)

const handleSearchInstituteDetails = (item) => {
  setCurrentInstituteId(item.id);
  setInstituteList(null);
  document.getElementById("searchInstituteNameBox").value=item.brandName

};
const [selectedOption, setSelectedOption] = useState('All');
const handleOptionChange = (e) => {
  setSelectedOption(e.target.value);
};
console.log("rules",rules)
    return ( 
   <Card className="card" style={{display:'flex', flexDirection:'row',width:"100%",minHeight:'100vh',backgroundColor:'white'}}>
      <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
      <div style={{width:isOpen?'80%':'100%',marginLeft:isOpen?"20%":'0%',padding:'10px'}}>
        <Topbar/>
        <div>            
            <p style={{color:'#D32027',fontFamily:'Inter-Medium',paddingLeft:'1em'}}>Policy Builder</p>
        </div>
        <div style={{display:'flex'}}>
        <div style={{width:'17%',marginLeft:'10px',boxShadow:'0px 2px 4px -2px #1018280F',borderRadius:'8px',border:'1px solid #EAECF0'}}>
          <p style={{textAlign:'center',fontFamily:'Inter-Medium',fontSize:'1vw'}}>Select Source</p>
          <div style={{margin:'1em'}}>
          <div>
              <div style={{display:'flex',flexDirection:'row',cursor:'pointer',height:'2.5em'}} onClick={handleDemographic}>
              {demographicOpen ? (
                    <IndeterminateCheckBoxIcon style={{ marginTop: '0.35em' }} />
                  ) : (
                    <AddBoxOutlinedIcon style={{ marginTop: '0.35em' }} />
                  )}
              <p style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',marginLeft:'0.5em'}}>Demographic</p>
              </div>
              {demographicOpen && (<>
                {Object.keys(sources).filter(key => [10].includes(sources[key])).map((key) => (
            <div key={key} style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',paddingLeft:'2.3em',cursor:"pointer",backgroundColor: clicked === key ? '#FFEDEE' : 'transparent', borderRadius:clicked === key ? '5px' : '0px',
              color: clicked === key ? '#D32028' : 'initial' }} onClick={() => {
                rules[currentRule].source=key
                handleSourceHover(sources[key]);
                handleSourceSelect(key);
                setClicked(key); 
              }}>
              {key}
            </div>
            ))}
              </>)}
             
            </div>
            <div>
              <div style={{display:'flex',flexDirection:'row',cursor:'pointer',height:'2.3em'}} onClick={handleKYC}>
              {kycOpen ? (
                    <IndeterminateCheckBoxIcon style={{ marginTop: '0.35em' }} />
                  ) : (
                    <AddBoxOutlinedIcon style={{ marginTop: '0.35em' }} />
                  )}
              <p style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',marginLeft:'0.5em'}}>Identity Risk</p>
              </div>
              {kycOpen && (<>
                {Object.keys(sources).filter(key => [5, 6, 7, 8].includes(sources[key])).map((key) => (
            <div key={key} style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',paddingLeft:'2.3em',cursor:"pointer",backgroundColor: clicked === key ? '#FFEDEE' : 'transparent', borderRadius:clicked === key ? '5px' : '0px',
              color: clicked === key ? '#D32028' : 'initial' }} onClick={() => {
                rules[currentRule].source=key
                handleSourceHover(sources[key]);
                handleSourceSelect(key);
                setClicked(key); 
              }}>
              {key}
            </div>
            ))}
              </>)}
             
            </div>
          <div>
              <div style={{display:'flex',flexDirection:'row',cursor:'pointer',height:'2.5em'}} onClick={handleBorrower}>
              {borrowerOpen ? (
                    <IndeterminateCheckBoxIcon style={{ marginTop: '0.35em' }} />
                  ) : (
                    <AddBoxOutlinedIcon style={{ marginTop: '0.35em' }} />
                  )}
              <p style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',marginLeft:'0.5em'}}>Credit Risk</p>
              </div>
              {borrowerOpen && (<>
                {Object.keys(sources).filter(key => [1,9].includes(sources[key])).map((key) => (
            <div key={key} style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',paddingLeft:'2.3em',cursor:"pointer",backgroundColor: clicked === key ? '#FFEDEE' : 'transparent', borderRadius:clicked === key ? '5px' : '0px',
              color: clicked === key ? '#D32028' : 'initial' }} onClick={() => {
                rules[currentRule].source=key
                handleSourceHover(sources[key]);
                handleSourceSelect(key);
                setClicked(key); 
              }}>
              {key}
            </div>
            ))}
              </>)}
             
            </div>
            
           
            <div>
              <div style={{display:'flex',flexDirection:'row',cursor:'pointer',height:'2.5em'}} onClick={handleFinance}>
              {financeOpen ? (
                    <IndeterminateCheckBoxIcon style={{ marginTop: '0.35em' }} />
                  ) : (
                    <AddBoxOutlinedIcon style={{ marginTop: '0.35em' }} />
                  )}
              <p style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',marginLeft:'0.5em'}}>Income Risk</p>
              </div>
              {financeOpen && (<>
                {Object.keys(sources).filter(key => [2,4].includes(sources[key])).map((key) => (
            <div key={key} style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',paddingLeft:'2.5em',cursor:"pointer",backgroundColor: clicked === key ? '#FFEDEE' : 'transparent', borderRadius:clicked === key ? '5px' : '0px',
              color: clicked === key ? '#D32028' : 'initial' }} onClick={() => {
                rules[currentRule].source=key
                handleSourceHover(sources[key]);
                handleSourceSelect(key);
                setClicked(key); 
              }}>
              {key}
            </div>
            ))}
              </>)}
             
            </div>
          {/* {Object.keys(sources).map((key) => (
            <div key={key} style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',paddingLeft:'5px',cursor:"pointer",backgroundColor: clicked === key ? '#FFEDEE' : 'transparent', borderRadius:clicked === key ? '5px' : '0px',
              color: clicked === key ? '#D32028' : 'initial' }} onClick={() => {
                rules[currentRule].source=key
                handleSourceHover(sources[key]);
                handleSourceSelect(key);
                setClicked(key); 
              }}>
              {key}
            </div>
            ))} */}
          </div>
        </div>
        {clicked ? <div style={{width:'16%',marginLeft:'10px',boxShadow:'0px 2px 4px -2px #1018280F',borderRadius:'8px',border:'1px solid #EAECF0'}}>
        <p style={{textAlign:'center',fontFamily:'Inter-Medium',fontSize:'1vw'}}>Select Data Element</p>
        <DataElements dataElements={dataElements} selectedElement={selectedElement} onClick={handleDataElementSelect}/>

      </div>:null}
        
        {clicked ?  <div style={{width:'17%',marginLeft:'10px',boxShadow:'0px 2px 4px -2px #1018280F',borderRadius:'8px',border:'1px solid #EAECF0'}}>
          <p style={{textAlign:'center',fontFamily:'Inter-Medium',fontSize:'1vw'}}>Select Operator</p>
          {/* <p style={{textAlign:'center',fontFamily:'sans-serif',fontSize:'0.7vw',lineHeight:'2px'}}>(Scroll down for more options)</p> */}
            <div style={{maxHeight:'55vh',overflow:'auto',margin:'1em'}}>
              {/* <div onClick={() => {selectOperator("<","Less Than (<)")
                rules[currentRule].op="<"
              }} style={{color:selectedOperator === "<" ?"#D32028":"black",cursor:'pointer',fontFamily:'Inter-Medium',fontSize:'0.9vw',backgroundColor:selectedOperator === "<" ? "#FFEDEE" : "transparent",borderRadius:selectedOperator === "<" ? "5px":"0px",padding:'5px'}}>Less Than ({"<"})</div> */}
              {/* <div onClick={() => {selectOperator(">","Greater Than");rules[currentRule].op=">"}} style={{color:selectedOperator === ">" ?"#D32028":"black",cursor:'pointer',fontFamily:'Inter-Medium',fontSize:'0.9vw',backgroundColor:selectedOperator === ">" ? "#FFEDEE" : "transparent",borderRadius:selectedOperator === ">" ? "5px":"0px",padding:'5px'}}>Greater Than ({">"})</div> */}
              <div onClick={() => {selectOperator("3","Equal To");rules[currentRule].op="3"}} style={{color:selectedOperator === "3" ?"#D32028":"black",cursor:'pointer',fontFamily:'Inter-Medium',fontSize:'0.9vw',backgroundColor:selectedOperator === "3" ? "#FFEDEE" : "transparent",borderRadius:selectedOperator === "3" ? "5px":"0px",paddingLeft:'5px'}}>Equal To </div>
              <div onClick={() => {selectOperator("7","NotEqualTo");rules[currentRule].op="7"}}style={{color:selectedOperator === "7" ?"#D32028":"black",cursor:'pointer',fontFamily:'Inter-Medium',fontSize:'0.9vw',backgroundColor:selectedOperator === "7" ? "#FFEDEE" : "transparent",borderRadius:selectedOperator === "7" ? "5px":"0px",paddingLeft:'5px'}}>NotEqualTo </div>
              <div onClick={() => {selectOperator("2","Less Than or Equal To");rules[currentRule].op="2"}} style={{color:selectedOperator === "2" ?"#D32028":"black",cursor:'pointer',fontFamily:'Inter-Medium',fontSize:'0.9vw',backgroundColor:selectedOperator === "2" ? "#FFEDEE" : "transparent",borderRadius:selectedOperator === "2" ? "5px":"0px",paddingLeft:'5px',whiteSpace:'nowrap'}}>Less Than or Equal To </div>
              <div onClick={() => {selectOperator("1","Greater Than or Equal To");rules[currentRule].op="1"}} style={{color:selectedOperator === "1" ?"#D32028":"black",cursor:'pointer',fontFamily:'Inter-Medium',fontSize:'0.9vw',backgroundColor:selectedOperator === "1" ? "#FFEDEE" : "transparent",borderRadius:selectedOperator === "1" ? "5px":"0px",paddingLeft:'5px',whiteSpace:'nowrap',paddingRight:'5px'}}>Greater Than or Equal To</div>
              <div onClick={() => {selectOperator("4","Between");rules[currentRule].op="4"}}style={{color:selectedOperator === "4" ?"#D32028":"black",cursor:'pointer',fontFamily:'Inter-Medium',fontSize:'0.9vw',backgroundColor:selectedOperator === "4" ? "#FFEDEE" : "transparent",borderRadius:selectedOperator === "4" ? "5px":"0px",paddingLeft:'5px'}}>Between</div>
              <div onClick={() => {selectOperator("6","Not In");rules[currentRule].op="6"}}style={{color:selectedOperator === "6" ?"#D32028":"black",cursor:'pointer',fontFamily:'Inter-Medium',fontSize:'0.9vw',backgroundColor:selectedOperator === "6" ? "#FFEDEE" : "transparent",borderRadius:selectedOperator === "6" ? "5px":"0px",paddingLeft:'5px'}}>Not In</div>
              <div onClick={() => {selectOperator("5","IN");rules[currentRule].op="5"}}style={{color:selectedOperator === "5" ?"#D32028":"black",cursor:'pointer',fontFamily:'Inter-Medium',fontSize:'0.9vw',backgroundColor:selectedOperator === "5" ? "#FFEDEE" : "transparent",borderRadius:selectedOperator === "5" ? "5px":"0px",paddingLeft:'5px'}}>IN</div>

           </div>
        </div>:null}
       
        <Modal isOpen={isModalOpen} toggle={()=>toggleModal()} backdrop="static">
        <ModalHeader toggle={()=>toggleModal()}>Enter Details</ModalHeader>
        <ModalBody style={{padding:'20px'}}>
          <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Policy Name</p>
          <Input onChange={handleNameChange} />
          <p style={{lineHeight:'1em',paddingTop:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Policy Description</p>
          <Input onChange={(e)=>setDescription(e.target.value)}/>
          <p style={{lineHeight:'1em',paddingTop:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Institute Name</p>
          <input
            type="radio"
            id="allOption"
            name="instituteOptions"
            value="All"
            checked={selectedOption === 'All'}
            onChange={handleOptionChange}
          />
            <label>All</label>
          <input
            type="radio"
            id="selectOption"
            name="instituteOptions"
            value="Select"
            checked={selectedOption === 'Select'}
            onChange={handleOptionChange}
            style={{ marginLeft: '2rem' }}
          />            
          <label>Select Institute</label>
          {selectedOption === 'Select' && (
          <Input id="searchInstituteNameBox" onChange={(e)=>getInsituteInfo(e.target.value)} type="text" placeholder="Search Institute Name" style={{marginTop:'1em'}}/>
          )}
          <div style={{marginTop:'1em'}}>
         {
             instituteList?instituteList.map((item)=>{
             return(
               <div onClick={()=>handleSearchInstituteDetails(item)} className="instituteName" style={{cursor:'pointer',border:'1px solid grey',borderRadius:'0.5em'}}>
               <p  style={{fontWeight:'600',paddingTop:'0.5em',paddingLeft:'0.5em'}}>{item.brandName}</p>
               </div>
             )
             }
             ):null

         }
         </div>
          <p style={{ lineHeight: '1em', paddingTop: '1em', fontFamily: 'Inter-Medium', fontSize: '0.9vw' }}>Stage</p>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle caret>
              {selectedStage}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleStageSelect("AfterConsent")}>AfterConsent</DropdownItem>
              <DropdownItem onClick={() => handleStageSelect("InProcess")}>InProcess</DropdownItem>
              <DropdownItem onClick={() => handleStageSelect("AfterSanction")}>AfterSanction</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </ModalBody>
        <Button style={{backgroundColor:'#D32028',border:'none',width:'15%',display:'flex',justifyContent:'center',marginLeft:'42%',marginBottom:'15px',marginTop:'10px'}} onClick={submit}>
            Submit
          </Button>
        </Modal>
        <Modal isOpen={modal} >
        <ModalHeader toggle={()=>handleEdit()}>Policy</ModalHeader>
        <ModalBody>
          <p>Policy Name : <span style={{fontWeight:'bold'}}>{policyName}</span></p>
          <p style={{color:'#D32028'}}>You Cannot Edit the policy as it is active and already assigned.</p>
        </ModalBody>
        
      </Modal>
        {clicked ? (
          <div  style={{width:'52%',marginLeft:'10px',boxShadow:'0px 2px 4px -2px #1018280F',borderRadius:'8px',border:'1px solid #EAECF0',display:'flex',flexDirection:'column',justifyContent:'space-between'}}> 
          <div>
            <div style={{display:'flex',justifyContent:'space-between',padding:'5px'}}>
            <div style={{textAlign:'center',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Rule Editor</div>         
          <div style={{fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Source : <span style={{color:'#D32028',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>{selectedSource}</span> </div>
          {selectedElement ? 
          <div style={{fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Data Element : <span style={{color:'#D32028',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>{selectedElement}</span> </div>:null}
            </div>
            {policyClicked?
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-around'}}>
            <p style={{fontFamily:'Inter-Medium',fontSize:'1vw',marginTop:'1em'}}>{selectedPolicyName}</p>
            <p><img src={close} style={{height:'20px',width:'20px',cursor:'pointer',marginTop:'1em'}} onClick={handleClose}/></p> 
          </div>:null
            }
          
            </div> 
            
          <div style={{display:'flex',flexDirection:'column',width:'100%',maxHeight:'50vh',overflow:'auto'}}>
        <>
        {policyClicked ? 
        <>
        
         {ruleArray?.map((item,index)=>(
          <div style={{display:'flex',alignItems:'center'}}>
          <div key={index} style={{display:'flex',alignItems:'center',justifyContent:'center',padding:'1em',border:'none',height:'7vh',marginTop:'0.5em',width:"66%",marginLeft:'2%', borderRadius: "8px",backgroundColor:selectedRuleIndex === index ?'#FFEDEE':"#F1F1F1",cursor:'pointer',border: selectedRuleIndex === index ? "1px solid #FAD9DB":""}} onClick={() => handleRuleClick(index,item)}>
          <div style={{fontFamily:'Inter-Medium',fontSize:'1vw',marginRight:'2%',lineHeight:'15px'}}>{item.element}</div>
          <div style={{fontFamily:'Inter-Medium',fontSize:'1vw',marginRight:'2%'}}>{item.operator === 1?">=":item.operator === 2?"<=":item.operator === 3?"=":item.operator === 5?"IN":item.operator === 6?"NotIn":item.operator === 7?"!=":item.operator}</div>
          {item.operator ? (
  <>
    {item.dataType === 1 && (item.operator === 1 || item.operator === 2 || item.operator === 3 || item.operator === "7") ? (
      <Input style={{ marginLeft: '1%', width: '45%', margin: '5px' }} onChange={(e) => setValue1(e.target.value)} defaultValue={item?.value1}/>
    ) : item.dataType === 2 && (item.operator === 1 || item.operator === 2 || item.operator === 4) ? (
      item.operator === 1 || item.operator === 2 ? (
        <Datetime
                  closeOnSelect="false"
                  id="fromDate"
                  dateFormat="DD MMM YYYY"
                  timeFormat={false}
                  inputProps={{ placeholder: 'Select Date',style:{fontFamily:'Inter-Medium', backgroundImage: `url(${calender})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '5px center',
                  backgroundSize: '15px 15px', paddingLeft: '30px',fontSize:"14px",width:'200px'} }}
                />
      ) : item.operator === 4 ? (
        <>
          <Datetime
                  closeOnSelect="false"
                  id="fromDate"
                  dateFormat="DD MMM YYYY"
                  timeFormat={false}
                  inputProps={{ placeholder: 'Select Date',style:{fontFamily:'Inter-Medium', backgroundImage: `url(${calender})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '5px center',
                  backgroundSize: '15px 15px', paddingLeft: '30px',fontSize:"14px",width:'200px'} }}
                />
          <Datetime
                  closeOnSelect="false"
                  id="fromDate"
                  dateFormat="DD MMM YYYY"
                  timeFormat={false}
                  inputProps={{ placeholder: 'Select Date',style:{fontFamily:'Inter-Medium', backgroundImage: `url(${calender})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '5px center',
                  backgroundSize: '15px 15px', paddingLeft: '30px',fontSize:"14px",width:'200px'} }}
                />
        </>
      ) : null
    ) : null}
  </>
) : ""}
          <button style={{backgroundColor:'#D32028',height:'20px',display:'flex',justifyContent:'center',alignItems:'center',padding:'5px',color:'white',borderRadius:'5px'}} onClick={()=>handleSubmit(item,index)}>save</button>
          </div>
          <div style={{display:'flex'}}>
          <Dropdown isOpen={trueDropdownOpen[index]} toggle={() => toggleTrueDropdown(index)} style={{marginLeft:'7%',position:'static'}}>
        <DropdownToggle caret style={{backgroundColor:selectedRuleIndex === index ?'#FFEDEE':'#F1F1F1',color:'black',borderColor:selectedRuleIndex === index ?'#FAD9DB':'#F1F1F1'}}>
        {modifiedTrueActions[index] 
            ? modifiedTrueActions[index] 
            : item?.ifTrueAction !== undefined 
              ? (item.ifTrueAction === 1 ? 'A' : item.ifTrueAction === 0 ? 'R' : 'M') 
              : (trueDropdownSelection[index] || 'T')}          </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => handleTrueDropdownSelect(index, 'Approve')}>Approve</DropdownItem>
          <DropdownItem onClick={() => handleTrueDropdownSelect(index, 'Manual')}>Manual</DropdownItem>
          <DropdownItem onClick={() => handleTrueDropdownSelect(index, 'Reject')}>Reject</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Dropdown isOpen={falseDropdownOpen[index]} toggle={() => toggleFalseDropdown(index)} style={{marginLeft:'7%',position:'static'}}>
        <DropdownToggle caret style={{backgroundColor:selectedRuleIndex === index ?'#FFEDEE':'#F1F1F1',color:'black',borderColor:selectedRuleIndex === index ?'#FAD9DB':'#F1F1F1'}}>
          {modifiedFalseActions[index] 
            ? modifiedFalseActions[index] 
            : item?.ifFalseAction !== undefined 
              ? (item.ifFalseAction === 1 ? 'A' : item.ifFalseAction === 0 ? 'R' : 'M') 
              : (falseDropdownSelection[index] || 'F')}  
          </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => handleFalseDropdownSelect(index, 'Approve')}>Approve</DropdownItem>
          <DropdownItem onClick={() => handleFalseDropdownSelect(index, 'Manual')}>Manual</DropdownItem>
          <DropdownItem onClick={() => handleFalseDropdownSelect(index, 'Reject')}>Reject</DropdownItem>
        </DropdownMenu>
      </Dropdown>
          </div>
          <div style={{marginLeft:'3%',}}>
         {index === 0 ? <img src={add} style={{height:'3vh',cursor:'pointer'}} onClick={() =>{ handleAddRule(index) 
          setCurrentRule(old=>old+1)
         } 
         }/>:
         <>
         <img src={add} style={{height:'3vh',cursor:'pointer'}} onClick={() => {handleAddRule(index) 
         setCurrentRule(old=>old+1)
         } }/>
         <img src={remove} style={{height:'3vh',marginLeft:'6px',cursor:'pointer'}} onClick={() => {handleDeleteRule(index)
           setCurrentRule(old=>old-1)
         }}/>

         </>}
          </div>
          </div>
        ))}
        </>
        : (
        <> {rules?.map((item,index)=>(
          <div style={{display:'flex',alignItems:'center'}}>
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',border:'none',height:'7vh',marginTop:'0.5em',width:"69%",marginLeft:'2%', borderRadius: "8px",backgroundColor:rules?.length -1 === index ?'#FFEDEE':'#F1F1F1',border: rules?.length -1 === index  ? "1px solid #FAD9DB":""}}>
          <div style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',marginRight:'2%',lineHeight:'15px'}}>{item.data}</div>
          <div style={{fontFamily:'Inter-Medium',fontSize:'1vw',marginRight:'2%'}}>{item.op === "1"?">=":item.op === "2"?"<=":item.op === "3"?"=":item.op === "5"?"IN":item.op === "6"?"NotIn":item.op === "7"?"!=":null}</div>
          {item.op ? (
  <>
   {datatype === 1 && item.op === "4" ? (
    <>
      <div style={{fontFamily:'Inter-Medium',fontSize:'1vw',marginRight:'1%'}}>{">"}</div>
     <Input style={{ marginLeft: '1%', width: '25%', margin: '5px' }} onChange={(e) => setValue1(e.target.value)} />
     <div style={{fontFamily:'Inter-Medium',fontSize:'1vw',marginRight:'1%'}}>{"<"}</div>
     <Input style={{ marginLeft: '1%', width: '25%', margin: '5px' }} onChange={(e) => setValue2(e.target.value)} />
     </>
) : datatype === 1 && (item.op === "1" || item.op === "2" || item.op === "3" || item.op === "7") ? (
  <Input style={{ marginLeft: '1%', width: '50%', margin: '5px' }} onChange={(e) => setValue1(e.target.value)} />
) : 
datatype === 2 && (item.op === "1" || item.op === "2" || item.op === "4") ? (
  item.op === "1" || item.op === "2" ? (
    <Input style={{ marginLeft: '1%', width: '50%', margin: '5px' }} onChange={(e) => setValue1(e.target.value)} />
  ) : item.op === "4" ? (
    <>
      <Datetime
        closeOnSelect="false"
        id="fromDate"
        dateFormat="DD MMM YYYY"
        timeFormat={false}
        inputProps={{ placeholder: 'Select Date', style: { fontFamily: 'Inter-Medium', backgroundImage: `url(${calender})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '5px center',
        backgroundSize: '15px 15px', paddingLeft: '30px', fontSize: "14px", width: '200px' } }}
      />
      <Datetime
        closeOnSelect="false"
        id="fromDate"
        dateFormat="DD MMM YYYY"
        timeFormat={false}
        inputProps={{ placeholder: 'Select Date', style: { fontFamily: 'Inter-Medium', backgroundImage: `url(${calender})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '5px center',
        backgroundSize: '15px 15px', paddingLeft: '30px', fontSize: "14px", width: '200px' } }}
      />
    </>
  ) : null
) : null}

  </>
) : ""}

          </div>
          <div style={{display:'flex'}}>
          <Dropdown isOpen={trueDropdownOpen[index]} toggle={() => toggleTrueDropdown(index)} style={{marginLeft:'7%',position:'static'}}>
        <DropdownToggle caret style={{backgroundColor:rules?.length -1 === index ?'#FFEDEE':'#F1F1F1',color:'black',borderColor:rules?.length -1 === index ?'#FAD9DB':'#F1F1F1'}}>{trueDropdownSelection[index] || 'T'}</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => handleTrueDropdownSelect(index, 'Approve')}>Approve</DropdownItem>
          <DropdownItem onClick={() => handleTrueDropdownSelect(index, 'Manual')}>Manual</DropdownItem>
          <DropdownItem onClick={() => handleTrueDropdownSelect(index, 'Reject')}>Reject</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <Dropdown isOpen={falseDropdownOpen[index]} toggle={() => toggleFalseDropdown(index)} style={{marginLeft:'7%',position:'static'}}>
        <DropdownToggle caret style={{backgroundColor:rules?.length -1 === index ?'#FFEDEE':'#F1F1F1',color:'black',borderColor:rules?.length -1 === index ?'#FAD9DB':'#F1F1F1'}}>{falseDropdownSelection[index] || 'F'}</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => handleFalseDropdownSelect(index, 'Approve')}>Approve</DropdownItem>
          <DropdownItem onClick={() => handleFalseDropdownSelect(index, 'Manual')}>Manual</DropdownItem>
          <DropdownItem onClick={() => handleFalseDropdownSelect(index, 'Reject')}>Reject</DropdownItem>
        </DropdownMenu>
      </Dropdown>
          </div>
          <div style={{marginLeft:'3%'}}>
         {index === 0 ? <img src={add} style={{height:'3vh',cursor:'pointer'}} onClick={() =>{ handleAddRule(index) 
          setCurrentRule(old=>old+1)
         } 
         }/>:
         <>
         <img src={add} style={{height:'3vh',cursor:'pointer'}} onClick={() => {handleAddRule(index) 
         setCurrentRule(old=>old+1)
         } }/>
         <img src={remove} style={{height:'3vh',marginLeft:'8px',cursor:'pointer'}} onClick={() => {handleDeleteRule(index)
           setCurrentRule(old=>old-1)
         }}/>

         </>} 
          </div>
          </div>
        ))}</>)
       
        }
        
       
        </>
        
        
       
         
         </div>
         <div>
         {clicked ? 
        <div style={{display:'flex',justifyContent:'space-between'}}>
          <div style={{margin:'2%',marginLeft:'5%'}}>
          {/* <button  style={{backgroundColor:'#D32028',borderRadius:'8px',paddingLeft:'10px',height:'40px',paddingRight:'10px',fontFamily:'Inter-Medium',color:'white',    cursor: 'pointer' }} onClick={() => {
            toggleModal();
        }}>Add</button> */}
          </div>
           <div style={{display:'flex',justifyContent:'flex-end',margin:'2%',paddingRight:'3%'}}>
        <button  style={{backgroundColor:'#D32028',borderRadius:'8px',paddingLeft:'10px',height:'40px',paddingRight:'10px',fontFamily:'Inter-Medium',color:'white',    cursor: 'pointer' }}
         onClick={() => {
            toggleModal();
        }}>Save</button>
        </div>
        </div>
       :""}
         </div>
        </div>
       ):"" }
        </div>
        <div  style={{overflow:'auto',backgroundColor:'white'}}>
          <table hover className='tables'>
            <thead className='table-heading'>
              <th style={{paddingLeft:'10%'}}>
                Policy Name
              </th>
              <th style={{paddingLeft:'10%'}}>
                Policy Description
              </th>
              <th style={{paddingLeft:'10%'}}>
                Institute Name
              </th>
              <th style={{paddingLeft:'10%'}}>
                Actions
              </th>
            </thead>
            {tableData?.map((item,index)=>{
              return(
                <tbody className="table-body" >
                  <tr className='table-row'>
                    <td style={{width:'40rem',paddingLeft:'10%'}}>
                      {item?.name}
                    </td>
                    <td style={{width:'40rem',paddingLeft:'10%'}}>
                      {item?.description}
                    </td>
                    <td style={{width:'40rem',paddingLeft:'10%'}}>
                      -
                    </td>
                    <td style={{width:'20rem',paddingLeft:'10%'}}>
                      <img src={edit} style={{height:'20px',paddingLeft:'20px',cursor:'pointer'}} onClick={() => item?.editable ? handlePolicy(item?.policyId,item?.name) : handleEdit(item.name)}/>
                    </td>
                  </tr>
                </tbody>
              )
            })}
          </table>
        </div>
      </div>
   </Card>
);
};

export default Rules;







