import React, { useState,useEffect } from 'react';
import axiosInstance from '../../../helpers/axios';
import axios from 'axios'
import Datetime from 'react-datetime';
import jwt_decode from 'jwt-decode';
import action3 from "../../../assets/images/action3.png"
import {
  Alert,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Spinner,
  FormGroup,
  Input,
} from 'reactstrap';
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import dropdown from "../../../assets/images/dropdown.png"
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import close from "../../../assets/images/close.png";

import {userActions} from '../../../actions/user.actions';


const ApplicationStatusEnum = {
  InSubmission: 'In Submission',
  InReview: 'In Review',
  InSanction: 'In Sanction',
  InDisbursement: 'In Disbursement',
  PendingDisbursement: "Pending Disbursement",
  Disbursed: 'Disbursed',
  Rejected: 'Rejected',
  Dropped: 'Dropped',
  OnHold:'On Hold', ReadyToDisburse:'Ready to Disburse',
};
export default function MoveSanctionStatus(ApplicantId) {
console.log("app",ApplicantId)
  //const { user } = useUserAuth();
  const user = sessionStorage.getItem("user")
  const decode=jwt_decode(user)
  const disbursedstate = useSelector((state) => state.Disbursedstatuschange?.res)

  const droppedstate = useSelector((state) => state.Droppedstatuschange?.res)
  const dispatch = useDispatch();

  const id = ApplicantId.ApplicantId.applicationId

const name = ApplicantId.ApplicantId.name

  const [formLoader, setFormLoader] = useState(false);
  const [formStatusOpen, setStatusOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [disbursedDate, setDisbursedDate] = useState(null);

  const [adjustmentAmt, setAdjustmentAmt] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('Hold On...');
  const [disbursedUTR, setDisbursedUTR] = useState('');
  const [descriptionText, setDescriptionText] = useState('');
  const [apploanId, setLoanId] = useState('');
  const [status, setStatus] = useState('Select Status');

  
  const updateDroppedStatusUrl = `/admin/application/update-status`;
  const updateInReviewStatusUrl = `/end-user/submit/admin`;

  const updateDisbursedUrl = `/admin/application/disburse`;
  const popUpStatusForm = () => {
    setStatusOpen(true);
  };

  const handleStatusChange = () => {
    if (status === 'Status') {
      alert('NO STATUS CHANGE !');
    } else if (status === 'In Disbursement') {
      
      const disbursementData = {
        userId: ApplicantId.ApplicantId.userId,
        applicationId: ApplicantId.ApplicantId.applicationId,
        status: ApplicationStatusEnum.ReadyToDisburse,
        notes: descriptionText,
      };
      if(disbursedstate){
          if (disbursedstate?.message === 'Successful') {
            setFormLoader(!formLoader);
            setStatusOpen(!formStatusOpen);
            setConfirmationDialog(!confirmationDialog);
            setConfirmationMessage('Moved to In Disbursement Successfully');
            setConfirmationDialog(true);
            setTimeout(() => setConfirmationDialog(false), 2000);
            setTimeout(() => window.location.reload(true), 500);
          }else{
            setConfirmationMessage("Unauthorized --- Error Code");
          setConfirmationDialog(true);
          setTimeout(() => setConfirmationDialog(false), 3500);
          setTimeout(() => window.location.reload(true), 3000);
          }
        }else{
          dispatch(userActions.fetchDisbursedstatuschange(updateDroppedStatusUrl,disbursementData, user),[])
        }
      
    } else if (status === 'Dropped') {
      
      const droppedData = {
        userId: ApplicantId.ApplicantId.userId,
        applicationId: ApplicantId.ApplicantId.applicationId,
        status: ApplicationStatusEnum.Dropped,
        notes: descriptionText,
      };
      if(droppedstate){
        if (droppedstate?.message === 'Successful') {
          setFormLoader(!formLoader);
          setStatusOpen(!formStatusOpen);
          setConfirmationDialog(!confirmationDialog);
          setConfirmationMessage('Dropped Successfully');
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
          setTimeout(() => setConfirmationDialog(false), 2000);
          setTimeout(() => window.location.reload(true), 2000);
        }else{
          setConfirmationMessage("error");
            setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
              setTimeout(() => setConfirmationDialog(false), 2750);
        }
      }else{
        dispatch(userActions.fetchDroppedstatuschange(updateDroppedStatusUrl,droppedData, user),[])
      }
      
    }  else if (status === 'On Hold') {
      
      const statusData = {
        userId: ApplicantId.ApplicantId.userId,
        applicationId: ApplicantId.ApplicantId.applicationId,
        status: ApplicationStatusEnum.OnHold,
        notes: descriptionText,
      };
      if(droppedstate){
        if (droppedstate?.message === 'Successful') {
          setFormLoader(!formLoader);
          setStatusOpen(!formStatusOpen);
          setConfirmationDialog(!confirmationDialog);
          setConfirmationMessage('Moved to OnHold Successfully');
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
          setTimeout(() => setConfirmationDialog(false), 2000);
          setTimeout(() => window.location.reload(true), 2000);
        }else{
          setConfirmationMessage("error");
              setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
              setTimeout(() => setConfirmationDialog(false), 2750);
        }
      }else{
        dispatch(userActions.fetchDroppedstatuschange(updateDroppedStatusUrl,statusData, user),[])
      }
      
    } 
  };

  useEffect(()=>{
    handleStatusChange();
  },[droppedstate,disbursedstate])


  const handleToggle = () => {
    setDropDownOpen(!dropDownOpen);
  };
  const handleDescriptionText = (text) => {
    setDescriptionText(text);
  };


  const sendSanctionMailToCoapp=()=>{

    const urlSendCoAppMail=`/admin/application/send-sanction-mail?userId=${ApplicantId.ApplicantId.coapplicantId}&applicationId=${ApplicantId.ApplicantId.applicationId}`

    axiosInstance.get(urlSendCoAppMail,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    })
    .then((res)=>{
      alert("Mail Sent Successfully!")
      setStatusOpen(false);

    })

  }

  const [manuallyUploadedLA,setManuallyUploadFile]=useState(null)
const handleManuallyFileUpload=(e)=>{
  
  setManuallyUploadFile(e.target.files[0])
}
  const uploadManuallySigned=()=>{

    const uploadUrl=`/file/upload`
    const urlUploadSigned=`/agreement/upload-signed`
    const uploadFile=new FormData()
    uploadFile.append('file',manuallyUploadedLA)
    uploadFile.append('type',"signed_url")
    axiosInstance.post(uploadUrl,uploadFile)
    .then((res)=>{
      const body={
        userId:ApplicantId.ApplicantId.userId,
        applicationId:ApplicantId.ApplicantId.applicationId,
        signedDocumentUrl:res?.data?.data?.s3Url,
      }
      axiosInstance.post(urlUploadSigned,body)
      .then((res)=>{
        window.alert("Uploaded Signed Document")
        setTimeout(() => {
            window.location.reload()
        }, 1000);
      })
      .catch((err)=>{
        window.alert("Error Connect Tech Support")
      })


    })
    .catch((err)=>{
      window.alert("Error While Uploading")
    })

  }
  return (
    <>
    <div>
            <img  onClick={() => popUpStatusForm()} style={{cursor:'pointer',width:'18px'}} src={action3} alt="resend consent"/>

      <Modal  isOpen={confirmationDialog}>
        <ModalHeader>Waiting For Confirmation</ModalHeader>
        <ModalBody>{confirmationMessage}</ModalBody>
      </Modal>
      {formStatusOpen && (
      <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
     
      </>
      )}
      
    </div>
     <Modal 
     
      style={{
         
        top:"10%",
        
        borderRadius:"10px",
      }} isOpen={formStatusOpen}>
        <div style={{backgroundColor:"#E9E9E9",borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:'space-between',height:'60px',padding:"10px"}}>
                <p style={{fontFamily:"Inter-Medium",fontSize:'14px'}}>Update Status Here ( Sanction to <span style={{fontFamily:"Inter-Medium",color:'#D32028'}}>{status}</span> )</p>
                <p style={{fontFamily:"Inter-Medium",cursor: 'pointer'}} onClick={() => setStatusOpen(!formStatusOpen)}>
                  <img src={close}/>
                </p>
            </div>
            </div>
           
            <div style={{marginTop:'10px'}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",backgroundColor:'#FCF0F1',width:"100%",borderRadius:"10px"}}>
              <p style={{marginLeft:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}>Application ID <br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{id}</span></p>
               <p style={{marginRight:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}> Applicant Name<br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{name}</span></p>
            </div>
         
          
        </div>
        <ModalBody>
          <Form>
            <FormGroup>
             {/* {ApplicantId.ApplicantId.fundSourceCode==="GBP"?<p style={{border:'1px solid orange',padding:'1em',borderRadius:'10px',backgroundColor:'#fff0f5'}}><strong>Disburse</strong> from this form is disabled for fund source : GBP (Glaze Barter PVT LTD). <p style={{textDecoration:'underline'}}>Proceed with checkbox and disburse selected</p></p>:null} */}
             <p style={{lineHeight:'10px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Select Status*</p>

              <Dropdown isOpen={dropDownOpen} toggle={() => handleToggle()}>
               
              <DropdownToggle caret style={{ width:'100%',lineHeight:'10px',textAlign:"left",height:'40px',backgroundColor:'white',color:'gray',fontFamily:"Inter-Medium",fontSize:'14px'}} >
                  {status} 
                </DropdownToggle>
                 
                <DropdownMenu >
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}}  disabled={(ApplicantId?.ApplicantId?.paymentStatus === "Link Generated" || ApplicantId?.ApplicantId?.paymentStatus === "Failed")} onClick={(e) => setStatus(e.target.innerText)}>
                    In Disbursement
                  </DropdownItem>
                { ApplicantId?.ApplicantId?.coapplicantId? <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}} onClick={(e) => setStatus(e.target.innerText)}>
                    Send Sanction Email
                  </DropdownItem>:null}
                  {/* <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}} onClick={(e) => setStatus(e.target.innerText)}>
                    Upload Manually Signed LA
                  </DropdownItem> */}
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}} onClick={(e) => setStatus(e.target.innerText)}>
                    Dropped
                  </DropdownItem>
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}} onClick={(e) => setStatus(e.target.innerText)}>
                    On Hold
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              {status === 'In Disbursement' ? (
                <div>
                  
                  <p style={{marginTop:"20px",lineHeight:'1px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Remarks</p>
              <input
                type="text"
                id="description"
                placeholder="Write Description Here"
                style={{borderRadius:"5px",borderStyle:"solid",borderWidth:"1px", height: '40px', width: '330px',fontFamily:"Inter-Medium",fontSize:'14px',paddingLeft:'10px' }}
                onChange={(e) => handleDescriptionText(e.target.value)}
              />
              </div>
              ) : (
                <></>
              )}
              {status === 'Dropped' ? (
                <div>
                
                <p style={{marginTop:"20px",lineHeight:'1px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Remarks</p>
              <input
                type="text"
                id="description"
                placeholder="Write Description Here"
                style={{borderRadius:"5px",borderStyle:"solid",borderWidth:"1px", height: '40px', width: '330px',fontFamily:"Inter-Medium",fontSize:'14px',paddingLeft:'10px' }}
                onChange={(e) => handleDescriptionText(e.target.value)}
              />
                </div>
              ) : (
                <></>
              )}
              {status === 'On Hold' ? (
                <div>
                  <p style={{marginTop:"20px",lineHeight:'1px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Remarks</p>
              <input
                type="text"
                id="description"
                placeholder="Write Description Here"
                style={{borderRadius:"5px",borderStyle:"solid",borderWidth:"1px", height: '40px', width: '330px',fontFamily:"Inter-Medium",fontSize:'14px',paddingLeft:'10px' }}
                onChange={(e) => handleDescriptionText(e.target.value)}
              />
                </div>
              ) : (
                <></>
              )}
              {status === 'Send Sanction Email' ? (
                <div style={{backgroundColor:'#FCF0F1',padding:'10px',display:'flex',marginTop:'1em',justifyContent: 'space-between',}}>
                  <p style={{marginTop:"20px",lineHeight:'1px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Trigger Sanction Mail to < span style={{fontWeight:'bold',color:'#d32028'}}>Co Applicant</span></p>
             <Button style={{backgroundColor:'#d32028',width:'13em',borderRadius:'1em',fontSize:'14px',border:'none'}} onClick={()=>sendSanctionMailToCoapp()}> Send Sanction Mail </Button>
                </div>
              ) : (
                <></>
              )}
              {status === 'Upload Manually Signed LA' ? (
                <>
                
                <div style={{backgroundColor:'#FCF0F1',padding:'10px',display:'flex',marginTop:'1em',justifyContent: 'space-between',}}>
                  <Input   type="file" onChange={(e)=>handleManuallyFileUpload(e)}/>
                </div>
             <Button style={{backgroundColor:'#d32028',width:'12em',borderRadius:'1em',fontSize:'14px',border:'none'}} onClick={()=>uploadManuallySigned()}> Upload </Button>
             </>
              ) : (
                <></>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        {status!=="Send Sanction Email"&&status!=="Upload Manually Signed LA"?<ModalFooter style={{display:"flex",flexDirection:"row",justifyContent:"center",backgroundColor:"#E9E9E9",height:'60px'}}>

         
<Button style={{backgroundColor:"#D32028",color:"white",fontFamily:"Inter-Medium",fontSize:"13px",border:"solid",borderRadius:"5px",borderWidth:"1px",height:"30px"}} disabled={formLoader} onClick={() => handleStatusChange()}>
  Make Changes
</Button>
</ModalFooter>:null}
      </Modal>
      </>
  );
}




