import {userConstants} from '../constants/user.constants'

const user=sessionStorage.getItem('user')
const initialState=user? {loggedIn:true,isLoading:false,user }:{}

export function generateManualPaymentsReceipt(state = initialState, action) {
  
  console.log("inside generateManual function", action)
  switch (action.type) {
    case userConstants.POST_GENERATE_RECEIPT_REQUEST:
      return {
        user: action.user,
        isLoading : true

      };
    case userConstants.POST_GENERATE_RECEIPT_SUCCESS:
      const {res} = action
        console.log("Action", action)
      return {
        res,
        isLoading : false
      };
    case userConstants.POST_GENERATE_RECEIPT_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}