/* eslint-disable no-undef */
import React,{useEffect} from 'react'


export default function Bureau() {

 const [link,setLink]=React.useState(null)

 getBureauSession('708587bad0904485abe1127847dd62cd','475a4139','Aaisha khan','','huzaifa mohammad khan','8208417798')
 .then((res)=>{
  console.log(res)
setLink(res)
})

  return (<>
    <iframe width="400" height="600" src={link} onLoad={startBureauSession} title="bureau">
      
    </iframe>
     
      </>
  )
}
