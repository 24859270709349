import React from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {Autocomplete, TextField} from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../../actions/user.actions';
import CustomTextField from '../../../TextField/TextField'
import { Box } from '@mui/material'
import Button from '@mui/material/Button';
import './BasicLoanForm.css'
import InputFileUpload from '../Fileupload/FileUpload';
import aadhaarImage from '../../../../assets/images/aadhar_image.png'
import panImage from '../../../../assets/images/pan-image.png'
import drivingLicenseImg from '../../../../assets/images/Driving-license-image.jpg'
import bankAccountImg from '../../../../assets/images/Bank-Account-image.jpg'
import saleDeed from '../../../../assets/images/saledeed.jpg'
import ec from '../../../../assets/images/ec.jpeg'
import legalOpinion from '../../../../assets/images/legal_opinion.jpg'
import electricityGasBillImg from '../../../../assets/images/electricity-gas-bill.jpg'
import othersImg from '../../../../assets/images/others-image.jpg'
import CustomAutoComplete from '../../../AutoComplete/AutoComplete'
import DeleteIcon from '@mui/icons-material/Delete';
import {Checkbox} from '@mui/material';
import axiosInstance from '../../../../helpers/axios';
import jwt_decode from 'jwt-decode'
import moment from 'moment'

const tenureOptions = [
  {label:'1'}, {label:'2'},{label:'3'},{label:'4'},{label:'5'},{label:'6'},{label:'7'},{label:'8'},{label:'9'},{label:'10'},
  {label:'11'}, {label:'12'},{label:'13'},{label:'14'},{label:'15'},{label:'16'},{label:'17'},{label:'18'},{label:'19'},{label:'20'},
  {label:'21'}, {label:'22'},{label:'23'},{label:'24'},
]

const propertyTypeOptions = [
  { label: 'Residential Property' },
  { label: 'Commertial Property'},
  { label: 'Industrial Property' },
  { label: 'Warehouse Property' },
]

const productTypeOptions = [
  { label: '2 Wheeler' },
  { label: '4 Wheeler'},
  { label: 'Commercial Vehicle' },
]


const fileCardTypes = [
    { value: 'Aadhaar Front', label: 'Aadhaar Front', id: 'aadhaar_front', image:aadhaarImage, disabled:false },
    { value: 'Aadhaar Back', label: 'Aadhaar Back', id: 'aadhaar_back', image:aadhaarImage, disabled:false },
    { value: 'PAN', label: 'PAN', id: 'pan' , image:panImage, disabled:false},
    { value: 'Driving License Front', label: 'Driving License Front', id: 'driving_license_front', image:drivingLicenseImg , disabled:false},
    { value: 'Driving License Back', label: 'Driving License Back', id: 'driving_license_back', image:drivingLicenseImg , disabled:false},
    { value: 'Bank Statement 3m', label: 'Bank Statement 3m', id: 'bank_statement3', image:bankAccountImg , disabled:false},
    { value: 'Bank Statement 6m', label: 'Bank Statement 6m', id: 'bank_statement6', image:bankAccountImg , disabled:false},
    { value: 'Utility Bill', label: 'Utility Bill', id: 'utility_bill', image:electricityGasBillImg, disabled:false },
    { value: 'Others', label: 'Others', id: 'others', image:othersImg, disabled:false },

]

const mlFileCardTypes = [
  ...fileCardTypes, 
  { value: 'Original Sale deed copy', label: 'Original Sale deed copy', id: 'original_sale_deed_copy', image: saleDeed, disabled:false},
  { value: 'Original Sale deed copy', label: 'EC', id: 'ec', image:ec , disabled:false},
  { value: 'Original Sale deed copy', label: 'Legal Opinion', id: 'legal_opinion', image:legalOpinion, disabled:false },
]

export default function BasicLoanForm({loanType}) {
  const dispatch = useDispatch()
  const user=sessionStorage.getItem('user')
  const decode=jwt_decode(user)
  const [employment, setEmployment] = useState('EMPLOYED')
  const [files, setFiles] = useState([])
  const [uploadedFile,setUploadedFile]=useState(null)
  const [documentType,setDocumentType]=useState(null)
  const [filePassword,setFilePassword]=useState()
  const [instituteName, setInistuteName] = useState('')
  const [instituteList,setInstituteList]=useState([])
  const [startingPhrase,setStarterPhrase]=useState("Type Institute Name")
  const [studentFirstName, setStudentFirstName] = useState(null)
  const [studentLastName, setStudentLastName] = useState(null)
  const [studentEmail, setStudentEmail] = useState(null)
  const [studentPhone, setStudentPhone] = useState(null)
  const [courseName, setCourseName] = useState(null)
  const [courseFees, setCourseFees] = useState(null)
  const [tenure, setLoanTenure] = useState(null)
  
  const [borrowerFirstName,setBorrowerFirstName]=useState(null)
  const [borrowerLastName,setBorrowerLastName]=useState(null)
  const [borrowerEmail,setBorrowerEmail]=useState(null)
  const [borrowerPhone,setBorrowerPhone]=useState(null)
  const [borrowerAadhaar,setBorrowerAadhaar]=useState(null)
  const [borrowerAddress,setBorrowerAddress]=useState(null)
  const [borrowerCity,setBorrowerCity]=useState(null)
  const [borrowerState,setBorrowerState]=useState(null)
  const [borrowerPin,setBorrowerPin]=useState(null)
  const [borrowerPan,setBorrowerPan]=useState(null)
  const [borrowerDOB,setBorrowerDOB]=useState(null)

  const [isValidStudentEmail,setIsValidStudentEmail]=useState(false)
  const [isValidBorrowerEmail,setIsValidBorrowerEmail]=useState(false)
  const [isStudentApplicant,setIsStudentApplicant]=useState(false)

  const [employerName, setEmployerName] = useState(null)
  const [borrowerSalary, setBorrowerSalary] = useState(null)

  const [disableFields, setDisabledFields] = useState(false)
  

  const [fileUploadCards, setFileUploadCards] = useState(loanType==='Mortgage Loan'?mlFileCardTypes:  fileCardTypes)

  let [uploadedFilesArray,setUploadedFilesArray]=useState([])

  const [errors, setErrors] = useState({})
  const [panApiLimit, setPanApiLimit] = useState(1)
  
  const validateEmail = email => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  const handleEmploymentChange = (e) => {
    setEmployment(e.target.value)
  }

  const handleFiles = (newFile, documentType) => {
    setUploadedFile(newFile)
    setDocumentType(documentType)
  }
  useEffect(() => {
    if (uploadedFile && documentType) {
      handleFileUpload(documentType);
    }
  }, [uploadedFile, documentType]);

  const handleFileUpload= async (documentType)=>{
    const urlUpload='/file/upload'
    const uploadObj=new FormData()
    if(uploadObj){
        uploadObj.append('file',uploadedFile)
        uploadObj.append('type',documentType)
      try{
        
        const res = await axiosInstance.post(urlUpload,uploadObj)
        const obj={
          userId: "",
          type:documentType==="aadhaar_back"||documentType==="aadhaar_front"?"aadhaar":documentType==="driving_license_back"||documentType==="driving_license_front"?"driving_license":documentType,
          url1:documentType!=="aadhaar_back"&&documentType!=="driving_license_back"? res?.data?.data?.s3Url: "",
          url2:(documentType==="aadhaar_back"||documentType==="driving_license_back")?res?.data?.data?.s3Url:"",
          documentId:'string',
          documentType:res?.data?.data?.s3Url,
          documentPassword:filePassword,
          fileType:documentType,
          fileName:uploadedFile.name
        }

        setFileUploadCards(prev =>
          prev.map(item => item.id === documentType ? {...item, disabled:true}: item)
        )

        if(uploadedFilesArray.length === 0){
          setUploadedFilesArray([obj])
        }
        else{
          setUploadedFilesArray(old => [...old, obj])
        }
        setUploadedFile(null) 
        setFilePassword(null)
        }
      catch(e){
        console.log('error', e)
        alert('Network Error, Please reupload the file')
      }
    }
      
  }

  const handleDeleteFile = (fileType) => {
      const updatedFileUploadCards = fileUploadCards.map(item => item.id===fileType ? {...item, disabled:false}: item)
      setFileUploadCards(updatedFileUploadCards)
      const finalFiles = uploadedFilesArray.filter(file => file.fileType !== fileType)
      setUploadedFilesArray(finalFiles)
  }

  const getInstituteInfo=(text)=>{
    setInistuteName(text)
    const instituteInfoUrl=`${process.env.REACT_APP_BASE_URL}/institute/info/name?name=${text}`;
    if(text?.length>=4)
    {
      axiosInstance.get(instituteInfoUrl,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res)=>{
        if(res.data.data==[])
        {
          setInstituteList([])
          setStarterPhrase("No institute Found")
        }
        else
        setInstituteList(res.data.data)
        setStarterPhrase("No institute Found")
      })
    }
    else{
      setInstituteList([])
      setStarterPhrase("Type Institute Name")
    }
  }

  const handleStudentBorrower=(event)=>{
      
    if(event.target.checked)
    {
      setIsStudentApplicant(true)
      setBorrowerFirstName(studentFirstName)
      setBorrowerLastName(studentLastName)
      setBorrowerPhone(studentPhone)
      setBorrowerEmail(studentEmail)
      setIsValidBorrowerEmail(isValidStudentEmail)
      setDisabledFields(true)
    }
    else
    { 
      setIsStudentApplicant(false)
      setBorrowerFirstName(null)
      setBorrowerLastName(null)
      setBorrowerPhone(null)
      setBorrowerEmail(null)
      setDisabledFields(false)
    }



  }

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

  const getPanPro=()=>{
   if(panApiLimit > 5){
     alert("You've reached your maximum API call limit for PAN details. Try again later.")
     window.location.reload(); 
     return
   }

    const panProUrl='/pan-pro'
    const panBody={
        
      panNumber     :     borrowerPan,
      dateOfBirth   :     moment(borrowerDOB).format('DD/MM/YYYY')  
    }

    if(getAge(borrowerDOB)<=18)
      {
       window.alert("Check borrower age ! must be above 18 years old")
      }
      else
      {
      axiosInstance.post(panProUrl,panBody)
      .then((res)=>{
        
        if(res?.data?.data?.pan_number)
        {
          createApplications(res?.data?.data, uploadedFilesArray)
          window.scrollTo(0, document.body.scrollHeight);
          // window.alert("Saved Details! Upload Documents Below.")
          
        }
        else
        {
          setPanApiLimit(prev => prev+1)
          window.alert("Invalid PAN Details or Date of Birth")
        }

      })
      .catch((err)=>{
        window.alert("Invalid PAN Details or Date of Birth")
      })
    }
  }
  

  const createApplications=(panProDetails,uploadedArray)=>{

    const get1=uploadedArray
    const get2=uploadedArray
    const get3=uploadedArray
    const aadhaarUrl1=get1?.filter(item=>item.type==="aadhaar")[0]?.url1
    const aadhaarUrl2=get2?.filter(item=>item.type==="aadhaar")[0]?.url2
    const panUrl=get3?.filter(item=>item.type==="pan")[0]?.url1
    
      const urlCreate='/summary/create/admin'
      
      

        const body={
          
           mobile: isStudentApplicant?studentPhone:borrowerPhone,
           instituteName:instituteName,
           firstName: isStudentApplicant?studentFirstName:borrowerFirstName,
           lastName: isStudentApplicant?studentLastName:borrowerLastName,
           studentName: `${studentFirstName} ${studentLastName}`,
           dateOfBirth: borrowerDOB,
           gender: panProDetails?.user_gender === "M" ? "Male":"Female",
           isCoapplicant: false,
           courseName: courseName,
           courseFees: courseFees,
           panId: borrowerPan.toUpperCase(),
           panImage: panUrl,
           aadhaarId: panProDetails?.masked_aadhaar,
           aadhaarFrontImage: aadhaarUrl1,
           aadhaarBackImage: aadhaarUrl2,
           employmentType:
                        employment === "EMPLOYED"
                          ? "salaried"
                          : employment === "SELF_EMPLOYED"
                          ? "self-employed"
                          : "unemployed",
            employerName: employment==="EMPLOYED"?employerName:"",
            salary: employment==="EMPLOYED"?borrowerSalary:"",
            incomePerMonth: employment==="SELF_EMPLOYED"?borrowerSalary:"",
            typeOfBusiness: employment==="SELF_EMPLOYED"?employerName:"",
           email: isStudentApplicant?studentEmail:borrowerEmail,
           currentAddress:borrowerAddress,
           currentCity: borrowerCity ,
           currentState: borrowerState,
           currentPincode: borrowerPin,
           loanTenure: tenure,
           channel:decode?.role!=="institute_user"&&decode?.role!=="institute_admin"?null:3
         
   } 
         dispatch(userActions.createApplication(urlCreate,body,user,uploadedArray))
   
        



  }

  const validateFields = () => {
    let formErrors = {}
    if(!instituteName){
      formErrors.instituteName = "Please enter institute name"

    }
    if(!studentFirstName){
      formErrors.studentFirstName = "Enter student's first name"

    }
    if(!studentLastName){
      formErrors.studentLastName = "Enter student's Last name"

    }
    if(!validateEmail(studentEmail)){
      formErrors.studentEmail = "Please enter a valid email address"

    }
    if(studentPhone?.length !== 10){
      formErrors.studentPhone = "Number must be 10 digits"

    }
    if(!courseName){
      formErrors.courseName = "Please enter course name"

    }
    if(!courseFees > 0){
      formErrors.courseFees = "course fees must be more than 0"

    }

    if(!tenure){
      formErrors.tenure = "Select loan tenure"

    }

    //Borrower details check
    
    if(!borrowerFirstName){
      formErrors.borrowerFirstName = "Enter barrower's first name"

    }
    if(!borrowerLastName){
      formErrors.borrowerLastName = "Enter barrower's last name"

    }
    if(!validateEmail(borrowerEmail)){
      formErrors.borrowerEmail = "Please enter a valid email address"

    }
    if(borrowerPhone?.length !== 10){
      formErrors.borrowerPhone = "Number must be 10 digits"

    }
    if(borrowerAadhaar?.length !== 12){
      formErrors.borrowerAadhaar = "Aadhaar must be 12 digits"

    }
    if(borrowerPan?.length !== 10){
      formErrors.borrowerPan = "Please enter a valid PAN"

    }
    if (!borrowerDOB) {
      formErrors.borrowerDOB = "Date of Birth is required";
    } else if (getAge(borrowerDOB) < 18) {
      formErrors.borrowerDOB = "Borrower must be at least 18 years old";
    }
    if(!borrowerAddress){
      formErrors.borrowerAddress = "Please enter address"

    }
    if(!borrowerPin){
      formErrors.borrowerPin = "PIN must be 6 digits"

    }
    if(!borrowerCity){
      formErrors.borrowerCity = "Please enter city"

    }
    if(!borrowerState){
      formErrors.borrowerState = 'Please enter state'

    }
    if(employment==='EMPLOYED' || employment ==='SELF_EMPLOYED'){
      if(!employerName){
        formErrors.employerName = "Enter employer name"
  
      }
      if(!borrowerSalary){
        formErrors.borrowerSalary = "Please enter salary"
  
      }
    }
    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  const validateFiles = () => {
    return uploadedFilesArray.length > 0
  }
   
  const handleSubmit = () => {
    if(validateFields()){
      if(validateFiles()){
        getPanPro()
     }
     else{
      alert('Please upload Aadhaar/PAN')
     }
    }
    else{
      alert('Incomplete information Given, Please Check the Details once')
    }
   
  }
  return (
    <>
      {loanType=== 'Education Loan' && 
          <Box  className='fields-container'>
          <h5 className='form-heading'>Student Details</h5>
            <Box
            sx={{ display:'flex' ,justifyContent:'space-around', flexWrap:'wrap'}}

          > 
            <CustomAutoComplete label={'Institute Name'} id='instituteName' value={decode?.role==="institute_user"||decode?.role==="institute_admin"?decode?.instituteName:instituteName} error={!!errors.instituteName} disabled={decode?.role==="institute_user"||decode?.role==="institute_admin"?true:false} helperText={errors.instituteName} noOptionsText={startingPhrase} options={instituteList} handleAutoCompleteChange={(name) => getInstituteInfo(name)} /> 
            <CustomTextField label={'First Name'} value={studentFirstName}  error={!!errors.studentFirstName} helperText={errors.studentFirstName} handleTextFieldOnChange={(stFtName) => setStudentFirstName(stFtName)}/>
            <CustomTextField label={'Last Name'} value={studentLastName}  error={!!errors.studentLastName} helperText={errors.studentLastName}  handleTextFieldOnChange={(stLtName) => setStudentLastName(stLtName)}/>
            <CustomTextField label={'Mobile'} type='number' value={studentPhone} error={!!errors.studentPhone} helperText={errors.studentPhone}    handleTextFieldOnChange={(stPhone) => setStudentPhone(stPhone)}/> 
            <CustomTextField label={'Email'} type='email' value={studentEmail}  error={!!errors.studentEmail} helperText={errors.studentEmail}  handleTextFieldOnChange={(stEmail) => {setStudentEmail(stEmail)}}/>
            <CustomTextField label={'Course Name'} value={courseName} error={!!errors.courseName} helperText={errors.courseName}  handleTextFieldOnChange={(cName) => setCourseName(cName)}/>
            <CustomTextField label={'Course Fees'} type='number' value={courseFees}   error={!!errors.courseFees} helperText={errors.courseFees} handleTextFieldOnChange={(cFee) => setCourseFees(cFee)}/>
            <Autocomplete  sx={{ "& .MuiPopper-root": {fontFamily:'Outfit', }}} options={tenureOptions} size='small'
                 onChange={(e, value) => setLoanTenure(value?.label)}
                renderInput={(params) => <TextField {...params} label="Loan Tenure" 
                error={!!errors.tenure} helperText={errors.tenure}
                InputLabelProps={{
                  sx: {fontFamily:'Outfit', bgcolor: "#fff8f4",fontWeight:'bold', borderRadius:'5px', fontSize:'14px', lineHeight:'20.25px', padding:'2px 8px 2px 8px', color:'#000'},
                }}
                
                sx={{minWidth:350, mb:3,
                  "& label.Mui-focused": {
                    color: "#000",
                   
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#707C8B",
                    },
                    "&:hover fieldset": {
                      borderColor: "#707C8B",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#707C8B",
                    },
                    '& .MuiInputBase-input': {
                      fontFamily: 'Outfit', // This applies the fontFamily to the input text
                    },
                  },}}
                />}
            /> 
          </Box>
            <span style={{display:'flex', alignItems:'center', fontFamily:'Outfit', fontWeight:'bold'}}>
              <Checkbox checked={isStudentApplicant} onChange={(e) => handleStudentBorrower(e)}  id="studentBorrowerCheck"/> Is Student the borrower   
              </span>
            </Box>
      }
      <Box  className='fields-container'>
          <h5 className='form-heading'>Borrower Details</h5>
        <Box
        sx={{ display:'flex' ,justifyContent:'space-evenly', flexWrap:'wrap'}}
        >
          <CustomTextField label={'First Name'} id='borrowerFirstName' disabled={disableFields} value={borrowerFirstName}  error={!!errors.borrowerFirstName} helperText={errors.borrowerFirstName}  handleTextFieldOnChange={(bftName) => setBorrowerFirstName(bftName)}/>
          <CustomTextField label={'Last Name'} id='borrowerLastName' disabled={disableFields} value={borrowerLastName}  error={!!errors.borrowerLastName} helperText={errors.borrowerLastName}  handleTextFieldOnChange={(bltName) => setBorrowerLastName(bltName)}/>
          <CustomTextField label={'Phone'} type={'number'} id='borrowerPhone' disabled={disableFields} value={borrowerPhone} error={!!errors.borrowerPhone} helperText={errors.borrowerPhone} handleTextFieldOnChange={(bPhone) => setBorrowerPhone(bPhone)}/>
          <CustomTextField label={'Email'} type={'email'} id='borrowerEmail' disabled={disableFields} value={borrowerEmail} error={!!errors.borrowerEmail} helperText={errors.borrowerEmail} handleTextFieldOnChange={(bEmail) => {setBorrowerEmail(bEmail)}}/>
          <CustomTextField label={'Aadhaar No'} type='number'  value={borrowerAadhaar} error={!!errors.borrowerAadhaar} helperText={errors.borrowerAadhaar}  handleTextFieldOnChange={(bAadhaar) => setBorrowerAadhaar(bAadhaar)}/>
          <CustomTextField label={'PAN'} value={borrowerPan} error={!!errors.borrowerPan} helperText={errors.borrowerPan} handleTextFieldOnChange={(bPan) => setBorrowerPan(bPan.toUpperCase())}/>
          <CustomTextField label='Date Of Birth' type='date' value={borrowerDOB} error={!!errors.borrowerDOB} helperText={errors.borrowerDOB} handleTextFieldOnChange={(dob) => setBorrowerDOB(dob)}/>
          <CustomTextField label={'Address'} value={borrowerAddress}  error={!!errors.borrowerAddress} helperText={errors.borrowerAddress} handleTextFieldOnChange={(bAddress) => setBorrowerAddress(bAddress)}/>
          <CustomTextField label={'PIN Code'} type='number' value={borrowerPin} error={!!errors.borrowerPin} helperText={errors.borrowerPin} handleTextFieldOnChange={(bPin) => setBorrowerPin(bPin)}/>
          <CustomTextField label={'City'} value={borrowerCity} error={!!errors.borrowerCity} helperText={errors.borrowerCity}   handleTextFieldOnChange={(bCity) => setBorrowerCity(bCity)}/>
          <CustomTextField label={'State'} value={borrowerState} error={!!errors.borrowerState} helperText={errors.borrowerState} handleTextFieldOnChange={(bState) => setBorrowerState(bState)}/>
        </Box>
      </Box>
      <Box  className='fields-container'>
          <h5 className='form-heading'>Employment</h5>
          <Box sx={{display:'flex', justifyContent:'center',mt:-4, mb:3}}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              row 
              value={employment}
              onChange={handleEmploymentChange}
            >
              <FormControlLabel value="EMPLOYED" control={<Radio />} label="Salaried" sx={{mr:5,  '& .MuiFormControlLabel-label': { fontFamily: 'Outfit', fontWeight:'bold' }}}/>
              <FormControlLabel value="SELF_EMPLOYED" control={<Radio />} label="Self Employed" sx={{mr:5, '& .MuiFormControlLabel-label': { fontFamily: 'Outfit', fontWeight:'bold' }}}/>
              <FormControlLabel value="UNEMPLOYED" control={<Radio />} label="Unemployed" sx={{mr:5, '& .MuiFormControlLabel-label': { fontFamily: 'Outfit', fontWeight:'bold' }}}/>
            </RadioGroup>
          </Box>
          {employment==='EMPLOYED' && <Box       sx={{ display:'flex' ,justifyContent:'space-evenly', flexWrap:'wrap'}}
          >
            <CustomTextField label={'Employer Name'} value={employerName} error={!!errors.employerName} helperText={errors.employerName} handleTextFieldOnChange={(eName) => setEmployerName(eName)}/>
            <CustomTextField label={'Salary'} type='number' value={borrowerSalary}  error={!!errors.borrowerSalary} helperText={errors.borrowerSalary} handleTextFieldOnChange={(bSalary) => setBorrowerSalary(bSalary)}/>
          </Box>}
          {employment==='SELF_EMPLOYED' && <Box       sx={{ display:'flex' ,justifyContent:'space-evenly', flexWrap:'wrap'}}
          >
            <CustomTextField label={'Type of Business'} value={employerName}  error={!!errors.employerName} helperText={errors.employerName} handleTextFieldOnChange={(eName) => setEmployerName(eName)}/>
            <CustomTextField label={'Income Per Month'} type='number' value={borrowerSalary}  error={!!errors.borrowerSalary} helperText={errors.borrowerSalary} handleTextFieldOnChange={(bSalary) => setBorrowerSalary(bSalary)}/>
          </Box>}
      
      </Box>
      {loanType==='Vehicle Loan' && <Box  className='fields-container'>
          <h5 className='form-heading'>Vehicle Details</h5>
  
      <Box
      sx={{ display:'flex' ,justifyContent:'space-evenly', flexWrap:'wrap'}}

    >
      
        <CustomAutoComplete label={'Product Type'} options={productTypeOptions}/>
        <CustomTextField label={'Brand Name'}/>
        <CustomTextField label={'Model'}/>
        <CustomTextField label={'Other Details'}/>
      
      </Box>
          </Box>
        }
        {
          loanType==='Mortgage Loan' &&  <Box  className='fields-container'>
            <h5 className='form-heading'>Property Details</h5>
  
              <Box
              sx={{ display:'flex' ,justifyContent:'space-evenly', flexWrap:'wrap'}}

            >
              
                <CustomAutoComplete label={'Property Type'} options={propertyTypeOptions}/>
                <CustomTextField label={'Property Size'}/>
                <CustomTextField label={'Address'}/>
                <CustomTextField label={'Property Description'}/>
                <CustomTextField label={'Other Remarks'}/>
              
              </Box>
              </Box>
        }
      <Box  className='fields-container'>
          <h5 className='form-heading'>File Uploads</h5>
          <Box sx={{display:'flex', flexWrap:'wrap', justifyContent:'space-evenly', padding: '10px 10px 10px 10px' }}>
            {
              fileUploadCards.map((fileCard, index) => (
                <InputFileUpload image={fileCard.image} label={fileCard.label} id={fileCard.id} handleFiles={handleFiles} disabled={fileCard.disabled}/>
              ))
            }
          </Box>
          {uploadedFilesArray.length > 0 && 
            <Box className='files-container'>
              <p className='file-count'>{`${uploadedFilesArray?.length} Files uploaded`}</p>
              {uploadedFilesArray.map(file => <Box className='file-card'>
                    {/* {file?.type==='image/jpeg' && <img 
                       src={URL.createObjectURL(file)} // Create a URL for the image file
                       alt='img'
                       style={{ maxWidth: '300px', maxHeight: '150px' }} // Adjust size as needed
                      /> } */}
                      <p>{`${file?.fileType} (${file?.fileName}) - uploaded`}</p>
                    <DeleteIcon fontSize='small' onClick={() => handleDeleteFile(file?.fileType)} sx={{cursor:'pointer', m:0}}/>
                    </Box>)}
              </Box>
          }
          
      </Box>
     
      <Button sx={{width:300, color:'#ffffff', bgcolor:'#D32028', fontFamily:'Outfit', '&:hover':{bgcolor:'#D32028', opacity:0.9, boxShadow:'0px 4px 16px #000', cursor:'pointer !important'}}} onClick={()=>handleSubmit()}>Submit</Button>
    </>
   
  )
}
