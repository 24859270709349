
import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import "./Rules.css"
const DataElements = ({ dataElements,selectedElement, onClick  }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <div>
      <div style={{margin:'1em'}}>
          {dataElements.map((element) => (
        <div key={element.id} onClick={() => onClick(element.element,element.id,element.datatype)} style={{ fontFamily:'Inter-Medium',fontSize:'0.9vw',cursor: 'pointer',  color: selectedElement === element.element ? '#D32028' : 'initial',paddingLeft:'5px',backgroundColor:selectedElement === element.element ? '#FFEDEE' : 'transparent',borderRadius:selectedElement === element.element ? '5px' : '0px',whiteSpace:'nowrap'}}>
          {element.element}
        </div>
      ))}
          
        </div>
    
  </div>
   
   
  );
};

export default DataElements;