import React, { useEffect, useState, useRef } from 'react';
import axiosInstance from '../../helpers/axios';
import jwt_decode from 'jwt-decode';
import EditRole from './editRole';
import DeleteRole from './deleteRole';
import { Input } from 'reactstrap';
import { userActions } from '../../actions/user.actions';
import { useDispatch, useSelector } from 'react-redux';

export default function Home() {
  const dispatch = useDispatch();
  const instituteState = useSelector((state) => state?.GETINSTITUTE?.res?.data?.data);
  const [startingPhrase, setStartingPhrase] = useState("Type Institute Name");
  const [instituteUser, setInstituteUser] = useState(null);
  const [instituteList, setInstituteList] = useState(null);
  const user = sessionStorage.getItem('user');
  const decode = jwt_decode(user);
  const [users, setUsers] = useState(null);
  const [currentInstituteId, setCurrentInstituteId] = useState(null);

  const searchInstituteRef = useRef(null);

  useEffect(() => {
    if (decode?.role !== "institute_user" && decode?.role !== "institute_admin") {
      axiosInstance.get('/admin/users', {
        headers: {
          Authorization: `Bearer ${user}`
        }
      })
      .then((res) => {
        setUsers(res?.data?.data);
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
    } else {
      axiosInstance.get(`/admin/institute-users?instituteId=${decode?.instituteId}`)
      .then((res) => {
        setUsers(res?.data?.data);
      })
      .catch(error => {
        console.error('Error fetching institute users:', error);
      });
    }
  }, [decode?.role, decode?.instituteId, user]);

  const getInstituteInfo = (text) => {
    const urlGetInstitute = `/institute/info/name?name=${text}`;

    if (text.length >= 4) {
      dispatch(userActions.getInstituteInfo(urlGetInstitute, user));
      setInstituteList(instituteState);
    } else {
      setInstituteList(null);
      setStartingPhrase("Type Institute Name");
      if (decode?.role !== "institute_user" && decode?.role !== "institute_admin") {
        // Fetch users when clearing input
        axiosInstance.get('/admin/users', {
          headers: {
            Authorization: `Bearer ${user}`
          }
        })
        .then((res) => {
          setUsers(res?.data?.data);
        })
        .catch(error => {
          console.error('Error fetching users:', error);
        });
      }
    }

    // Handle case where text is empty
    if (text.trim() === '') {
      setCurrentInstituteId(null);
      setInstituteUser(null);
    }
  };

  const handleSearchInstituteDetails = (data) => {
    setCurrentInstituteId(data.id);

    const url = `/admin/institute-users?instituteId=${data.id}`;

    axiosInstance.get(url)
      .then(response => {
        setInstituteUser(response.data.data);
        console.log('Institute details:', response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the institute details!', error);
      });

    setInstituteList(null);
    setStartingPhrase("Type Institute Name"); // Optionally reset startingPhrase
    setUsers(null); // Reset users when clearing input
    searchInstituteRef.current.value = data.brandName;
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    getInstituteInfo(text);
  };

  return (
    <div>
      <div style={{ display: "block", marginTop: '20px' }}>
        <div>
          <Input
            id="searchInstituteNameBox"
            innerRef={searchInstituteRef}
            onChange={handleInputChange}
            type="text"
            placeholder={startingPhrase}
            style={{ border: '1px solid #F0F0F0', boxShadow: '0px 0px 1px 1px #D0D0D0', width: '25em' }}
          />
        </div>
        <div style={{ marginTop: '1em' }}>
          {instituteList ? instituteList.map((item) => (
            <div onClick={() => handleSearchInstituteDetails(item)} key={item.id} className="instituteName" style={{ cursor: 'pointer', border: '1px solid grey', borderRadius: '0.5em' }}>
              <p style={{ fontWeight: '600', paddingTop: '0.5em', paddingLeft: '0.5em' }}>{item.brandName}</p>
            </div>
          )) : null}
        </div>
      </div>
      <div style={{ display: 'block', justifyContent: 'center', height: '100%', boxShadow: '0px 0px 6px 0px rgba(0, 0, 0.5, 0.8, 0.3)', margin: '5%', borderRadius: '10px', marginLeft: '10%' }}>
        <div style={{ display: 'flex', padding: '1em', justifyContent: 'space-between', backgroundColor: '#F8F8F8' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p style={{ fontFamily: 'Inter', fontWeight: 'bold', textAlign: 'left', width: '10em' }}>Name</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p style={{ fontFamily: 'Inter', fontWeight: 'bold', textAlign: 'center', width: '8em' }}>Phone</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p style={{ fontFamily: 'Inter', fontWeight: 'bold', textAlign: 'center', width: '8em' }}>Role</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p style={{ fontFamily: 'Inter', fontWeight: 'bold', textAlign: 'center', width: '13em' }}>Email</p>
          </div>
          {(decode?.role === "admin" || decode?.role === "institute_admin") && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <p style={{ fontFamily: 'Inter', fontWeight: 'bold', textAlign: 'right' }}>Actions</p>
            </div>
          )}
        </div>
        {((currentInstituteId && instituteUser) || (users && !instituteList)) && (
          instituteUser?.map((item) => (
            <div key={item.id} style={{ display: 'flex', padding: '1em', justifyContent: 'space-between', borderBottom: '1px solid #DbDbDb' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p style={{ fontFamily: 'Inter', textAlign: 'left', width: '10em' }}>{item.name}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p style={{ fontFamily: 'Inter', textAlign: 'center', width: '9em' }}>{item.mobile}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p style={{ fontFamily: 'Inter', textAlign: 'center', width: '9em' }}>{item.role}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p style={{ fontFamily: 'Inter', textAlign: 'right', width: '16em' }}>{item.emailId}</p>
              </div>
              {(decode?.role === "admin" || decode?.role === "institute_admin") && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '3.5em' }}>
                  <EditRole updateUser={item} />
                  <DeleteRole deleteUser={item} />
                </div> )}
            </div>
          ))
        )}
        {((!instituteList && !currentInstituteId && users) || (users && !instituteList)) && (
          users?.map((item) => (
            <div key={item.id} style={{ display: 'flex', padding: '1em', justifyContent: 'space-between', borderBottom: '1px solid #DbDbDb' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p style={{ fontFamily: 'Inter', textAlign: 'left', width: '10em' }}>{item.name}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p style={{ fontFamily: 'Inter', textAlign: 'center', width: '8em' }}>{item.mobile}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p style={{ fontFamily: 'Inter', textAlign: 'center', width: '8em' }}>{item.role}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p style={{ fontFamily: 'Inter', textAlign: 'right', width: '13em' }}>{item.emailId}</p>
              </div>
              {(decode?.role === "admin" || decode?.role === "institute_admin") && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '3.5em' }}>
                  <EditRole updateUser={item} />
                  <DeleteRole deleteUser={item} />
                </div>
                   )}
            </div>))
         ) }
        </div>
      </div>
    );
  }
  
