import React from 'react'
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import download from "../../assets/images/download.png"
import { useState, useEffect  } from 'react';
import axiosInstance from '../../helpers/axios';
import { Card as CustomCard } from 'reactstrap';
import { Doughnut } from "react-chartjs-2";
import {
  Card
} from 'reactstrap';

function Portfoliocuts() {

  const [data, setData] = useState({ irrRangeCount: [], ticketsizeWiseCount: [] });
  const [TableData, setTableData] = useState({ tenureWiseCount: [], tenureWiseParCount: [] });
  const [sumTenureCount, setSumTenureCount] = useState(0);
  const [sumirrCount, setSumIrrCount] = useState(0);
  const [sumticketseCount, setSumticketseCount] = useState(0);
  
  const user = sessionStorage.getItem('user');
  useEffect(() => {
    axiosInstance.get('/analytics/range-count', {
      headers: {
        Authorization: `Bearer ${user}`,
      }
    })
      .then( (res)=> {
        setData(res?.data?.data);
        const sumirr = res?.data?.data?.irrRangeCount.reduce((acc, item) => acc + (item.count || 0), 0);
        console.log(sumirr,'irr')
        const sumticket = res?.data?.data?.ticketsizeWiseCount.reduce((acc, item) => acc + (item.count || 0), 0);
        console.log(sumticket,'tickets')
        setSumIrrCount(sumirr);
        setSumticketseCount(sumticket)
        

      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

  useEffect(() => {
    axiosInstance.get('/analytics/tenurewise-count', {
      headers: {
        Authorization: `Bearer ${user}`,
      }
    })
      .then( (res)=> {
        setTableData(res?.data?.data);
        console.log(res?.data?.data?.tenureWiseCount,'data');
        const sum = res?.data?.data?.tenureWiseCount.reduce((acc, item) => acc + (item.tenure_count || 0), 0);
        setSumTenureCount(sum);
        
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);



  
  const handleDownloadList=()=>{
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
    const formattedDate = `${day}-${month}-${year}`;
    document.getElementById("downloadButton").disabled=true;
    document.getElementById("downloadButton").innerText="Wait....";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "130px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    // document.getElementById("downloadButton").style.marginLeft="150px";
    document.getElementById("downloadButton").style.backgroundImage = "none"


    const downloadTemplateUrl=`${process.env.REACT_APP_BASE_URL}/excel/IrrCountInRange `
    
   
    
    axiosInstance.get("/excel/IrrCountInRange", {
      responseType: 'arraybuffer',
      headers: {
          Authorization: `Bearer ${user}`
      }
  }).then((res)=>{
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 2000);
    const blob = new Blob([res.data], { type: "application/xlsx", });
    const url= window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href =url
    link.download = `${formattedDate}.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
    
  ).catch((error)=>{
    alert("Error Downloading")
    document.getElementById("downloadButton").innerText="Error..";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      // document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='20px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='15px 10px'
    }, 1000);
  })
  } 
//   const dataDoughnut = {
//     labels:data?.irrRangeCount?.map((item)=>(item?.irr_range)),

//     datasets: [
//         {
         
//           data: data?.irrRangeCount?.map((item)=>(item?.irr_count)),
//           backgroundColor: [
//             'rgba(0, 128, 128, 0.2)',
//             'rgba(128, 0, 128, 0.2)',
//             'rgba(0, 255, 0, 0.2)',
//             'rgba(0, 0, 255, 0.2)',
//             'rgba(255, 0, 255, 0.2)',
//             'rgba(128, 128, 0, 0.2)'
           
//         ],
//         borderColor: [
//           'rgba(0, 128, 128, 0.2)',
//           'rgba(128, 0, 128, 0.2)',
//           'rgba(0, 255, 0, 0.2)',
//           'rgba(0, 0, 255, 0.2)',
//           'rgba(255, 0, 255, 0.2)',
//           'rgba(128, 128, 0, 0.2)'
           
//         ]
// ,        
//           borderWidth: 1,
//         },
//       ],
//   };
//   const ticketSizeData = {
//     labels:data?.ticketsizeWiseCount?.map((item)=>(item?.loan_amount_range)),

//     datasets: [
//         {
         
//           data: data?.ticketsizeWiseCount?.map((item)=>(item?.count)),
//           backgroundColor: [
//             'rgba(0, 128, 128, 0.2)',
//             'rgba(128, 0, 128, 0.2)',
//             'rgba(0, 255, 0, 0.2)',
//             'rgba(0, 0, 255, 0.2)',
//             'rgba(255, 0, 255, 0.2)',
//             'rgba(128, 128, 0, 0.2)'
           
//         ],
//         borderColor: [
//           'rgba(0, 128, 128, 0.2)',
//           'rgba(128, 0, 128, 0.2)',
//           'rgba(0, 255, 0, 0.2)',
//           'rgba(0, 0, 255, 0.2)',
//           'rgba(255, 0, 255, 0.2)',
//           'rgba(128, 128, 0, 0.2)'
           
//         ]
// ,        
//           borderWidth: 1,
//         },
//       ],
//   };

  function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-IN'); 
  }
  const [isOpen,setIsOpen]=useState(true) 

  return (
    <div style={{display:'flex',width:"100%",backgroundColor:'white',height:'130%'}}>
    <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
    <div style={{width:isOpen?'80%':'100%',marginLeft:isOpen?"20%":'0%',padding:'10px'}}>
    <Topbar/>
    <p style={{textAlign:"center",fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px'}}>Portfolio cuts data as of last month </p>
    <div style={{backgroundColor:'#D22129',display: 'flex', alignItems: 'center',width:'130px',height:'34px',borderStyle:'none',borderRadius:"5px",fontFamily:"Inter-Medium",paddingLeft:'10px',justifyContent:'space-around',cursor:'pointer',marginRight:'20px'}}
               id="downloadButton"   onClick={() => handleDownloadList()} >
             
         <img src={download} style={{height:'20px',marginLeft:'8px'}}/> 
         <span style={{fontFamily:"Inter-Medium",color:'white',paddingRight:"15px"}}>Export</span>
        
          </div>
    <div style={{ display: 'flex',justifyContent:'space-between' }}>
    
        <table className='tables' style={{marginRight:'10px'}}>
          <thead className='table-heading'>
            <tr>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '8px%', fontFamily: "Inter-Medium", paddingLeft: '10px' }}>Tenure</th>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>No. of Borrowers</th>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium",paddingLeft: '10px' }}>AUM</th>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>% Share</th>
            </tr>
          </thead>
          
          <tbody className='table-body'>
            {/* Table 1 body */}
            {TableData?.tenureWiseCount?.map((item, index) => (
              <tr className="table-row" key={index} style={{ lineHeight:"25px"}}>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085', paddingLeft: '10px' }}>{item?.tenure_range ? item?.tenure_range : '-'}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.tenure_count ? item?.tenure_count : '-'}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.total_loan_amount ? (<>₹{formatNumberWithCommas(item.total_loan_amount)}</>): '-'}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}> {(item?.tenure_count / sumTenureCount * 100).toFixed(2)}%</td>
              </tr>
            ))}
          </tbody>
        </table>

        <table className='tables'>
          <thead className='table-heading'>
            <tr>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '8%', fontFamily: "Inter-Medium", paddingLeft: '10px' }}>Tenure</th>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '11%', fontFamily: "Inter-Medium" }}>Total PAR</th>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10%', fontFamily: "Inter-Medium" }}>PAR{'>'}30</th>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '11%', fontFamily: "Inter-Medium" }}>PAR{'>'}60</th>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10%', fontFamily: "Inter-Medium" }}>PAR{'>'}90</th>
            </tr>
          </thead>
          <tbody className='table-body'>
            {/* Table 2 body */}
            {TableData?.tenureWiseParCount?.map((item, index) => (
              <tr className="table-row" key={index} style={{ lineHeight:"25px"}}>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.tenure ? item?.tenure : '-'}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.total_overdue_amount ? (<>₹{formatNumberWithCommas(item.total_overdue_amount)}</>) : '-'}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.overdue_0_30 ? (<>₹{formatNumberWithCommas(item.overdue_0_30 )}</>) : '-'}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.overdue_31_60 ? (<>₹{formatNumberWithCommas(item.overdue_31_60 )}</>) : '-'}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.overdue_90_plus ? (<>₹{formatNumberWithCommas(item.overdue_90_plus)}</>) : '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
    </div>
    {/* <div style={{ display: 'flex',justifyContent:'space-around' }}>

    <div style={{ width: "20em", height: "20em" }}>
    <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px',display:'flex',justifyContent:'center'}}>IrrRange Count</p>
              <Doughnut redraw={true} data={dataDoughnut} />
            </div>  
            <div style={{ width: "20em", height: "20em" }}>
    <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px',display:'flex',justifyContent:'center'}}>Ticket Size Wise</p>
              <Doughnut redraw={true} data={ticketSizeData} />
            </div>   

    </div> */}

<div style={{ display: 'flex', justifyContent: 'space-between', }}>
    
    <table className='tables' style={{ marginRight: '10px' }}>
      <thead className='table-heading'>
        <tr>
          <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '8%', fontFamily: "Inter-Medium", paddingLeft: '10px' }}>Irr range</th>
          <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10%', fontFamily: "Inter-Medium" }}>No. of Borrowers</th>
          <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10%', fontFamily: "Inter-Medium", paddingLeft: '10px' }}>AUM</th>
          <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10%', fontFamily: "Inter-Medium" }}>% Share</th>
        </tr>
      </thead>
      
      <tbody className='table-body'>
        {/* Table 1 body */}
        {data?.irrRangeCount?.map((item, index) => (
          <tr className="table-row" key={index} style={{ lineHeight: "25px" }}>
            <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085', paddingLeft: '10px' }}>{item?.irr_range ? item?.irr_range : '-'}</td>
            <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.count ? item?.count : '-'}</td>
            <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.total_amount_requested ? (<>₹{formatNumberWithCommas(item.total_amount_requested)}</>) : '-'}</td>
            <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}> {(item?.count / sumirrCount * 100).toFixed(2)}%</td>
          </tr>
        ))}
      </tbody>
    </table>

    <table className='tables'>
      <thead className='table-heading'>
        <tr>
          <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10%', fontFamily: "Inter-Medium", paddingLeft: '10px' }}>Tickets range</th>
          <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '11%', fontFamily: "Inter-Medium" }}>No. of Borrowers</th>
          <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10%', fontFamily: "Inter-Medium" }}>AUM</th>
          <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '11%', fontFamily: "Inter-Medium" }}>% Share</th>
        </tr>
      </thead>
      <tbody className='table-body'>
        {/* Table 2 body */}
        {data?.ticketsizeWiseCount?.map((item, index) => (
          <tr className="table-row" key={index} style={{ lineHeight: "25px" }}>
            <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '12em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.loan_amount_range ? item?.loan_amount_range : '-'}</td>
            <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '12em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.count ? item?.count : '-'}</td>
            <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '12em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.Total_loan_amount ? (<>₹{formatNumberWithCommas(item?.Total_loan_amount) }</>): '-'}</td>
            <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '12em', fontFamily: "Inter-Medium", color: '#667085' }}> {(item?.count / sumticketseCount * 100).toFixed(2)}%</td>
          </tr>
        ))}
      </tbody>
    </table>

</div>



  </div> 
         
       
  </div>


           
  
    
  )
}

export default Portfoliocuts