
  
import {
  Col,
  Table,
  Card,
  Form,
  Button,
  Input,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import axios from 'axios';
import axiosInstance from '../../../helpers/axios';
import { useState, useEffect } from 'react';
import statusimage from "../../../assets/images/status.png";
import rightarrow from "../../../assets/images/chevron-right.png"
import download from "../../../assets/images/download.png";
import Datetime from 'react-datetime';
import jwt_decode from 'jwt-decode'
import 'react-datetime/css/react-datetime.css';
import search from "../../../assets/images/search.png"
import {useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../Sidebar'
import Topbar from '../../Topbar'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import EditForm from '../EditForm/EditForm';
import * as moment from 'moment'
//import MoveStatus from './MoveStatus';
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import Sort from "../../../assets/images/sort.png";
import "./DisbursedTable.css"
import PaginationTable from '../PaginationTable';
import { useSelector, useDispatch } from "react-redux";

import {userActions} from '../../../actions/user.actions';

// eslint-disable-next-line import/extensions
//import ComponentCard from '../ComponentCard';

//require('moment/locale/fr');
//require('moment/locale/de');
//require('moment/locale/en-gb');

const DisbursedTable = () => {
  
  const state = useSelector((state) => state?.Indisbursed?.res);
  const datestate = useSelector((state) => state.Datefilter?.res);
 const userstate = useSelector((state) => state.Handleuser?.res)
  const userCoAppstate = useSelector((state) => state.Handlecoappuser?.res)
  
  const usersearchdefstate = useSelector((state) => state?.UserSearchDefault?.res?.data?.data);
  const usersearchinsstate = useSelector((state) => state?.UserSearchIns?.res?.data?.data);


  // const state = useMemo(() => (res), []);

  const [userDetails, setUserDetails] = useState(null)

  const dispatch = useDispatch();


  const ApplicationStatusEnum = {
  InSubmission: 'In Submission',
  InReview: 'In Review',
  InSanction: 'In Sanction',
  InDisbursement: 'In Disbursement',
  Disbursed: 'Disbursed',
  Rejected: 'Rejected',
  Dropped: 'Dropped',
  OnHold:'On Hold', ReadyToDisburse:'Ready to Disburse',
};
  const location=useLocation();
  const [status, setStatus] = useState(ApplicationStatusEnum.Disbursed);
  const localStatus = sessionStorage.setItem("status",status)
  const [isLoading, setIsLoading] = useState(true);
  const user = sessionStorage.getItem('user');
  const decode=jwt_decode(user)
  //filter
  const [filterInstituteName, setFilterInstituteName] = useState('');
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateTo, setFilterDateTo] = useState("");
  const [searchText, setSearchText] = useState("")

  const valid = (current) => {
    return current.isAfter(filterDateFrom);
  };

  //table
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [userProfileId, setUserProfileId] = useState(null);
  const [time, setTime] = useState('');
  const [dropDownOpen, setDropDownOpen] = useState(false);
  //pagination
  const [currentPage, setcurrentPage] = useState(sessionStorage.getItem("page") ? parseInt(sessionStorage.getItem("page")) : 1);
  const [rowsPerPage, setrowsPerPage] = useState(15);
  const [totalFetchRows, setTotalFetchRows] = useState(null);
  const [clicked,setClicked] = useState(false)

  const [sort,setSort]=useState(-1)

  const [activePage, setActivePage] = useState(15); 

  //const urlUsers = `${process.env.REACT_APP_BASE_URL}/admin/application/disbursed?perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;

  const[toggleSort,setToggleSort]=useState(false);

  let urlUsers =``
   if(decode?.instituteId==="acac6d59-cc03-4405-acee-adda97175e1c")
      {
       

        urlUsers = `/admin/application/disbursed?fromDate=01 Apr 2023&toDate=${moment(new Date()).format("DD MMM YYYY")+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
      }
      else
      {
       urlUsers= `/admin/application/disbursed?perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`

      }
  //const headers = { Authorization: `Bearer ${user}`}
  const clearDateFilter =()=>{
    setClicked(false)
    setFilterDateFrom('')
    setFilterDateTo('')
        
        axiosInstance.get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
         
          setTableData(res?.data?.data?.details);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
       
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      }
  
  useEffect(() => {
    if(usersearchinsstate){
      setIsLoading(false);
      setTableData(usersearchinsstate);
    }else{
      if(usersearchdefstate){
        setIsLoading(false);
        setUserDetails(usersearchdefstate?.details)
          setTableData(usersearchdefstate?.details);
          setFilterTableData(usersearchdefstate?.details);
          setcurrentPage(usersearchdefstate?.pagination?.currentPage);
          setTotalFetchRows(usersearchdefstate?.pagination?.totalRows);
          setrowsPerPage(usersearchdefstate?.pagination?.perPage);  
      }else{
     
          if(searchText === "" && filterDateFrom === '' && filterDateTo ===''){
           
            
    
            axiosInstance.get(urlUsers, {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            })
            .then((res) => {
             if(decode?.instituteId==="acac6d59-cc03-4405-acee-adda97175e1c")
             {
               setTableData(res?.data?.data?.details.filter((item)=>item.hasOwnProperty('disbursementDate')));
               setcurrentPage(res?.data?.data?.pagination?.currentPage);
               setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
               setrowsPerPage(res?.data?.data?.pagination?.perPage);  
             }
             else
             {
              setTableData(res?.data?.data?.details);
               setcurrentPage(res?.data?.data?.pagination?.currentPage);
               setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
               setrowsPerPage(res?.data?.data?.pagination?.perPage); 
             }
              const loadingTimeout = setTimeout(() => {
                setIsLoading(false);
              }, 300);
            })
            .catch((error) => {
              alert(error?.response?.data?.message)
              console.error("Error fetching filtered data:", error);
            });
            }
      
      }
    }
    
  

    
        
  }, [toggleSort,urlUsers,datestate,usersearchdefstate,usersearchinsstate]);
  const [id,setId]=useState(null)
  const getInsituteInfo=(text)=>{
    
    const urlGetInstitute=`/institute/info/name?name=${text}`;
      
    if(text.length>=4)
    {
      axiosInstance.get(urlGetInstitute,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        console.log("text",res?.data?.data[0]?.id)
        setId(res?.data?.data[0]?.id)
      });
  
   
    }
 
  }




  const handlepageChange = (number) => {

    setIsLoading(true)
    setActivePage(number)
    setrowsPerPage(number);

    let queryParams = {}
    
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = filterDateFrom;
      queryParams.toDueDate = filterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
   
    if(status){
      queryParams.status = status
    }
  
   
    if(rowsPerPage && currentPage){
      queryParams.perPage = number;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/admin/application/disbursed',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage); 
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error)=>console.log(error))

};






  const handleInputChange = (event) => {

    if(event.target.value.length >= 4 && filterDateFrom === "" && filterDateTo === ""){
      setSearchText(event.target.value)
    

    const urlSearchUsers = `/admin/application/disbursed?instituteId=${id}&perPage=${rowsPerPage}&pageNo=${1}&sortOrder=${sort}`;
  console.log("url",urlSearchUsers)
    axiosInstance.get(urlSearchUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
  }else if(event.target.value.length >= 4 && filterDateFrom !== "" && filterDateTo !== "") {
    const urlFilterUsers = `/admin/application/disbursed?instituteId=${id}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${1}&sortOrder=${sort}`;
    axiosInstance.get(urlFilterUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
  } else{
    setSearchText("")
    setFilterDateFrom('')
    setFilterDateTo('')
    ;

    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });



  }
};

  //datefilter
  const handleDateFilterApi = (startDate, endDate) => {
    setClicked(!clicked)
    setFilterDateFrom(startDate)
    setFilterDateTo(endDate)
     if (startDate === null || endDate === null) alert('Enter Date Range to Filter');
    else {
      setIsLoading(true);
      if(searchText){
        const urlFilterUsers = `/admin/application/disbursed?instituteId=${id}&fromDate=${startDate}&toDate=${endDate+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${1}&sortOrder=${sort}`;
        axiosInstance.get(urlFilterUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
          console.log("filtering",res)
          setTableData(res?.data?.data?.details);
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage);  
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      }else{
        const urlFilterUsers = `/admin/application/disbursed?fromDate=${startDate}&toDate=${endDate+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${1}&sortOrder=${sort}`;
        axiosInstance.get(urlFilterUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
          console.log("filtering",res)
          setTableData(res?.data?.data?.details);
          setcurrentPage(res?.data?.data?.pagination.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination.totalRows);
          setrowsPerPage(res?.data?.data?.pagination.perPage);  
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      }
     
    }
  };



  const navigate = useNavigate();
   
  
  
  const handleUser = (event) => {
    
    const Id = event.userId;
    
    const urlProfile = `/summary/user?id=${Id}&applicationId=${event.applicationId}&status=Disbursed`;
    const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/user?id=${Id}&applicationId=${event.applicationId}&status=Disbursed`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `${process.env.REACT_APP_BASE_URL}/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user));
      const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  
    dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
      navigate('/status/disbursed/profile', {
        state: { id: 1, profileId: Id,applicantId:Id,coapplicantId:event.coapplicantId,applicationId:event.applicationId,dir:"Disbursed" },
      });
    
  };

  const handleCoApp = (event) => {
    
    const Id = event.coapplicantId;
    
        const urlProfile = `/summary/coapplicant?id=${Id}&status=Disbursed`
    const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/coapplicant?id=${Id}&status=Disbursed`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `${process.env.REACT_APP_BASE_URL}/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user));
      const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    dispatch(userActions.fetchHandlcoappuser(urlProfile, user),[]) 
    dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
      navigate('/status/disbursed/profile', {
        state: { id: 1, profileId: Id,clickEvent:"coapp",applicantId:event.userId,coapplicantId:event.coapplicantId,applicationId:event.applicationId ,dir:"Disbursed"},
  })
}


   //Go To Application Profile
   const handleApplicationProfile=(currApplicationId,currUID,currCoAppId)=>{
    sessionStorage.setItem("userId",currUID)
    sessionStorage.setItem("appId",currApplicationId)
    sessionStorage.setItem("coappId",currCoAppId)
    const urlForApplicationDetails=`/admin/application/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const urlForBankSelfie=`/integrations/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const getEmiUrl=`/emis?applicationId=${sessionStorage.getItem("appId")}`
    const getActivityUrl=`/audit?applicationId=${sessionStorage.getItem("appId")}`
    dispatch(userActions.fetchUrlforApplicationdetails(urlForApplicationDetails,user),[])
    dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    dispatch(userActions.fetchEmiUrl(getEmiUrl,user),[])
    dispatch(userActions.getActivity(getActivityUrl,user))
    navigate("/status/disbursed/appProfile")
    
    //sessionStorage.setItem("appId",currApplicationId)
    //navigate("/status/appProfile")
   
    
  }


  //changepage
  const paginate = (pageNumber) => {


    console.log("Search Text", searchText)
    // setcurrentPage(pageNumber);
    if(searchText.length >= 4 && filterDateFrom === '' && filterDateTo ===''){
      
      const urlUsers = `/admin/application/disbursed?instituteId=${id}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
  console.log("url",urlUsers)
    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });

    }else if(searchText === "" && filterDateFrom !== '' && filterDateTo !=='' && clicked === true){
      const urlFilterUsers = `/admin/application/disbursed?fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
      axiosInstance.get(urlFilterUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
      
        setTableData(res?.data?.data?.details);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
     
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }else if(searchText !== "" && filterDateFrom !== '' && filterDateTo !=='' && clicked === true){
      const urlFilterUsers = `/admin/application/disbursed?instituteId=${id}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
      axiosInstance.get(urlFilterUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
        setTableData(res?.data?.data?.details);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
     
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }
    else{
      const urlUsers = `/admin/application/disbursed?perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;

    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
    
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
    }
    
  };


  //download List
  const handleDownloadList=()=>{
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
  
    const formattedDate = `${day}-${month}-${year}`;
    document.getElementById("downloadButton").disabled=true;
    document.getElementById("downloadButton").innerText="Wait....";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "90px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    document.getElementById("downloadButton").style.marginLeft="150px";
    document.getElementById("downloadButton").style.backgroundImage = "none"


    const downloadTemplateUrl=`${process.env.REACT_APP_BASE_URL}/excel/download`
    const downloadData={
        instituteId: decode.instituteId?decode.instituteId:id,

        status:status,
        fromDate:filterDateFrom,
        toDate:filterDateTo+" "+"23:59:59"
       
    }
    axios.post(downloadTemplateUrl,downloadData, {
      responseType:'arraybuffer',
      headers:{
        Authorization:`Bearer ${user}`

    }
  }).then((res)=>{
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "90px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      document.getElementById("downloadButton").style.marginLeft= "150px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 2000);
    const blob = new Blob([res.data], { type: "application/xlsx", });
    const url= window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href =url
    link.download = `${formattedDate}_${status}.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
      
    
  ).catch((error)=>{
    alert("Error Downloading")
    document.getElementById("downloadButton").innerText="Error..";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "90px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      document.getElementById("downloadButton").style.marginLeft= "150px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 1000);
  })
  }     
  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN'); 
  }

  const [isOpen,setIsOpen]=useState(true)

  if (tableData) {
    return (
      <Card className="card" style={{display:'flex', flexDirection:'row',width:"100%",height:'100%'}}>
        
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%'}}>
        <Topbar/>
        <CardBody>
        <div>
          <p style={{display:'flex',alignItems:'center'}}><img src={statusimage}/><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px'}}>status</span><span style={{marginLeft:'20px',marginBottom:'3px'}}> <img style={{height:"18px",width:"14px"}} src={rightarrow}/></span><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px',color:'#D32028'}}>Disbursed</span></p>
          
         </div>
          <Row>
          <div style={{ display: 'flex', flex: 'wrap' }}>
          <Col sm="12" md="10" lg="3">
            <div style={{ height: '23px', marginRight: '10px',fontFamily:'Inter'  , position: 'relative', display: 'flex', alignItems: 'center' }}>
            {decode.role === "institute_admin" || decode.role === "institute_user" ?
            <Input
            type="text"
            id="userSearch"
            value={decode.instituteName}
            readOnly
            disabled
            placeholder={'Institute Name'
            }
            style={{
               fontSize: '0.8vw',
              paddingLeft: '26px',
              height: '23px',
              fontFamily: 'Inter',
              backgroundImage: `url(${search})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '5px center',
              backgroundSize: '15px 15px',
              paddingRight: '5px', 
              borderColor:'#cccccc',
              borderStyle:'solid',
              borderRadius:"5px",
              borderWidth:'1px',
              height:'34px',
              marginTop:'11px'
            }}
          />: <Input
          type="text"
          id="userSearch"
          placeholder={'Institute Name'
          } onChange={(event) => {
            getInsituteInfo(event.target.value);
            handleInputChange(event);
          }}
          style={{
             fontSize: '0.8vw',
            paddingLeft: '26px',
            height: '23px',
            fontFamily: 'Inter',
            backgroundImage: `url(${search})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '5px center',
            backgroundSize: '15px 15px',
            paddingRight: '5px', 
            borderColor:'#cccccc',
            borderStyle:'solid',
            borderRadius:"5px",
            borderWidth:'1px',
            height:'34px',
            marginTop:'11px'
          }}
        />
          }
            
          </div>
            </Col>
            <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                                closeOnSelect="false"

                  id="fromDate"
                  dateFormat="DD MMM YYYY"
                  timeFormat={false}
                  onChange={(event) =>{ 
                    if (event && event.format) {
                    setFilterDateFrom(event.format('DD MMM YYYY'));
                  }else{
                    setFilterDateFrom('');
                    clearDateFilter()
                  }
                  }}
                  locale="en-gb"
                  inputProps={{ placeholder: 'From Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime 
                closeOnSelect="false"
                  id="toDate"
                  dateFormat="DD MMM YYYY"
                  isValidDate={valid}
                  timeFormat={false}
                  locale="en-gb"
                  onChange={(event) => {
                    if (event && event.format) {
                      setFilterDateTo(event.format('DD MMM YYYY'));
                    } else {
                      setFilterDateTo(''); 
                      clearDateFilter()
                    }
                  }}
                  inputProps={{ placeholder: 'To Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>

            <Col>
            <div> 
            <button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter",paddingLeft:'10px'}}
                onClick={() => handleDateFilterApi(filterDateFrom, filterDateTo)}
               
              >
                Search Results
              </button>
              </div>
            </Col>
            {/* <Col>
            <div>
              <button style={{width:'100px',backgroundColor:'gray',height:'36.5px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter"}} onClick={() => handleClearFilter()} color="primary">
                Clear Filter
              </button>
              </div>
            </Col> */}
             <Col>
            <button style={{backgroundColor:'#D22129',width:'90px',height:'34px',padding:'10px',display: 'flex', alignItems: 'center',marginLeft:"150px",borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter"}} id="downloadButton" onClick={() => handleDownloadList()}>
           <p style={{display:'flex',alignItems:'center',marginTop:'15px'}}><img style={{height:'15px',alignItems:"center",marginRight:'5px'}}  src={download} alt="download"/><span>Export</span></p> 
            </button>
            </Col>
          </div>
        </Row>
        {isLoading ? (
           <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%',backgroundColor:'white',height:'100vh'}}>
           <CircularProgress />
         </Box>
        ):(
        <>
        <div className="tables" style={{ marginTop: '20px' }}>
                    <table hover>
                      <thead className='table-heading'>
                        <tr >
                          <th style={{  fontSize: '0.9vw',fontFamily: "Inter",width:'5em' ,borderTopLeftRadius:'8px' }} id="appId">
                        <span style={{marginLeft:'10px'}}></span>Appl. Id
                          {/* <img style={{width:'14px',cursor:'pointer',fontFamily: "Inter"}} src={Sort} alt="sort"/> */}
                          </th>
                          <th  id="appname" style={{ fontSize: '0.9vw',fontFamily: "Inter",width:'15%' }}><span style={{marginLeft:'10px'}}>Appl. Name</span></th>
                          {(decode.role==="institute_user"||decode.role=="institute_admin" ) && decode.instituteId === "0f25fafd-77c0-4c05-9cf1-1b238f5eebd3" ? 
                          <th  style={{  fontSize: '0.9vw',fontFamily:"Inter",width:'10em' }} id="appname">
                          <span style={{marginLeft:'10px'}}></span> Student Name
                          </th>:null
                          }
                          {decode.role==="institute_user"||decode.role==="institute_admin" ? null: <th  id="insName" style={{ fontSize: '0.9vw',fontFamily: "Inter",width:'15em' }}><span style={{marginLeft:'10px'}} >Institute Name</span></th>}
                          <th  id="amt" style={{width:'10em'}}><span style={{ fontSize: '0.9vw',fontFamily: "Inter",marginLeft:'10px'  }}>Amount</span></th>
                          {decode.role==="institute_user"||decode.role==="institute_admin"?<th  id="amt" title="Subvention Amount"><span style={{marginLeft:'10px'}}>SV</span></th>:null}
                          {decode.instituteId==="1a62ed6b-a5f5-4ab3-acfa-6e353b070149"?<th  id="amt" title="Processing Fee Amount"><span style={{marginLeft:'20px'}}>PF</span></th>:null}
                          <th  id="pfAmount"  style={{width:'4em'}}> <span style={{marginLeft:'15px', fontSize: '0.9vw',fontFamily: "Inter" ,whiteSpace:'nowrap'}}>Disbursed Dt.</span></th>
                          <th  id="disbursedAmount" style={{width:'4em'}}> <span style={{ fontSize: '0.9vw',fontFamily: "Inter",marginLeft:'20px' ,whiteSpace:'nowrap'}}>Disbursed Amt</span></th>
                          <th  id="tenure" style={{ textAlign: 'center' ,width:'10em'}}>
                          <span  style={{marginLeft:'10px', fontSize: '0.9vw',fontFamily: "Inter" }}>Tenure</span> 
                          </th>
                          {decode.role==="institute_user"||decode.role==="institute_admin" ? null: <th  id="insName" style={{ fontSize: '0.9vw',fontFamily: "Inter",width:'5em' }}><span style={{marginLeft:'10px'}} >Fund Src.</span></th>}
                          {decode.role==="institute_user"||decode.role==="institute_admin" ?  <th  style={{ textAlign: 'center' ,width:'5em',borderTopRightRadius:'8px'}}>
                          <span style={{marginRight:'10px', fontSize: '0.9vw',fontFamily: "Inter" ,whiteSpace:'nowrap'}}>  Emi First Date</span>
                          </th>:null}
                        {decode.role==="institute_user"||decode.role==="institute_admin" ?  <th  style={{ textAlign: 'center' ,width:'1em',borderTopRightRadius:'8px'}}>
                          <span style={{ fontSize: '0.9vw',fontFamily: "Inter" ,whiteSpace:'nowrap'}}>  Emi Amount</span>
                          </th>:null}
                        
                          <th id="utr" style={{ textAlign: 'center' ,width:'10em',borderTopRightRadius:'8px'}}>
                          <span style={{marginRight:'50px', fontSize: '0.9vw',fontFamily: "Inter" }}>  UTR</span>
                          </th>
                        </tr>
                      </thead>
                      {tableData.map((item,index) => {
                      
                          return (
                            <tbody className="table-body" key={item.applicationId}>
                              <tr className='table-row' style={{ lineHeight: '20px' }}>
                                {decode.role!=="institute_user"&&decode.role!=="institute_admin"?<td style={{width:'8em' ,fontFamily: "Inter"}} >
                                <span onClick={()=>handleApplicationProfile(item.applicationId,item.userId,item.coapplicantId)} style={{cursor:'pointer', fontSize: '0.9vw', fontWeight: '500', fontFamily:'Inter', width: '8em' ,color:'#101828',fontFamily: "Inter" ,display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}> {item.applicationId}</span>
                                </td>:<td style={{width:'5em',fontFamily: "Inter"}} >
                                  <span style={{  fontSize: '0.9vw', fontWeight: '400', width: '5em',fontFamily: "Inter" }}>{item.applicationId}</span>
                                </td>
                                }
                                {decode.role==="institute_user"||decode.role==="institute_admin"?<td
                                  style={{
                                    fontSize: '0.9vw' ,color:'#101828'
                                    ,fontFamily: "Inter",
                                  
                                  }}
                                
                                >
                                  <span title={item.name} style={{maxWidth: '12em',fontFamily: "Inter",lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}} >{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                                  {item.coapplicantName ? (
                                    <span title={item.coapplicantName} style={{maxWidth: '12em',fontFamily: "Inter",lineHeight: '2em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                                      {' '}
                                      {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </td>:<td
                                  style={{
                                    fontSize: '0.9vw',
                                    color: '#101828',
                                    cursor: 'pointer',
                                fontFamily: "Inter"
                                    
                                  }}
                                >
                                  <span title={item.name} style={{width: '18em',fontFamily: "Inter",lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }} onClick={() => handleUser(item)}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                                  {item.coapplicantName ? (
                                    <span title={item.coapplicantName} style={{width: '18em',fontFamily: "Inter",lineHeight: '2em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }} onClick={() => handleCoApp(item)}>
                                      {' '}
                                      {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </td>}
                                {(decode.role==="institute_user"||decode.role==="institute_admin")&&decode.instituteId === "0f25fafd-77c0-4c05-9cf1-1b238f5eebd3" ? <td title={item.studentName} >
                                <span style={{width: '10em', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}> {item.studentName ? item.studentName : "-"}</span>
                                </td>:null}
                                {decode.role==="institute_user"||decode.role==="institute_admin"? null:
                              <td title={item.instituteName}><span style={{  fontSize: '0.9vw', width: '10em', color: '#667085',fontFamily: "Inter",display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item.instituteName?item.instituteName:'-'}</span></td> 
                              }
                                <td><span style={{  fontSize: '0.9vw', width: '5.5em', textAlign: 'center', color: '#667085',fontFamily: "Inter"}}onClick={() => console.log('cibil')}>
                                {item.amount ? ( <>&#8377;{formatNumberWithCommas(item.amount)}</>) : ( '-')}
                                </span>
                                  
                                </td>
                                { decode.role==="institute_user"||decode.role==="institute_admin"?<td
                                  style={{  fontSize: '0.9vw', width: '4em', textAlign: 'center', color: '#667085',fontFamily: "Inter"  }}
                                  onClick={() => console.log('cibil')}
                                >
                                  {Math.round(item.subventionAmount)}{' '}
                                </td>:null}
                              { decode.instituteId==="1a62ed6b-a5f5-4ab3-acfa-6e353b070149"?<td
                                  style={{  fontSize: '0.9vw', width: '4em', textAlign: 'center', color: '#667085',fontFamily: "Inter"  }}
                                  onClick={() => console.log('cibil')}
                                >
                                  {Math.round(item.processingFee)}{' '}
                                </td>:null}
                                <td  style={{ fontSize: '0.9vw', width: '4em', textAlign: 'center' ,fontFamily:"Inter",color: '#667085'}}>{item.disbursementDate?item.disbursementDate:'-'}</td>
                                <td style={{ fontSize: '0.9vw', width: '4em', textAlign: 'center', color: '#667085', fontFamily: 'Inter' }}>
                                  {item.disbursedAmount ? (<>&#8377;{formatNumberWithCommas(Math.round(item.disbursedAmount))}</>) : ('-')}</td>
                                <td style={{  fontSize: '0.9vw', width: '5em', textAlign: 'center', color: '#667085' ,fontFamily: "Inter" }}>
                                  {item.tenure?item.tenure:'-'}
                                </td>
                                {decode.role==="institute_user"||decode.role==="institute_admin"? null:
                              <td><span style={{  fontSize: '0.9vw', width: '5em', color: '#667085',fontFamily: "Inter",display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item.fundSource?item.fundSource:'-'}</span></td> 
                              }
                                { decode.role==="institute_user"||decode.role==="institute_admin" ?  <td style={{  fontSize: '0.9vw', maxWidth: '1em', textAlign: 'center' , color: '#667085' ,fontFamily: "Inter"}}>
                                  {item.emiFirstDate?item.emiFirstDate:'-'}
                                </td>:null}
                              {decode.role==="institute_user"||decode.role==="institute_admin" ?  <td style={{  fontSize: '0.9vw', width: '1em', textAlign: 'center', color: '#667085' ,fontFamily: "Inter" }}>
                                {item.emiAmount ? ( <>&#8377;{formatNumberWithCommas(item.emiAmount)}</>) : ( '-')}                          
                                </td>:null}
                              
                                <td style={{  fontSize: '0.9vw', width: '8em', color: '#667085',fontFamily: "Inter"  }}>{item.disbursementUtr?item.disbursementUtr:'-'}</td>
                              </tr>
                            
                            </tbody>
                          );
                      })}
                    </table>
                    </div>
                    {usersearchinsstate?null:<>{totalFetchRows ? (searchText && totalFetchRows <= 10 || filterDateFrom && filterDateTo && totalFetchRows <= 10?(
                    <div style={{display:"flex" ,justifyContent:"center",marginTop:"10px"}}>
                    <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px' }}>
              
              Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{" "}
              records of {totalFetchRows} |
              
            </div>
          <div style={{display:"flex", justifyContent:"space-between",}}>
          &nbsp;<p style={{fontWeight:'600'}}>Rows Per Pages :</p>
        <div>
        <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
        <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
        <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
        <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
        </div>


        </div>
                    
                  </div>
              
          ):( <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}><div style={{ textAlign: 'center',fontWeight: 'bold',justifyContent:"space-between",fontFamily:"Inter-Medium", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px',marginRight:'10px'}}>
              
          Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length} {" "}
          records of {totalFetchRows} |
          
        </div>
        <div style={{display:"flex", justifyContent:"space-between",}}>
          <p style={{fontWeight:'600'}}>Rows Per Pages :</p>
        <div>
        <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
        <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
        <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
        <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
        </div>
        </div>
        </div> )
            
          ): (
                      <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
                    )}
                    <PaginationTable
                      startPage={currentPage}
                      rowsPerPage={rowsPerPage}
                      totalRows={totalFetchRows}
                      paginate={paginate}
                    searchText={searchText}
                    filterDateFrom={filterDateFrom}
                    /></>}
        </>
        )}
          
          
        </CardBody>
        </div>
      </Card>
    );
  }
  return <div>....Loading</div>;
};

export default DisbursedTable;


