import React,{useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import Group_1 from '../../assets/images/Group_1.png'
import FeeMonk_HighRes_Logo from '../../assets/images/FeeMonk_HighRes_Logo.png'
import Family  from '../../assets/images/card/family.png';

import elipse  from '../../assets/images/Ellipse.png';
import backArrow from '../../assets/images/icons/arrow.png'
import success from '../../assets/images/icons/success.png'
import './index.css'
import axiosInstance from '../../helpers/axios'

export default function ForgotPassword() {

    const navigate=useNavigate();
    const [newPassword,setNewPassword]=useState(null)
    const [recoveryEmail,setRecoveryEmail]=useState(null)
    const [confirmNewPassword,setConfirmNewPassword]=useState(null)
    const [newPasswordVisible,setNewPasswordVisible]=useState(false)
    const [forgotPasswordVisible,setForgotPasswordVisible]=useState(true)
    const [processCompleted,setProcessCompleted]=useState(false)

    const handleGoBackForgot=()=>{
         setNewPasswordVisible(false)
      setForgotPasswordVisible(true)
      setRecoveryEmail(null)
    }
    const handleGoBackLogin=()=>{
        navigate("/")
    }

    const urlToSendEmail=`/auth/trigger-otp`
    const [submitDisabled,setSubmitDisabled]=useState(null)
    const [actualEmailId,setActualEmailId]=useState(null)
    const [timer,setTimer]=useState(false)
    const handleSetNewPassword=()=>{
        setTimer(false)
      
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(recoveryEmail))
        {
         

        setSubmitDisabled(true)
        const data={
          emailId:recoveryEmail
        }
        axiosInstance.post(urlToSendEmail,data)
        .then((res)=>{
          if(res.data.message==="Successful"){
            setNewPasswordVisible(true)
            setActualEmailId(res?.data?.data)
            setForgotPasswordVisible(false)
            setTimeout(() => {
              
              setSubmitDisabled(false)
            }, 1000);
            setTimeout(() => {
              
              setTimer(true)
            }, 45000);
  
            }
          
        }
        )
        .catch((err)=>{
          window.alert("User email does not exist")
          setTimeout(() => {
              
              setSubmitDisabled(false)
            }, 1000);
        })
      }
      else
      {
        alert('Enter valid mail Id')
      }

      
    }

    const [resetOtp,setResetOTP]=useState(null)

    const urlSetNewPassword=`/auth/password-reset`
    const handleProcessCompleted=()=>{
      console.log(newPassword)
      if(newPassword&&confirmNewPassword)
      {

        if(newPassword===confirmNewPassword&&newPassword.length>=8&&confirmNewPassword.length>=8)
        {
  
          const data={
            emailId:recoveryEmail,
            otp:resetOtp,
            password:newPassword
          }
          axiosInstance.post(urlSetNewPassword,data)
          .then((res)=>{
            if(res.data.message==="Successful")
            {
               setNewPasswordVisible(false)
              setProcessCompleted(true)
            }
            
          })
          .catch((err)=>{
            window.alert('Invalid OTP')
          })
          
        }
        else
        {
          window.alert("Passwords Do not Match or Length is less than 8")
        }
      }
      else
      {
        window.alert('Please enter password')
      }
    }

    const handleGotToLogin=()=>{
      navigate("/")
    }

  return(

     <div className="login-form-container">
        <div className="login-background">
          {/* <div className="login-background-2"> */}
          <img src={Group_1} className="login-image" alt="login" />

          <img
            src={FeeMonk_HighRes_Logo}
            className="feemonk-image"
            alt="website logo"
            
          />

          <p className="caption">Simplified education fee payments & fiscal management</p>
        {/* </div> */}
        </div>
       { forgotPasswordVisible?
        <div className="form-container">
          
            <h3 style={{textAlign:'center',fontFamily:'Inter',fontWeight:'600'}}>Forgot Password ?</h3>
          <small style={{textAlign:'center',fontFamily:'Inter',color:'grey',fontWeight:'500'}}>No worries, we’ll send you an <strong> OTP</strong> to your registered email Id.</small>
           
          <p style={{fontSize:'13px',fontFamily:'Inter',fontWeight:'600',marginTop:'5em'}}>Email</p>
          <input className="input-filed" type="text"  onChange={(e)=>setRecoveryEmail(e.target.value)} placeholder="Enter your email"/>
      
          <button disabled={submitDisabled} onClick={()=>handleSetNewPassword()}className="submit" type="submit">{submitDisabled?'Wait...':'Submit'}</button>

          <div style={{padding:'1.5em'}}>
            <img onClick={()=>handleGoBackLogin()} style={{cursor:'pointer',width:'15px',marginLeft:'8em'}} src={backArrow} alt=""/><span onClick={()=>handleGoBackLogin()} style={{fontFamily:'Inter',fontSize:'13px',cursor:'pointer',marginLeft:'1em',color:'grey',fontWeight:'500',textDecoration:'underline'}}>Back to log in</span>
          </div>
          
        </div>:null
         }
        
          { newPasswordVisible?
          <div className="form-container-new" style={{lineHeight:'2vw'}}>
          <img onClick={()=>handleGoBackForgot()} style={{cursor:'pointer',width:'25px'}} src={backArrow} alt="back"/>
          <h3 style={{textAlign:'center',fontFamily:'Inter',fontWeight:'600'}}>Set new Password</h3>
          <small style={{textAlign:'center',fontFamily:'Inter',color:'grey',fontWeight:'500'}}>OTP is sent to <strong style={{textDecoration:'underline',color:'black'}}>{actualEmailId}</strong></small>
           
          <p style={{fontSize:'13px',fontFamily:'Inter',fontWeight:'600',marginTop:'10px'}}>OTP<br/><small onClick={!timer?()=>console.log("wait few seconds"):()=>handleSetNewPassword()}>Didn't receive OTP ? Wait for 45s and hit <strong style={{fontSize:'14px', color:!timer?'grey':'blue',cursor:timer?"pointer":""}}><u>Resend</u></strong></small></p>
         
          <input className="input-filed" type="text"  onChange={(e)=>setResetOTP(e.target.value)} placeholder="Enter OTP sent to your mail"/>
          <p style={{fontSize:'13px',fontFamily:'Inter',fontWeight:'600',marginTop:'2em'}}>New Password</p>
          <input className="input-filed" type="password"  onChange={(e)=>setNewPassword(e.target.value)} placeholder="New Password"/>
          <small style={{fontFamily:'Inter',fontSize:'12px',padding:'5pxw',color:'grey'}}>Must be at least 8 characters</small>
          <p style={{fontSize:'13px',marginTop:'3em',fontFamily:'Inter',fontWeight:'600'}}>Confirm New Password</p>
          <input className="input-filed" type="password" onChange={(e)=>setConfirmNewPassword(e.target.value)} placeholder="Confirm New Password"/>
          <button onClick={()=>handleProcessCompleted()} className="submit" type="submit">Submit</button> 

          </div>:null
          }
           
          { processCompleted?
          <div className="form-container-new">
          <img style={{width:'50px',alignSelf:'center'}} src={success} alt="success"/>
          <h3 style={{textAlign:'center',fontFamily:'Inter',fontWeight:'600'}}>Password Changed successfully!</h3>
          <small style={{textAlign:'center',fontFamily:'Inter',color:'grey',fontWeight:'500'}}>Password Changed successfully!</small>
           
          
          <button onClick={()=>handleGotToLogin()} className="submit" type="submit">Continue</button> 
          </div>:null
          }


            <img className="family-image" src={Family} alt="family"/>
        <img className="elipse" src={elipse} alt="bg"/>
      </div>
   


  )
}