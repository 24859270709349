import { userConstants } from './../constants/user.constants';

let user = sessionStorage.getItem('user');
const initialState = user ? { loggedIn: false, user, isLoading: false } : {};

//ckyc
export function CKYC(state = initialState, action) {
  console.log("inside CKYC function", action)
  switch (action.type) {
    case userConstants.CKYC_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.CKYC_SUCCESS:
        console.log("Action", action)
      return {
      ...state,
        loggedIn: true,
        ckycData: action,
        isLoading : false
      };
    case userConstants.CKYC_FAILURE:
      return {
        ...state,
        isLoading : false
      };
    default:
      return state
  }
}

//digilocker
export function DIGILOCKER(state = initialState, action) {
  console.log("inside DIGILOCKER function", action)
  switch (action.type) {
    case userConstants.DIGILOCKER_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.DIGILOCKER_SUCCESS:
        console.log("Action", action)
      return {
          
        loggedIn: true,
        digiLockerData: action,
        isLoading : false
      };
    case userConstants.DIGILOCKER_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}

//pan 

export function PAN(state = initialState, action) {
  console.log("inside PAN function", action)
  switch (action.type) {
    case userConstants.PAN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.PAN_SUCCESS:
        console.log("Action", action)
      return {
          
        loggedIn: true,
        panStatus: action,
        isLoading : false
      };
    case userConstants.PAN_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}
export function PANPRO(state = initialState, action) {
  console.log("inside PANPRO function", action)
  switch (action.type) {
    case userConstants.PANPRO_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.PANPRO_SUCCESS:
        console.log("Action", action)
      return {
          
        loggedIn: true,
        panStatus: action,
        isLoading : false
      };
    case userConstants.PANPRO_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}


export function BANKSELFIE(state = initialState, action) {
  console.log("inside BANK_SELFIE function", action)
  console.log(action,"THIS IS ACTION")
  console.log(action.type,"THIS IS ACTION TYPE")
  switch (action.type) {
    case userConstants.BANK_SELFIE_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.BANK_SELFIE_SUCCESS:
        console.log("Action", action)
      return {
          
        loggedIn: true,
        bankSelfieData: action,
        isLoading : false
      };
    case userConstants.BANK_SELFIE_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}
export function PROFILE(state = initialState, action) {
  console.log("inside PROFILE function", action)
  
  switch (action.type) {
    case userConstants.PROFILE_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.PROFILE_SUCCESS:
        console.log("Action", action)
      return {
          
        loggedIn: true,
        profileDetails: action,
        isLoading : false
      };
    case userConstants.PROFILE_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}
export function UPLOADEDFILES(state = initialState, action) {
  console.log("inside UPLOADEDFILES function", action)
  switch (action.type) {
    case userConstants.UPLOADEDFILES_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.UPLOADEDFILES_SUCCESS:
        console.log("Action", action)
      return {
          
        loggedIn: true,
        uploadedFilesDetails: action,
        isLoading : false
      };
    case userConstants.UPLOADEDFILES_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}
export function CIBIL(state = initialState, action) {
  console.log("inside CIBIL function", action)
  switch (action.type) {
    case userConstants.CIBIL_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.CIBIL_SUCCESS:
        console.log("Action", action)
      return {
          
        loggedIn: true,
        cibilData: action,
        isLoading : false
      };
    case userConstants.CIBIL_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}

export function CIBILSOFT(state = initialState, action) {
  console.log("inside CIBILSOFT function", action)
  switch (action.type) {
    case userConstants.CIBILSOFT_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.CIBILSOFT_SUCCESS:
        console.log("Action", action)
      return {
          
        loggedIn: true,
        cibilSoftData: action,
        isLoading : false
      };
    case userConstants.CIBILSOFT_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}

export function POSTINCOMEPDF(state = initialState, action) {
  console.log("inside postIncomePDf function", action)
  switch (action.type) {
    case userConstants.POST_INCOMEPROFILE_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.POST_INCOMEPROFILE_SUCCESS:
        console.log("Action", action)
      return {

        loggedIn: true,
        postIncomePdfData: action,
        isLoading : false
      };
    case userConstants.POST_INCOMEPROFILE_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}

export function GETINCOMEPROFILE(state = initialState, action) {
  console.log("inside IncomeProfile function", action)
  switch (action.type) {
    case userConstants.GET_INCOMEPROFILE_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.GET_INCOMEPROFILE_SUCCESS:
        console.log("Action", action)
      return {

        loggedIn: true,
        getIncomePdfData: action,
        isLoading : false
      };
    case userConstants.GET_INCOMEPROFILE_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}
export function POSTFOIR(state = initialState, action) {
  console.log("inside POST foir function", action)
  switch (action.type) {
    case userConstants.POSTFOIR_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.POSTFOIR_SUCCESS:
        console.log("Action", action)
      return {

        loggedIn: true,
        postFoirData: action,
        isLoading : false
      };
    case userConstants.POSTFOIR_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}
export function GETFOIRDETAILS(state = initialState, action) {
  console.log("inside GET foir function", action)
  switch (action.type) {
    case userConstants.GETFOIR_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.GETFOIR_SUCCESS:
        console.log("Action", action)
      return {

        loggedIn: true,
        getFoirDetails: action,
        isLoading : false
      };
    case userConstants.GETFOIR_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}