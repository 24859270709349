import React, { useState,useEffect} from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import close from "../../assets/images/close.png"
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import edit from "../../assets/images/edit.png"
import { userActions } from '../../actions/user.actions';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Input, FormText,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';

export default function ManageInstitutes(InstituteId) {
   console.log(InstituteId.InstituteId.logo,"inst")
    const editstate = useSelector((state) => state.Editform?.res);
    const savedstate = useSelector((state)=> state.Updateeditform?.res)
    console.log("before dispatch",editstate)
    const [formOpen, setFormOpen] = useState(false);
    const [updateInstituteName,setUpdateInstituteName]=useState('');
    const [updateInstituteCode,setUpdateInstituteCode]=useState('');
    const [updateInstituteEmail,setUpdateInstituteEmail]=useState('');
    const [gstApplicableCheck, setGSTApplicableCheck] = useState(false);
    const [updateInstituteContact,setUpdateInstituteContact]=useState('');
    const [updateInstituteLogo,setUpdateInstituteLogo]=useState('');
    const [updateInstituteAddress,setUpdateInstituteAddress]=useState('');
    const [updateInstituteState,setUpdateInstituteState]=useState('');
    const [updateInstituteCity,setUpdateInstituteCity]=useState('');
    const [updateInstituteGst,setUpdateInstituteGst]=useState('');
    const [updateInstitutePincode,setUpdateInstitutePincode]=useState('');
    const [selectedInstitute,setSelectedInstitute]=useState(null)
    const [updateGstNumber, setUpdateGSTNumber] = useState('');
    const dispatch = useDispatch();

  const location = useLocation();


  //const { user } = useUserAuth();
  
  const user = sessionStorage.getItem("user")
 
const updateDetailsOfInstitute=()=>{
    
  if(updateInstituteLogo)
  {

    const file=new FormData();
   file.append('file',updateInstituteLogo)
   file.append('type',"logo")
   axios.post(`${process.env.REACT_APP_BASE_URL}/file/upload`,file)
   .then((res)=>{
   const updateInstituteDetailsUrl=`/institute/info/edit`

   const updateObj={
    
          id: InstituteId?.InstituteId?.id,
          code: updateInstituteCode?updateInstituteCode:document.getElementById("insCode").value,
          name: updateInstituteName?updateInstituteName:document.getElementById("insName").value,
          email: updateInstituteEmail?updateInstituteEmail:document.getElementById("insEmail").value,
          contact: updateInstituteContact?updateInstituteContact:document.getElementById("insContact").value,
          address: updateInstituteAddress?updateInstituteAddress:document.getElementById("insAddress").value,
          city: updateInstituteCity?updateInstituteCity:document.getElementById("insCity").value,
          gstNumber: updateGstNumber ? updateGstNumber : (document.getElementById("insGst") ? document.getElementById("insGst").value : null),
          // gstNumber: updateGstNumber?updateGstNumber:document.getElementById("insGst").value,

          state: updateInstituteState?updateInstituteState:document.getElementById("insState").value,
          pincode: updateInstitutePincode?updateInstitutePincode:document.getElementById("insPincode").value,
          logo: res?.data?.data?.s3Url,
      
   }
   dispatch(userActions.updateInstitutedetails(updateInstituteDetailsUrl,updateObj,user));
 })
  }
  else
  {
     const updateInstituteDetailsUrl=`/institute/info/edit`

      const updateObj={
          id: InstituteId?.InstituteId?.id,
          code: updateInstituteCode?updateInstituteCode:document.getElementById("insCode").value,
          name: updateInstituteName?updateInstituteName:document.getElementById("insName").value,
          email: updateInstituteEmail?updateInstituteEmail:document.getElementById("insEmail").value,
          contact: updateInstituteContact?updateInstituteContact:document.getElementById("insContact").value,
          address: updateInstituteAddress?updateInstituteAddress:document.getElementById("insAddress").value,
          city: updateInstituteCity?updateInstituteCity:document.getElementById("insCity").value,
          gstNumber: updateGstNumber ? updateGstNumber : (document.getElementById("insGst") ? document.getElementById("insGst").value : null),
          gstApplicable: updateGstNumber ||document.getElementById("insGst").value?true:false,
          state: updateInstituteState?updateInstituteState:document.getElementById("insState").value,
          pincode: updateInstitutePincode?updateInstitutePincode:document.getElementById("insPincode").value,
          
      }
      dispatch(userActions.updateInstitutedetails(updateInstituteDetailsUrl,updateObj,user));

   
  }
}
  
  const popUpForm = () => {
    setFormOpen(true);
  }
  return (
    <div>
     <img style={{height:'16px',width:'16px'}} onClick={() => popUpForm()} src={edit}/>
      {formOpen && (
      <>
     <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
      <Modal 
      style={{
         
        top: '10%',
       
        backgroundColor: 'white',maxWidth: '100%'}} size="lg" className='edit-form ' isOpen={formOpen}>
          <div style={{maxHeight:'600px',overflowY:'auto'}}>
          <div style={{borderTopLeftRadius:'10px',borderTopRightRadius:"10px",display:"flex",flexDirection:'row',padding:"20px",justifyContent:"space-between",backgroundColor:"#E9E9E9",height:"50px",alignItems:"center"}}><p className='heading'>Manage Institute</p>
          <img style={{height:'1.5em',width:'1.5em'}}  className="image1" onClick={()=>setFormOpen(!formOpen)} src={close}/>
          </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
            <div>
                      <div style={{display:'flex',justifyContent: 'space-between',}}>
                        <div style={{display:'block'}}>
                            <p style={{fontWeight:'600'}}> Institute Name</p>
                            <Input style={{width:'20em',marginRight:'1em'}} id="insName" placeholder="Update Institute Name" defaultValue={InstituteId?.InstituteId?.brandName || ''} onChange={(e)=>setUpdateInstituteName(e.target.value)}/>
                        </div>
                        
                        <div style={{display:'block'}}>
                            <p style={{fontWeight:'600'}}> Institute Code</p>
                            <Input style={{width:'20em',marginRight:'1em'}} id="insCode" placeholder="Update Institute Name" defaultValue={InstituteId?.InstituteId?.code || ''} onChange={(e)=>setUpdateInstituteCode(e.target.value)}/>
                        </div>
                        <div style={{display:'block'}}>
                            <p style={{fontWeight:'600'}}> Institute Email</p>
                            <Input style={{width:'20em'}} id="insEmail" placeholder="Update Institute Email" defaultValue={InstituteId?.InstituteId?.email || ''} onChange={(e)=>setUpdateInstituteEmail(e.target.value)}/>
                        </div>
                       
                         
                            
                      </div>
                      <div style={{display:'flex',justifyContent: 'space-between',marginTop:'2em'}}>
                      <div style={{display:'block'}}>
                            <p style={{fontWeight:'600'}}> Institute Contact</p>
                            <Input style={{width:'20em'}} id="insContact" placeholder="Update Institute Contact" defaultValue={InstituteId?.InstituteId?.phone || ''} onChange={(e)=>setUpdateInstituteContact(e.target.value)}/>
                        </div>
                        
                        <div style={{display:'block'}}>
                        <p style={{fontWeight:'600'}}> Institute Address</p>
                            <Input style={{width:'20em',marginBottom:'0.5em'}} id="insAddress" placeholder="Update Institute Address" defaultValue={InstituteId?.InstituteId?.address || ''} onChange={(e)=>setUpdateInstituteAddress(e.target.value)}/>
                        </div>
                        <div style={{display:'block'}}>
                        <p style={{fontWeight:'600'}}> Institute City</p>
                            <Input style={{width:'20em',marginBottom:'0.5em'}} id="insCity" placeholder="Update Institute City" defaultValue={InstituteId?.InstituteId?.city || ''} onChange={(e)=>setUpdateInstituteCity(e.target.value)}/>
                        </div>
                       
                         
                            
                      </div>
                      <div style={{display:'flex',justifyContent: 'space-between',marginTop:'2em'}}>
                      <div style={{display:'block'}}>
                      <p style={{fontWeight:'600'}}> Institute State</p>
                            <Input style={{width:'20em',marginBottom:'0.5em'}} id="insState" placeholder="Update Institute State" defaultValue={InstituteId?.InstituteId?.state || ''} onChange={(e)=>setUpdateInstituteState(e.target.value)}/>
                        </div>
                        
                        <div style={{display:'block'}}>
                        <p style={{fontWeight:'600'}}> Institute Pincode</p>
                            <Input style={{width:'20em',marginBottom:'0.5em'}} id="insPincode" placeholder="Update Institute Pincode" defaultValue={InstituteId?.InstituteId?.pincode || ''} onChange={(e)=>setUpdateInstitutePincode(e.target.value)}/>
                        </div>
                       
                        <div style={{display:'block'}}>
                         
                            <p style={{fontWeight:'600'}}> Institute Logo</p>
                            <Input type="file"style={{width:'20em'}} placeholder="Update Institute Logo" id="logo"  defaultValue={selectedInstitute?.logo} onChange={(e)=>setUpdateInstituteLogo(e.target.files[0])}/>
                            {/* {InstituteId?.logo?<img style={{width:'10em',height:'10em',}} src={InstituteId?.logo} alt="logo"/>:null} */}
                            
                        </div>
                      </div>
                      <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div>
                          <p style={{marginTop: '1em',fontWeight:'600'}}><span >GST Number</span></p>
                          
                        <Input id="insGst" defaultValue={InstituteId?.InstituteId?.gstNumber || ''} onChange={(e)=>setUpdateGSTNumber(e.target.value)} type="text" placeholder="GST Number"/> 
                          </div>
                         {InstituteId?.InstituteId?.logo ?  <img style={{width:'6em',marginRight:'10em'}} id="image" src ={InstituteId.InstituteId.logo}/> : null}
                        </div>
                    </div>
                    <Button style={{marginLeft:'40%',width:'10em',backgroundColor:'#D32028',border:'none'}}
                        onClick={() => updateDetailsOfInstitute()}
                      >
                     <span style={{fontFamily:'Inter-Medium',fontSize:'16px',fontWeight:'500', textTransform: 'capitalize',color:'white'}}>Update Details</span>
                      </Button>
            </FormGroup>
          </Form>
        </ModalBody>
       
          </div>
          
        
      </Modal>
      </>
       )}
    </div>
  );
}
