 import React,{useRef,useState,useEffect} from 'react'
 import {
    Col,
    Card,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Row,
  } from 'reactstrap';      
  import {useLocation,useNavigate} from 'react-router-dom';
  import {useSelector,useDispatch} from 'react-redux'
  import {userActions} from '../../actions/user.actions';
   import Sidebar from '../Sidebar';

  import Topbar from '../Topbar';
  import Home from './home.js'
  import AddMembers from './addMembers.js'
  

  export default function Roles() {
   const location=useLocation();
  const navigate=useNavigate();
  
  const [checked, setChecked] = useState(location?.state?.clickEvent==="coapp"?false:true);
  
  const  curruserId=checked?location?.state?.applicantId:location?.state?.coapplicantId
  const viaCibil=location?.state?.viaCibil
  console.log(location?.state?.clickEvent,"dyanmic userId")
  const  currapplicationId=location?.state?.applicationId
  const user=sessionStorage.getItem("user");

   const bankselfiedetails = useSelector((state)=>state.Bankselfiedetails?.res)
  const activityState=useSelector((state)=>state?.ACTIVITY?.activityData)
  const digiLockerState=useSelector((state)=>state?.DIGILOCKER?.digiLockerData);
  const cibilState=useSelector((state)=>state?.CIBIL?.cibilData);
  const panState=useSelector((state)=>state?.PAN?.panStatus);
  const profileState=useSelector((state)=>state?.PROFILE?.profileDetails);
  const ckycState=useSelector((state)=>state?.CKYC?.ckycData);
  const bankSelfieState=useSelector((state)=>state?.BANKSELFIE?.bankSelfieData);
  const uploadedFileState=useSelector((state)=>state?.UPLOADEDFILES?.uploadedFilesDetails);


  const [bankSelfieData,setBankSelfieData]=useState(null)

  const [ckycDetails,setCkycDetails]=useState(null)
  const [activityData,setActivityDetails]=useState(null)
  const [cibilReport,setCibilReport]=useState(null)
  const [panDetails,setPanDetails]=useState(null)
  const [digiLockerDetails,setDigiLockerDetails]=useState(null)
  const [bankSelfieDetails,setBankSelfieDetails]=useState(null)
  const [uploadedFilesDetails,setUploadedFiles]=useState(null)
  const [profileDetails,setProfileDetails]=useState(null)
  const incomeProfileState=useSelector((state)=>state?.GETINCOMEPROFILE?.getIncomePdfData)
  const getFoirState=useSelector((state)=>state?.GETFOIRDETAILS?.getFoirDetails)

  const [incomeProfileData,setIncomeProfileData]=useState(null)
   
    const ckycUrl=`/ckyc/details?userId=${curruserId}`;
  const profileUrl=checked?`/summary/user?id=${curruserId}&applicationId=${currapplicationId}&status=${location?.state?.dir}`:`/summary/coapplicant?id=${curruserId}&applicationId=${currapplicationId}&status=${location?.state?.dir}`;
  const digilockerUrl=`/digilocker/details?applicationId=${currapplicationId
  }&userId=${curruserId}`;
  const panUrl=`/application/pan-details?userId=${curruserId}&applicationId=${currapplicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${currapplicationId
  }&userId=${curruserId}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${curruserId}`
  const getActivityUrl=`/audit?applicationId=${currapplicationId
  }`
   const urlForBankSelfie=`/integrations/application-profile?applicationId=${currapplicationId}`
  //cibil
  const urlCIBIL = `/cibil/report?userId=${curruserId}`
   const [foirRefData,setFoirRefData]=useState(null)
    
  const getFoirRefUrl=`/admin/application/additional-info?applicationId=${currapplicationId}`


  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${curruserId}&applicationId=${currapplicationId}`
  const dispatch = useDispatch();
 
  useEffect(() => {
      if(viaCibil)
      {
        toggle('3')
      }
    
      if(profileState||uploadedFileState){
        
         
        setBankSelfieData(bankselfiedetails)
        setActivityDetails(activityState?.res?.data?.data)
        setDigiLockerDetails(digiLockerState?.res)
        setCkycDetails(ckycState?.res)
        setPanDetails(panState?.res);
        setCibilReport(cibilState?.res?.data?.data?.summary)
        setBankSelfieDetails(bankSelfieState?.res)
        setProfileDetails(profileState?.res?.data)
        sessionStorage.setItem("courseFees",profileState?.res?.data?.courseFees)
        setUploadedFiles(uploadedFileState?.res?.data?.data)
        setIncomeProfileData(incomeProfileState?.res?.data?.data)
        setFoirRefData(getFoirState?.res?.data?.data)
    }
      else{
       
        dispatch(userActions.getCkycDetails(ckycUrl,user));
        dispatch(userActions.getCibilDetails(urlCIBIL));
        dispatch(userActions.getPanStatus(panUrl));
        dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
        dispatch(userActions.getDigiLocker(digilockerUrl,user));
        dispatch(userActions.getProfileDetails(profileUrl,user));
        dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
        dispatch(userActions.getActivity(getActivityUrl,user));
        dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
        dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])


      }
     
  
  }, [profileUrl,profileState,uploadedFileState,getFoirState,bankSelfieDetails])
  
  const [activeTab, setActiveTab] = useState('1');
   
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])

  };
  
 const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleNavigateActivity=()=>{
    sessionStorage.setItem('activityUserId',curruserId)
    sessionStorage.setItem('activityAppId',currapplicationId)
    navigate('/status/profile/activity',{
      state:{
        activityDetails:activityData
      }
    })
  }

  const ref=useRef(null)
  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN'); 
  }
  const prev = () => {
    requestAnimationFrame(() => {
      const scrollLeft = ref.current?.scrollLeft;
      const itemWidth = parseInt(
        getComputedStyle(ref.current?.children[0]).width
      );
      ref.current.scrollLeft = scrollLeft - itemWidth * 1.5;
    });
  };

  const next = () => {
    requestAnimationFrame(() => {
      const scrollLeft = ref.current?.scrollLeft;
      const itemWidth = parseInt(
        getComputedStyle(ref.current?.children[0]).width
      );
      ref.current.scrollLeft = scrollLeft + itemWidth * 1.5;
    });
  };

  const dateOfBirth = new Date(profileDetails?.dateOfBirth);
  const today = new Date();
  
  let age = today.getFullYear() - dateOfBirth.getFullYear();
  
  if (today.getMonth() < dateOfBirth.getMonth() || (today.getMonth() === dateOfBirth.getMonth() && today.getDate() < dateOfBirth.getDate())) {
    age--; 
  }

  const handleApplicationProfile=(currApplicationId)=>{
    console.log("clicked")
    sessionStorage.setItem("appId",currApplicationId)
    const urlForApplicationDetails=`/admin/application/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const urlForBankSelfie=`/integrations/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const getEmiUrl=`/emis?applicationId=${sessionStorage.getItem("appId")}`
    const getActivityUrl=`/audit?applicationId=${sessionStorage.getItem("appId")}`
    
    dispatch(userActions.fetchUrlforApplicationdetails(urlForApplicationDetails,user),[])
    dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    dispatch(userActions.fetchEmiUrl(getEmiUrl,user),[])
    dispatch(userActions.getActivity(getActivityUrl,user))
    navigate("/status/appProfile")
    
  }



   const [editModal,setEditModal]=useState(false)
   const [addRef2,setAddRef2]=useState(false)
  const [addRef3,setAddRef3]=useState(false)
  const [refName,setRefName]=useState(null)
  const [ref2Name,setRef2Name]=useState(null)
  const [ref3Name,setRef3Name]=useState(null)
  const [relationship,setRelationship]=useState("")
  const [relationship2,setRelationship2]=useState("")
  const [relationship3,setRelationship3]=useState("")
  const [refMobile,setRefMobile]=useState(null)
  const [ref2Mobile,setRef2Mobile]=useState(null)
  const [ref3Mobile,setRef3Mobile]=useState(null)

  const postFoirRefUrl=`/admin/application/additional-info`
  const saveReferenceDetails=()=>{  


    const data={
      userId:curruserId,
      applicationId:currapplicationId,
      contact1: refMobile?refMobile.trim():foirRefData?.contact1?foirRefData?.contact1:'',
      contact1Relation:relationship?relationship.trim():foirRefData?.contact1Relation?foirRefData?.contact1Relation:'',
      contact1Name: refName?refName.trim():foirRefData?.contact1Name?foirRefData?.contact1Name:'',
      contact2: ref2Name?ref2Mobile.trim():foirRefData?.contact2?foirRefData?.contact2:'',
      contact2Relation: relationship2?relationship2.trim():foirRefData?.contact2Relation?foirRefData?.contact2Relation:'',
      contact2Name: ref2Name?ref2Name.trim():foirRefData?.contact2Name?foirRefData?.contact2Name:'',
      contact3: ref3Name?ref3Mobile.trim():foirRefData?.contact3?foirRefData?.contact3:'',
      contact3Relation: relationship3?relationship3.trim():foirRefData?.contact3Relation?foirRefData?.contact3Relation:'',
      contact3Name: ref3Name?ref3Name.trim():foirRefData?.contact3Name?foirRefData?.contact3Name:'',
    }
    dispatch(userActions.postFoirDetails(postFoirRefUrl,data,user))
    setEditModal(false)
    window.location.reload();

  }

  const [documentToggle,setDocumentToggle]=React.useState(false)
const handleDocToggle = () => {
    setDocumentToggle(!documentToggle);
  };

  const [isOpen,setIsOpen]=useState(true)

    return (
    
      <Card className="card" style={{width:"100%",height:'100%'}} >
    {/* {
      console.log(profileDetails,"proooo")
    } */}
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%',height:'100%'}}>
          <Topbar/>
         <div style={{paddingLeft: '1em',paddingRight:'1em',marginTop:'1em'}}>
           <Nav tabs>
              <NavItem style={{backgroundColor:activeTab === '1' ? '#fff2f2' : '#fff'}}>
                <NavLink
                 style={{textDecoration:'none',borderBottom: activeTab === '1' ? '2px solid #D32028' : ''}}
                  className={activeTab === '1' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                    toggle('1');
                  }}
                >
                <span  className={activeTab === '1' ? 'tabsHeadingClicked' : 'tabsHeading'}>All Members</span>

                </NavLink>
              </NavItem>

              <NavItem style={{backgroundColor:activeTab === '2' ? '#fff2f2' : '#fff'}}>
                <NavLink
                style={{textDecoration:'none',borderBottom: activeTab === '2' ? '2px solid #D32028' : ''}}
                  className={activeTab === '2' ? 'active bg-transparent' : 'cursor-pointer'}
                  onClick={() => {
                    
                    toggle('2');
                  }}>
                  <span  className={activeTab === '2' ? 'tabsHeadingClicked' : 'tabsHeading'} >Roles & Permissions</span>
                </NavLink>
              </NavItem>
             
          
             
            </Nav>
           
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col  sm="12">
                  <Home/>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12" >
                   
                <AddMembers/>
                  </Col>
                </Row>
              </TabPane>
             
            </TabContent>
             </div>
            </div>
  </Card>
    )

  }