import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import resend from "../../../assets/images/resend.png"
import {
  Alert,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Spinner,
} from 'reactstrap';

import jwt_decode from 'jwt-decode'
import message from "../../../assets/images/message.png";
import Datetime from 'react-datetime';
import "./ResendContent.css";
import close from "../../../assets/images/close.png";
import Switch from 'react-bootstrap-switch';
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import Resend from "../../../assets/images/resend.png";

import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';

export default function ResendConsent(userInfo) {
  //to refresh token
  const ApplicationStatusEnum = {
    InSubmission: 'In Submission',
    InReview: 'In Review',
    InSanction: 'In Sanction',
    InDisbursement: 'In Disbursement',
    Disbursed: 'Disbursed',
    Rejected: 'Rejected',
    Dropped: 'Dropped',
    OnHold:'On Hold'
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  //const { user, logOut } = useUserAuth();
  const user = sessionStorage.getItem("user")
const decode=jwt_decode(user)


  
 

//resend consent
const [resendModal,setResendModal]=useState(false)
const urlResendConsent=`${process.env.REACT_APP_BASE_URL}/end-user/submit/resend-consent`
const handleResendConsent=(item)=>{
  console.log(item)
  const data={
    userId:item.userInfo.userId,
    mobile:item.userInfo.mobile
  }
  axios.post(urlResendConsent,data,{
    headers:{
      Authorization:`Bearer ${user}`
    }
  })
  .then((res)=>{
    console.log("response of resend consent --->>>.",res)
  alert(`Resend Consent ${res.data.message}`)
  setResendModal(!resendModal)})
  .catch((err)=>alert(err));
  
}
  
  return (
    <div>
      <img onClick={()=>setIsModalOpen(true)} style={{cursor:'pointer',width:'1.1vw'}} src={resend} alt="resend consent"/>
     
      {isModalOpen && (
         <>
        <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
     <Modal
     style={{
      
      height:'200px',
      top: '30%',
      
       
     
      
    }} className='resend' isOpen={isModalOpen}>
      
     <img style={{marginLeft:"28em",height:'18px',width:'18px',marginTop:'10px',cursor:"pointer"}} onClick={()=>setIsModalOpen(!isModalOpen)} src={close}/>
              <div style={{marginLeft:'4em'}}><img className="image" src={message} alt="message" /></div>
            <div style={{paddingLeft:'1.5em'}}>
              <p style={{}}>On Clicking <strong> Resend Consent </strong> an SMS will be sent to the <span style={{color:'black',textDecoration:"underline",paddingLeft:'5em'}}>Applicant registered mobile number</span> <strong>{userInfo.phone}</strong></p>
              </div>
           
              <div style={{display:'flex',alignItems:'center',justifyContent:'center',padding:'1em'}} >
              <button style={{width:"150px",backgroundColor:"#D32028",color:"white",border:"none",height:'30px',borderRadius:"5px", display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={()=>handleResendConsent(userInfo)} type="button">Resend Consent</button>
              </div>
          </Modal>
          </>
          )}
          
    </div>
  );
}

