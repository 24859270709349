import * as React from 'react';
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import download from "../../assets/images/download.png"
import Datetime from 'react-datetime';
import calender from "../../assets/images/calender.png"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// import {
//     Chart as ChartJS,
//     LinearScale,
//     ArcElement,
//     CategoryScale,
//     BarElement,
//     PointElement,
//     LineElement,
//     Legend,
//     Tooltip,
//     LineController,
//     BarController,
//   } from "chart.js";
//   import { Chart, Bar, Doughnut,Line } from "react-chartjs-2";
  import { useState, useEffect  } from 'react';
import axiosInstance from '../../helpers/axios';
 

const DemandReports = () =>{
  const user = sessionStorage.getItem('user');
  const [demandReport,setDemandReport] = useState([]);
  const [duefilterDateFrom, setDueFilterDateFrom] = useState("");
  const [duefilterDateTo, setDueFilterDateTo] = useState("");
  const [isLoading, setIsLoading] = useState(false); 

 


  useEffect(() => {
    setIsLoading(true)
    axiosInstance.get('/analytics/demand-collection', {
      headers: {
        Authorization: `Bearer ${user}`,
      }
    })
      .then(function (response) {
      setDemandReport(response?.data?.data);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
        
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);
 
  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN'); 
  }

  const clearDateFilter = () => {
    const url = `/analytics/demand-collection`

   
    setDueFilterDateFrom("");
    setDueFilterDateTo("");
    axiosInstance.get(url, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {

    setDemandReport(res?.data?.data);
       
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
  };




  const handleDownloadList=()=>{
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
    const formattedDate = `${day}-${month}-${year}`;
    document.getElementById("downloadButton").disabled=true;
    document.getElementById("downloadButton").innerText="Wait....";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "130px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    // document.getElementById("downloadButton").style.marginLeft="150px";
    document.getElementById("downloadButton").style.backgroundImage = "none"


    const downloadTemplateUrl=`${process.env.REACT_APP_BASE_URL}/excel/demand-collection `
    
    let queryParams = {}
   
    if(duefilterDateFrom && duefilterDateTo){
      queryParams.fromDate = duefilterDateFrom;
      queryParams.toDate = duefilterDateTo
    }
    
    axiosInstance.get("/excel/demand-collection", {
      params: queryParams,
      responseType: 'arraybuffer',
      headers: {
          Authorization: `Bearer ${user}`
      }
  }).then((res)=>{
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 2000);
    const blob = new Blob([res.data], { type: "application/xlsx", });
    const url= window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href =url
    link.download = `${formattedDate}.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
    
  ).catch((error)=>{
    alert("Error Downloading")
    document.getElementById("downloadButton").innerText="Error..";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      // document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='20px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='15px 10px'
    }, 1000);
  })
  } 


  const handleDateFilterApi = (duefilterDateFrom,duefilterDateTo) => {
    setIsLoading(true);
   
    if (duefilterDateFrom && !duefilterDateTo) {
      alert('Enter  Date Range to Filter');
      setIsLoading(false);
      return;
    }
   
    setDueFilterDateFrom(duefilterDateFrom);
    setDueFilterDateTo(duefilterDateTo);
    let queryParams = {}
   
    if(duefilterDateFrom && duefilterDateTo){
      queryParams.fromDate = duefilterDateFrom;
      queryParams.toDate = duefilterDateTo
    }
   
    
    axiosInstance.get('/analytics/demand-collection',{
      params:queryParams
    }, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
  
    setDemandReport(res?.data?.data);
      
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
      
    
  };


 
    // const options = {
    //     responsive: true,
    //     plugins: {
         
         
    //     },
    //   };
      // const data1 = {
      //   labels:['October 2023','November 2023','December 2023','January 2024','February 2024','March 2024'],
      //   datasets: [
      //     {
      //       label: 'Demand',
      //       data: ["1234","2234","3334","4434","5534","6634"],
      //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
      //     },
      //     {
      //       label: 'Collection',
      //       data: ["1130","1230","2330","3430","4530","5630"],
      //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
      //     },
         
      //   ],
      // };
      const [isOpen,setIsOpen]=useState(true)

    return(
        <div style={{display:'flex',width:"100%",backgroundColor:'white',minHeight:'100vh'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%',padding:'10px'}}>
        <Topbar/>
        {/* <div style={{backgroundColor:'#F8F8F8',borderRadius:'10px',margin:'15px',padding:'20px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}> */}
        <p style={{textAlign:"center",fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px'}}>Demand Vs Collection</p>
        
            <div style={{display:'flex', justifyContent:'space-between'}} >
              <div style={{display:'flex', }}>
            <div style={{ height: '7px',width:'12em'}}>
            <Datetime
                    closeOnSelect="false"
                    id="fromDate"
                    dateFormat="DD MMM YYYY"
                    timeFormat={false}
                    onChange={(event) =>{ 
                      if (event && event.format) {
                      setDueFilterDateFrom(event.format('DD MMM YYYY'));
                    }else{
                      setDueFilterDateFrom('');
                      clearDateFilter()
                    }
                    }}

                    locale="en-gb"
                    inputProps={{ placeholder: 'From Date',style:{fontFamily:'Inter-Medium', backgroundImage: `url(${calender})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '5px center',
                    backgroundSize: '15px 15px', paddingLeft: '30px',fontSize:"14px"} }}
                  />
            </div>
            <div style={{ height: '7px',width:'12em',marginLeft:'20px' }}>
            <Datetime
                  closeOnSelect="false"
                    id="toDate"
                    dateFormat="DD MMM YYYY"
                    timeFormat={false}
                    locale="en-gb"
                    onChange={(event) => {
                      if (event && event.format) {
                        setDueFilterDateTo(event.format('DD MMM YYYY'));
                      } else {
                        setDueFilterDateTo(''); 
                        clearDateFilter()
                      }
                    }}

                    inputProps={{ placeholder: 'To Date',style:{fontFamily:'Inter-Medium', backgroundImage: `url(${calender})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '5px center',
                    backgroundSize: '15px 15px', paddingLeft: '30px',fontSize:"14px"} }}
                  />
            </div>
            <button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium",paddingLeft:'10px',marginLeft:'20px'}}
            
            onClick={() => handleDateFilterApi(duefilterDateFrom,duefilterDateTo)}
            >
              Search Results
            </button>
            </div>
            <div style={{backgroundColor:'#D22129',display: 'flex', alignItems: 'center',width:'130px',height:'34px',borderStyle:'none',borderRadius:"5px",fontFamily:"Inter-Medium",paddingLeft:'10px',justifyContent:'space-around',cursor:'pointer',marginRight:'20px'}}
               id="downloadButton"  onClick={() => handleDownloadList()} >
             
         <img src={download} style={{height:'20px',marginLeft:'8px'}}/> 
         <span style={{fontFamily:"Inter-Medium",color:'white',paddingRight:"15px"}}>Export</span>
        
          </div>    
          </div>
           
           
         
          
        {/* <Bar redraw={true} options={options} data={data1}/>
        </div> */}

         {isLoading ? (
                  <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%'}}>
                  <CircularProgress />
                </Box>
                ):(<>
                 <div className='tables' style={{ marginTop: '20px' }}>
        
        <table  >
            <thead className='table-heading'>
            {/* <tr>
                   <th colSpan="1" style={{  fontSize: '1vw',cursor:'pointer',width:'12%',fontFamily:"Inter-Medium"}}></th>
                   <th colSpan="3" style={{  fontSize: '1vw',cursor:'pointer',width:'12%',fontFamily:"Inter-Medium"}}>Demand /Due (Principal)</th>
                    <th colSpan="4" style={{  fontSize: '1vw',cursor:'pointer',width:'12%',fontFamily:"Inter-Medium"}}>Collection (Principal)</th>
                </tr> */}
                <tr>
                    <th style={{  fontSize: '0.9vw',width:'8%',fontFamily:"Inter",paddingLeft:'10px'}}>Month</th>
                    <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>Arrears</th>
                    <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>Curr. Demand</th>
                    <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>Tot. Demand</th>
                    <th style={{  fontSize: '0.9vw',width:'11%',fontFamily:"Inter"}}>Curr. Collection</th>
                    <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>Arrear Collection</th>
                    <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>Prepayments</th>
                    <th style={{  fontSize: '0.9vw',width:'11%',fontFamily:"Inter"}}>Total Collection</th>
                    <th style={{  fontSize: '0.9vw',width:'11%',fontFamily:"Inter"}}>Repayment Rate(%)</th>
                  
                </tr>
            </thead>
            <tbody className='table-body'>
            {duefilterDateFrom && duefilterDateTo ? 
              demandReport?.map((item, index) =>(
            <tr key={index} className="table-row" style={{lineHeight:'1.3rem'}}>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.month}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.opening_arrears ? <>₹{formatNumberWithCommas(item.opening_arrears)}</> : "-"}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.currentDemand ? <>₹{formatNumberWithCommas(item.currentDemand)}</> : "-"}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>₹{formatNumberWithCommas(parseFloat(item.opening_arrears ? item.opening_arrears : 0) + parseFloat(item.currentDemand ? item.currentDemand : 0))}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.currentCollection ? <>₹{formatNumberWithCommas(item.currentCollection)}</> : '-'}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.arrearCollection ? <>₹{formatNumberWithCommas(item.arrearCollection)}</> : '-'}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.prePayments.differentMonthPre ? <>₹{formatNumberWithCommas(item.prePayments.differentMonthPre)}</> : '-'}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>₹{item.totalCollection ? <>₹{formatNumberWithCommas(item.totalCollection)}</> : '-'}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.currentRepaymentRate ? parseFloat(item.currentRepaymentRate).toFixed(2) : '-'}</td>
           </tr>
    )) : 
    demandReport.slice(-12).map((item, index) => (
        <tr key={index} className="table-row" style={{lineHeight:'1.3rem'}}>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.month}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.opening_arrears ? <>₹{formatNumberWithCommas(item.opening_arrears)}</> : "-"}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.currentDemand ? <>₹{formatNumberWithCommas(item.currentDemand)}</> : "-"}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>₹{formatNumberWithCommas(parseFloat(item.opening_arrears ? item.opening_arrears : 0) + parseFloat(item.currentDemand ? item.currentDemand : 0))}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.currentCollection ? <>₹{formatNumberWithCommas(item.currentCollection + item.prePayments.sameMonthPre + item.arrearCollection.sameMonthPre)}</> : '-'}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.arrearCollection ? <>₹{formatNumberWithCommas(item.arrearCollection.differentMonthPre )}</> : '-'}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.prePayments.differentMonthPre ? <>₹{formatNumberWithCommas(item.prePayments.differentMonthPre)}</> : '-'}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.totalCollection ? <>₹{formatNumberWithCommas(item.totalCollection)}</> : '-'}</td>
            <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.currentRepaymentRate ? parseFloat(item.currentRepaymentRate).toFixed(2) : '-'}</td>
        </tr>
    ))
}


</tbody>
        </table>

        </div>
       
                </>)
        
}

        </div>
       
        </div>
     
        
    )
}

export default DemandReports;