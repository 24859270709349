import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';


export default function ActionAreaCard({loanType, handleLoanTypeForm, image}) {

  
  return (
    <Card sx={{ minWidth: 275, minHeight:230, mb:1, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', textAlign:'center', display:'flex', alignItem:'center', justifyContent:'center' }} onClick={() => handleLoanTypeForm(loanType)}>
      <CardActionArea>
        <img src={image} style={{width:'105px', height:'100px'}}/>
        <CardContent>
          <Typography  variant="h6" component="div" sx={{fontSize:"18px", fontFamily:"Inter", fontWeight:'bold'}}>
            {loanType}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
