import React,{useState,useEffect}from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import * as moment from 'moment'
import basicdetails from "../../assets/images/basicdetails.png";
import kyc from "../../assets/images/kyc.png";
import truecaller from "../../assets/images/truecaller.png"
import whatsapp from "../../assets/images/whatsapp.png"
import gmail from "../../assets/images/gmail.png"
import kycclicked from "../../assets/images/kycclicked.png";
import Basicdetailsclicked from "../../assets/images/Basicdetailsclicked.png";
import sanctionclicked from "../../assets/images/sanctionclicked.png";
import tickicon from "../../assets/images/tickicon.png";
import incomeclicked from "../../assets/images/incomeclicked.png";
import loandetailsclicked from "../../assets/images/loandetailsclicked.png";
import disbursalclicked from "../../assets/images/disbursalclicked.png";
import income from "../../assets/images/income.png";
import share from "../../assets/images/share.png";
import document from "../../assets/images/document.png";
import amount from "../../assets/images/amount.png";
import resend from "../../assets/images/resend.png";
import sanction from "../../assets/images/sanction.png";
import edit from "../../assets/images/edit2.png";
import close from "../../assets/images/close.png"
import rupee from "../../assets/images/rupee.png";
import calender from "../../assets/images/calender.png";
import utr from "../../assets/images/utr.png";
import mandate from "../../assets/images/mandate.png";
import USER from "../../assets/images/user1.jpg";
import Activity from "../../assets/images/activity.png";
import sanctionletter from "../../assets/images/sanctionletter.png";
import loanagreement from "../../assets/images/loanagreement.png";
//import emandate from "../../assets/images/emandate.png";
import applicant from "../../assets/images/applicant.png";
import coapplicant from "../../assets/images/coapplicant.png";
import disbursal from "../../assets/images/dibursal.png";
import loan from "../../assets/images/loan.png";
//import axios from 'axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import EditForm from './EditForm/EditForm';
import {ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import initiateddot from "../../assets/images/initiatedDot.png"
import activedot from "../../assets/images/activeDot.png"
import pendingdot  from "../../assets/images/pendingDot.png"
import  closeddot from "../../assets/images/closedDot.png"


import {
  Table,
    Card,
    CardBody,
    Button,
    Form,FormGroup,
    Modal,
    CardHeader,
    Nav,
    NavItem,
     Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
    NavLink,
} from 'reactstrap'
import { userActions } from '../../actions/user.actions';
import axiosInstance from '../../helpers/axios';

function ApplicationProfile(){
  const [status, setStatus] = useState('')
    const user = sessionStorage.getItem("user")
    const navigate = useNavigate()
    const location = useLocation();

    const dispatch = useDispatch()
    //const state = useSelector((state)=>state)
    const bankselfiedetails = useSelector((state)=>state.Bankselfiedetails?.res)
    const applicationdetails = useSelector((state)=>state.Applicationdetails?.res)
    const emidetails = useSelector((state)=>state.Emidetails?.res)

    const activityState=useSelector((state)=>state?.ACTIVITY?.activityData)
    const  currapplicationId=location?.state?.appId
    console.log("bankselfie details12",bankselfiedetails)

    const getActivityUrl=`/audit?applicationId=${sessionStorage.getItem("appId")}`
    const [activityData,setActivityDetails]=useState(null)

    
    const urlForSelfieRetrigger=`/selfie/retrigger`
    const urlForNachRetrigger=`/nach/retrigger`

    const reInitiateNach=()=>{
      const body={
        applicationId:sessionStorage.getItem('appId'),
        userId:sessionStorage.getItem('userId')
      }
      axiosInstance.post(urlForNachRetrigger,body,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>alert("Re-Initiated Nach Process"))
      .catch((err)=>alert("Error Contact Team"))
    }
  
    const reInitiateSelfie=()=>{
      const body={
        applicationId:sessionStorage.getItem('appId'),
        userId:sessionStorage.getItem('userId')
      }
      axiosInstance.post(urlForSelfieRetrigger,body,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>alert("Re-Initiated Selfie Process"))
      .catch((err)=>alert("Error Contact Team"))
    }
  
    //const navigate=useNavigate();
    const [bankSelfieData,setBankSelfieData]=useState(null)
    const [showActivity,setShowActivity]=useState(false)  

    const [open, setOpen] = useState();
     const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  // const handleAppProfileToProfile = (event) => {
    
  //   const Id = event;
    
  //   const urlProfile = `${process.env.REACT_APP_BASE_URL}/summary/user?id=${Id}&status=In Review`;
  //   axios.get(urlProfile).then((res) => {
      

  //     navigate('/status/appProfile/profile', {
  //       state: { id: 1, profileId: Id,clickEvent:"coapp", userData: res.data.data,applicationId:event.applicationId },
  //     });
  //   });
  // };
  const [isExpanded, setIsExpanded] = useState(false);
  const [isImageClicked1, setIsImageClicked1] = useState(true);
  const [isImageClicked2, setIsImageClicked2] = useState(false);
  const [isImageClicked3, setIsImageClicked3] = useState(false);
  const [isImageClicked4, setIsImageClicked4] = useState(false);
  const [isImageClicked5, setIsImageClicked5] = useState(false);
  const [isImageClicked6, setIsImageClicked6] = useState(false);


  const [showCards1, setShowCards1] = useState(true);
  const [showCards2, setShowCards2] = useState(false);
  const [showCards3, setShowCards3] = useState(false);
  const [showCards4, setShowCards4] = useState(false);
  const [showCards5, setShowCards5] = useState(false);
  const [showCards6, setShowCards6] = useState(false);

    const handleToggle=(id)=>{

        setShowActivity(false)
        
        
        toggle(id)
        if (id === '1') {
            setShowCards1(true);
            setIsImageClicked1(true);
            
          } else {
            setShowCards1(false);
            setIsImageClicked1(false);
          }
          if (id === '2') {
            setShowCards2(true);
            setIsImageClicked2(true)
            
          } else {
            setShowCards2(false);
            setIsImageClicked2(false)
          }
          if (id === '3') {
            setShowCards3(true);
            setIsImageClicked3(true)
          } else {
            setShowCards3(false);
            setIsImageClicked3(false)
          }
          if (id === '4') {
            setShowCards4(true);
            setIsImageClicked4(true)
          } else {
            setShowCards4(false);
            setIsImageClicked4(false)
          }
          if (id === '5') {
            setShowCards5(true);
            setIsImageClicked5(true)
          } else {
            setShowCards5(false);
            setIsImageClicked5(false)
          }
          if (id === '6') {
            setShowCards6(true);
            setIsImageClicked6(true)
          } else {
            setShowCards6(false);
            setIsImageClicked6(false)
          }

    }

  
  
  const urlForBankSelfie=`/integrations/application-profile?applicationId=${sessionStorage.getItem("appId")}`
   

  const [applicationDetails,setApplicationDetails]=useState(null)

  const urlForApplicationDetails=`/admin/application/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    
  const getEmiUrl=`/emis?applicationId=${sessionStorage.getItem("appId")}`
  const [emiTable,setEmiTable]=useState(null)
  const [appUserId,setAppUserId]=useState(null)
  const [coAppUserId,setCoAppUserId]=useState(null)
  const [mandateDetails, setMandateDetails] = useState(null);
  
  const handleNavigateActivity=()=>{
    sessionStorage.setItem('activityUserId',sessionStorage.getItem("userId"))
    sessionStorage.setItem('activityAppId',sessionStorage.getItem("appId"))
    console.log("activity clicked")
    navigate('/status/profile/activity',{
      state:{
        activityDetails:activityData
      }
    })
  }

  const handleMandateDetails=(Emandate)=>{
    setMandateDetails(Emandate)
  }


//  const handleAppProfileToCIBIL = (event) => {
    
//   const Id = event;
  
//   const urlProfile = `${process.env.REACT_APP_BASE_URL}/summary/user?id=${Id}&status=In Review`;
//   axios.get(urlProfile).then((res) => {
//     navigate('/status/appProfile/CIBIL', { state: { id: 1, profileId: Id,clickEvent:"coapp" } });
//   });
// };


useEffect(() => {
  console.log("bank detailsss",bankselfiedetails)
  if(bankselfiedetails){
    
    setBankSelfieData(bankselfiedetails)
  }else{
    dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  }

    if(applicationdetails){
      console.log("application detailsss",applicationdetails)
      sessionStorage.setItem('userId',applicationdetails?.profile?.userDetails?.userId)
      setAppUserId(applicationdetails?.profile?.userDetails?.userId)
      setCoAppUserId(applicationdetails?.coapplicantProfile?.userDetails?.userId)
      setApplicationDetails(applicationdetails)
      setStatus(applicationdetails?.status)
    }else{

      dispatch(userActions.fetchUrlforApplicationdetails(urlForApplicationDetails,user),[])
    }

  if(emidetails){
    console.log("emi details",emidetails)
    setEmiTable(emidetails)
  }else{
    dispatch(userActions.fetchEmiUrl(getEmiUrl,user),[])
  }
   
  if(activityState){
    setActivityDetails(activityState?.res?.data?.data)

  }else{
    dispatch(userActions.getActivity(getActivityUrl,user));

  }         
     
   }, [activityState,getActivityUrl,emidetails,getEmiUrl,bankselfiedetails,urlForBankSelfie,urlForApplicationDetails,applicationdetails])
   const [popUp, setpopUp] = useState(false);
   const handleMobileDetails=()=>{
    setpopUp(!popUp);
    
  }
  const [popUp1, setpopUp1] = useState(false);
   const handleCoappMobileDetails=()=>{
    setpopUp1(!popUp1);
    
  }
 

  const statusIndex = {
    submission : 'In Submission',
    Review : 'In Review',
    Process : 'In Process',
    Pending :'Pending Disbursement',
    onHold: 'On Hold',
    dropped: 'Dropped',
    rejected:'Rejected',
    sanction:'In Sanction',
    disbursement:'Ready to Disburse',
    disbursed:'Disbursed'

  }
 
  const formattedStatus =  Object.keys(statusIndex).find(key => statusIndex[key] === status) || status
  console.log("status",status)
console.log("status path",formattedStatus)


  const handleUser = (event) => {
    
    const Id =sessionStorage.getItem("userId");
    const applId =sessionStorage.getItem("appId");
    const coAppId = sessionStorage.getItem("coappId")
    const urlProfile = `/summary/user?id=${Id}&applicationId=${applId}&status=${status}`;

    dispatch(userActions.fetchHandleuser(urlProfile, user,navigate,applId,Id),[])

      const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/user?id=${Id}&applicationId=${applId}&status=In Sanction`;
  const digilockerUrl=`/digilocker/details?applicationId=${applId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${applId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${applId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${applId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${applId}`
  //cibil
  const urlCIBIL = `${process.env.REACT_APP_BASE_URL}/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${applId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    
   const getFoirRefUrl=`/admin/application/additional-info?applicationId=${applId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(urlProfile));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate(`/status/${formattedStatus}/profile`, {
      state: { id: 1, profileId: Id,applicantId:Id,coapplicantId:coAppId,applicationId:applId,dir:`${status}` },
    });

   
  };
  const handleCoApp = (event,currTab) => {
    
    const Id = sessionStorage.getItem("coappId")
    const applId = sessionStorage.getItem("appId");
    const userId =sessionStorage.getItem("userId");
    const urlProfile = `/summary/coapplicant?id=${Id}&status=${status}`;
   
    dispatch(userActions.fetchHandlcoappuser(urlProfile, user,navigate,applId,Id),[])

      const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/coapplicant?id=${Id}&status=${status}`;
  const digilockerUrl=`/digilocker/details?applicationId=${applId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${applId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${applId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${applId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${applId}`
  //cibil
  const urlCIBIL = `${process.env.REACT_APP_BASE_URL}/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${applId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    
   const getFoirRefUrl=`/admin/application/additional-info?applicationId=${applId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate(`/status/${formattedStatus}/profile`, {
      state: { id: 1, profileId: Id,clickEvent:"coapp",applicantId:userId,coapplicantId:Id,applicationId:applId,dir:`${status}` },
    });
     
   
  };

   const [documentToggle,setDocumentToggle]=React.useState(false)
const handleDocToggle = () => {
    setDocumentToggle(!documentToggle);
  };
  function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-IN'); 
  }

  const [modal, setModal] = useState(false);
  const [agreementUrl,setAgreementUrl] = useState(null)
  const toggle1 = () => {
    const url = `/file/url-content?path=${bankSelfieData?.agreementUrl}`
    axiosInstance
        .get(url, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res)=>{
          setAgreementUrl(res?.data?.data)
          })
    setModal(!modal)
  };

  const [sanctionModal, setModalSanction] = useState(false);
  const [sanctionUrl,setSanctionUrl] = useState(null)

  const toggleSanction = () => {
    const url = `/file/url-content?path=${bankSelfieData?.sanctionLetter}`
    axiosInstance
        .get(url, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res)=>{
          setSanctionUrl(res?.data?.data)
          })
    setModalSanction(!sanctionModal)
  };
  const [isOpen,setIsOpen]=useState(true)
  const [selfiePath,setSelfiePath] = useState(null)

  useEffect(() => {
    const url = `/file/url-content?path=${bankSelfieData?.selfieDetails?.selfie}`
  axiosInstance
      .get(url, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res)=>{
        setSelfiePath(res?.data?.data)
        })
  }, [bankSelfieData]);

  return(
    <div style={{ display:'flex',flexDirection:'row',width:"100%",backgroundColor:"white"}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'77%':'97%', overflow:"auto",marginLeft:isOpen?"21%":'0%',}}>
      <Topbar/>
      <div>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
      <div style={{display:'flex',flexDirection:'row',marginTop:'20px'}}>
         <img style={{width:'7em',height:'7em',borderRadius:'50%'}} src={selfiePath?? USER} alt="User"/>
          <div style={{display:'flex',flexDirection:'column',marginLeft:'20px'}}>
            <span style={{fontFamily:'Inter-Medium',fontWeight:'600',fontSize:'1.5vw',color:'#101828'}}>{sessionStorage.getItem("appId")}</span>
            {applicationDetails?.coapplicantProfile && applicationDetails.coapplicantProfile.userDetails.name && (
                <p >
                <span style={{fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'1vw',color:'#667085'}}> Applicant:</span><span style={{fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'1vw',color:'#D32028',marginLeft:'7px',cursor:'pointer'}} onClick={() => handleUser()} >{applicationDetails?.profile?.userDetails?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || "-------"}</span>
                  
                  <span style={{marginLeft:'10px',marginRight:'10px'}}>|</span><span style={{fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'1vw',color:'#667085'}}>Co-applicant:</span><span style={{fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'1vw',color:'#D32028',marginLeft:'7px',cursor:'pointer'}} onClick={() => handleCoApp()}>{applicationDetails.coapplicantProfile.userDetails.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span> 
                </p>
              )}

              {(!applicationDetails?.coapplicantProfile || !applicationDetails.coapplicantProfile.userDetails.name) && (
                <p >
                  <span style={{fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'1vw',color:'#667085'}}>Applicant:</span> <span style={{fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'1vw',color:'#D32028',marginLeft:'7px',cursor:'pointer'}} onClick={(event) => handleUser(event)}>{applicationDetails?.profile?.userDetails?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') || "-------"}</span>
                </p>
              )}
            {/* <span style={{fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'18px',color:'#667085'}}>Applicant <span style={{fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'18px',color:'#D32028',marginLeft:'7px'}}> {applicationDetails ? applicationDetails?.profile.userDetails.name : null}</span></span> */}
            <span style={{fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'16px',color:'#667085'}}> Work <span style={{fontFamily:'Inter-Medium',fontWeight:'500',fontSize:'16px',color:'#D32028',marginLeft:'7px'}}>{applicationDetails ? applicationDetails?.profile?.userDetails?.company : null} </span></span>
          </div>
      </div>
      <Modal isOpen={modal}  size="xl" style={{height:'100%'}}>
        <ModalHeader toggle={toggle1}>Agreement</ModalHeader>
        <ModalBody >
        <iframe
            src={agreementUrl}
            width="100%"
            height="1000vh"
            style={{ border: 'none' }}
          ></iframe>
        </ModalBody>
        
      </Modal>
      <Modal isOpen={sanctionModal}  size="xl" style={{height:'100%'}}>
        <ModalHeader toggle={toggleSanction}>Sanction Letter</ModalHeader>
        <ModalBody >
        <iframe
            src={sanctionUrl}
            width="100%"
            height="1000vh"
            style={{ border: 'none' }}
          ></iframe>
        </ModalBody>
       
       
      </Modal>
      <div style={{display:'flex',flexDirection:'row',justifyContent: 'space-between',}}>
       
         
          <p onClick={()=>handleNavigateActivity()} style={{borderRadius:'5px',color:'white',marginRight:'10px',cursor:'pointer',fontFamily:'Inter-Medium',fontSize:'1vw',fontWeight:'400',width:'6.5vw',backgroundColor:'#d32028',textAlign:'center'}}><img style={{marginRight:'5px'}}  src={Activity} alt="activity"/>Activity</p>
      
      
          <Dropdown style={{marginBottom:'1em'}}  isOpen={documentToggle} toggle={() => handleDocToggle()}>
                   <DropdownToggle caret style={{backgroundColor:'#d32028',border:'none',height:'2.8em'}}>
                          Documents
                  </DropdownToggle>
                       <DropdownMenu style={{}}>
                          <DropdownItem disabled={bankSelfieData?.agreementUrl?false:true}>
                                 <div onClick={toggle1}>Agreement</div>
                      </DropdownItem>
                          <DropdownItem disabled={bankSelfieData?.sanctionLetter?false:true}>
                                 <div href={bankSelfieData?.sanctionLetter} target="_blank" rel="noreferrer" onClick={toggleSanction}>Sanction Letter</div>
                      </DropdownItem>
                        </DropdownMenu>
                </Dropdown>

          
      
         
       


      </div>

      </div>
      
      <div style={{boxShadow: " 0px 4px 6px 0px #00000026", position: 'relative',backgroundColor:'#F8F8F8',borderRadius:'10px',height:'160px',width:"100%",display:'flex',justifyContent: "space-between",padding:"10px",marginTop:'20px'}}>
      <div>
      <p style={{color:'#D32028',fontWeight:'550',fontFamily:'Inter-Medium',marginLeft:'40px',marginTop:'20px'}}>Institute Name </p>
      <p style={{color:"black",fontFamily:'Inter-Medium',fontFamily:'Inter-Medium',marginLeft:'40px'}}>{applicationDetails?applicationDetails?.profile?.userDetails?.instituteName:null}</p>
      </div>
      <div>
      <p style={{color:'#D32028',fontWeight:'550',fontFamily:'Inter-Medium',marginLeft:'30px',marginTop:'20px'}}>Fees to be Paid </p>
      <p style={{color:"black",fontFamily:'Inter-Medium',fontFamily:'Inter-Medium',marginLeft:'30px'}}>₹{applicationDetails?.profile?.userDetails?.loanAmount !== null
      ? formatNumberWithCommas(applicationDetails?.profile?.userDetails?.loanAmount)
      : null}</p>
      </div>
      <div>
      <p style={{color:'#D32028',fontWeight:'550',fontFamily:'Inter-Medium',marginLeft:'30px',marginTop:'20px'}}>EMI Amount</p>
      <p style={{color:"black",fontFamily:'Inter-Medium',fontFamily:'Inter-Medium',marginLeft:'30px'}}>{applicationDetails?.profile?.userDetails?.emiAmount !== null
      ? `₹${formatNumberWithCommas(applicationDetails?.profile?.userDetails?.emiAmount)?formatNumberWithCommas(applicationDetails?.profile?.userDetails?.emiAmount):"Yet to be selected"}`
      : "Yet to be selected"}</p>
      </div>
      <div>
      <p style={{color:'#D32028',fontWeight:'550',fontFamily:'Inter-Medium',marginLeft:'30px',marginTop:'20px'}}>First EMI Date </p>
      <p style={{color:"black",fontFamily:'Inter-Medium',fontFamily:'Inter-Medium',marginLeft:'30px'}}>{applicationDetails?.profile?.userDetails?.emiDate?moment(applicationDetails?.profile?.userDetails?.emiDate).format("DD MMM YYYY"):"Yet to be selected"}</p>
      </div>
      <div>
      <p style={{color:'#D32028',fontWeight:'550',fontFamily:'Inter-Medium',marginLeft:'30px',marginTop:'20px'}}>Salary</p>
      <p style={{color:"black",fontFamily:'Inter-Medium',fontFamily:'Inter-Medium',marginLeft:'30px'}}>₹{applicationDetails?.profile?.userDetails?.salary !== null
      ? formatNumberWithCommas(applicationDetails?.profile?.userDetails?.salary)
      : null}</p>
      </div>
      <div>
      <p style={{color:'#D32028',fontWeight:'550',fontFamily:'Inter-Medium',marginRight:"100px",marginTop:'20px'}}>Status</p>
      <p style={{color:"black",fontFamily:'Inter-Medium'}}> {status}</p>
      </div>
      <div style={{ position: 'absolute', bottom: 0, right: 25 ,cursor:'pointer'}}>
        <EditForm user={appUserId} />
      </div>
      
      </div>
      {status == "Disbursed"? 
       <Nav className="navProfile" style={{listStyleType:"none",display:"flex",flexDirection:"row",justifyContent:"space-between",marginRight:'50px',marginBottom:'30px',marginTop:"30px"}}>
    {/* <p style={{padding:'0.6em',color:'black',borderRight:'2px solid black',borderRadius:'0.2em',background:'orange',fontWeight:'500'}}>Application Progress</p>  */}
  
  <NavItem style={{display:"flex",flexDirection:'column'}}>
    <NavLink 
    style={{fontWeight:'500',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',justifyContent:"space-between",alignItems:"center",textAlign:'center'}}
      
      onClick={()=>handleToggle('1')
      }
    >
        
    <img  style={{height:'40px',width:'40px'}} src={isImageClicked1 ? Basicdetailsclicked :basicdetails} alt="basicdetails"/>
    <div style={{marginTop:"10px"}}><img style={{padding:'3px',borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div>
    <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginTop:"10px",color: isImageClicked1 ? '#D32027' : 'black'}}>Basic Details</p> 
   
    </NavLink>
  </NavItem >
  <div style={{ alignSelf: 'center', height: '3px', width: '80px', background: '#12B76A'}}></div>
  <NavItem style={{display:"flex",flexDirection:'column'}}>
    <NavLink style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',justifyContent:'space-between',alignItems:"center"}}
    
    onClick={()=>handleToggle('2')
  }>
      
       <img style={{height:'40px',width:'40px'}} src={isImageClicked2 ? kycclicked: kyc} alt="kyc"/>
       <div style={{marginTop:"10px"}}><img style={{padding:'3px',borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div>

      <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginTop:"10px",color: isImageClicked2 ? '#D32027' : 'black'}}>KYC</p>
    </NavLink>
  </NavItem>
  <div style={{ alignSelf: 'center', height: '3px', width: '80px', background: '#12B76A' }}></div>

  <NavItem style={{display:"flex",flexDirection:'column'}}>
    <NavLink
    style={{marginRight:"10px",fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
      
      onClick={()=>handleToggle('3')}
    >
        <img  style={{height:'40px',width:'40px'}} src={isImageClicked3? incomeclicked :income} alt='income'/>
        {/* <div style={{marginTop:"10px",borderStyle:"solid",borderColor:"#F8A31F",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center"}}></div> */}
        <div style={{marginTop:"10px"}}><img style={{padding:'3px',borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div>
      <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginTop:"10px",color: isImageClicked3 ? '#D32027' : 'black'}}>Income & CIBIL</p>
    </NavLink>
  </NavItem>
  <div style={{ alignSelf: 'center', height: '3px', width: '80px', background: '#12B76A' }}></div>

  <NavItem style={{display:"flex",flexDirection:'column'}}>
    <NavLink
    style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
      
      onClick={()=>{
        handleToggle('4');
        handleMandateDetails(bankSelfieData?bankSelfieData.razorpayDetails:null)}}
    >
        <img style={{height:'40px',width:'40px'}} src={isImageClicked4 ? sanctionclicked :sanction} alt="sanction"/>
        {/* <div style={{marginTop:"20px"}}><img style={{borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div> */}
        <div style={{marginTop:"10px"}}><img style={{padding:'3px',borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div>
      <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginTop:"10px",color: isImageClicked4 ? '#D32027' : 'black'}}>Sanction</p>
    </NavLink>
  </NavItem>
  <div style={{ alignSelf: 'center', height: '3px', width: '80px', background: '#12B76A' }}></div>

  <NavItem style={{display:"flex",flexDirection:'column'}}>
    <NavLink
      style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
      
      onClick={()=>handleToggle('5')}
    >
        <img style={{height:'40px',width:'40px'}} src={isImageClicked5 ? disbursalclicked:disbursal} alt="disbursal"/>
        {/* <div style={{marginTop:"20px"}}><img style={{borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div> */}
        <div style={{marginTop:"10px"}}><img style={{padding:'3px',borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div>
     <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginTop:"10px",color: isImageClicked5 ? '#D32027' : 'black'}}>Disbursal</p> 
    </NavLink>
  </NavItem>
  <div style={{ alignSelf: 'center', height: '3px', width: '80px', background: '#12B76A' }}></div>

  <NavItem style={{display:"flex",flexDirection:'column'}}>
    <NavLink
    style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
      
      onClick={()=>handleToggle('6')}
    >
        <img style={{height:'40px',width:'40px'}} src={isImageClicked6 ? loandetailsclicked :loan} alt="loan"/>
        {/* <div style={{marginTop:"20px"}}><img style={{borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div> */}
        <div style={{marginTop:"10px"}}><img style={{padding:'3px',borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div>
      <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginTop:"10px",color: isImageClicked6 ? '#D32027' : 'black'}}>Loan Details</p>
    </NavLink>
  </NavItem>
  
      </Nav> :(
        <Nav className="navProfile" style={{listStyleType:"none",display:"flex",flexDirection:"row",justifyContent:"space-between",marginRight:'50px',marginBottom:'30px',marginTop:"30px"}}>
        {/* <p style={{padding:'0.6em',color:'black',borderRight:'2px solid black',borderRadius:'0.2em',background:'orange',fontWeight:'500'}}>Application Progress</p>  */}
      
      <NavItem style={{display:"flex",flexDirection:'column'}}>
        <NavLink 
        style={{fontWeight:'500',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',justifyContent:"space-between",alignItems:"center",textAlign:'center'}}
          
          onClick={()=>handleToggle('1')
          }
        >
            
        <img  style={{height:'40px',width:'40px'}} src={isImageClicked1 ? Basicdetailsclicked :basicdetails} alt="basicdetails"/>
        <div style={{marginTop:"10px"}}><img style={{padding:'3px',borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div>
        <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginTop:"10px",color: isImageClicked1 ? '#D32027' : 'black'}}>Basic Details</p> 
       
        </NavLink>
      </NavItem >
      <div style={{ alignSelf: 'center', height: '3px', width: '80px', background: '#12B76A'}}></div>
      <NavItem style={{display:"flex",flexDirection:'column'}}>
        <NavLink style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',justifyContent:'space-between',alignItems:"center"}}
        
        onClick={()=>handleToggle('2')
      }>
          
           <img style={{height:'40px',width:'40px'}} src={isImageClicked2 ? kycclicked: kyc} alt="kyc"/>
           <div style={{marginTop:"10px"}}><img style={{padding:'3px',borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div>
    
          <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginTop:"10px",color: isImageClicked2 ? '#D32027' : 'black'}}>KYC</p>
        </NavLink>
      </NavItem>
      <div style={{ alignSelf: 'center', height: '3px', width: '80px', background: '#12B76A' }}></div>
    
      <NavItem style={{display:"flex",flexDirection:'column'}}>
        <NavLink
        style={{marginRight:"10px",fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
          
          onClick={()=>handleToggle('3')}
        >
            <img  style={{height:'40px',width:'40px'}} src={isImageClicked3? incomeclicked :income} alt='income'/>
            <div style={{marginTop:"10px",borderStyle:"solid",borderColor:"#F8A31F",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center"}}></div>
            {/* <div style={{marginTop:"10px"}}><img style={{borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div> */}
          <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginTop:"10px",color: isImageClicked3 ? '#D32027' : 'black'}}>Income & CIBIL</p>
        </NavLink>
      </NavItem>
      <div style={{ borderBottom: "3px dashed #F8A31F",borderSpacing:"40px",alignSelf: 'center', height: '3px', width: '80px',marginRight:'10px'}}></div>
    
      <NavItem style={{display:"flex",flexDirection:'column'}}>
        <NavLink
        style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
          
          onClick={()=>{
            handleToggle('4');
            handleMandateDetails(bankSelfieData?bankSelfieData.razorpayDetails:null)}}
        >
            <img style={{height:'40px',width:'40px'}} src={isImageClicked4 ? sanctionclicked :sanction} alt="sanction"/>
            {/* <div style={{marginTop:"20px"}}><img style={{borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div> */}
            <div style={{marginTop:"10px",borderStyle:"solid",borderColor:"#CFCFCF",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center"}}></div>
          <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginTop:"10px",color: isImageClicked4 ? '#D32027' : 'black'}}>Sanction</p>
        </NavLink>
      </NavItem>
      <div style={{ borderBottom: "3px solid #CFCFCF",borderSpacing:"40px",alignSelf: 'center', height: '3px', width: '80px'}}></div>
    
      <NavItem style={{display:"flex",flexDirection:'column'}}>
        <NavLink
          style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
          
          onClick={()=>handleToggle('5')}
        >
            <img style={{height:'40px',width:'40px'}} src={isImageClicked5 ? disbursalclicked:disbursal} alt="disbursal"/>
            {/* <div style={{marginTop:"20px"}}><img style={{borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div> */}
            <div style={{marginTop:"10px",borderStyle:"solid",borderColor:"#CFCFCF",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center"}}></div>
         <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginTop:"10px",color: isImageClicked5 ? '#D32027' : 'black'}}>Disbursal</p> 
        </NavLink>
      </NavItem>
      <div style={{ borderBottom: "3px solid #CFCFCF",borderSpacing:"40px",alignSelf: 'center', height: '3px', width: '80px' }}></div>
    
      <NavItem style={{display:"flex",flexDirection:'column'}}>
        <NavLink
        style={{fontWeight:'500',fontSize:'15px',color:'black',cursor:'pointer',lineHeight:"20px",display:'flex',flexDirection:'column',alignItems:"center",textAlign:'center'}}
          
          onClick={()=>handleToggle('6')}
        >
            <img style={{height:'40px',width:'40px'}} src={isImageClicked6 ? loandetailsclicked :loan} alt="loan"/>
            {/* <div style={{marginTop:"20px"}}><img style={{borderStyle:"solid",borderColor:"#12B76A",borderRadius:"50%",height:"24px",width:"24px",alignSelf:"center",backgroundColor:'#12B76A'}} src={tickicon}/></div> */}
            <div style={{marginTop:"10px",borderStyle:"solid",borderColor:"#CFCFCF",borderRadius:"50%",height:"22px",width:"22px",alignSelf:"center"}}></div>
          <p style={{fontFamily:'Inter-Medium',fontSize:'14px',marginTop:"10px",color: isImageClicked6 ? '#D32027' : 'black'}}>Loan Details</p>
        </NavLink>
      </NavItem>
      
          </Nav>
      )}
     

      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",gap:'30px'}}>
        {showCards1 && (
      <>
      <Card style={{ border: 'solid',borderRadius:"10px",borderWidth: '1px', borderColor: '#DBDBDB', width: '100%', height: 'auto',display:"flex",flexDirection:"column" }}>
        <div style={{marginTop:'10px'}} >
          <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center',height:'40px',justifyContent:'space-between' }}>
            <div style={{display:'flex',alignItems:'center'}}>
            <img style={{height:"33px",width:'33px'}} src={applicant} alt="applicant" />
            <p style={{marginLeft:"20px",fontFamily:"Inter-Medium",color:'#232323',marginTop:'15px',fontWeight:'500'}}>Applicant</p>
            </div>
            
            <div style={{display:'flex',alignItems:'center',marginRight:'10px'}}>
            {applicationDetails && applicationDetails.profile ? (
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
             {popUp && (
        <>
         <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
        <Modal style={{
      width:"500px",
      
      top:"30%",borderRadius:"10px"}} isOpen={popUp}>
        
        
       
          <Form>
            <FormGroup>
              <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'20px',paddingLeft:'20px'}}>
              <p style={{display:'flex',alignItems:'center'}}><span style={{color:'#D22129',fontFamily:'Inter-Medium',fontSize:'20px',fontWeight:'500'}}>Mobile :</span><span style={{fontFamily:'Inter-Medium',fontSize:'20px',fontWeight:'500',color:'black',marginLeft:'20px'}}>{applicationDetails.profile.userDetails.mobile}</span> </p>
              <p style={{marginRight:'20px',cursor:"pointer",marginBottom:'30px'}}><img onClick={() => setpopUp(!popUp)} src={close} alt="close"/></p>
              </div>
            </FormGroup>
          </Form>
       
       
        
      </Modal>
        </>
      )}
        <img src={truecaller} alt='mobile' style={{cursor:'pointer'}} onClick={()=>handleMobileDetails()}/>
     
        
        <a href={`https://wa.me/+91${applicationDetails?.profile?.userDetails?.mobile}`} target="_blank" rel="noreferrer">
          <img src={whatsapp} style={{ paddingLeft: '15px' }} />
        </a>
        <a href={`mailto:${applicationDetails?.profile?.userDetails?.email}`} target="_blank" rel="noreferrer">
          <img src={gmail} style={{ paddingLeft: '15px' }} />
        </a>
      </div>
    ) : (
      ""
    )}
                            </div>
          </div>
          <div style={{ borderTop: '0.75px solid #DBDBDB', margin: '10px 0' }}></div>
        </div>
        <div style={{ paddingLeft: '20px' }}>
          <CardBody>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
  <div style={{ width: 'calc(50% - 1em)', margin: '0.5em' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Name</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '30px' : '30px',
          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>
        {applicationDetails && applicationDetails?.profile?.userDetails?.name ? applicationDetails?.profile?.userDetails?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : '-------'}
      </span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 1em)', margin: '0.5em' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Student Name</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '30px' : '30px',
          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>
        {applicationDetails && applicationDetails?.profile?.userDetails?.studentName ? applicationDetails?.profile?.userDetails?.studentName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : '-------'}
      </span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 1em)', margin: '0.5em' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Employment</strong>
      <span style={{ color: '#232323', fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>{applicationDetails && applicationDetails?.profile?.userDetails?.typeOfBusiness ? applicationDetails?.profile?.userDetails?.typeOfBusiness : '-------'}</span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 1em)', margin: '0.5em' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Salary</strong>
      <span style={{ color: '#232323', fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>{applicationDetails ? applicationDetails?.profile?.userDetails?.salary : null}</span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 1em)', margin: '0.5em' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Date Of Birth</strong>
      <span style={{ color: '#232323', fontSize: '15px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>{applicationDetails && applicationDetails?.profile?.userDetails?.dob ? applicationDetails?.profile?.userDetails?.dob : '-------'}</span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 1em)', margin: '0.5em' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Company Name</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '30px' : '30px',
          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>{applicationDetails && applicationDetails?.profile?.userDetails?.company ? applicationDetails?.profile?.userDetails?.company : '-------'}
      </span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 1em)', margin: '0.5em' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Income per month</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '30px' : '30px',
          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>{applicationDetails && applicationDetails?.profile?.userDetails?.incomePerMonth ? applicationDetails?.profile?.userDetails?.incomePerMonth : '-------'}
      </span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 1em)', margin: '0.5em' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '10px', fontSize: '15px' }}>Permanent Address</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '30px' : '30px',
          wordBreak: 'break-word'
        }}>{applicationDetails && applicationDetails?.profile?.userDetails?.permanentAddress ? applicationDetails?.profile?.userDetails?.permanentAddress : '-------'}
      </span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 1em)', margin: '0.5em' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '10px', fontSize: '15px' }}>Current Address</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '30px' : '30px',
          wordBreak: 'break-word'
        }}>
        {applicationDetails && applicationDetails?.profile?.userDetails?.currentAddress ? applicationDetails?.profile?.userDetails?.currentAddress : '-------'}
      </span>
    </p>
  </div>
</div>
          </CardBody>
        </div>
      </Card>
      { applicationDetails?applicationDetails.coapplicantProfile?
            <Card style={{ border: 'solid',borderRadius:"10px",borderWidth: '1px', borderColor: '#DBDBDB', width: '100%', height: 'auto',display:"flex",flexDirection:"column" }}>
              <div style={{marginTop:'10px'}} >
                    <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center',height:'40px',justifyContent:'space-between' }}>
                      <div style={{display:'flex',alignItems:'center'}}>
                      <img style={{height:"33px",width:'33px'}} src={coapplicant} alt="coapplicant" />
                    <p style={{marginLeft:"20px",fontFamily:"Inter-Medium",color:'#232323',marginTop:'15px',fontWeight:'500'}}>Co-Applicant</p>
                      </div>
                      <div style={{display:'flex',alignItems:'center'}}>
                      {applicationDetails && applicationDetails.coapplicantProfile ? (
                          <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                            {popUp1 && (
        <>
         <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
        <Modal style={{
      width:"500px",
       
      top:"30%",borderRadius:"10px"}} isOpen={popUp1}>
        
        
       
          <Form>
            <FormGroup>
              <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:'20px',paddingLeft:'20px'}}>
              <p style={{display:'flex',alignItems:'center'}}><span style={{color:'#D22129',fontFamily:'Inter-Medium',fontSize:'20px',fontWeight:'500'}}>Mobile :</span><span style={{fontFamily:'Inter-Medium',fontSize:'20px',fontWeight:'500',color:'black',marginLeft:'20px'}}>{applicationDetails.coapplicantProfile.userDetails.mobile}</span> </p>
              <p style={{marginRight:'20px',cursor:"pointer",marginBottom:'30px'}}><img onClick={() => setpopUp1(!popUp1)} src={close} alt="close"/></p>
              </div>
            </FormGroup>
          </Form>
       
       
        
      </Modal>
        </>
      )}
        <img src={truecaller} alt='mobile' style={{cursor:'pointer'}} onClick={()=>handleCoappMobileDetails()}/>
                         
                            <a href={`https://wa.me/+91${applicationDetails?.coapplicantProfile?.userDetails?.mobile}`} target="_blank" rel="noreferrer">
                              <img src={whatsapp} style={{ paddingLeft: '15px' }} />
                            </a>
                            <a href={`mailto:${applicationDetails?.coapplicantProfile?.userDetails?.email}`} target="_blank" rel="noreferrer">
                              <img src={gmail} style={{ paddingLeft: '15px' }} />
                            </a>
                          </div>
                        ) : (
                            ""
                              )}
                            </div>
                    
                    
                    </div>
                    <div style={{ borderTop: '0.75px solid #DBDBDB', margin: '10px 0' }}></div>
              </div>
              <div style={{ paddingLeft: '20px' }}>
              <CardBody>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Name</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '30px' : '30px',
          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>
        {applicationDetails && applicationDetails?.coapplicantProfile?.userDetails?.name ? applicationDetails?.coapplicantProfile?.userDetails?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : '-------'}
      </span>
    </p>
  </div>

  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Student Name</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '30px' : '30px',
          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>
        {applicationDetails && applicationDetails?.coapplicantProfile?.userDetails?.studentName ? applicationDetails?.coapplicantProfile?.userDetails?.studentName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : '-------'}
      </span>
    </p>
  </div>

  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Employment</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {applicationDetails && applicationDetails.coapplicantProfile.userDetails.typeOfBusiness ? applicationDetails.coapplicantProfile.userDetails.typeOfBusiness : '-------'}
      </span>
    </p>
  </div>

  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Salary</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {applicationDetails && applicationDetails.coapplicantProfile.userDetails.salary ? applicationDetails.coapplicantProfile.userDetails.salary : '-------'}
      </span>
    </p>
  </div>

  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Date Of Birth</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {applicationDetails && applicationDetails.coapplicantProfile.userDetails.dob ? applicationDetails.coapplicantProfile.userDetails.dob : '-------'}
      </span>
    </p>
  </div>

  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Company Name</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '30px' : '30px',
          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>
        {applicationDetails && applicationDetails.coapplicantProfile.userDetails.company ? applicationDetails.coapplicantProfile.userDetails.company : '-------'}
      </span>
    </p>
  </div>

  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '10px', fontSize: '15px' }}>Permanent Address</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '30px' : '30px',          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>
        {applicationDetails && applicationDetails.profile.userDetails.permanentAddress ? applicationDetails.profile.userDetails.permanentAddress : '-------'}
      </span>
    </p>
  </div>

  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '10px', fontSize: '15px' }}>Current Address</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '30px' : '30px',
          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>
        {applicationDetails && applicationDetails.coapplicantProfile.userDetails.currentAddress ? applicationDetails.coapplicantProfile.userDetails.currentAddress : '-------'}
      </span>
    </p>
  </div>
</div>


                </CardBody>
                </div>
            </Card>:null:null}
            </>
            )}
      </div>
      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",gap:'30px'}}>
        {showCards2 && (
      <>
      <Card style={{ border: 'solid',borderRadius:"10px",borderWidth: '1px', borderColor: '#DBDBDB', width: '100%', height: 'auto',display:"flex",flexDirection:"column" }}>
      <div style={{marginTop:"10px"}} >
          <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center',height:'40px',justifyContent:'space-between' }}>
            <div style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
            <img style={{height:"33px",width:'33px'}} src={applicant} alt="applicant" />
            <p style={{marginLeft:"20px",fontFamily:"Inter-Medium",color:'#232323',marginTop:'13px',fontWeight:'500'}}>Applicant</p>
            </div>
            <div  style={{marginRight:'20px',display:'flex',alignItems:'center'}}>
            <img  style={{height:'20px',width:'20px',marginRight:'10px',cursor:"pointer"}} src={resend}  alt="resend"/>
          <span><button style={{display:'flex',fontWeight:"800",alignItems:"center",flexDirection:"row",justifyContent:"center",backgroundColor:"white",borderRadius:'30px',height:'35px',width:'80px',borderColor:'#D32027',color:'#D32027',borderStyle:'solid',borderWidth:'1px'}}>Reject</button></span>
              {/* <span>
              {bankSelfieData?bankSelfieData.bankDetails?``:null:null}{bankSelfieData?bankSelfieData.bankDetails?(Math.round((bankSelfieData.bankDetails.matchPercentage + Number.EPSILON) * 100) / 100)*100<65?<span><img  style={{height:'20px',width:'20px',marginRight:'10px',cursor:"pointer"}} src={resend} onClick={()=>reInitiateNach()} alt="resend"/></span>:null:null:null}
              </span> */}
            
            {/* <span>{bankSelfieData?bankSelfieData.selfieDetails?``:null:null}{bankSelfieData?bankSelfieData.selfieDetails?(Math.round((bankSelfieData.selfieDetails.faceLivenessScore + Number.EPSILON) * 10000) / 100)*100<65?<span><button onClick={()=>reInitiateSelfie()} style={{display:'flex',fontWeight:"800",alignItems:"center",flexDirection:"row",justifyContent:"center",backgroundColor:"white",borderRadius:'30px',height:'35px',width:'80px',borderColor:'#D32027',color:'#D32027',borderStyle:'solid',borderWidth:'1px'}}>Reject</button></span>:null:null:null}</span> */}
            {/* <span>{bankSelfieData?bankSelfieData.selfieDetails?(Math.round((bankSelfieData.selfieDetails.faceLivenessScore+Number.EPSILON)*10000)/100)<65?
                  <span><button style={{display:'flex',fontWeight:"800",alignItems:"center",flexDirection:"row",justifyContent:"center",backgroundColor:"white",borderRadius:'30px',height:'35px',width:'80px',borderColor:'#D32027',color:'#D32027',borderStyle:'solid',borderWidth:'1px'}}>Reject</button></span>:null:null:null}</span> */}
            </div>
            
          </div>
          <div style={{ borderTop: '0.75px solid #DBDBDB', margin: '10px 0' }}></div>
        </div>
        <div style={{ paddingLeft: '20px' }}>
          <CardBody>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Aadhar Number</strong>
      <span style={{ textDecoration: 'none', color: '#232323', fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {applicationDetails && applicationDetails.profile.userDetails.aadhaar ? applicationDetails.profile.userDetails.aadhaar : '-------'}
      </span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Bank Account</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {bankSelfieData?.bankDetails?.accountNumber || '-------'}
      </span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>PAN Number</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {applicationDetails && applicationDetails.profile.userDetails.pan ? applicationDetails.profile.userDetails.pan : '-------'}
      </span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>IFSC Code</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {bankSelfieData?.bankDetails?.ifsc || '-------'}
      </span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Beneficiary Name</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '15px' : '30px',
          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>
        {bankSelfieData?.bankDetails?.beneficiaryName || '-------'}
      </span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Verified Name</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '15px' : '30px',
          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>
        {applicationDetails && applicationDetails.profile.userDetails.name ? applicationDetails.profile.userDetails.name : '-------'}
      </span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '10px', fontSize: '15px' }}>C-KYC</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {applicationDetails ? applicationDetails.profile.ckycAvailable ? "Available" : "NIL" : null}
      </span>
    </p>
  </div>
  <div style={{ width: 'calc(50% - 10px)', marginBottom: '20px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '10px', fontSize: '15px' }}>DigiLocker</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {bankSelfieData ? bankSelfieData.digilockerDetailsAvailable ? "Available" : "NIL" : null}
      </span>
    </p>
  </div>
</div>   
          </CardBody>
        </div>
        <div style={{ borderTop: '0.75px dashed #DBDBDB', margin: '10px 0' }}></div>
        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",marginRight:"10px"}}>
                  <div>
                  <p style={{display:'flex',flexDirection:'column'}}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',marginRight:"30px",fontWeight:'400',lineHeight:"10px",fontSize:'13px'}} >Face Match</strong>
                    
                  <span> {bankSelfieData?.bankDetails?.facematchScore || '-------'}</span> 
                    </p>
                  </div>
                  <div>
                  <p style={{display:'flex',flexDirection:'column'}}><strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'13px'}}>Face Liveness</strong>
                  <span>
                    {bankSelfieData && bankSelfieData.selfieDetails && bankSelfieData.selfieDetails.faceLivenessScore
                      ? `${(Math.round((bankSelfieData.selfieDetails.faceLivenessScore + Number.EPSILON) * 10000) / 100)} %`
                      : '-------'}
                    {bankSelfieData && bankSelfieData.selfieDetails && (Math.round((bankSelfieData.selfieDetails.faceLivenessScore + Number.EPSILON) * 10000) / 100) < 65 ? (
                      <span style={{ marginLeft: '1em' }}>
                        
                        <Button onClick={() => reInitiateSelfie()} color="danger">Reject</Button>
                      </span>
                    ) : null}
                  </span>

                  {/* <span>
                  {bankSelfieData && bankSelfieData.selfieDetails
                    ? `${(Math.round((bankSelfieData.selfieDetails.faceLivenessScore + Number.EPSILON) * 10000) / 100)} %`
                    : '-------'}</span> */}
                  </p>
                  </div>
                  <div>
                  <p style={{display:'flex',flexDirection:'column'}}>
                    <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'13px'}}>Name Match</strong>
                    <span>
                    {bankSelfieData && bankSelfieData.bankDetails && bankSelfieData.bankDetails.matchPercentage
                      ? `${(Math.round((bankSelfieData.bankDetails.matchPercentage + Number.EPSILON) * 100) / 100) * 100} %`
                      : '-------'}
                    {bankSelfieData && bankSelfieData.bankDetails && (Math.round((bankSelfieData.bankDetails.matchPercentage + Number.EPSILON) * 100) / 100) * 100 < 65 ? (
                      <span>
                        <Button onClick={() => reInitiateNach()} color="danger">Reject</Button>
                      </span>
                    ) : null}
                  </span>

                  {/* <span> 
                   {bankSelfieData && bankSelfieData.selfieDetails
                    ? `${(Math.round((bankSelfieData.selfieDetails.faceLivenessScore + Number.EPSILON) * 10000) / 100)} %`
                    : '-------'}</span> */}
                    </p>
                  </div>
              </div>

      </Card>
      { applicationDetails?applicationDetails.coapplicantProfile?
            <Card style={{ border: 'solid',borderRadius:"10px",borderWidth: '1px', borderColor: '#DBDBDB', width: '100%', height: 'auto',display:"flex",flexDirection:"column" }}>
            <div style={{marginTop:"10px"}} >
                    <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center',justifyContent:"space-between",height:'40px' }}>
                      <div style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
                      <img style={{height:"33px",width:'33px'}} src={coapplicant} alt="coapplicant" />
                      <p style={{marginLeft:"20px",fontFamily:"Inter-Medium",color:'#232323',marginTop:'13px',fononcltWeight:'500'}}>Co-Applicant</p>
                      </div>
                      <div style={{marginRight:'20px',display:'flex',alignItems:'center'}}>
                      <img  style={{height:'20px',width:'20px',marginRight:'10px',cursor:"pointer"}} src={resend}  alt="resend"/>
          <span><button style={{display:'flex',fontWeight:"800",alignItems:"center",flexDirection:"row",justifyContent:"center",backgroundColor:"white",borderRadius:'30px',height:'35px',width:'80px',borderColor:'#D32027',color:'#D32027',borderStyle:'solid',borderWidth:'1px'}}>Reject</button></span>
                      </div>
                    </div>
                    <div style={{ borderTop: '0.75px solid #DBDBDB', margin: '10px 0' }}></div>
              </div>
            <div style={{ paddingLeft: '20px' }}>
              <CardBody>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
  <div style={{ flex: '0 0 calc(50% - 20px)', minWidth: '220px', height: '60px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Aadhar Number</strong>
      <span style={{ textDecoration: 'none', color: '#232323', fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {applicationDetails && applicationDetails.coapplicantProfile.userDetails.aadhaar ? applicationDetails.coapplicantProfile.userDetails.aadhaar : '-------'}
      </span>
    </p>
  </div>
  <div style={{ flex: '0 0 calc(50% - 20px)', minWidth: '220px', height: '60px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Bank Account</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>{bankSelfieData?.bankDetails?.accountNumber || '-------'}</span>
    </p>
  </div>
  <div style={{ flex: '0 0 calc(50% - 20px)', minWidth: '220px', height: '60px', marginTop: '10px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>PAN Number</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {applicationDetails && applicationDetails.coapplicantProfile.userDetails.pan ? applicationDetails.coapplicantProfile.userDetails.pan : '-------'}
      </span>
    </p>
  </div>
  <div style={{ flex: '0 0 calc(50% - 20px)', minWidth: '220px', height: '60px', marginTop: '10px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>IFSC Code</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {bankSelfieData?.bankDetails?.ifsc || '-------'}
      </span>
    </p>
  </div>
  <div style={{ flex: '0 0 calc(50% - 20px)', minWidth: '220px', height: '60px', marginTop: '10px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Beneficiary Name</strong>
      <span
        onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '15px' : '30px',
          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>
        {bankSelfieData?.bankDetails?.beneficiaryName || '-------'}
      </span>
    </p>
  </div>
  <div style={{ flex: '0 0 calc(50% - 20px)', minWidth: '220px', height: '60px', marginTop: '10px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '20px', fontSize: '15px' }}>Verified Name</strong>
      <span onClick={() => setIsExpanded(!isExpanded)}
        title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
        style={{
          whiteSpace: isExpanded ? 'normal' : 'nowrap',
          textOverflow: isExpanded ? 'unset' : 'ellipsis',
          overflow: isExpanded ? 'unset' : 'hidden',
          cursor: isExpanded ? 'auto' : 'pointer',
          textDecoration: 'none',
          color: '#232323',
          fontSize: '13px',
          fontFamily: 'Inter-Medium',
          lineHeight: isExpanded ? '15px' : '30px',
          display: 'block',
          width: '100%',
          wordBreak: 'break-word'
        }}>
        {applicationDetails && applicationDetails.coapplicantProfile.userDetails.name ? applicationDetails.coapplicantProfile.userDetails.name : '-------'}
      </span>
    </p>
  </div>
  <div style={{ flex: '0 0 calc(50% - 20px)', minWidth: '220px', marginTop: '10px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '10px', fontSize: '15px' }}>C-KYC</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {applicationDetails.coapplicantProfile.ckycAvailable ? "Available" : "NIL"}
      </span>
    </p>
  </div>
  <div style={{ flex: '0 0 calc(50% - 20px)', minWidth: '220px', marginTop: '10px' }}>
    <p style={{ display: 'flex', flexDirection: 'column' }}>
      <strong style={{ color: 'gray', fontFamily: 'Inter-Medium', fontWeight: '400', lineHeight: '10px', fontSize: '15px' }}>DigiLocker</strong>
      <span style={{ color: "#232323", fontSize: '13px', fontFamily: 'Inter-Medium', lineHeight: '30px' }}>
        {bankSelfieData ? (bankSelfieData.digilockerDetailsAvailable ? "Available" : "NIL") : null}
      </span>
    </p>
  </div>
</div>
              </CardBody>
            </div>
            <div style={{ borderTop: '0.75px dashed #DBDBDB', margin: '10px 0' }}></div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",marginRight:"10px"}}>
              <div>
                <p style={{display:'flex',flexDirection:'column'}}>
                <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',marginRight:"30px",lineHeight:"10px",fontSize:'13px'}} >Face Match</strong>
                <span> {bankSelfieData?.bankDetails?.facematchScore || '-------'}</span>
                </p>
              </div>
              <div>
              <p style={{display:'flex',flexDirection:'column'}}>
                <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'13px'}}>Face Liveness</strong>
                <span>
                  {bankSelfieData && bankSelfieData.selfieDetails && bankSelfieData.selfieDetails.faceLivenessScore
                    ? `${(Math.round((bankSelfieData.selfieDetails.faceLivenessScore + Number.EPSILON) * 10000) / 100)} %`
                    : '-------'}
                  {bankSelfieData && bankSelfieData.selfieDetails && (Math.round((bankSelfieData.selfieDetails.faceLivenessScore + Number.EPSILON) * 10000) / 100) < 65 ? (
                    <span style={{ marginLeft: '1em' }}>
                      <Button color="danger">Reject</Button>
                    </span>
                  ) : null}
                </span>

              {/* <span>
                 {bankSelfieData?bankSelfieData.selfieDetails?`${(Math.round((bankSelfieData.selfieDetails.faceLivenessScore+Number.EPSILON)*10000)/100)} %`:null:null}
              </span> */}
              </p>

              </div>
              <div>
              <p style={{display:'flex',flexDirection:'column'}}>
              <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'13px'}}>Name Match</strong>
              <span>
                {bankSelfieData && bankSelfieData.bankDetails && bankSelfieData.bankDetails.matchPercentage
                  ? `${(Math.round((bankSelfieData.bankDetails.matchPercentage + Number.EPSILON) * 100) / 100) * 100} %`
                  : '-------'}
                {bankSelfieData && bankSelfieData.bankDetails && (Math.round((bankSelfieData.bankDetails.matchPercentage + Number.EPSILON) * 100) / 100) * 100 < 65 ? (
                  <span>
                    <Button color="danger">Reject</Button>
                  </span>
                ) : null}
              </span>

              {/* <span> 
                 {bankSelfieData?bankSelfieData.bankDetails?`${(Math.round((bankSelfieData.bankDetails.matchPercentage + Number.EPSILON) * 100) / 100)*100} %`:null:null}
              </span> */}
              </p>
              </div>   
                      
                      
              </div>
    
          </Card>:null:null}
            </>
            )}
      </div>    
      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",gap:'30px'}}>
        {showCards3 && (
      <>
      <Card style={{ border: 'solid',borderRadius:"10px",borderWidth: '1px', borderColor: '#DBDBDB', width: '100%', height: 'auto',display:"flex",flexDirection:"column" }}>
      <div style={{marginTop:"10px"}} >
          <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center',height:'40px' }}>
            <img style={{height:"33px",width:'33px'}} src={applicant} alt="applicant" />
            <p style={{marginLeft:"20px",fontFamily:"Inter-Medium",color:'#232323',marginTop:'13px'}}>Applicant</p>
          </div>
          <div style={{ borderTop: '0.75px solid #DBDBDB', margin: '10px 0' }}></div>
        </div>
        <div style={{ paddingLeft: '20px' }}>
          <CardBody>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '20px' }}>
            <div style={{width:'220px',height:'60px'}}>
                <p  style={{display:'flex',flexDirection:"column"}}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"20px",fontSize:'15px'}}>CIBIL </strong>
                  <span  style={{  textDecoration: 'none', color: '#232323',fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px' }}>
                  {applicationDetails && applicationDetails.profile && applicationDetails.profile.cibilDetails && applicationDetails.profile.cibilDetails.score ? applicationDetails.profile.cibilDetails.score: '-------'}
                  </span>
                </p>
              </div>
              <div style={{width:'220px',height:'60px'}}> 
                <p style={{display:'flex',flexDirection:"column"}}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"20px",fontSize:'15px'}}>Average Daily Income</strong>
                  
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{bankSelfieData?.accountDetails?.averageBalance || '-------'}</span> 
                </p>
              </div>
              <div style={{ width:'220px',height:"60px" ,marginTop:'10px'}}>
                <p style={{display:'flex',flexDirection:"column" }}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"20px",fontSize:'15px'}}>Monthly Income</strong>
                  
                  <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{bankSelfieData?.accountDetails?.income || '-------'}</span>
                </p>
               </div>
               <div style={{ width:'220px',height:"60px" ,marginTop:'10px'}}> 
                <p style={{display:'flex',flexDirection:"column" }}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"20px",fontSize:'15px'}}>Current Balance</strong>
                 
                  <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>
                  {applicationDetails && applicationDetails.profile && applicationDetails.profile.cibilDetails && applicationDetails.profile.cibilDetails.currentBalance ? applicationDetails.profile.cibilDetails.currentBalance: '-------'}
                     </span>
                </p>
              </div>
              <div style={{ width:'220px',height:"60px" ,marginTop:'10px'}}>
                <p style={{display:'flex',flexDirection:"column" }}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"20px",fontSize:'15px'}}>Current EMIs</strong>
                 
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>
                 {applicationDetails && applicationDetails.profile && applicationDetails.profile.cibilDetails && applicationDetails.profile.cibilDetails.currentEmis ? applicationDetails.profile.cibilDetails.currentEmis: '-------'}
                   </span>
                </p>
              </div>
              <div style={{ width:'220px',height:"60px" ,marginTop:'10px'}}>
                <p style={{display:'flex',flexDirection:"column" }}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"20px",fontSize:'15px'}}>Overdue </strong>
                  
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>
                 {applicationDetails && applicationDetails.profile && applicationDetails.profile.cibilDetails && applicationDetails.profile.cibilDetails.overdues ? applicationDetails.profile.cibilDetails.overdues: '-------'}
                   </span>
                </p>
              </div>
              <div style={{ width:'220px' ,marginTop:'10px'}}>
                <p style={{display:'flex',flexDirection:"column"}}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'15px'}}>Write Offs</strong>
                  
                  <span  style={{  color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>
                  {applicationDetails && applicationDetails.profile && applicationDetails.profile.cibilDetails && applicationDetails.profile.cibilDetails.writeOffs ? applicationDetails.profile.cibilDetails.writeOffs: '-------'}
                    </span>
                </p>
               </div>
               <div style={{ width:'220px' ,marginTop:'10px'}}> 
                <p style={{display:'flex',flexDirection:"column"  }}
                >
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'15px'}}>FOIR(%)</strong>
                  
                  <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>
                    {bankSelfieData?.accountDetails?.foir || '-------'}</span>
                </p>
              </div>
              <div style={{ width:'220px' ,marginTop:'10px'}}>
              <p style={{display:'flex',flexDirection:"column"  }}
                >
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'15px'}}>Delinquencies</strong>
                  
                  <span onClick={() => setIsExpanded(!isExpanded)}
                      title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
                      style={{
                        whiteSpace: isExpanded ? 'normal' : 'nowrap',
                        textOverflow: isExpanded ? 'unset' : 'ellipsis',
                        overflow: isExpanded ? 'unset' : 'hidden',
                        cursor: isExpanded ? 'auto' : 'pointer',
                        textDecoration: 'none',
                        color: '#232323',
                        fontSize: '13px',
                        fontFamily: 'Inter-Medium',
                        lineHeight: isExpanded ? '15px' : '30px',
                        display: 'block',
                        width: '100%',
                        wordBreak: 'break-word'
                      }}>
                  {applicationDetails && applicationDetails.profile && applicationDetails.profile.cibilDetails && applicationDetails.profile.cibilDetails.deliquencies ? applicationDetails.profile.cibilDetails.deliquencies: '-------'}
                        </span>
                </p>
              </div>
            </div>
            
          </CardBody>
        </div>
        

      </Card>
      { applicationDetails?applicationDetails.coapplicantProfile?
            <Card style={{ border: 'solid',borderRadius:"10px",borderWidth: '1px', borderColor: '#DBDBDB', width: '100%', height: 'auto',display:"flex",flexDirection:"column" }}>
            <div style={{marginTop:"10px"}} >
                    <div style={{ marginLeft: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center',height:'40px' }}>
                    <img style={{height:"33px",width:'33px'}} src={coapplicant} alt="coapplicant" />
                    <p style={{marginLeft:"20px",fontFamily:"Inter-Medium",color:'#232323',marginTop:'13px'}}>Co-Applicant</p>
                    </div>
                    <div style={{ borderTop: '0.75px solid #DBDBDB', margin: '10px 0' }}></div>
              </div>
            <div style={{ paddingLeft: '20px' }}>
              <CardBody>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '0px' }}>
                <div style={{width:'220px',height:'60px' }} >
                <p  style={{display:'flex',flexDirection:"column"}}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"20px",fontSize:'15px'}}>CIBIL</strong>
                  
                  <span span onClick={(event) => {
                  event.target.style.whiteSpace = 'normal';
                  event.target.style.overflow = 'visible';
                  event.target.style.cursor = 'text';
                }} style={{ width: '10em',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',cursor: 'pointer', textDecoration: 'none', color: '#232323',fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px' }}>
                  {applicationDetails && applicationDetails.coapplicantProfile && applicationDetails.coapplicantProfile.cibilDetails && applicationDetails.coapplicantProfile.cibilDetails.score ? applicationDetails.coapplicantProfile.cibilDetails.score: '-------'}
                  </span>
                </p>
                </div>
                <div style={{width:'220px',height:'60px'}}>
                <p style={{display:'flex',flexDirection:"column"}}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'15px'}}>Average Daily Income</strong>
                 
                  <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{bankSelfieData?.accountDetails?.averageBalance || '-------'}</span>
                </p>
                </div>
                <div  style={{ width:'220px',height:"60px" ,marginTop:'10px' }}>
                <p style={{display:'flex',flexDirection:"column"}}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'15px'}}>Monthly Income </strong>
                 
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{bankSelfieData?.accountDetails?.income || '-------'}</span> 
                </p>
                </div>
                <div style={{ width:'220px',height:"60px" ,marginTop:'10px' }}>
                <p style={{display:'flex',flexDirection:"column"}}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'15px'}}> Current Balance</strong>
                  
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>
                 {applicationDetails && applicationDetails.coapplicantProfile && applicationDetails.coapplicantProfile.cibilDetails && applicationDetails.coapplicantProfile.cibilDetails.currentBalance ? applicationDetails.coapplicantProfile.cibilDetails.currentBalance: '-------'}
                   </span>
                </p>
                </div>
                <div style={{ width:'220px',height:"60px" ,marginTop:'10px' }}>
                <p style={{display:'flex',flexDirection:"column"}}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'15px'}}>Current EMIs</strong>
                  
                  <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>
                  {applicationDetails && applicationDetails.coapplicantProfile && applicationDetails.coapplicantProfile.cibilDetails && applicationDetails.coapplicantProfile.cibilDetails.currentEmis ? applicationDetails.coapplicantProfile.cibilDetails.currentEmis: '-------'}
                    </span>
                </p>
                </div>
                <div style={{ width:'220px',height:"60px" ,marginTop:'10px' }}>
                <p style={{display:'flex',flexDirection:"column"}}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'15px'}}>Overdue </strong>
                 
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>
                 {applicationDetails && applicationDetails.coapplicantProfile && applicationDetails.coapplicantProfile.cibilDetails && applicationDetails.coapplicantProfile.cibilDetails.overdues ? applicationDetails.coapplicantProfile.cibilDetails.overdues: '-------'}
                   </span>
                </p>
                </div>
              <div  style={{ width:'220px',height:"60px" ,marginTop:'10px' }}>
                <p style={{display:'flex',flexDirection:"column"}}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'15px'}}>Write Offs</strong>
                  
                <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>
                {applicationDetails && applicationDetails.coapplicantProfile && applicationDetails.coapplicantProfile.cibilDetails && applicationDetails.coapplicantProfile.cibilDetails.writeOffs ? applicationDetails.coapplicantProfile.cibilDetails.writeOffs: '-------'}
                  </span> 
                </p>
                </div>
                <div style={{ width:'220px',height:"60px" ,marginTop:'10px' }}>
                <p style={{display:'flex',flexDirection:"column"}}>
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'15px'}}>FOIR(%)</strong>
                 
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>
                 {bankSelfieData?.accountDetails?.foir || '-------'}                   </span>
                </p>
              </div>
              <div style={{ width:'220px' ,marginTop:'10px'}}>
              <p style={{display:'flex',flexDirection:"column"  }}
                >
                  <strong style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'400',lineHeight:"10px",fontSize:'15px'}}>Delinquencies</strong>
                  
                  <span onClick={() => setIsExpanded(!isExpanded)}
                      title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
                      style={{
                        whiteSpace: isExpanded ? 'normal' : 'nowrap',
                        textOverflow: isExpanded ? 'unset' : 'ellipsis',
                        overflow: isExpanded ? 'unset' : 'hidden',
                        cursor: isExpanded ? 'auto' : 'pointer',
                        textDecoration: 'none',
                        color: '#232323',
                        fontSize: '13px',
                        fontFamily: 'Inter-Medium',
                        lineHeight: isExpanded ? '15px' : '30px',
                        display: 'block',
                        width: '100%',
                        wordBreak: 'break-word'
                      }}>
                  {applicationDetails && applicationDetails.coapplicantProfile && applicationDetails.coapplicantProfile.cibilDetails && applicationDetails.coapplicantProfile.cibilDetails.deliquencies ? applicationDetails.coapplicantProfile.cibilDetails.deliquencies: '-------'}
                        </span>
                </p>
              </div>
                </div>
                
              </CardBody>
            </div>
            
    
          </Card>:null:null}
            </>
            )}
      </div> 
      <div >
      {showCards4 && (
        <div style={{ border: 'solid',borderRadius:"10px",borderWidth: '1px', borderColor: '#DBDBDB', height: 'auto',padding:'10px' }}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',height:'30px'}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <img style={{height:'28px',width:'33px'}} src={mandate} alt="mandate"/>
          <p style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px',marginTop:'13px'}} >E-Mandate : </p>
          </div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <div style={{height:'35px',padding:"5px",display:'flex',flexDirection:'row',alignItems:'center', border: 'solid',borderRadius:"5px",borderWidth: '1px', borderColor: '#DBDBDB'}}>
            
           <p style={{marginTop:"15px"}}><img src={loanagreement} alt="loanagreement"/> <span>{bankSelfieData?bankSelfieData.sanctionLetter?<a style={{textDecoration:"none",color:'black',cursor:'pointer'}} target="_blank" rel="noreferrer" href={bankSelfieData.sanctionLetter}><span style={{fontFamily:'Inter-Medium',fontSize:'14px'}}>Loan Agreement</span></a>:<span style={{fontFamily:'Inter-Medium',fontSize:"14px"}}>Not yet Generated</span>:null}
</span></p> 
          </div>
          <div style={{height:'35px',padding:"5px",display:'flex',flexDirection:'row',alignItems:'center',marginLeft:'10px', border: 'solid',borderRadius:"5px",borderWidth: '1px', borderColor: '#DBDBDB'}}>
            <p style={{marginTop:"15px"}}><img src={sanctionletter} alt="sanctionletter"/><span>
          {bankSelfieData?bankSelfieData.agreementUrl?<a style={{textDecoration:"none",color:'black',cursor:'pointer'}} target="_blank" rel="noreferrer"  href={bankSelfieData.sanctionLetter}><span style={{fontFamily:'Inter-Medium',fontSize:'14px',marginLeft:'5px'}}>Sanction Letter</span></a>:<span style={{fontFamily:'Inter-Medium',fontSize:"14px"}}>Not yet Generated</span>:null}
              </span></p>
          
            {/* <img src={sanctionletter} alt="sanctionletter"/>
            <p style={{fontFamily:'Inter-Medium',cursor:'pointer',fontSize:'14px',marginLeft:'5px'}}>
              {bankSelfieData?bankSelfieData.agreementUrl?<a style={{textDecoration:"none",color:'black',fontFamily:'Inter-Medium',cursor:'pointer',fontSize:'14px'}} target="_blank" rel="noreferrer"  href={bankSelfieData.sanctionLetter}>Sanction Letter</a>:<span style={{fontFamily:'Inter-Medium',fontSize:"14px"}}>Not yet Generated</span>:null}</p> */}
          </div>
          </div>
        </div>
        <div style={{ borderTop: '0.75px solid #DBDBDB', margin: '10px 0' }}></div>
        
       
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div style={{width:'200px',height:'60px', marginBottom: '10px'}}> 
                <p style={{display:'flex',flexDirection:"column"}}>
                  <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Name</span>
                  
                 <span onClick={() => setIsExpanded(!isExpanded)}
                      title={applicationDetails?.coapplicantProfile?.userDetails?.name || ''}
                      style={{
                        whiteSpace: isExpanded ? 'normal' : 'nowrap',
                        textOverflow: isExpanded ? 'unset' : 'ellipsis',
                        overflow: isExpanded ? 'unset' : 'hidden',
                        cursor: isExpanded ? 'auto' : 'pointer',
                        textDecoration: 'none',
                        color: '#232323',
                        fontSize: '13px',
                        fontFamily: 'Inter-Medium',
                        lineHeight: isExpanded ? '15px' : '30px',
                        display: 'block',
                        width: '100%',
                        wordBreak: 'break-word'
                      }}>{mandateDetails?.name || '-------'}</span> 
                </p>
      </div>
      <div style={{width:'200px',height:'60px', marginBottom: '10px'}}> 
                <p style={{display:'flex',flexDirection:"column"}}>
                  <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Account Number</span>
                  
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?.accountNumber || '-------'}</span> 
                </p>
      </div>
      <div style={{width:'200px',height:'60px', marginBottom: '10px'}}> 
                <p style={{display:'flex',flexDirection:"column"}}>
                  <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Account Type</span>
                  
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?.accountType || '-------'}</span> 
                </p>
      </div>
      <div style={{width:'200px',height:'60px', marginBottom: '10px'}}> 
                <p style={{display:'flex',flexDirection:"column"}}>
                  <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>IFSC Code </span>
                  
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?.ifsc || '-------'}</span> 
                </p>
      </div>
      <div style={{width:'200px',height:'60px', marginBottom: '10px'}}> 
                <p style={{display:'flex',flexDirection:"column"}}>
                  <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Customer mobile</span>
                  
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?.phone || '-------'}</span> 
                </p>
      </div>
      <div style={{width:'200px',height:'60px', marginBottom: '10px'}}> 
                <p style={{display:'flex',flexDirection:"column"}}>
                  <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Mandate Type </span>
                  
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?.mandateType || '-------'}</span> 
                </p>
      </div>
      <div style={{width:'200px',height:'60px', marginBottom: '10px'}}> 
                <p style={{display:'flex',flexDirection:"column"}}>
                  <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>CustomerId</span>
                  
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?.customerId || '-------'}</span> 
                </p>
      </div>
      <div style={{width:'200px',height:'60px', marginBottom: '10px'}}> 
                <p style={{display:'flex',flexDirection:"column"}}>
                  <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>TokenId</span>
                  
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?.tokenId || '-------'}</span> 
                </p>
      </div>
      <div style={{width:'200px',height:'60px', marginBottom: '10px'}}> 
                <p style={{display:'flex',flexDirection:"column"}}>
                  <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>PaymentId</span>
                  
                 <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?.paymentId || '-------'}</span> 
                </p>
      </div>
      </div>
        </div>
      )}
      
      
      </div>
      <div>
        {showCards5 && (<>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around'}}>
          <div style={{height:'100px',boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",borderColor:"#EAECF0",borderRadius:'5px',padding:'10px',borderStyle:'solid',borderWidth:'1px',display:'flex',flexDirection:'row',alignItems:'center',width:'280px',justifyContent:'space-between'}}>
            <div>
              <p style={{lineHeight:'5px',fontFamily:'Inter-Medium',color:'#858585',fontWeight:'500'}}>Amount Disbursed</p>
              <p style={{lineHeight:'30px',color:'#101828',fontWeight:'600',fontFamily:'Inter-Medium',fontSize:'15px'}}>
              ₹{applicationDetails && applicationDetails?.profile?.disbursementData && applicationDetails?.profile?.disbursementData?.disbursedAmount
              ? formatNumberWithCommas(applicationDetails?.profile?.disbursementData?.disbursedAmount)
              : '-------'}

                </p>
            </div>
              <span><img src={rupee} alt="rupee" style={{height:'53px',width:'53px'}} /></span>
          </div>
          <div style={{height:'100px',boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",borderColor:"#EAECF0",borderRadius:'5px',padding:'10px',borderStyle:'solid',borderWidth:'1px',display:'flex',flexDirection:'row',alignItems:'center',width:'280px',justifyContent:'space-between'}}>
            <div>
              <p style={{lineHeight:'5px',fontFamily:'Inter-Medium',color:'#858585',fontWeight:'500'}}>Disbursement Date</p>
              {/* <p style={{lineHeight:'30px',color:'#101828',fontWeight:'600',fontFamily:'Inter-Medium',fontSize:'15px'}}>{applicationDetails?applicationDetails.profile.disbursementData.disbursedData.slice(0, 10).replace(/-/g, "/"):null}</p> */}
              <p style={{lineHeight:'30px',color:'#101828',fontWeight:'600',fontFamily:'Inter-Medium',fontSize:'15px'}}>
              {applicationDetails?.profile?.disbursementData?.disbursedDate?.substr(0, 10)}</p>
            </div>
              <span><img src={calender} alt="calender" style={{height:'53px',width:'53px'}} /></span>
          </div>
          <div style={{height:'100px',boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",borderColor:"#EAECF0",borderRadius:'5px',padding:'10px',borderStyle:'solid',borderWidth:'1px',display:'flex',flexDirection:'row',alignItems:'center',width:'280px',justifyContent:'space-between'}}>
            <div>
              <p style={{lineHeight:'5px',fontFamily:'Inter-Medium',color:'#858585',fontWeight:'500'}}>UTR</p>
              <p style={{lineHeight:'30px',color:'#101828',fontWeight:'600',fontFamily:'Inter-Medium',fontSize:'15px'}}>
              {applicationDetails && applicationDetails?.profile?.disbursementData && applicationDetails?.profile?.disbursementData?.utr
  ? applicationDetails?.profile?.disbursementData?.utr
  : '-------'}</p>
            </div>
              <span><img src={utr} alt="utr" style={{height:'53px',width:'60px'}} /></span>
          </div>
          </div>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
  <div style={{ borderRadius: '1em', width: '100%', margin: '1em', boxShadow: '0px 0px 2px 2px #f0f0f0' }}>
    <div style={{ display: 'flex', justifyContent: 'center', padding: '1em', width: '100%', borderBottom: '1px solid #f0f0f0' }}>
      <h2 style={{ fontSize: '18px', fontWeight: 'bold', fontFamily: 'Outfit', margin: '0' }}>Disbursement Breakup:</h2>
    </div>
    <div style={{ padding: '1em',  }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: 'auto' }}>
      <div style={{ width: '25%' }}>
          <p style={{ color: '#667085', textAlign: 'center'  }}>Type :</p>
          <p style={{ fontWeight: 'bold', textAlign: 'center'  }}>{applicationDetails?.profile?.productData?.facilityType
            ? (applicationDetails?.profile?.productData?.facilityType == 1 ? "Discounted Interest" 
              : applicationDetails?.profile?.productData?.facilityType == 2 ? "Standard Interest" 
              : applicationDetails?.profile?.productData?.facilityType == 3 ? "Hybrid"
              : applicationDetails?.profile?.productData?.facilityType == 4 ? "Business 2 Business"
              : applicationDetails?.profile?.productData?.facilityType == 4 ? "Discounted Interest Without Gst"
              : applicationDetails?.profile?.productData?.facilityType
            ) 
            : applicationDetails?.profile?.productData?.facilityType}</p>
        </div>
        <div style={{ width: '25%' }}>
          <p style={{ color: '#667085', textAlign: 'center'  }}> Discounted Intrst % :</p>
          <p style={{ fontWeight: 'bold', textAlign: 'center'  }}>{applicationDetails?.profile?.productData?.discountedInterestPercentage
            ? (applicationDetails?.profile?.productData?.discountedInterestPercentage).toFixed(2) : "-"}</p>
        </div>
        <div style={{ width: '25%' }}>
          <p style={{ color: '#667085', textAlign: 'center'  }}>Tenure :</p>
          <p style={{ fontWeight: 'bold', textAlign: 'center'  }}>{applicationDetails?.profile?.productData?.tenure
            ? (applicationDetails?.profile?.productData?.tenure) : "-"}</p>
        </div>
        <div style={{ width: '25%' }}>
          <p style={{ color: '#667085', textAlign: 'center'  }}>Processing Fee Type :</p>
          <p style={{ fontWeight: 'bold', textAlign: 'center'  }}>{applicationDetails?.profile?.productData?.processingfeeType
            ? (applicationDetails?.profile?.productData?.processingfeeType == 1 ? "Flat" 
              : applicationDetails?.profile?.productData?.processingfeeType == 2 ? "Percentage" 
              : applicationDetails?.profile?.productData?.processingfeeType
            ) 
            : applicationDetails?.profile?.productData?.processingfeeType}</p>
        </div>
        <div style={{ width: '25%' }}>
          <p style={{ color: '#667085', textAlign: 'center'  }}>Processing Fee :</p>
          <p style={{ fontWeight: 'bold', textAlign: 'center'  }}>₹{applicationDetails && applicationDetails?.profile?.disbursementData && applicationDetails?.profile?.disbursementData?.processingFee
            ? formatNumberWithCommas(applicationDetails?.profile?.disbursementData?.processingFee) : 0}</p>
        </div>
        <div style={{ width: '25%', textAlign: 'center' }}>
          <p style={{ color: '#667085' }}>Processing Fee GST :</p>
          <p style={{ fontWeight: 'bold' }}>₹{applicationDetails && applicationDetails?.profile?.disbursementData && applicationDetails?.profile?.disbursementData?.processingFeeGst
            ? formatNumberWithCommas(applicationDetails?.profile?.disbursementData?.processingFeeGst) : 0}</p>
        </div>
       
      </div>

   

      <div style={{ display: 'flex', justifyContent: 'space-between', margin: 'auto' }}>
      
        <div style={{ width: '25%' }}>
          <p style={{ color: '#667085', textAlign: 'center'  }}>Subvention :</p>
          <p style={{ fontWeight: 'bold', textAlign: 'center'  }}>₹{applicationDetails && applicationDetails?.profile?.disbursementData && applicationDetails?.profile?.disbursementData?.subvention
            ? formatNumberWithCommas(applicationDetails?.profile?.disbursementData?.subvention) :0}</p>
        </div>
      <div style={{ width: '25%', textAlign: 'center' }}>
          <p style={{ color: '#667085' }}>Subvention GST:</p>
          <p style={{ fontWeight: 'bold' }}>₹{applicationDetails && applicationDetails?.profile?.disbursementData && applicationDetails?.profile?.disbursementData?.subventionGst
            ? formatNumberWithCommas(applicationDetails?.profile?.disbursementData?.subventionGst) : '0'}</p>
        </div>
        <div style={{ width: '25%' }}>
          <p style={{ color: '#667085' , textAlign: 'center' }}>Advance EMI :</p>
          <p style={{ fontWeight: 'bold' , textAlign: 'center' }}>{applicationDetails?.profile?.productData?.advanceEmis
            ? (applicationDetails?.profile?.productData?.advanceEmis) : "0"}</p>
        </div>
        <div style={{ width: '25%', textAlign: 'center' }}>
          <p style={{ color: '#667085' }}>Advance EMI Amount:</p>
          <p style={{ fontWeight: 'bold' }}>₹{applicationDetails && applicationDetails?.profile?.disbursementData && applicationDetails?.profile?.disbursementData?.advanceEmiAmount
            ? formatNumberWithCommas(applicationDetails?.profile?.disbursementData?.advanceEmiAmount) : '0'}</p>
        </div>
        <div style={{ width: '25%', textAlign: 'center' }}>
          <p style={{ color: '#667085' }}>fldg Applicable:</p>
          <p style={{ fontWeight: 'bold' }}>{applicationDetails?.profile?.productData?.fldgApplicable
            ? (applicationDetails?.profile?.productData?.fldgApplicable === true ? "Yes" 
              : applicationDetails?.profile?.productData?.fldgApplicable === false ? "No":"No"
            ) 
            : "No"}</p>
        </div>
        <div style={{ width: '25%', textAlign: 'center' }}>
          <p style={{ color: '#667085' }}>fldg Percentage:</p>
          <p style={{ fontWeight: 'bold' }}>{applicationDetails?.profile?.productData?.fldgPercentage
            ? (applicationDetails?.profile?.productData?.fldgPercentage) : "0"}</p>
        </div>
      </div>
    </div>
  </div>
</div>

          </>
        )
        

        }
      </div>

     
                   

      <div>
        {showCards6 && (
          <div style={{display:"flex",flexDirection:'row',justifyContent:'space-between',gap:'50px'}}>
          <div style={{height:"300px",borderStyle:'solid',borderWidth:'1px',borderColor:"#DBDBDB",width:'100%',padding:'10px',borderRadius:'10px'}}>
            <div style={{display:"flex",flexDirection:'row',alignItems:'center',height:'30px',marginLeft:"20px"}}>
              <img style={{height:'26px',width:"28px"}} src={mandate}/>
              <p style={{fontFamily:"Inter-Medium",fontSize:'14px',fontWeight:'500',marginTop:'13px'}}>Loan Details</p>
              <p style={{fontFamily:"Inter-Medium",fontSize:'14px',fontWeight:'500',marginTop:'13px',paddingLeft:'70px',marginLeft:'90px',}}>Loan Id : {emidetails[0]?.loanId}</p>
            </div>
            <div style={{ borderTop: '0.75px solid #DBDBDB', margin: '10px 0' }}></div>
            <div style={{display:"flex",flexDirection:'row',justifyContent:"space-between"}}>
            <div style={{display:'flex',flexDirection:'column', justifyContent:'space-between'}}>
              <div>
                <p style={{fontWeight:"400",color:"#667085",fontFamily:"Inter-Medium",fontSize:"18px",lineHeight:'10px',marginTop:'13px'}}>Amount Disbursed</p>
                <p style={{fontFamily:"Inter-Medium",fontSize:"16px"}}>
              
                ₹{formatNumberWithCommas(applicationDetails?.profile?.disbursementData?.disbursedAmount) || '-------'}
                  </p>
              </div>
              <div style={{marginRight:'20px',marginTop:'40px'}}>
                <p style={{fontWeight:"400",color:"#667085",fontFamily:"Inter-Medium",fontSize:"18px",lineHeight:'10px'}}>EMI</p>
                <p style={{fontFamily:"Inter-Medium",fontSize:"16px"}}>  ₹{formatNumberWithCommas(emidetails[0]?.emiAmount) || '-------'}</p>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'column', justifyContent:'space-between',marginRight:'50px'}}>
              <div>
                <p style={{fontWeight:"400",color:"#667085",fontFamily:"Inter-Medium",fontSize:"18px",lineHeight:'10px',marginTop:'13px'}}>Loan Amount</p>
                <p style={{fontFamily:"Inter-Medium",fontSize:"16px"}}>₹{formatNumberWithCommas(applicationDetails?.profile?.userDetails?.loanAmount) || '-------'}</p>
              </div>
              <div style={{marginRight:'20px'}}>
                <p style={{fontWeight:"400",color:"#667085",fontFamily:"Inter-Medium",fontSize:"18px",lineHeight:'10px'}}>No Dues</p>
                <p> <a style={{fontFamily:"Inter-Medium",fontSize:"16px"}} href="#">Download</a> </p>
              </div>
            </div>
            </div>
          </div>
          <div style={{height:"300px",borderStyle:'solid',borderWidth:'1px',borderColor:"#DBDBDB",overflow:"auto",width:'100%',padding:'10px',borderRadius:'10px'}}>
            
            <div style={{display:"flex",flexDirection:'row',alignItems:'center',height:'30px',marginLeft:"20px"}}>
              <img style={{height:'26px',width:"28px"}} src={mandate}/>
              <p style={{fontFamily:"Inter-Medium",fontSize:'14px',fontWeight:'500',marginTop:'13px'}}>EMI History</p>
            </div>
            <div style={{ borderTop: '0.75px solid #DBDBDB', margin: '10px 0' }}></div>
            <div style={{width:'100%'}}>
              <Table style={{border:'none'}} >
                    <thead>
                      <th style={{color:"#D32028",textAlign:"left",fontWeight:'600',textAlign: 'center',fontFamily:"Inter-Medium",fontSize:'14px'}}>Due Date</th>
                      <th style={{color:"#D32028",textAlign:"left",fontWeight:'600',textAlign: 'center',fontFamily:"Inter-Medium",fontSize:'14px'}}>Emi</th>
                      <th style={{color:"#D32028",textAlign:"left",fontWeight:'600',textAlign: 'center',fontFamily:"Inter-Medium",fontSize:'14px'}}>Status</th>
                      <th style={{color:"#D32028",textAlign:"left",fontWeight:'600',textAlign: 'center',fontFamily:"Inter-Medium",fontSize:'14px'}}>Action</th>
                    </thead>
                    <tbody style={{ maxHeight: '100%' }}>
                      
                    {emiTable && emiTable.length > 0 ? (
                        emiTable.map((item, idx) => (
                          <tr style={{ lineHeight: '15px', borderBottom: '1px solid #DBDBDB' }} key={idx}>
                            <td style={{ textAlign: 'center', fontFamily: "Inter-Medium", fontWeight: '300', fontSize: '14px' }}>{item.dueDate}</td>
                            <td style={{ textAlign: 'center', fontFamily: "Inter-Medium", fontSize: '14px', fontWeight: '300' }}>{item.emiAmount}</td>
                            <td style={{ textAlign: 'center', fontFamily: "Inter-Medium", fontSize: '14px', fontWeight: '300' }}>
                            {item.status?
                            item.status == 1?<span style={{backgroundColor:'#EFE4FF',color:'#6E24E7', borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px'}}><img  src={initiateddot}/>UnPaid</span>
                            :item.status==3?<span style={{backgroundColor:'#FFF6E8',color:'#F8A31F', borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px'}}><img  src={pendingdot}/>PartlyPaid</span>
                            :item.status==2?<span style={{backgroundColor:'#ECFDF3',color:'#12B76A',borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px'}}><img  src={activedot}/>Paid</span>
                            :item.status==4?<span style={{backgroundColor:'#8ab7ff',color:'black',borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px',whiteSpace:'nowrap'}}>OvdPd.</span>
                            :item.status==5?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px'}}><img  src={closeddot}/>Ovd</span>
                            :item.status==6?<span style={{backgroundColor:'#FFF6E8',color:'#F8A31F', borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px'}}><img  src={pendingdot}/>Paused</span>
                            :item.status==7?<span style={{backgroundColor:'#EDC7E2',color:'black', borderRadius:'8px',padding:'3px',fontFamily:'Outfit',fontSize:'13px',whiteSpace:'nowrap'}}><img  src={pendingdot}/>Mand.In Prog</span>
                            :item.status
                            :'-'}                              </td>
                            <td style={{ textAlign: 'center' }}>
                              <img style={{ marginRight: '5px', cursor: "pointer" }} src={document} alt="document" />
                              <img style={{ marginRight: '5px', cursor: "pointer" }} src={share} alt="share" />
                              <img style={{ height: '22px', width: "24px", cursor: "pointer" }} src={amount} alt="amount" />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4" style={{ textAlign: 'center', fontFamily: "Inter-Medium", fontSize: '14px', fontWeight: '300', borderBottom: '1px solid #DBDBDB' }}>
                            -------
                          </td>
                        </tr>
                      )}

                    </tbody>
              </Table>
            </div> 
          </div>  
          </div>
          
        )}
          
      </div>
    
      </div>
      </div>
      
    </div>
    
  )
}



export default ApplicationProfile













