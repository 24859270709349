import React, {useEffect,useState} from 'react'
import axiosInstance from '../../../helpers/axios';

import {
    Col,
    Row,
} from 'reactstrap';

const PreScreen = ({userId,applicationId})=>{
  const user=sessionStorage.getItem('user')
  const [cibilDetails,setCibilDetails]=useState(null)
  const [filterEligibility,setFilterEligibility]=useState(null)
  const [eligibilityRules,setEligibilityules]=useState(null)
  const [field, setField] = useState(null);

    function formatNumberWithCommas(number) {
        return number?.toLocaleString('en-IN'); 
      }
  const preScreenUrl = `/experian/report?userId=${userId}&applicationId=${applicationId}`

  useEffect(() => {
    axiosInstance.get(preScreenUrl, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }).then((res=>{ 
        setCibilDetails(res?.data?.data)
        axiosInstance.get(`rules/eligibility?phone=${res?.data?.data?.phone}`, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }).then((res=>{ setEligibilityules(res?.data?.data)}
         )).catch((error)=>(console.log("error",error)))

      }
     )).catch((error)=>(console.log("error",error)))
  
  }, [userId,applicationId])
  
  const handleFieldClick = (type) => {
    setField(type);
  };
  const filterData = (item) => {
    if (!field) return true;
    if (field === 'reject') return eligibilityRules?.reject_keys?.includes(item);
    if (field === 'manual') return eligibilityRules?.manual_keys?.includes(item);
    if (field === 'pass') return eligibilityRules?.pass_keys?.includes(item);
  };
     
    
    return(
        <>
                    <Row style={{marginTop:'20px'}}>
                        <div style={{display:'flex',justifyContent:'space-between',paddingLeft:'12px',paddingRight:'10px',paddingTop:"10px"}}>
                            <div>
                                <Col sm={4}>
                            <div style={{borderStyle:'solid',borderWidth:'1px',borderColor:'white',borderRadius:'8px',padding:'20px',boxShadow: '0px 0px 2px 2px  #f0f0f0',width:'250px'}}>
                                <p style={{fontFamily:'Inter-Medium',color:'#667085'}}>summary_bureau_name</p>
                                <p style={{fontFamily:'Inter-Medium',fontSize:'1vw',lineHeight:'1em'}}>{cibilDetails?.summary?.bureau_name}</p>
                            </div>
                                </Col>
                            </div>
                            <div>
                                <Col sm={4}>
                                <div style={{borderStyle:'solid',borderWidth:'1px',borderColor:'white',borderRadius:'8px',padding:'20px',marginLeft:'7px',boxShadow: '0px 0px 2px 2px  #f0f0f0',width:'250px'}}>
                                <p style={{fontFamily:'Inter-Medium',color:'#667085'}}>Score Range</p>
                                <p style={{fontFamily:'Inter-Medium',fontSize:'1vw',lineHeight:'1em'}}>{cibilDetails?.summary?.bureau_score_bucket}</p>
                            </div> 
                                </Col>
                            </div>
                            <div>
                                <Col sm={4}> 
                                <div style={{borderStyle:'solid',borderWidth:'1px',borderColor:'white',borderRadius:'8px',padding:'20px',marginLeft:'7px',boxShadow: '0px 0px 2px 2px  #f0f0f0',width:'250px'}}>
                                <p style={{fontFamily:'Inter-Medium',color:'#667085'}}>obligation</p>
                                <p style={{fontFamily:'Inter-Medium',fontSize:'1vw',lineHeight:'1em'}}>{cibilDetails?.obligation}</p>
                            </div>
                                
                                </Col>
                            </div>
                            <div>
                                <Col sm={4}> 
                                <div style={{borderStyle:'solid',borderWidth:'1px',borderColor:cibilDetails?.summary?.max_dpd_ever>30?"#d32028":"#fff",borderRadius:'8px',padding:'20px',marginLeft:'7px',boxShadow: '0px 0px 2px 2px  #f0f0f0',width:'250px',backgroundColor:cibilDetails?.summary?.max_dpd_ever>30?"#Feedee":"#fff"}}>
                                <p style={{fontFamily:'Inter-Medium',color:'#667085'}}>Max DPD days</p>
                                <p style={{fontFamily:'Inter-Medium',fontSize:'1vw',lineHeight:'1em'}}>{cibilDetails?.summary?.max_dpd_ever}</p>
                            </div>
                                
                                </Col>
                            </div>
                            
                        </div>
                    </Row>
                    <Row style={{marginTop:'20px'}}>
                    <Col sm={2} style={{ marginBottom: '15px' }}>
                        <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: parseInt(cibilDetails?.dpd_risk_flag)?"#d32028":"#fff",backgroundColor:parseInt(cibilDetails?.dpd_risk_flag)?"#Feedee":"#fff", borderRadius: '8px', padding: '20px', boxShadow: '0px 0px 2px 2px  #f0f0f0', height: '100%' }}>
                        <p style={{ fontFamily: 'Inter-Medium', color: '#667085' }}>dpd_risk_flag</p>
                        <p style={{ fontFamily: 'Inter-Medium', fontSize: '1vw', lineHeight: '1em',color:cibilDetails?.dpd_risk_flag?"#d32028":"#000" }}>{cibilDetails?.dpd_risk_flag}</p>
                        </div>
                    </Col>
                    <Col sm={2} style={{ marginBottom: '15px' }}>
                        <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white', borderRadius: '8px', padding: '20px', boxShadow: '0px 0px 2px 2px  #f0f0f0', height: '100%' }}>
                        <p style={{ fontFamily: 'Inter-Medium', color: '#667085' }}>summary_wilful_default_flag</p>
                        <p style={{ fontFamily: 'Inter-Medium', fontSize: '1vw', lineHeight: '1em',color:cibilDetails?.summary?.is_suitfiled_willfuldefault_writtenoffstatus_last24months?"#d32028":"#fff"}}>{cibilDetails?.summary?.is_suitfiled_willfuldefault_writtenoffstatus_last24months.toString()}</p>
                        </div>
                    </Col>
                    <Col sm={2} style={{ marginBottom: '15px' }}>
                        <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white', borderRadius: '8px', padding: '20px', boxShadow: '0px 0px 2px 2px  #f0f0f0', height: '100%',backgroundColor: cibilDetails?.summary?.is_suitfiled_wilfuldefault_last24months?"#FEEDEE":"#fff", }}>
                        <p style={{ fontFamily: 'Inter-Medium', color: '#667085' }}>summary_suit_filed_flag</p>
                        <p style={{ fontFamily: 'Inter-Medium', fontSize: '1vw', lineHeight: '1em',color: cibilDetails?.summary?.is_suitfiled_wilfuldefault_last24months?"#d32028":"#000"}}>{cibilDetails?.summary?.is_suitfiled_wilfuldefault_last24months.toString()}</p>
                        </div>
                    </Col>
                    <Col sm={2} style={{ marginBottom: '15px' }}>
                        <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white', borderRadius: '8px', padding: '20px', boxShadow: '0px 0px 2px 2px  #f0f0f0', height: '100%' }}>
                        <p style={{ fontFamily: 'Inter-Medium', color: '#667085' }}>summary_settled_written_off_flag</p>
                        <p style={{ fontFamily: 'Inter-Medium', fontSize: '1vw', lineHeight: '1em' }}>{cibilDetails?.summary?.settled_written_off_flag}</p>
                        </div>
                    </Col>
                    <Col sm={2} style={{ marginBottom: '15px' }}>
                        <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white', borderRadius: '8px', padding: '20px', boxShadow: '0px 0px 2px 2px  #f0f0f0', height: '100%' }}>
                        <p style={{ fontFamily: 'Inter-Medium', color: '#667085' }}>summary_written_off_gt_1000_flag</p>
                        <p style={{ fontFamily: 'Inter-Medium', fontSize: '1vw', lineHeight: '1em' }}>{cibilDetails?.summary?.written_off_gt_1000_flag}</p>
                        </div>
                    </Col>
                    <Col sm={2} style={{ marginBottom: '15px' }}>
                        <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white', borderRadius: '8px', padding: '20px', boxShadow: '0px 0px 2px 2px  #f0f0f0', height: '100%' }}>
                        <p style={{ fontFamily: 'Inter-Medium', color: '#667085' }}>summary_written_off_flag</p>
                        <p style={{ fontFamily: 'Inter-Medium', fontSize: '1vw', lineHeight: '1em' }}>{cibilDetails?.summary?.written_off_flag}</p>
                        </div>
                    </Col>
                    </Row>
                    <Row style={{marginTop:'20px'}}>
                    <Col sm={4} style={{ marginBottom: '15px' }}>
                        <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white', borderRadius: '8px', padding: '20px', boxShadow: '0px 0px 2px 2px  #f0f0f0', height: '100%' }}>
                        <p style={{ fontFamily: 'Inter-Medium', color: '#667085' , lineHeight: '1em'}}>Active / All Loans / Overdue Accounts</p>
                        <p style={{ fontFamily: 'Inter-Medium', fontSize: '1em' }}>{cibilDetails?.summary?.cnt_active_accounts} / {cibilDetails?.summary?.cnt_all_accounts} / {cibilDetails?.summary?.cnt_overdue_accounts}</p>
                        </div>
                    </Col>
                    <Col sm={4} style={{ marginBottom: '15px' }}>
                        <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white', borderRadius: '8px', padding: '20px', boxShadow: '0px 0px 2px 2px  #f0f0f0', height: '100%' }}>
                        <p style={{ fontFamily: 'Inter-Medium', color: '#667085' , lineHeight: '1em' }}>Oustanding / Sanctioned</p>
                        <p style={{ fontFamily: 'Inter-Medium', fontSize: '1vw' }}>{formatNumberWithCommas(cibilDetails?.summary?.total_outstanding_amount)} / {formatNumberWithCommas(cibilDetails?.summary?.sum_tradelines_balance)}</p>                        </div>
                    </Col>
                    <Col sm={4} style={{ marginBottom: '15px' }}>
                        <div style={{ borderStyle: 'solid', borderWidth: '1px', borderColor: 'white', borderRadius: '8px', padding: '20px', boxShadow: '0px 0px 2px 2px  #f0f0f0', height: '100%' }}>
                        <p style={{ fontFamily: 'Inter-Medium', color: '#667085', lineHeight: '1em'  }}>Write Offs</p>
                        <p style={{ fontFamily: 'Inter-Medium', fontSize: '1vw',color:'#d32028' }}>{cibilDetails?.summary?.cnt_writtenoff} / {cibilDetails?.summary?.total_writtenoff_amt}</p>
                        </div>
                    </Col>
                   
                    </Row>
                    <p style={{fontWeight:'bold',}}>Experian Data  <span style={{backgroundColor:'#0039a6',color:'white',padding:'8px',borderRadius:'10px', marginRight:'1rem',cursor:'pointer'}} onClick={() => setFilterEligibility(null)}>QEC Results</span>
                    <span > 
                    <span onClick={() =>setFilterEligibility(eligibilityRules?.reject_keys)} style={{cursor:'pointer'}}>  Reject Fields <span style={{padding:'5px',backgroundColor:'red',color:'white',borderRadius:'10px',marginRight:'1rem',cursor:'pointer'}} >{eligibilityRules?.reject_keys?.length} </span> </span>
                    <span onClick={() =>setFilterEligibility(eligibilityRules?.manual_keys)} style={{cursor:'pointer'}}> To be checked Manually Fields<span style={{padding:'5px',backgroundColor:'yellow',color:'black',borderRadius:'10px',marginRight:'1rem',cursor:'pointer'}} >{eligibilityRules?.manual_keys?.length}</span></span>
                    <span onClick={() =>setFilterEligibility(eligibilityRules?.pass_keys)} style={{cursor:'pointer'}}>  Passed Fields<span style={{padding:'5px',backgroundColor:'green',color:'white',borderRadius:'10px',marginRight:'1rem',cursor:'pointer'}} >{eligibilityRules?.pass_keys?.length}</span></span>
                   </span></p>

                   <div style={{height:'25rem',overflowY:'scroll'}}>
                   <div>
                   <div style={{borderTopLeftRadius:'10px',alignItems:'center',borderTopRightRadius:'10px',borderBottom:'1px solid #D0D0D0',backgroundColor:'#F0F0F0',display:'flex',paddingLeft:'20%',paddingRight:'20%'}}>
                                <p style={{fontFamily:'Inter-Medium',fontWeight:"bold",textAlign:'left',width:'15rem'}}>S.No</p>
                                <p style={{fontFamily:'Inter-Medium',fontWeight:"bold",textAlign:'left',width:'25rem'}}>Name</p>
                                <p style={{fontFamily:'Inter-Medium',fontWeight:"bold",fontSize:'1vw'}}>Value / Flag</p>
                    </div>
                    </div>
                    <div>
                    {filterEligibility?.map((item,idx)=>(<div style={{backgroundColor:eligibilityRules?.reject_keys?.includes(item)?'rgb(255, 190, 187)':eligibilityRules?.pass_keys?.includes(item)?'#22bb33':eligibilityRules?.manual_keys?.includes(item)?'rgb(255, 255, 130)':"",alignItems:'center', borderBottom:'1px solid #D0D0D0',display:'flex',paddingLeft:'20%',paddingRight:'20%'}}>
                    <p style={{fontFamily:'Inter-Medium',fontWeight:"bold",textAlign:'left',width:'15rem'}}>{idx+1}</p>
                    <p style={{fontFamily:'Inter-Medium',color:'#000',width:'25rem'}}>{item}</p>
                    <p style={{fontFamily:'Inter-Medium',fontSize:'1vw'}}>{cibilDetails?.summary[item]?cibilDetails?.summary[item]:'NA'}</p>
                    </div>))}
                    {cibilDetails?.summary&&!(filterEligibility)?Object?.keys(cibilDetails?.summary).filter((item)=>!(eligibilityRules?.reject_keys?.includes(item)&&eligibilityRules?.manual_keys?.includes(item)&&eligibilityRules?.pass_keys?.includes(item)))?.map((item,idx)=>(<div style={{backgroundColor:eligibilityRules?.reject_keys?.includes(item)?'rgb(255, 190, 187)':eligibilityRules?.pass_keys?.includes(item)?'#22bb33':eligibilityRules?.manual_keys?.includes(item)?'rgb(255, 255, 130)':"",alignItems:'center', borderBottom:'1px solid #D0D0D0',display:'flex',paddingLeft:'20%',paddingRight:'20%'}}>
                    <p style={{fontFamily:'Inter-Medium',fontWeight:"bold",textAlign:'left',width:'15rem'}}>{idx+1}</p>
                    <p style={{fontFamily:'Inter-Medium',color:'#000',width:'25rem'}}>{item}</p>
                    <p style={{fontFamily:'Inter-Medium',fontSize:'1vw'}}>{cibilDetails?.summary[item]?cibilDetails?.summary[item]:'NA'}</p>
                    </div>)):<></>}
                    </div>
                   </div>
                    
                                      
                    
        </>
    )
}
export default PreScreen