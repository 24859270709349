import * as React from 'react';

import { styled } from '@mui/material/styles';import Sidebar from "../Sidebar"
import Topbar from "../Topbar"
import { Link, useNavigate } from "react-router-dom";

import { useState, useEffect  } from 'react';
import axiosInstance from '../../helpers/axios';
import {
    Chart as ChartJS,
    LinearScale,
    ArcElement,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
  } from "chart.js";
  import { Chart, Bar, Doughnut,Line } from "react-chartjs-2";
  ChartJS.register(
    ArcElement,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
  );
const Item = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    width:'11.5vw',
    height:'150px',
   
    color: theme.palette.text.secondary,
   
  }));
  

const Analytics = () =>{

    const [data,setData] = useState([])
    const [currMonthDemand,setCurrMonthDemand] = useState("")
    const [demand,setDemand] = useState([])
    const [applicationCount,setApplicationCount]=useState([])
   
    


const url = `/analytics`
const emiUrl = `/emis/demand`
const user = sessionStorage.getItem('user');

useEffect(()=>{
    axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
        setData(res?.data?.data)
        const sortedApplications = res?.data?.data?.statusWiseApplications.sort((a, b) => {
          // Sorting in descending order based on applicationCount
          return a.month - b.month;
      });
        setApplicationCount(sortedApplications)

      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });


      axiosInstance.get(emiUrl, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
        setCurrMonthDemand(res?.data?.data?.currentMonthDemand)
        setDemand(res?.data?.data?.monthWiseDemand)
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
},[url,emiUrl])

const optionsVerticalBar = {
    responsive: true,
    maintainAspectRatio: true,

    plugins: {
      legend: {
        display: true,
      },
      
    },
   
  };
  const approvalOptions = {
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: 100, // Assuming percentages range from 0 to 100
        ticks: {
          callback: function (value) {
            return value + '%'; // Display percentages on the Y-axis
          },
          stepSize: 20,
        },
      },
    },
  };
  const approvalRateData = {
    labels: applicationCount.map(item => `${getMonthName(item.month)} ${item.year}`),
    datasets: [{
      label: 'Approval Rate',
      data: applicationCount.map(item => (parseFloat(item.approved) / parseFloat(item.applied)) * 100),
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]    
  };

  let labels = demand.slice(0, 7).map(item => Object.keys(item)[0])

  const dataVertical = {
    labels,

    datasets: [ {
        label: 'Demand',
      
        data:demand.slice(0, 7).map(item => Object.values(item)[0]),
        backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 99, 132, 0.2)',
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 99, 132)',
            'rgb(255, 99, 132)',
            'rgb(255, 99, 132)',
            'rgb(255, 99, 132)',
            'rgb(255, 99, 132)',
            'rgb(255, 99, 132)',
          ],
          borderWidth: 1,
          
      }],
  };

  
  const ranges = [
    { label: '0-6', start: 0, end: 6 },
    { label: '6-12', start: 6, end: 12 },
    { label: '12-18', start: 12, end: 18 },
    { label: '18-24', start: 18, end: 24 },
    { label: '24-36', start: 24, end: 36 },
    { label: '36+', start: 36 } // No end for the last range
];

const rangeSums = ranges.map(range => {
    const filteredItems = data?.tenureWiseAUM?.filter(item => 
        item?.tenure >= range.start && (range.end ? item?.tenure < range.end : true)
    );
    const sum = filteredItems?.reduce((total, item) => total + item?.tenure_wise_aum, 0);
    return sum;
});

   const newdata = ranges.map(range => range.label);

  
  
  
  const dataDoughnut = {
      labels: newdata,
      datasets: [
          {
              data: rangeSums,
              backgroundColor: [
                  'rgba(255, 99, 132, 0.2)',
                  'rgba(54, 162, 235, 0.2)',
                  'rgba(255, 206, 86, 0.2)',
                  'rgba(75, 192, 192, 0.2)',
                  'rgba(153, 102, 255, 0.2)',
                  'rgba(255, 159, 64, 0.2)',
                  'rgba(50, 205, 50, 0.2)',
                  'rgba(255, 215, 0, 0.2)',
                  'rgba(0, 128, 128, 0.2)',
                  'rgba(128, 0, 128, 0.2)',
                  'rgba(0, 255, 0, 0.2)',
                  'rgba(0, 0, 255, 0.2)',
                  'rgba(255, 0, 255, 0.2)',
                  'rgba(128, 128, 0, 0.2)'
              ],
              borderColor: [
                  'rgba(255, 99, 132, 1)',
                  'rgba(54, 162, 235, 1)',
                  'rgba(255, 206, 86, 1)',
                  'rgba(75, 192, 192, 1)',
                  'rgba(153, 102, 255, 1)',
                  'rgba(255, 159, 64, 1)',
                  'rgba(50, 205, 50, 1)',
                  'rgba(255, 215, 0, 1)',
                  'rgba(0, 128, 128, 1)',
                  'rgba(128, 0, 128, 1)',
                  'rgba(0, 255, 0, 1)',
                  'rgba(0, 0, 255, 1)',
                  'rgba(255, 0, 255, 1)',
                  'rgba(128, 128, 0, 1)'
              ],
              borderWidth: 1,
          },
      ],
  };
  const options = {
    responsive: true,
    plugins: {
     
     
    },
  };
  function getMonthName(monthNumber) {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    if (monthNumber >= 1 && monthNumber <= 12) {
      return monthNames[monthNumber - 1];
    }
  
    return 'Invalid Month';
  }
  const data1 = {
    labels:applicationCount.map(item => `${getMonthName(item.month)} ${item.year}`),
    datasets: [
      {
        label: 'Applied',
        data: applicationCount.map(item => `${item.applied}`),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Approved',
        data: applicationCount.map(item => `${item.approved}`),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Disbursed',
        data: applicationCount.map(item => `${item.disbursed}`),
        backgroundColor: 'rgba(75, 192, 192, 1)',
      },
    ],
  };
  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN');
  }
  const [isOpen,setIsOpen]=useState(true)

    return(
        <div style={{display:'flex',width:"100%",backgroundColor:'white',minHeight:'100vh'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%',padding:'10px'}}>
        <Topbar/>
        <div style={{backgroundColor:'#F8F8F8',borderRadius:'10px'}}>
        <div style={{display:'flex',justifyContent:"space-between",marginTop:'20px',padding:'15px'}}>
                <div >
                <Item  style={{display:'flex',flexDirection:'column',alignItems:'center',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white'}}>
                        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'15px',alignSelf:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}>Total Loans</p>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'25px',color:'black',padding:'10px'}}>{data?.totalActiveLoans ?(<>{formatNumberWithCommas(data?.totalActiveLoans)}</>)  :"-"}</p>
                </Item>
                </div>
                <div>
                <Item style={{display:'flex',flexDirection:'column',alignItems:'center',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white'}}>
               
                        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'15px',alignSelf:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}>Total POS</p>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'25px',color:'black',padding:'10px'}}>{data?.totalPos ?(<>₹{formatNumberWithCommas(Math.floor(data?.totalPos))}</>)  :"-"}</p>

                </Item>
                </div>
                <div>
                <Item  style={{display:'flex',flexDirection:'column',alignItems:'center',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white'}}>
                    
                        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'15px',alignSelf:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}>MTD Disb.</p>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'25px',color:'black',padding:'10px'}}>{data?.mtdDisbursements ?(<>₹{formatNumberWithCommas(Math.floor(data?.mtdDisbursements))}</>)  :"-"}</p>
                
                </Item>
                </div>
                <div>
                <Item  style={{display:'flex',flexDirection:'column',alignItems:'center',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white'}}>
                   
                        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'15px',alignSelf:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}>MTD Loans</p>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'25px',color:'black',padding:'10px'}}>{data?.mtdLoans ?(<>{formatNumberWithCommas(data?.mtdLoans)}</>)  :"-"}</p>

                </Item>
                </div>
                <div>
                <Item  style={{display:'flex',flexDirection:'column',alignItems:'center',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white'}}>
                   
                        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'15px',alignSelf:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}>Curr.Month Demand</p>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'25px',color:'black',padding:'10px'}}>₹{formatNumberWithCommas(Math.floor(currMonthDemand))}</p>

                </Item>
                </div>
                <div>
                <Item  style={{display:'flex',flexDirection:'column',alignItems:'center',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white'}}>
                        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'15px',alignSelf:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}>Total Inst</p>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'25px',color:'black',padding:'10px'}}>{data?.instituteCount ?(<>{formatNumberWithCommas(data?.instituteCount)}</>)  :"-"}</p>
                   
                </Item>
                </div>
            </div>
            <div style={{display:'flex',justifyContent:"space-between",padding:'15px'}}>
                <div>
                <Item  style={{display:'flex',flexDirection:'column',alignItems:'center',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white'}}>
                        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'15px',alignSelf:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}>Avg Loan Amt.</p>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'25px',color:'black',padding:'10px'}}>{data?.avgLoanAmount ?(<>₹{formatNumberWithCommas(Math.floor(data?.avgLoanAmount))}</>)  :"-"}</p>
                   
                </Item>              
                 </div>
                <div>
                <Item  style={{display:'flex',flexDirection:'column',alignItems:'center',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white'}}>
                        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'15px',alignSelf:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}>Avg Tenure</p>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'25px',color:'black',padding:'10px'}}>{data?.avgTenure ? data?.avgTenure:''}</p>
                   
                </Item>              </div>
                <div>
                <Item  style={{display:'flex',flexDirection:'column',alignItems:'center',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white'}}>
                        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'15px',alignSelf:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}>Avg CIBIL</p>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'25px',color:'black',padding:'10px'}}>{data?.avgCibil ? data?.avgCibil :'-'}</p>
                   
                </Item>               </div>
                <div>
                <Item  style={{display:'flex',flexDirection:'column',alignItems:'center',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white'}}>
                        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'15px',alignSelf:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}>Avg IRR</p>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'25px',color:'black',padding:'10px'}}>{data?.avgIrr}</p>
                   
                </Item>                </div>
                <div>
                <Item  style={{display:'flex',flexDirection:'column',alignItems:'center',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white'}}>
                        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'15px',alignSelf:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}>Avg Age</p>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'25px',color:'black',padding:'10px'}}>{data?.avgAge}</p>
                   
                </Item>               
                 </div>
                <div>
                <Item  style={{display:'flex',flexDirection:'column',alignItems:'center',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white'}}>
                        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'15px',alignSelf:'flex-start',paddingLeft:'10px',paddingTop:'20px'}}>NTC</p>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'25px',color:'black',padding:'10px'}}>{data?.ntcCustomersPercentage ? data?.ntcCustomersPercentage:0}%</p>
                </Item>               
                 </div>
            </div>
       
        <div>
        <div  style={{display:'flex',justifyContent:'space-between',width:'100%',padding:'1em'}}>
          <div style={{width:'36vw',padding:'20px',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white',display:'flex',alignItems:'center',flexDirection:'column',backgroundColor:'white'}}>
            <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px'}}>Disbursal Trend</p>
              <Bar redraw={true} options={options} data={data1} />
          </div>
          <div style={{width:'36vw',padding:'20px',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white',display:'flex',alignItems:'center',flexDirection:'column',backgroundColor:'white'}}>
            <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px'}}>Approval Rate</p>
          
              <Chart type="line"
              data={approvalRateData} options={approvalOptions}/>
          </div>
        </div>
        <div style={{display:'flex',justifyContent:'space-between',width:'100%',padding:'1em'}}>
        <div style={{width:'36vw',padding:'20px',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white',display:'flex',alignItems:'center',flexDirection:'column',backgroundColor:'white'}}>
        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px'}}>Demand</p>
        <Bar
              redraw={true}
              options={optionsVerticalBar}
              data={dataVertical}
            />

        </div>
        <div style={{width:'36vw',padding:'20px',borderRadius:'10px',boxShadow: "4px 7px 9px 2px #1018280F",border:'1px solid white',display:'flex',alignItems:'center',flexDirection:'column',backgroundColor:'white'}}>
        <p style={{fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px'}}>AUM-Tenure Wise</p>
        <div style={{ width: "20em", height: "20em" }}>
              <Doughnut redraw={true} data={dataDoughnut} />

            </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        
    )
}
export default Analytics