import React,{useState,useEffect} from 'react'
import Sidebar from '../Sidebar'
import Topbar from '../Topbar'
import moment from 'moment'
import axiosInstance from '../../helpers/axios'
import jwt_decode from 'jwt-decode'
import {
    Button,
   Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
 
  
} from 'reactstrap';
import {useDispatch,useSelector} from 'react-redux';

import {userActions} from '../../actions/user.actions'
import add from '../../assets/images/plus_icon.png'
import deleteIcon from '../../assets/images/icons/delete-icon.png'
import alertIcon from '../../assets/images/alertIcon.png'
import "./index.css"
import LoanCards from '../LoanCards'
import { useNavigate } from 'react-router-dom'
import {useLocation} from 'react-router-dom';
import BasicLoanForm from '../LoanCards/LoanForms/BasicLoanForm/BasicLoanForm.js'


const Dashboard = () =>{
    const location=useLocation();
    const user=sessionStorage.getItem('user')
    const decode=jwt_decode(user)

     const handleScroll = (e) => {

  e.target.blur()

  
  e.stopPropagation()

 
  setTimeout(() => {
    e.target.focus()
  }, 0)
}
    // const newUserIdState=useSelector((state)=>state?.createApplication)
    const dispatch=useDispatch()
 const options = [
  { value: 'Select Type', label: 'Select Type', id: 'Select_Type' },
    { value: 'PAN', label: 'PAN', id: 'pan' },
    { value: 'Aadhaar Front', label: 'Aadhaar Front', id: 'aadhaar_front' },
    { value: 'Aadhaar Back', label: 'Aadhaar Back', id: 'aadhaar_back' },
    { value: 'Driving License Front', label: 'Driving License Front', id: 'driving_license_front' },
    { value: 'Driving License Back', label: 'Driving License Back', id: 'driving_license_back' },
    { value: 'Voter ID', label: 'Voter ID', id: 'voterid' },
    { value: 'ITR', label: 'ITR', id: 'itr' },
    { value: 'Bank Statement 3m', label: 'Bank Statement 3m', id: 'bank_statement3' },
    { value: 'Bank Statement 6m', label: 'Bank Statement 6m', id: 'bank_statement6' },
    { value: 'Pay Slip 1st M', label: 'Pay Slip 1st M', id: 'pay_slip1' },
    { value: 'Pay Slip 2nd M', label: 'Pay Slip 2nd M', id: 'pay_slip2' },
    { value: 'Pay Slip 3rd M', label: 'Pay Slip 3rd M', id: 'pay_slip3' },
    { value: 'Offer letter', label: 'Offer letter', id: 'offer_letter' },
    { value: 'No Dues', label: 'No Dues', id: 'no_dues' },
    { value: 'Utility Bill', label: 'Utility Bill', id: 'utility_bill' },
    { value: 'Office Id', label: 'Office Id', id: 'office_id' },
    {
      value: 'Registration Certificate ',
      label: 'Registration Certificate',
      id: 'registration_certificate',
    },
    { value: 'Others', label: 'Others', id: 'others' },
  ];

  const validateEmail = email => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };
 const loanTenureArray = decode?.instituteName==="NxtWave Disruptive Technologies Private Limited"?[3,6,9,10,12,18,24]:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24];

    const [isValidStudentEmail,setIsValidStudentEmail]=useState(false)
    const [isValidBorrowerEmail,setIsValidBorrowerEmail]=useState(false)
    const [selectedInstitute,setSelectedInstituteName]=useState(null)
    const [studentFirstName,setStudentFirstName]=useState(null)
    const [studentLastName,setStudentLastName]=useState(null)
    const [studentEmail,setStudentEmail]=useState(null)
    const [studentPhone,setStudentPhone]=useState(null)
    const [courseName,setCourseName]=useState(null)
    const [courseFees,setCourseFees]=useState(null)
    const [loanTenure,setLoanTenure]=useState('Loan Tenure')
    const [loanTenureDropDownOpen,setLoanTenureDropDown]=useState(false)
    const [borrowerFirstName,setBorrowerFirstName]=useState(null)
    const [borrowerLastName,setBorrowerLastName]=useState(null)
    const [applicantPan,setAppPan]=useState(null)
    const [dateOfBirth,setDOB]=useState(null)
    const [borrowerSalary,setBorrowerSalary]=useState(null)
    const [employerName,setEmployerName]=useState(null)
    const [borrowerPhone,setBorrowerPhone]=useState(null)
    const [borrowerAddress,setBorrowerAddress]=useState(null)
    const [borrowerCity,setBorrowerCity]=useState(null)
    const [borrowerState,setBorrowerState]=useState(null)
    const [borrowerZip,setBorrowerZip]=useState(null)
    const [borrowerAadhaar,setBorrowerAadhaar]=useState(null)
    const [borrowerEmail,setBorrowerEmail]=useState(null)
    const [isStudentApplicant,setIsStudentApplicant]=useState(false)
    const [radio, setRadio] = useState("EMPLOYED");
    const [instituteList,setInstituteList]=useState(null)
    const [loanTypeForm, setLoanTypeForm] = useState('')
    const navigate = useNavigate()
   const [count,setCount]=useState([{
    fileType:'',
    file:'',
    password:''
   }])
    const addMoreDocuments=()=>{
      const obj={
        userId:"",
        type: "",
        url1: "",
        url2: "",
        documentPassword: ""
      }
      setUploadedFilesArray(old=>[...old,obj])
    

    }
const [startingPhrase,setStarterPhrase]=useState("Type Institute Name")


const getInstituteInfo=(text)=>{
  const instituteInfoUrl=`${process.env.REACT_APP_BASE_URL}/institute/info/name?name=${text}`;
  if(text.length>=4)
  {
    axiosInstance.get(instituteInfoUrl,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res)=>{
      if(res.data.data==[])
      {
        setInstituteList(null)
        setStarterPhrase("No institute Found")
      }
      else
      setInstituteList(res.data.data)

    })
  }
  else{
    setInstituteList(null)
    setStarterPhrase("Type Institute Name")
  }
}



    

    const handleSearchInstituteDetails=(data)=>{
  
       // setCurrentInstituteId(data.id)
        document.getElementById('instituteNameField').value=data
        setInstituteList(null);
        setSelectedInstituteName(data)
        
       
}

    // const setUploadedDocument=(file)=>{
    //    const uploadedUrl= handleFileUpload(file)
    // }

    const handleStudentBorrower=(event)=>{
      
      if(event.target.checked)
      {
        setIsStudentApplicant(true)
        document.getElementById('borrowerPhone').value=studentPhone
        setBorrowerPhone(studentPhone)
        document.getElementById('borrowerEmail').value=studentEmail
        setBorrowerEmail(studentEmail)
        setIsValidBorrowerEmail(isValidStudentEmail)
        document.getElementById('borrowerFirstName').value=studentFirstName
        setBorrowerFirstName(studentFirstName)

        document.getElementById('borrowerLastName').value=studentLastName
        setBorrowerLastName(studentLastName)

        document.getElementById('borrowerFirstName').disabled=true
        document.getElementById('borrowerLastName').disabled=true
         document.getElementById('borrowerPhone').disabled=true
        document.getElementById('borrowerEmail').disabled=true
      }
      else
      { setIsStudentApplicant(false)
        document.getElementById('borrowerPhone').value=null
        setBorrowerPhone(null)

        document.getElementById('borrowerEmail').value=null
        setBorrowerEmail(null)
        setIsValidBorrowerEmail(false)

        document.getElementById('borrowerPhone').disabled=false
        setBorrowerFirstName(null)

        document.getElementById('borrowerEmail').disabled=false
        setBorrowerLastName(null)
        
         document.getElementById('borrowerFirstName').value=null
        document.getElementById('borrowerLastName').value=null
        document.getElementById('borrowerFirstName').disabled=false
        document.getElementById('borrowerLastName').disabled=false
      }



    }




    //search ins
    
    
    //submit details
    const [panProResult,setPanProResult]=useState(false)
    const [panProDetails,setPanProDetails]=useState(null)

    //age
     function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
}

    const getPanPro=()=>{

      const panProUrl='/pan-pro'
      const panBody={
          
        panNumber     :     applicantPan    ,
        dateOfBirth     :     moment(dateOfBirth).format('DD/MM/YYYY')  
      }

      if(getAge(dateOfBirth)<=18)
        {
         window.alert("Check borrower age ! must be above 18 years old")
        }
        else
        {
        axiosInstance.post(panProUrl,panBody)
        .then((res)=>{
          
          if(res?.data?.data?.pan_number)
          {

            setPanProDetails(res?.data?.data)
            setAddFiles(true)
            window.scrollTo(0, document.body.scrollHeight);
            window.alert("Saved Details! Upload Documents Below.")
            
          }
          else
          {
            window.alert("Invalid PAN Details or Date of Birth")
          }

        })
        .catch((err)=>{
          window.alert("Invalid PAN Details or Date of Birth")
        })
      }
    }
    
    const [addFiles,setAddFiles]=useState(false)


    const createApplications=(panProDetails,uploadedArray)=>{

      const get1=uploadedArray
      const get2=uploadedArray
      const get3=uploadedArray
      const aadhaarUrl1=get1?.filter(item=>item.type==="aadhaar")[0]?.url1
      const aadhaarUrl2=get2?.filter(item=>item.type==="aadhaar")[0]?.url2
      const panUrl=get3?.filter(item=>item.type==="pan")[0]?.url1
      
        const  urlCreate='/summary/create/admin'
        
        

          const body={
            
             mobile: isStudentApplicant?studentPhone:borrowerPhone,
             instituteName: selectedInstitute?selectedInstitute:decode?.instituteName,
             firstName: isStudentApplicant?studentFirstName:borrowerFirstName,
             lastName: isStudentApplicant?studentLastName:borrowerLastName,
             studentName: `${studentFirstName} ${studentLastName}`,
             dateOfBirth: dateOfBirth,
             gender: panProDetails?.user_gender === "M" ? "Male":"Female",
             isCoapplicant: false,
             courseName: courseName,
             courseFees: courseFees,
             panId: applicantPan.toUpperCase(),
             panImage: panUrl,
             aadhaarId: panProDetails?.masked_aadhaar,
             aadhaarFrontImage: aadhaarUrl1,
             aadhaarBackImage: aadhaarUrl2,
             employmentType:
                          radio === "EMPLOYED"
                            ? "salaried"
                            : radio === "SELF_EMPLOYED"
                            ? "self-employed"
                            : "unemployed",
              employerName: radio==="EMPLOYED"?employerName:"",
              salary: radio==="EMPLOYED"?borrowerSalary:"",
              incomePerMonth: radio==="SELF_EMPLOYED"?borrowerSalary:"",
              typeOfBusiness: radio==="SELF_EMPLOYED"?employerName:"",
             email: isStudentApplicant?studentEmail:borrowerEmail,
             currentAddress:borrowerAddress,
             currentCity: borrowerCity ,
             currentState: borrowerState,
             currentPincode: borrowerZip,
             loanTenure: loanTenure,
             channel:decode?.role!=="institute_user"&&decode?.role!=="institute_admin"?null:3
           
     } 
           dispatch(userActions.createApplication(urlCreate,body,user,uploadedArray))
     
          



    }
    
    const [filePassword,setFilePassword]=useState()
    const [dropdownOpen,setDropDownOpen]=useState(0)
    const [documentType,setDocumentType]=useState(null)
    const [uploadedFile,setUploadedFile]=useState(null)
    const [uploadedFilesArray,setUploadedFilesArray]=useState([                 {     
      userId:"",
      type: "",
      url1: "",
      url2: "",
      documentPassword: ""
    }])

    const handleFileUpload=(idx)=>{
      console.log(uploadedFilesArray,"-->Array")
      const urlUpload='/file/upload'
      const uploadObj=new FormData()
      if(uploadedFile)
      {

        uploadObj.append('file',uploadedFile)
        uploadObj.append('type',documentType)
        axiosInstance.post(urlUpload,uploadObj)
        .then((res)=>{

          if(documentType==="aadhaar_back"||documentType==="aadhaar_front")
          { 
            let flag=0;
            for(let i=0;i<uploadedFilesArray.length;i++)
            {
              if(uploadedFilesArray[i].type==="aadhaar")
              {
                  flag=1;
                  if(documentType==="aadhaar_back")
                  {
                    document.getElementById(`uploadButton${idx}`).className="uploaded"
                    uploadedFilesArray[i].url2=res?.data?.data?.s3Url
                    document.getElementById(`documentType${idx}`).disabled=true
                    document.getElementById(`filePassword${idx}`).disabled=true
                    document.getElementById(`uploadedFile${idx}`).disabled=true
                    return res
                  }
                  else if(documentType==="aadhaar_front")
                  {
                    uploadedFilesArray[i].url1=res?.data?.data?.s3Url
                    document.getElementById(`uploadButton${idx}`).className="uploaded"
                    document.getElementById(`documentType${idx}`).disabled=true
                    document.getElementById(`filePassword${idx}`).disabled=true
                    document.getElementById(`uploadedFile${idx}`).disabled=true

                    return res
                  }
              }
            }
            if(!flag)
            { 
              
                 uploadedFilesArray[idx].userId=""
            uploadedFilesArray[idx].type=documentType==="aadhaar_back"||documentType==="aadhaar_front"?"aadhaar":documentType==="driving_license_back"||documentType==="driving_license_front"?"driving_license":documentType
            uploadedFilesArray[idx].url1=documentType!=="aadhaar_back"&&documentType!=="driving_license_back"?res?.data?.data?.s3Url:""
            uploadedFilesArray[idx].documentId='string'
            uploadedFilesArray[idx].url2=documentType==="aadhaar_back"||documentType==="driving_license_Back"?res?.data?.data?.s3Url:""
            uploadedFilesArray[idx].documentPassword=filePassword
            
            setUploadedFile(null)
            
            setFilePassword(null)
            document.getElementById(`uploadButton${idx}`).className="uploaded"
            document.getElementById(`documentType${idx}`).disabled=true
            document.getElementById(`filePassword${idx}`).disabled=true
            document.getElementById(`uploadedFile${idx}`).disabled=true

            
            }

          }
          else if(documentType==="driving_license_back"||documentType==="driving_license_front")
          {
            let Dflag=0;
            for(let i=0;i<uploadedFilesArray.length;i++)
            {
              if(uploadedFilesArray[i].type==="driving_license")
              {
                Dflag=1;
                  if(documentType==="driving_license_back")
                  {
                    document.getElementById(`uploadButton${idx}`).className="uploaded"
                    uploadedFilesArray[i].url2=res?.data?.data?.s3Url
                    document.getElementById(`documentType${idx}`).disabled=true
                    document.getElementById(`filePassword${idx}`).disabled=true
                    document.getElementById(`uploadedFile${idx}`).disabled=true

                    return res
                  }
                  else if(documentType==="driving_license_front")
                  {
                    document.getElementById(`uploadButton${idx}`).className="uploaded"
                    uploadedFilesArray[i].url1=res?.data?.data?.s3Url
                    document.getElementById(`documentType${idx}`).disabled=true
                    document.getElementById(`filePassword${idx}`).disabled=true
                    document.getElementById(`uploadedFile${idx}`).disabled=true

                    return res

                  }
              }
            }
            if(!Dflag)
            {   
                 uploadedFilesArray[idx].userId=""
            uploadedFilesArray[idx].type=documentType==="aadhaar_back"||documentType==="aadhaar_front"?"aadhaar":documentType==="driving_license_back"||documentType==="driving_license_front"?"driving_license":documentType
            uploadedFilesArray[idx].url1=documentType!=="aadhaar_back"&&documentType!=="driving_license_back"?res?.data?.data?.s3Url:""
            uploadedFilesArray[idx].documentId='string'
            uploadedFilesArray[idx].url2=documentType==="aadhaar_back"||documentType==="driving_license_Back"?res?.data?.data?.s3Url:""
            uploadedFilesArray[idx].documentPassword=filePassword
            
            setUploadedFile(null)
            
            setFilePassword(null)
            document.getElementById(`uploadButton${idx}`).className="uploaded"
            document.getElementById(`documentType${idx}`).disabled=true
            document.getElementById(`filePassword${idx}`).disabled=true
            document.getElementById(`uploadedFile${idx}`).disabled=true

            
            }
          }
          else
          {

            uploadedFilesArray[idx].userId=""
            uploadedFilesArray[idx].type=documentType==="aadhaar_back"||documentType==="aadhaar_front"?"aadhaar":documentType==="driving_license_back"||documentType==="driving_license_front"?"driving_license":documentType
            uploadedFilesArray[idx].url1=documentType!=="aadhaar_back"&&documentType!=="driving_license_back"?res?.data?.data?.s3Url:""
            uploadedFilesArray[idx].documentId=documentType!=="pan"?"string":applicantPan
            uploadedFilesArray[idx].url2=documentType==="aadhaar_back"||documentType==="driving_license_Back"?res?.data?.data?.s3Url:""
            uploadedFilesArray[idx].documentPassword=filePassword
            
            setUploadedFile(null)
            
            setFilePassword(null)
            document.getElementById(`uploadButton${idx}`).className="uploaded"
            document.getElementById(`documentType${idx}`).disabled=true
            document.getElementById(`filePassword${idx}`).disabled=true
            document.getElementById(`uploadedFile${idx}`).disabled=true

            
          }
          return res
        })
        .catch((err)=>
        {
          return err
        })
      }
      else
      {
        window.alert("Choose file to upload")
      }

    }


    const handleRemoveFiles=(idx)=>{
      if(idx>=1)
      {
        uploadedFilesArray.pop()
        setUploadedFilesArray(old=>[...old])
      }
      

    }


    
    const resetPage=()=>{
      setUploadedFilesArray([ {     
      userId:"",
      type: "",
      url1: "",
      url2: "",
      documentPassword: ""
    }])
     setAddFiles(false)
    document.getElementById('instituteNameField').value=""
    document.getElementById('studentFirstName').value=""
    document.getElementById('studentLastName').value=""
    document.getElementById('studentPhone').value=""
    document.getElementById('studentEmail').value=""
    document.getElementById('studentCourseName').value=""
    document.getElementById('studentCourseFees').value=""
    setLoanTenure('Loan Tenure')
    document.getElementById('studentBorrowerCheck').checked=false
    document.getElementById('borrowerFirstName').value=""
    document.getElementById('borrowerLastName').value=""
    document.getElementById('panNumber').value=""
    document.getElementById('dateOfBirth').value=""
    document.getElementById('borrowerPhone').value=""
    document.getElementById('borrowerEmail').value=""

    }
    const handleSave=()=>{
      // if(borrowerEmail)
      // {
        if(isValidBorrowerEmail&&isValidStudentEmail&&studentPhone?.length===10&&borrowerPhone?.length===10&&applicantPan?.length===10&&courseName?.length>0&&courseFees>0 &&studentFirstName.length>0&&studentLastName.length>0&&borrowerFirstName.length>0&&borrowerLastName.length>0&&(loanTenure!=="Loan Tenure")&&borrowerAadhaar.length===12&&borrowerAddress.length>0)
          getPanPro()
        else
        {
          alert("Incomplete Information Given Please Check Details")
        }

      // }
      // else if(isValidStudentEmail&&studentPhone?.length===10&&borrowerPhone?.length===10&&applicantPan?.length===10&&courseName?.length>0)
      //  getPanPro()
      // else
      // {
      //   window.alert("Incomplete Information Given Please Check")
      // }
      
    }
    
    useEffect(() => {

    // if(isStudentApplicant)
    // {
    //   document.getElementById('borrowerPhone').value=studentPhone
    //     document.getElementById('borrowerEmail').value=studentEmail
    //      document.getElementById('borrowerPhone').disabled=true
    //     document.getElementById('borrowerEmail').disabled=true
    // }
    // else
    // {
    //   document.getElementById('borrowerPhone').value=null
    //     document.getElementById('borrowerEmail').value=null
    //     document.getElementById('borrowerPhone').disabled=false
    //     document.getElementById('borrowerEmail').disabled=false
    // }
    
    }, [studentEmail,studentPhone])

    const [isOpen,setIsOpen]=useState(true)

    const handleLoanTypeForm = (loanType) => {
      setLoanTypeForm(loanType)
    }
    return(
        
    <div style={{display:'flex' ,flexDirection:'row',width:"100%" ,height:'100%',backgroundColor:"#FFF"}}>
       
          <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"21%":'0%'}}>
                <Topbar/>
                
      
  
      
        
       <div className="fade-In">
          <h1>
            <p style={{fontWeight:'500',fontSize:'1em',fontFamily:'Outfit'}}>Welcome ! <span style={{ fontSize: '1em',color:'#d32028',fontFamily:'Outfit' }}>
              {decode.display_name}
            </span>{' '}</p>{' '}
           
            <p style={{ fontSize: '0.5em' }}>
              Begin the <span style={{fontWeight:'bold'}}>{loanTypeForm}</span> Applicant Onboarding Journey by filling out the form with few details.
            </p>
          </h1>
          {/* {loanTypeForm !== '' && <span style={{display:'flex'}}>
            <CustomButton size='medium' variant='outlined' onClick={e => {e.preventDefault() 
                        console.log('Clicked')
                        navigate('/')
                }}   sx={{p:0, ml:3,mb:2, textTransform:'none'}}><KeyboardBackspaceIcon fontSize='medium'/>Back</CustomButton></span>  } */}

         {/* {(location?.state.loanType !== '' && location?.state.loanType !== 'Term Loan') &&<BasicLoanForm loanType={location?.state.loanType}/>} */}
    
         {loanTypeForm === '' && <LoanCards handleLoanTypeForm={handleLoanTypeForm} />}
         {(loanTypeForm !== ""  && loanTypeForm!=='Term Loan')&& <BasicLoanForm loanType={loanTypeForm}/>}
        
       { false &&
         <div style={{display:'flex',justifyContent: 'space-between',flexDirection: 'row',paddingLeft: '2%',paddingRight: '2%',paddingTop: '2%',backgroundColor:'#fff'}}>
          <div style={{display:'block',justifyContent: 'space-between',flexDirection: 'column', width:'50%',paddingLeft:'2%',paddingRight:'2%'}} >
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row',}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Institute Name</p> 
                    <div style={{display:"block",width:'98%',marginRight:'3%'}}>
                    <input defaultValue={decode?.role==="institute_user"||decode?.role==="institute_admin"?decode?.instituteName:""} disabled={decode?.role==="institute_user"||decode?.role==="institute_admin"?true:false} id="instituteNameField" className="inputField" type="text" placeholder=" Institute Name" onChange={(e)=>getInstituteInfo(e.target.value)}/>

                     <div style={{marginTop:'0.5em',position: 'absolute',zIndex:'999',backgroundColor: '#fff',width:'19em',marginLeft:'1%'}}>
                    {
                        instituteList?instituteList.map((item)=>{
                        return(
                          <div onClick={()=>handleSearchInstituteDetails(item.brandName)} className="instituteName" style={{cursor:'pointer',border:'1px solid #DbDbDB',borderRadius:'0.5em'}}>
                          <p  style={{fontWeight:'600',paddingTop:'0.5em',paddingLeft:'0.5em'}}>{item.brandName}</p>
                          </div>
                        )
                        }
                        ):null

                    }
                    </div>
                  </div>
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Student First Name</p> 
                    <input id="studentFirstName" className="inputField" type="text" placeholder=" Student First Name" onChange={(e)=>setStudentFirstName(e.target.value)}/>
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Student Last Name</p> 
                    <input id="studentLastName" className="inputField" type="text" placeholder=" Student Last Name" onChange={(e)=>setStudentLastName(e.target.value)}/>
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Student Email</p> 
                    <input id="studentEmail" className="inputField" type="text" placeholder=" Student Email" onChange={(e)=>{setStudentEmail(e.target.value) 
                    setIsValidStudentEmail(validateEmail(e.target.value))}}/>
                    {!isValidStudentEmail&&studentEmail?<img style={{width:'2.5em',height:'2.5em'}} src={alertIcon}/>:null}
                    
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Student Phone</p> 
                    <input id="studentPhone" min="0" className="inputField" type="number" onWheel={handleScroll} placeholder=" Student Mobile" onChange={(e)=>setStudentPhone(e.target.value)}/>
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Course Name</p> 
                    <input id="studentCourseName" className="inputField" type="text" placeholder=" Course Name" onChange={(e)=>setCourseName(e.target.value)}/>
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Course Fees</p> 
                    <input min="0" id="studentCourseFees" className="inputField" type="number" onWheel={handleScroll} placeholder=" Course Fees" onChange={(e)=>setCourseFees(e.target.value)}/>
                  </div>
                  <div style={{display:'flex',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Loan Tenure</p> 
                    <Dropdown  toggle={()=>setLoanTenureDropDown(!loanTenureDropDownOpen)} isOpen={loanTenureDropDownOpen}>
                        <DropdownToggle   style={{width:'8em',backgroundColor:'#d32028',border:'none'}} caret >
                            {loanTenure}
                        </DropdownToggle>
                        <DropdownMenu  style={{height:'9em',overflowY:'scroll'}}>
                          {loanTenureArray.map((item)=>{
                            return(

                          <DropdownItem onClick={(e)=>setLoanTenure(e.target.innerText)}>
                          {item}
                          </DropdownItem>
                            )
                          }
                        )}
                        </DropdownMenu>
                  </Dropdown>
                  </div>
                  <div style={{display:'flex',}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',}}>Is Student the borrower  <input id="studentBorrowerCheck" style={{width:'1.5em',height:'1.5em',marginLeft:'6em'}} type="checkbox" onChange={(e)=>handleStudentBorrower(e)}/></p> 
                   
                  </div>
          </div>
          <div className="divider">
          </div>
          <div style={{display:'block',justifyContent: 'space-between',flexDirection: 'column', width:'50%',paddingLeft:'2%',paddingRight:'2%',backgroundColor:'#fff'}} >
           
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Borrower First Name</p> 
                    <input id="borrowerFirstName" className="inputField" type="text" placeholder=" Borrower First Name" onChange={(e)=>setBorrowerFirstName(e.target.value)}/>
                  </div>
                 
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Borrower Last Name</p> 
                    <input id="borrowerLastName" className="inputField" type="text" placeholder=" Borrower Last Name" onChange={(e)=>setBorrowerLastName(e.target.value)}/>
                  </div>
                 
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>PAN</p> 
                    <input id="panNumber" style={{textTransform:'uppercase'}} className="inputField" type="text" placeholder="  Borrower PAN" onChange={(e)=>setAppPan(e.target.value)}/>
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Date Of Birth</p> 
                    <input id="dateOfBirth" className="inputField" type="date"   onChange={(e)=>setDOB(e.target.value)}/>
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Borrower Phone</p> 
                    <input min="0" className="inputField" id="borrowerPhone" type="number" onWheel={handleScroll} placeholder=" Borrower Phone" onChange={(e)=>setBorrowerPhone(e.target.value)}/>
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Borrower Email</p> 
                    <input className="inputField" id="borrowerEmail" type="text" placeholder=" Borrower Email" onChange={(e)=>{setBorrowerEmail(e.target.value)
                    setIsValidBorrowerEmail(validateEmail(e.target.value))}}/>
                    {!isValidBorrowerEmail&&borrowerEmail?<img style={{width:'2.5em',height:'2.5em'}} src={alertIcon}/>:null}
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Borrower Address</p> 
                    <input className="inputField" id="borrowerAddress" type="text" placeholder=" Borrower Address" onChange={(e)=>{setBorrowerAddress(e.target.value)}}/>
                   
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Borrower City</p> 
                    <input className="inputField" id="borrowerCity" type="text" placeholder=" Borrower City" onChange={(e)=>{setBorrowerCity(e.target.value)}}/>
                   
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Borrower State</p> 
                    <input className="inputField" id="borrowerState" type="text" placeholder=" Borrower State" onChange={(e)=>{setBorrowerState(e.target.value)}}/>
                   
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Borrower Zip</p> 
                    <input className="inputField" id="borrowerZip" type="text" placeholder=" Borrower Zip" onChange={(e)=>{setBorrowerZip(e.target.value)}}/>
                   
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',flexDirection: 'row'}}>
                    <p style={{fontWeight:'bold',fontFamily:'Outfit',width:'50%',textAlign:'left'}}>Borrower Aadhaar</p> 
                    <input className="inputField" id="borrowerAadhaar" type="number" onWheel={handleScroll}  min="0" placeholder=" Borrower Aadhaar Number" onChange={(e)=>{setBorrowerAadhaar(e.target.value)}}/>
                
                  </div>
                <div style={{display:'block',justifyContent:'space-between',flexDirection: 'row'}}>
                  <div style={{display:"flex",justifyContent: 'space-around',}}
                onChange={(event) =>
                  setRadio(event.target.value)
                }
                defaultValue="EMPLOYED"
                
              >
                <label style={{fontWeight:"bold"}}>
                  <input
                    type="radio"
                    value="EMPLOYED"
                    name="employment"
                    checked={radio==="EMPLOYED"}
                  />
                  Salaried
                </label>
                <label style={{fontWeight:"bold"}}>
                  <input
                    type="radio"
                    value="SELF_EMPLOYED"
                    name="employment"
                    checked={radio==="SELF_EMPLOYED"}
                  />
                  Self Employed
                </label>
                <label style={{fontWeight:"bold"}}>
                  <input
                    type="radio"
                    value="UNEMPLOYED"
                    name="employment"
                    checked={radio==="UNEMPLOYED"}
                  />
                  Unemployed
                </label>
              </div>
              {radio === "EMPLOYED" && (
                <>
                  <div >
                    <span style={{fontWeight:"bold"}}>Employer Name</span>
                    <input
                      className="inputField"
                      placeholder="Employer Name"
                      type="text"
                      onChange={(e)=>setEmployerName(e.target.value)}

                    />
                  </div>
                  <div >
                   <span style={{fontWeight:"bold"}}> Salary</span>
                    <input
                      className="inputField"

                      placeholder="Salary"
                      type="number"
                      onWheel={handleScroll}
                      onChange={(e)=>setBorrowerSalary(e.target.value)}

                    />
                  </div>
                </>
              )}
              {radio === "SELF_EMPLOYED" && (
                <>
                  <div >
                    <span style={{fontWeight:"bold"}}>Type of Business</span>
                    <input
                      className="inputField"

                      placeholder="Type of Business"
                      type="text"
                     onChange={(e)=>setEmployerName(e.target.value)}

                    />
                  </div>
                  <div >
                   <span style={{fontWeight:"bold"}}>Income per Month</span>
                    <input
                      className="inputField"

                      placeholder="Income per Month"
                      type="number"
                      onWheel={handleScroll}
                      onChange={(e)=>setBorrowerSalary(e.target.value)
                      }
                      
                    />
                  </div>
                </>
              )}
            </div>
          <Button onClick={()=>handleSave()} style={{backgroundColor:'#d32028',border:'none'}}>Save</Button>
          </div>
        </div>
        }
       

      {/* <div style={{display:'flex',flexDirection: 'row-reverse',marginRight:'2em'}}>
        <Button onClick={()=>getPanPro()} style={{border:'none',backgroundColor:'#d32028',width:'7em' }}>
          Submit
        </Button>
        <Button style={{border:'none',marginRight:'1em',width:'7em'}}>
          Reset
        </Button>
      </div> */}
         
  
     { addFiles?<div style={{padding:'1em',fontFamily:'Outfit',}}>
        <h3 style={{fontFamily:'Outfit'}}>Add Files ( Optional )</h3>
        <br/> 
        
    
 { uploadedFilesArray?.map((item,idx)=>{
  return(
      <div id={idx}  style={{display:'flex',justifyContent: 'center',flexDirection:'row',}}>
      <div style={{display:'flex',justifyContent: 'space-between',width:'80%',border:'1px dashed #D32028',padding:'1em',borderRadius:'10px',marginBottom:'6px'}}>
       <select  id={`documentType${idx}`} style={{marginTop:'0.5em',borderRadius:'10px',border:'1px solid #DbDbDb',height:'3em'}} onChange={(e)=>{setDocumentType(e.target.value);}} name="Document Type">
          {options?.map((item)=>{
            return(

              <option value={item.id} name={item.value}>{item.value}</option>
            )
           }
       )
        }

      </select>
        <input id={`uploadedFile${idx}`} type="file" style={{backgroundColor:'#F8F8F8',border:'1px solid #DbDbDb',padding:'10px',borderRadius:'10px',}} onChange={(e)=>setUploadedFile(e.target.files[0])}/>
        {documentType==="bank_statement3"||documentType==="bank_statement6"?<><p>File Password<input id={`filePassword${idx}`} style={{marginTop:'0.5em',marginLeft:"10px",borderRadius:'10px',border:'1px solid #DbDbDb',paddingLeft:'10px'}} type="password" onChange={(e)=>setFilePassword(e.target.value)}/></p></>:null}
</div>
        
        <button id={`uploadButton${idx}`} className="upload" onClick={()=>handleFileUpload(idx)}>Upload</button>
        {idx===uploadedFilesArray.length-1?<><img src={add} title="Add More" style={{height:'2em',marginTop:'2em',marginLeft:'1em'}} onClick={()=>addMoreDocuments()}/><img src={deleteIcon} style={{height:'2em',marginTop:'2em',marginLeft:'1em'}} onClick={()=>handleRemoveFiles(idx)}/></>:null}
     
</div>
  )
      }
      )
      }
       <div style={{padding:'2em',display:'flex',justifyContent:'space-evenly'}}>
        {/* {
          uploadedFilesArray?.length>1?uploadedFilesArray?.map((item)=>{
            return(
              <div style={{display:'flex',justifyContent: 'space-between',}}>
                <p>File Name : {item?.type}</p>
                <p style={{border:'1px solid #D32028',backgroundColor:'#FFFCE3',borderRadius:'10px',padding:'3px',width:'7em',textAlign:'center'}} onClick={()=>handleRemoveFiles(item)}>Remove</p>
              </div>

            )
        })
      :null
        
        } */}
        <Button style={{border:'none'}} onClick={()=>resetPage()}>Reset</Button>
        <Button style={{backgroundColor:'#D32028',border:'none'}} onClick={()=>createApplications(panProDetails,uploadedFilesArray)}>Submit</Button>
      </div>

       
      </div>:null}
      
      
      </div>
      
      </div>
     
       
       
    </div>
    
    )
}

export default Dashboard