import { userConstants } from './../constants/user.constants';

let user = sessionStorage.getItem('user');
const initialState = user ? { user, isLoading: false } : {};

export function Getaadharfront(state = initialState, action) {
  
  //console.log("inside fundcode", action)
  switch (action.type) {
    case userConstants.GETAADHARFRONT_REQUEST:
      return {
        user: action.user,
        isLoading : true

      };
    case userConstants.GETAADHARFRONT_SUCCESS:
      const {res} = action
        console.log("Action", action)
      return {
        res,
        isLoading : false
      };
    case userConstants.GETAADHARFRONT_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}