import Sidebar from "../Sidebar"
import Topbar from "../Topbar"
import { useState, useEffect } from 'react';
import search from "../../assets/images/search.png"
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import PaginationTable from "../status/PaginationTable";
import ManageInstitutes from "./ManageInstitutes"
import BankImg from "../../assets/images/bankImg.png"
import "./InstituteTables.css"
import AddBank from "./AddBankdetails";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axiosInstance from "../../helpers/axios";
import { useSelector, useDispatch } from "react-redux";

 import * as moment from 'moment'


import {userActions} from '../../actions/user.actions';
import {
    Col,
    Card,
    Input,

    Row,

  } from 'reactstrap';
  
import AddInstitute from "./AddInstitute"
const InstituteTables = () =>{
    const [filterInstituteName, setFilterInstituteName] = useState('');
    const user = sessionStorage.getItem('user');
    const decode=jwt_decode(user)
    const [tableData, setTableData] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [rowsPerPage, setrowsPerPage] = useState(10);
    const [totalFetchRows, setTotalFetchRows] = useState(null);
    const [selectedInstituteBankInfo,setSelectedInstituteBankInfo]=useState(null)
    const [searchText, setSearchText] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const insstate = useSelector((state) => state?.Institutes?.res);
    
    const getinst = `${process.env.REACT_APP_BASE_URL}/institute/info?perPage=${rowsPerPage}&pageNo=${currentPage}`;
    const handleAddInstitute = (newInstitute) => {
      setTableData((prevTableData) => [...prevTableData, newInstitute]);
    };

useEffect(()=>{
 
  if(searchText === ""){
    
    const getinst = `/institute/info?perPage=${rowsPerPage}&pageNo=${currentPage}`;

    axiosInstance.get(getinst, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
     
      setTableData(res?.data?.data?.institutes);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
      setTimeout(() => {
        setIsLoading(false); 
      }, 300);
    })
    .catch((error) => {
      setIsLoading(false);
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
    }
    
},[])
const [id,setId]=useState(null)
  const getInsituteInfo=(text)=>{
    
    const urlGetInstitute=`/institute/info/name?name=${text}`;
      
    if(text.length>=4)
    {
      axiosInstance.get(urlGetInstitute,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        console.log("text",res?.data?.data[0]?.id)
        setId(res?.data?.data[0]?.id)
      });
  
   
    }
 
  }
  const handleInputChange = (event) => {

    if(event.target.value.length >= 4){
      setSearchText(event.target.value)
    
      const getinst = `/institute/info?instituteId=${id}&perPage=${rowsPerPage}&pageNo=${1}`;

  console.log("url",getinst)
    axiosInstance.get(getinst, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.institutes);
      setcurrentPage(currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
   
    })
    .catch((error) => {
      console.error("Error fetching filtered data:", error);
    });
  }
  else{
    setSearchText("")
    const getinst = `/institute/info?perPage=${rowsPerPage}&pageNo=${currentPage}`;

    axiosInstance.get(getinst, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.institutes);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
   
    })
    .catch((error) => {
      console.error("Error fetching filtered data:", error);
    });



  }
};
const paginate = (pageNumber) => {


  console.log("Search Text", searchText)
  // setcurrentPage(pageNumber);
  if(searchText.length >= 4){

    const getinst = `/institute/info?instituteId=${id}&perPage=${rowsPerPage}&pageNo=${currentPage}`;
    console.log("url",getinst)
  axiosInstance.get(getinst, {
    headers: {
      Authorization: `Bearer ${user}`,
    },
  })
  .then((res) => {
    console.log("filtering",res)
    setTableData(res?.data?.data?.institutes);
    setcurrentPage(res?.data?.data?.pagination?.currentPage);
    setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
    setrowsPerPage(res?.data?.data?.pagination?.perPage);  
 
  })
  .catch((error) => {
    console.error("Error fetching filtered data:", error);
  });

  }
  else{
    const getinst = `/institute/info?perPage=${rowsPerPage}&pageNo=${pageNumber}`;
  


  axiosInstance.get(getinst, {
    headers: {
      Authorization: `Bearer ${user}`,
    },
  })
  .then((res) => {
  
    setTableData(res?.data?.data?.institutes);
    setcurrentPage(res?.data?.data?.pagination?.currentPage);
    setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
    setrowsPerPage(res?.data?.data?.pagination?.perPage);  
 
  })
  .catch((error) => {
    console.error("Error fetching filtered data:", error);
  });
  }
  
};


const [isOpen,setIsOpen]=useState(true)

    console.log("tablessss",tableData)
    return(
        <Card style={{display:'flex', flexDirection:'row',width:"100%",backgroundColor:'white',minHeight:'100vh'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'77%':'97%', overflow:"auto",marginLeft:isOpen?"22%":'0%',}}>
               <Topbar/>
               <div>
               <h1 style={{fontFamily:'Inter-Medium',fontSize:'1vw',fontWeight:'500',color:'#D32028',marginTop:'10px',marginLeft:'5px'}}>Institutes</h1>

               <Row>
          <div style={{ display: 'flex', flex: 'wrap',marginTop:'10px',flexDirection:'row',justifyContent:'space-between' }}>
            <div>
            <Col sm="12" md="10" lg="3">
            <div style={{ height: '23px', marginRight: '10px',fontFamily:'Inter'  , position: 'relative', display: 'flex', alignItems: 'center',width:'250px' }}>
            <Input
              type="text"
              placeholder="Institute Name"
              onChange={(event) => {
                getInsituteInfo(event.target.value);
                handleInputChange(event);
              }}
              style={{
                 fontSize: '0.8vw',
                paddingLeft: '26px',
                height: '23px',
                fontFamily: 'Inter',
                backgroundImage: `url(${search})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '5px center',
                backgroundSize: '15px 15px',
                paddingRight: '5px', 
                borderColor:'#cccccc',
                borderStyle:'solid',
                borderRadius:"5px",
                borderWidth:'1px',
                height:'34px',
                marginTop:'11px',marginLeft:'3px'
              }}
            />
          </div>
            </Col>
            </div>
            <div>
            <Col >
            <AddInstitute onAddInstitute={handleAddInstitute} />            
            </Col>
            </div>
            
          </div>
        </Row>
        {isLoading ? (
           <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%',backgroundColor:'white',height:'100vh'}}>
           <CircularProgress />
         </Box>
        ):(
          <>
          <div className='tables'  style={{marginTop:'10px'}}>
        <table >
              <thead className='table-heading' style={{width:'100%'}} >
                <tr >
                 
                  <th  style={{  fontSize: '0.9vw',fontFamily:"Inter" ,width:'20em',borderTopLeftRadius:'8px'}} id="insName">
                <span style={{marginLeft:'10px'}}>Brand Name</span> 
                  </th>
                  <th  style={{  fontSize: '0.9vw',fontFamily:"Inter" ,width:'20em'}} id="insName">
                <span style={{marginLeft:'10px'}}>Entity Name</span> 
                  </th>
                  <th  style={{  fontSize: '0.9vw' ,fontFamily:"Inter",width:'5em',paddingLeft:'10px'}} id="coursename">
                  Inst.code
                  </th>
                  <th   style={{  fontSize: '0.9vw',width:'10em',cursor:'pointer',fontFamily:"Inter" }} id="appfromdate">
                  <span style={{marginLeft:'10px'}}></span>  Email
                  </th>
                  <th   style={{  fontSize: '0.9vw',width:'20em',cursor:'pointer',fontFamily:"Inter" }} id="appfromdate">
                  <span style={{marginLeft:'10px'}}></span>    gstAppl.
                  </th>
                  <th   style={{  fontSize: '0.9vw',width:'20em',cursor:'pointer',fontFamily:"Inter" }} id="appfromdate">
                  <span style={{marginLeft:'10px'}}></span>    gstNumber
                  </th>
                  <th   style={{  fontSize: '0.9vw',width:'20em',cursor:'pointer',fontFamily:"Inter",borderTopRightRadius:'8px' }} id="appfromdate">
                  <span style={{marginLeft:'10px'}}></span>   Action
                  </th>
                </tr>
              </thead>
              
              {tableData.map((item,index)=>{
                 const filterInstitute = filterInstituteName.toLowerCase();
                 const currentInstituteName = (item.name || '').toLowerCase();
                 if (currentInstituteName.indexOf(filterInstitute) !== -1)
                return(
                    <tbody className='table-body'>
                    <tr  className='table-row' key={index} style={{ lineHeight: '14px' }}>
                    <td title={item.brandName}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',lineHeight:'1.5em',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',width:'20em'}}>{item?.brandName?.charAt(0)?.toUpperCase() + item?.brandName?.slice(1)?.toLowerCase()}</span></td>
                    <td title={item.entityName}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',lineHeight:'1.5em',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',width:'20em'}}>{item?.entityName?.charAt(0)?.toUpperCase() + item?.entityName?.slice(1)?.toLowerCase()}</span></td>
                    <td title={item.code}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',lineHeight:'1.5em',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',width:'5em'}}>{item.code || '-'}</span></td>
                    <td title={item.email}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',lineHeight:'1.5em',display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',width:'10em'}}>{item.email || '-'}</span></td>
                    <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>{item.gstApplicable ? 'Yes' : 'No'}</td>
                    <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>{item.gstNumber || "-"}</td>
                    <td >
                      <div style={{display:'flex'}}>
                      <span title="Manage Institute" style={{cursor:'pointer' }}>
                        <ManageInstitutes InstituteId={item}/>
                          </span>
                          <span title="Add or edit bank details" style={{  marginLeft:"25px",cursor:'pointer' }}>
                            <AddBank InstituteId={item} />
                          </span>
                      </div>
                    
                    </td>
                    </tr>
                   
                    </tbody>
                )
             
                    })}
              
           
            </table>
        </div>
       
        {totalFetchRows ? ( searchText && totalFetchRows <= 10 ?(
               <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',marginLeft:'10px',marginBottom:'5px' }}>
               Showing {totalFetchRows }-{totalFetchRows}{' '}
               records
             </div>
            ):( <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',marginLeft:'10px',marginBottom:'5px' }}>
            Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage}{' '}
            records
          </div>)
             
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
            )}
            <PaginationTable
              startPage={searchText !== "" ? 1 : currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={totalFetchRows}
              paginate={paginate}
              searchText={searchText}
            />
          </>
        )}
        
               </div>
             </div>
        </Card>
    )
}
export default InstituteTables