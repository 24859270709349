import React from 'react';
 
import './index.css';
import App from './App';
import { createRoot } from 'react-dom/client';

import {BrowserRouter} from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { StyledEngineProvider } from '@mui/material/styles';
const root= createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
  <StyledEngineProvider injectFirst>
  <ThemeProvider theme={theme}> 
    <CssBaseline />
    <App />
  </ThemeProvider>
  </StyledEngineProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
