import { userConstants } from './../constants/user.constants';

let user = sessionStorage.getItem('user');
const initialState = user ? { loggedIn: false, user, isLoading: false } : {};

export function createApplication(state = initialState, action) {
  console.log("inside createApplication function", action)
   switch (action.type) {
    case userConstants.CREATE_APPLICATION_REQUEST:
      return {
        user: action.user,
        isLoading : true

      };
    case userConstants.CREATE_APPLICATION_SUCCESS:
      const {res} = action
        console.log("Action", action)
      return {
        res,
        isLoading : false
      };
    case userConstants.CREATE_APPLICATION_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }

}
