import React from 'react'
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { useState, useEffect  } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axiosInstance from '../../helpers/axios';
import finvuLogo from '../../assets/images/finvulogo.png';
import Onemoneylogo from '../../assets/images/onemoney-logo.png';
import VirtualizedSelect  from 'react-virtualized-select';
import 'react-virtualized-select/styles.css'; 
import reseticon from '../../assets/images/reset.png';
import RefreshIcon from '@mui/icons-material/Refresh';






const ActiveBank = () => {
  const [data, setData] = useState([]);
  const [TableData, setTableData] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortOrder1, setSortOrder2] = useState('asc');
 
  const [resetClicked, setResetClicked] = useState(false);


 
 

  const user = sessionStorage.getItem('user');
  useEffect(() => {
    setIsLoading(true)
    axiosInstance.get('account-aggregator/get-active-banks', {
      headers: {
        Authorization: `Bearer ${user}`,
      }
    })
      .then( (res)=> {
        setData(res?.data?.data?.banks);
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
        
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);

 

  useEffect(() => {
    setIsLoading(true)
    axiosInstance.get('account-aggregator/get-fips', {
      headers: {
        Authorization: `Bearer ${user}`,
      }
    })
      .then( (res)=> {
        setTableData(res?.data?.data?.banks?.fip); 
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);   
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);


  const handleSort = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
  };
  

  const sortedData = [...TableData].sort((a, b) => {
    const nameA = a.name || '';
    const nameB = b.name || ''; 
  
    if (sortOrder === 'asc') {
      return nameA.localeCompare(nameB);
    } else {
      return nameB.localeCompare(nameA);
    }
  });
  
  const handleSort2 = () => {
    setSortOrder2(sortOrder1 === 'asc' ? 'desc' : 'asc');
  };
  const sortData = [...data].sort((a, b) => {
    const nameA = a.bank || ''; 
    const nameB = b.bank || '';
    if (sortOrder1 === 'asc') {
      return nameA.localeCompare(nameB);
    } else {
      return nameB.localeCompare(nameA);
    }
  });


  const [selectedBank, setSelectedBank] = useState('');


  
  const handleDropdownChanged = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setSelectedBank(selectedOption.value);
    }
  };
  const handleReset = () => {
    setSelectedBank(null);
    setResetClicked(true);
    setTimeout(() => {
        setResetClicked(false);
    }, 300); //
  };
 


  const allBanks = [
    ...TableData.filter(item => item.enabled === true).map(item => ({ source: 'Onemoney', name: item.name || '' })),
    ...data.map(item => ({ name: item.bank || '', source: 'Finvu' }))
  ];
  const uniqueBanks = [...new Set(allBanks.map(item => `${item.name}:${item.source}`))];

  const filteredData1 = selectedBank && selectedBank.endsWith('Finvu')
  ? data.filter(item => item.bank === selectedBank.split(':')[0])
  : data;

const filteredData2 = selectedBank && selectedBank.endsWith('Onemoney')
  ? sortedData.filter(item =>  item.name === selectedBank.split(':')[0])
  : sortedData;
  console.log(filteredData2[0],"filterdata")


  
    const [isLoading, setIsLoading] = useState(false);

    const [isOpen,setIsOpen]=useState(true) 

  return (
    <div style={{display:'flex',width:"100%",backgroundColor:'white',minHeight:'100vh'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%',padding:'10px'}}>
        <Topbar/>
        <>
            <style>
                {`
                @keyframes rotate {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }

                .rotate-reset {
                    animation: rotate 0.5s linear;
                }
                `}
            </style>
        <div style={{ width: '40%', display: 'flex',marginTop:'20px' }}>
    <div style={{ position: 'relative', width: '100%' }}>
        <VirtualizedSelect
            placeholder="" 
            onChange={handleDropdownChanged}
            options={uniqueBanks.map((bank) => ({
                label: bank,
                value: bank,
            }))}
            filterOptions={(options, filter) => {
                const inputValue = filter.toLowerCase();
                if (!inputValue) return [];

                const startsWith = [];
                const contains = [];
                
                options.forEach(option => {
                    const label = option.label.toLowerCase();
                    if (label.startsWith(inputValue)) {
                        startsWith.push(option);
                    } else if (label.includes(inputValue)) {
                        contains.push(option);
                    }
                });

                return [...startsWith, ...contains];
            }}
            inputRenderer={(props) => (
                <div style={{ position: 'relative', width: '100%' }}>
                    <input
                        type="text"
                        placeholder="Select a bank"
                        {...props}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            height: '40px',
                            fontSize: '16px',
                            padding: '8px',
                            width: 'calc(100% - 120px)', // Adjusted width to match container
                            boxSizing: 'border-box',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                        }}
                    />
                   <div>
            <button
                onClick={handleReset}
                style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    height:"5px",                  
                    paddingBottom:'30px'

                }}
            >
              
                <img
                    src={reseticon}
                    alt="Reset"
                    className={resetClicked ? 'rotate-reset' : ''}
                    style={{ width: '27px', height: '27px' }}  />
            </button>
        </div>
                </div>
            )}
            menuStyle={{ position: 'absolute', zIndex: 100 }} // Adjust z-index and position as needed
        />
    </div>
</div>
</>


          <div style={{ display: 'flex',justifyContent:'space-between' }}>
                  <div>
                            {isLoading ? (
                            <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%'}}>
                            <CircularProgress />
                          </Box>
                          ): (    <>

                      { <table className='tables'>
                        <thead className='table-heading'>
                        <tr>
                          <th colSpan="3" style={{  fontSize: '1vw',cursor:'pointer',width:'12px%',fontFamily:"Inter-Medium", textAlign: "center"}}>
                          <img src={Onemoneylogo} alt="Finvu Logo" style={{ height: '3.5em', marginRight: '5px', }} /></th>
                          </tr> 
                          <tr>
                            <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '14em', fontFamily: "Inter-Medium", paddingLeft: '10px' }}>Sl.no</th>
                            <th onClick={handleSort} style={{ fontSize: '0.9vw', cursor: 'pointer', width: '14em', fontFamily: "Inter-Medium",paddingLeft: '10px' }}>Banks {sortOrder === 'asc' ? '▲' : '▼'}</th>
                            <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '14em', fontFamily: "Inter-Medium" }}>Status</th>
                          </tr>
                        </thead>
                        <tbody className='table-body'>
                        
                        { selectedBank ? filteredData2?.filter(item => item?.enabled === true).map((item, index) => (
                            <tr className="table-row" key={index} style={{ lineHeight: "25px" }}>
                              <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '14em', fontFamily: "Inter-Medium", color: '#667085' }}>{index + 1}</td>
                              <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '14em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.name ? item?.name : '-'}</td>
                              <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '14em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.consentHealthState? <p title='Paid' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p>: <p title='Failed' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p> }</td>
                            </tr>
                          )):sortedData?.filter(item => item?.enabled === true).map((item, index) => (
                            <tr className="table-row" key={index} style={{ lineHeight: "25px" }}>
                              <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '14em', fontFamily: "Inter-Medium", color: '#667085' }}>{index + 1}</td>
                              <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '14em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.name ? item?.name : '-'}</td>
                              <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '14em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.consentHealthState? <p title='Paid' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p>: <p title='Failed' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p> }</td>
                            </tr>
                          ))}
                        </tbody>
                      </table> }</>)} </div>

                      <div>

                          {isLoading ? (
                            <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%'}}>
                            <CircularProgress />
                          </Box>
                          ): ( <>

                      {<table className='tables' style={{marginRight:'10px'}}>
                        <thead className='table-heading'>
                           <tr>
                          <th colSpan="4" style={{  fontSize: '1vw',cursor:'pointer',width:'12%',fontFamily:"Inter-Medium", textAlign: "center"}}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={finvuLogo} alt="Finvu Logo" style={{ height: '4em', marginRight: '5px', }} />
                          </div>

                          </th>
                          </tr> 
                          <tr>
                            <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '8%', fontFamily: "Inter-Medium", paddingLeft: '10px' }}>Sl.no</th>
                            <th  onClick={handleSort2} style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10%', fontFamily: "Inter-Medium" }}>Banks {sortOrder1 === 'asc' ? '▲' : '▼'}</th>
                            <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10%', fontFamily: "Inter-Medium",paddingLeft: '10px' }}>Finvu status </th>
                            <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10%', fontFamily: "Inter-Medium",paddingLeft: '10px' }}>Finvu issues </th>
                          </tr>
                        </thead>
                        
                        <tbody className='table-body'>
                          { selectedBank ? filteredData1?.map((item, index) => (
                            <tr className="table-row" key={index} style={{ lineHeight:"25px"}}>
                              <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085', paddingLeft: '10px' }}>{index+1}</td>
                              <td  style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.bank ? item?.bank : '-'}</td>
                              <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.health_up? <p title='Paid' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p>: <p title='Failed' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p>}</td>
                              <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.issue_details ? item?.issue_details : '-'}</td>
                            </tr>
                          )):sortData?.map((item, index) => (
                            <tr className="table-row" key={index} style={{ lineHeight:"25px"}}>
                              <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085', paddingLeft: '10px' }}>{index+1}</td>
                              <td  style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.bank ? item?.bank : '-'}</td>
                              <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.health_up? <p title='Paid' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p>: <p title='Failed' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p>}</td>
                              <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item?.issue_details ? item?.issue_details : '-'}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>}</>) } </div>
                  </div>
        </div>  
      </div>  
  )
}



export default ActiveBank;