import React,{useState,useEffect} from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap'
import axiosInstance from '../../helpers/axios'
import edit from '../../assets/images/edit.png'
import './roles.css'

export default function EditRole({updateUser}) {

  const UserType = {
    Admin: 'admin',
    LoanProcessor: 'loan_processor',
    User: 'user',
    InstituteUser: 'institute_user',
    CoLender: 'co_lender',
    Finance:'finance',
    Collection:'collection',
    InstituteAdmin:'institute_admin'
}
  const user=sessionStorage.getItem('user')
  const [openEditRole,setEditRole]=useState(false)
  const [selectRoleOpen,setSelectRoleOpen]=useState(false)
  
  const [selectedRole,setSelectedRole]=useState("Role")
  const [updatedName,setUpdatedName]=useState(null)
  const [updatedMobile,setUpdatedMobile]=useState(null)
  const [updatedEmail,setUpdatedEmail]=useState(null)

  const updateUserInfo='/admin/update-info'


  const handleUpdateInfo=()=>{

        const body={
          userId:updateUser?.userId,
          name:updatedName?updatedName:updateUser?.name,
          mobile:updatedMobile?updatedMobile:updateUser?.mobile,
          emailId:updatedEmail?updatedEmail:updateUser?.emailId,
          role:selectedRole?selectedRole:updateUser?.role,
        }
        axiosInstance.post(updateUserInfo,body,{
          headers:{
            Authorization:`Bearer ${user}`
          }
        })
        .then((res)=>{
          window.alert("Updated Details")
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        })
        .catch((err)=>{
          window.alert("Error While Updating")
        })

    }
  


  return (
    <div>
      <Modal size="md" isOpen={openEditRole} toggle={()=>setEditRole(!openEditRole)}>
        <ModalHeader>Edit Role</ModalHeader>
        <ModalBody>
          <div style={{display:'block',padding:'1em',width:'100%'}}>
            <div style={{display:'flex',justifyContent:'space-evenly',}}>
              <p className="heading">Name</p>
              <input id="name" className="inputClass"  type="text" placeholder="Update Name" defaultValue={updateUser?.name} onChange={(e)=>setUpdatedName(e.target.value)}/>
            </div>
            <div style={{display:'flex',justifyContent:'space-evenly',}}>
              <p className="heading">Mobile</p>
              <input  id="mobile" className="inputClass" type="text" placeholder="Update Number" defaultValue={updateUser?.mobile} onChange={(e)=>setUpdatedMobile(e.target.value)}/>
            </div>
            <div style={{display:'flex',justifyContent:'space-evenly',}}>
              <p className="heading">Email</p>
              <input  id="email" className="inputClass" type="text" placeholder="Update Email" defaultValue={updateUser?.emailId} onChange={(e)=>setUpdatedEmail(e.target.value)}/>
            </div>
            <div style={{display:'flex',justifyContent:'space-evenly'}}>
              <p className="heading">Role</p>
              <Dropdown style={{margin:'10px'}} isOpen={selectRoleOpen} toggle={()=>setSelectRoleOpen(!selectRoleOpen)}>
                <DropdownToggle style={{width:'14em',backgroundColor: '#d32028',border:'none'}} caret >
                  {selectedRole==="loan_processor"?"CRA":selectedRole==="institute_admin"?"Inst. Admin":selectedRole==="institute_user"?"Inst. User":selectedRole.toUpperCase()}
                </DropdownToggle>
                  <DropdownMenu>
                      {updateUser?.role!=="institute_admin"&&updateUser?.role!=="institute_user"?<><DropdownItem onClick={(e)=>setSelectedRole(UserType.Admin)}>
                        Admin
                      </DropdownItem>
                      <DropdownItem onClick={(e)=>setSelectedRole(UserType.Finance)}>
                        Finance
                      </DropdownItem>
                      <DropdownItem onClick={(e)=>setSelectedRole(UserType.LoanProcessor)}>
                        CRA
                      </DropdownItem>
                      <DropdownItem onClick={(e)=>setSelectedRole(UserType.InstituteAdmin)}>
                        Institute Admin
                      </DropdownItem>
                      <DropdownItem onClick={(e)=>setSelectedRole(UserType.Collection)}>
                        Collections
                      </DropdownItem></>:<>
                      <DropdownItem onClick={(e)=>setSelectedRole(UserType.InstituteAdmin)}>
                        Institute Admin
                      </DropdownItem>
                      <DropdownItem onClick={(e)=>setSelectedRole(UserType.InstituteUser)}>
                       Institute User
                      </DropdownItem>
                      </>
                     
                     }
                  </DropdownMenu>
              </Dropdown>
            </div>
            {/* <div style={{display:'flex',justifyContent:'space-evenly',}}>
              <p className="heading">Institute</p>
              <input className="inputClass" type="text" placeholder="Update Institute" defaultValue={updateUser?.emailId} onChange={(e)=>setUpdatedEmail(e.target.value)}/>
            </div> */}
          </div>

        </ModalBody>
        <ModalFooter style={{display:'flex',justifyContent: 'space-between',flexDirection: 'row',}}>
          <Button onClick={()=>setEditRole(false)}>Close</Button>
          <Button style={{border:'none',backgroundColor:'#d32028'}} onClick={()=>handleUpdateInfo()}>Submit</Button>
        </ModalFooter>

      </Modal>

      <img onClick={()=>setEditRole(!openEditRole)} style={{width:'1.5em',height:'1.5em'}} src={edit}/>
    </div>
  )
}
