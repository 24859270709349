import React, { useState } from 'react';
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import axiosInstance from '../../helpers/axios';
import Select from 'react-select';
import LoopIcon from '@mui/icons-material/Loop';



const stateOptions = [
    { value: 'Andhra Pradesh Medical Council', label: 'Andhra Pradesh Medical Council' },
    { value: 'Arunachal Pradesh Medical Council', label: 'Arunachal Pradesh Medical Council' },
    { value: 'Assam Medical Council', label: 'Assam Medical Council' },
    { value: 'Bhopal Medical Council', label: 'Bhopal Medical Council' },
    { value: 'Bihar Medical Council', label: 'Bihar Medical Council' },
    { value: 'Bombay Medical Council', label: 'Bombay Medical Council' },
    { value: 'Chandigarh Medical Council', label: 'Chandigarh Medical Council' },
    { value: 'Chattisgarh Medical Council', label: 'Chattisgarh Medical Council' },
    { value: 'Delhi Medical Council', label: 'Delhi Medical Council' },
    { value: 'Goa Medical Council', label: 'Goa Medical Council' },
    { value: 'Gujarat Medical Council', label: 'Gujarat Medical Council' },
    { value: 'Haryana Medical Council', label: 'Haryana Medical Council' },
    { value: 'Himanchal Pradesh Medical Council', label: 'Himanchal Pradesh Medical Council' },
    { value: 'Hyderabad Medical Council', label: 'Hyderabad Medical Council' },
    { value: 'Jammu & Kashmir Medical Council', label: 'Jammu & Kashmir Medical Council' },
    { value: 'Jharkhand Medical Council', label: 'Jharkhand Medical Council' },
    { value: 'Karnataka Medical Council', label: 'Karnataka Medical Council' },
    { value: 'Madhya Pradesh Medical Council', label: 'Madhya Pradesh Medical Council' },
    { value: 'Madras Medical Council', label: 'Madras Medical Council' },
    { value: 'Mahakoshal Medical Council', label: 'Mahakoshal Medical Council' },
    { value: 'Maharashtra Medical Council', label: 'Maharashtra Medical Council' },
    { value: 'Manipur Medical Council', label: 'Manipur Medical Council' },
    { value: 'Medical Council of India', label: 'Medical Council of India' },
    { value: 'Medical Council of Tanganyika', label: 'Medical Council of Tanganyika' },
    { value: 'Mizoram Medical Council', label: 'Mizoram Medical Council' },
    { value: 'Mysore Medical Council', label: 'Mysore Medical Council' },
    { value: 'Nagaland Medical Council', label: 'Nagaland Medical Council' },
    { value: 'Orissa Council of Medical Registration', label: 'Orissa Council of Medical Registration' },
    { value: 'Pondicherry Medical Council', label: 'Pondicherry Medical Council' },
    { value: 'Punjab Medical Council', label: 'Punjab Medical Council' },
    { value: 'Rajasthan Medical Council', label: 'Rajasthan Medical Council' },
    { value: 'Sikkim Medical Council', label: 'Sikkim Medical Council' },
    { value: 'Tamil Nadu Medical Council', label: 'Tamil Nadu Medical Council' },
    { value: 'Telangana State Medical Council', label: 'Telangana State Medical Council' },
    { value: 'Travancore Cochin Medical Council, Trivandrum', label: 'Travancore Cochin Medical Council, Trivandrum' },
    { value: 'Tripura State Medical Council', label: 'Tripura State Medical Council' },
    { value: 'Uttar Pradesh Medical Council', label: 'Uttar Pradesh Medical Council' },
    { value: 'Uttarakhand Medical Council', label: 'Uttarakhand Medical Council' },
    { value: 'Vidharba Medical Council', label: 'Vidharba Medical Council' },
    { value: 'West Bengal Medical Council', label: 'West Bengal Medical Council' }
  ];
  

export default function MCIsearch() {
  const [isOpen, setIsOpen] = useState(true);

  const [registrationNo, setRegistrationNo] = useState('');
  const [stateMedicalCouncil, setStateMedicalCouncil] = useState('');
  const [qualificationYear, setQualificationYear] = useState('');
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);
 
  
  const user = sessionStorage.getItem('user');

  const handleSearch = () => {
    setIsSubmitted(true); // Mark form as submitted

    // Perform validation
    const registrationNoValid = registrationNo.trim() !== '';
    const stateMedicalCouncilValid = stateMedicalCouncil !== null;
    const qualificationYearValid = qualificationYear.trim() !== '';

    if (!registrationNoValid || !stateMedicalCouncilValid || !qualificationYearValid) {
      return; // Stop further processing if any field is invalid
    }

    setLoading(true);
    const body = {
      registrationNo,
      stateMedicalCouncil: stateMedicalCouncil ? stateMedicalCouncil.value : '',
      qualificationYear
    };
   
  
    axiosInstance.post('/mcir/verify',body, {
      headers: {
        Authorization: `${user}`,
      },
    })
    .then((res) => {
      console.log(res,'responsed',res)
      setResponse(res?.data?.data?.result);
      setError(res?.message);
    })
    .catch((error) => {
      // const errorMsg = error.response?.data || 'Error fetching filtered data';
      // setError(errorMsg);
      // setResponse(null);  // Clear the response state if there's an error
      // console.error("Error fetching filtered data:", error);
      console.log('error',error?.response);
      setError (JSON.parse(error?.response?.request?.response)?.message);
    })
    .finally(() => {
      setLoading(false); // Set loading state to false after request is completed
    });
  };
  
  return (
    <div style={{ display: 'flex', width: "100%", backgroundColor: 'white', height: '130%' }}>
      <Sidebar isOpenSidebar={isOpen} handler={setIsOpen} />
      <div style={{ width: isOpen ? '80%' : '100%', marginLeft: isOpen ? "20%" : '0%', padding: '10px' }}>
        <Topbar />
        <div>MCI search</div>
       
   <div className="container mt-4 p-4 border rounded bg-light">
      <div className="form-group">
        <label>Registration No: <span style={{color:'red'}}>*</span></label>
        <input
          type="text"
          className={`form-control ${isSubmitted && registrationNo.trim() === '' ? 'border-danger' : ''}`}
          value={registrationNo}
          onChange={(e) => setRegistrationNo(e.target.value)}
        />
     
      </div>
      <div className="form-group">
        <label>State Medical Council: <span style={{color:'red'}}>*</span></label>
        <Select
          className={`form-control p-0 ${isSubmitted && stateMedicalCouncil === '' ? 'border-danger' : ''}`}
          value={stateMedicalCouncil}
          onChange={setStateMedicalCouncil}
          options={stateOptions}
          placeholder="Select State Medical Council"
          isClearable
        />
      
      </div>
      <div className="form-group">
        <label>Qualification Year: <span style={{color:'red'}}>*</span></label>
        <input
          type="text"
          className={`form-control ${isSubmitted && qualificationYear.trim() === '' ? 'border-danger' : ''}`}
          value={qualificationYear}
          onChange={(e) => setQualificationYear(e.target.value)}
        />
       
      </div>
      <button className="btn btn-primary" onClick={handleSearch} disabled={loading}>
      {loading ? (
              <LoopIcon
                type="ThreeDots"
                color="#ffffff"
                height={10}
                width={20}
              />
            ) : (
              'Search'
            )}
      </button> 
      {response && (
        <div className="mt-4">
          <h2>Response:</h2>
          <pre>name: {response.name}</pre>
          <pre>qualificationYear: {response.dateOfReg}</pre>
          <pre>permanentAddress: {response.permanentAddress}</pre>
        </div>
      )} 
      {error && (
        <div className="mt-4">
          <h2>Error:</h2>
          <pre>{error}</pre>
        </div>
      )}
    </div>
      </div>
    </div>
  );
}
