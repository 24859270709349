import React from 'react'
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { useState, useEffect  } from 'react';
import axiosInstance from '../../helpers/axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


 const MonthlyPAR=()=>{
  const user = sessionStorage.getItem('user');
  const [monthlyPar, setMonthlyPar] = useState({ parCount: [], parAmount: [] });
  const [isLoading, setIsLoading] = useState(false); 


  useEffect(() => {
    setIsLoading(true)
    axiosInstance.get('/analytics/par-reports', {
      headers: {
        Authorization: `Bearer ${user}`,
      }
    })
      .then(function (res) {
        setMonthlyPar(res?.data?.data);
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
        
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);
 
  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN'); 
  }


  const [isOpen,setIsOpen]=useState(true) 
  return (
    <div style={{display:'flex',width:"100%",backgroundColor:'white',minHeight:'100vh'}}>
    <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
    <div style={{width:isOpen?'80%':'100%',marginLeft:isOpen?"20%":'0%',padding:'10px'}}>
    <Topbar/>
    <p style={{textAlign:"center",fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px'}}>Monthly Par Count</p>
  {isLoading ? (
                  <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%'}}>
                  <CircularProgress />
                </Box>
                ): (
                  <>
                  <div className='tables' style={{ marginTop: '20px' }}> 
                  <table  >
                      <thead className='table-heading'>
                      {/* <tr>
                             <th colSpan="1" style={{  fontSize: '1vw',cursor:'pointer',width:'12%',fontFamily:"Inter-Medium"}}></th>
                             <th colSpan="3" style={{  fontSize: '1vw',cursor:'pointer',width:'12%',fontFamily:"Inter-Medium"}}>Demand /Due (Principal)</th>
                              <th colSpan="4" style={{  fontSize: '1vw',cursor:'pointer',width:'12%',fontFamily:"Inter-Medium"}}>Collection (Principal)</th>
                          </tr> */}
                          <tr>
                              <th style={{  fontSize: '0.9vw',width:'8%',fontFamily:"Inter",paddingLeft:'10px'}}>Month</th>
                              <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>Current</th>
                              <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>S1(1-30)</th>
                              <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>S2(31-60)</th>
                              <th style={{  fontSize: '0.9vw',width:'11%',fontFamily:"Inter"}}>S3(61-90)</th>
                              <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>M1(91-120)</th>
                              <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>M2(121-180)</th>
                              <th style={{  fontSize: '0.9vw',width:'11%',fontFamily:"Inter"}}>X(Above-181)</th>
                              <th style={{  fontSize: '0.9vw',width:'11%',fontFamily:"Inter"}}>Total Portfolio</th>
                            
                          </tr>
                      </thead>
                      <tbody className='table-body'>
                      { monthlyPar?.parLoanCount?.slice(0,12)?.map((item, index) =>(
                      <tr key={index} className="table-row" style={{lineHeight:'1.3rem'}}>
                      <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.month_start_date?item.month_start_date:'-'}</td>
                      <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{
                            (item.total_loan_count ? item.total_loan_count : 0) -
                            (item.s1_1_to_30_days ? item.s1_1_to_30_days : 0) -
                            (item.s2_31_to_60_days ? item.s2_31_to_60_days : 0) -
                            (item.s3_61_to_90_days ? item.s3_61_to_90_days : 0) -
                            (item.m1_91_to_120_days ? item.m1_91_to_120_days : 0) -
                            (item.m2_121_to_180_days ? item.m2_121_to_180_days : 0) -
                            (item.X_Above_181 ? item.X_Above_181 : 0)
                          }
                          </td>
                      <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.s1_1_to_30_days ? item.s1_1_to_30_days : "0"}</td>
                      <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.s2_31_to_60_days ? item.s2_31_to_60_days : "0"}</td>
                      <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.s3_61_to_90_days ? item.s3_61_to_90_days : "0"}</td>
                      <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.m1_91_to_120_days ? item.m1_91_to_120_days : "0"}</td>
                      <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.m2_121_to_180_days ? item.m2_121_to_180_days : '0'}</td>
                      <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.X_Above_181 ? item.X_Above_181 : '0'}</td>
                      <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.total_loan_count ? item.total_loan_count : '0'}</td>
                     </tr>
              )) 
          }
          
          </tbody>
          
                  </table>
             
                  </div>
                  <p style={{textAlign:"center",fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px'}}>Monthly Par Amount</p>
             
             <div className='tables' style={{ marginTop: '20px' }}>
               
                 <table  >
                     <thead className='table-heading'>
                     {/* <tr>
                            <th colSpan="1" style={{  fontSize: '1vw',cursor:'pointer',width:'12%',fontFamily:"Inter-Medium"}}></th>
                            <th colSpan="3" style={{  fontSize: '1vw',cursor:'pointer',width:'12%',fontFamily:"Inter-Medium"}}>Demand /Due (Principal)</th>
                             <th colSpan="4" style={{  fontSize: '1vw',cursor:'pointer',width:'12%',fontFamily:"Inter-Medium"}}>Collection (Principal)</th>
                         </tr> */}
                         <tr>
                             <th style={{  fontSize: '0.9vw',width:'8%',fontFamily:"Inter",paddingLeft:'10px'}}>Month</th>
                             <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>Current</th>
                             <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>S1(1-30)</th>
                             <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>S2(31-60)</th>
                             <th style={{  fontSize: '0.9vw',width:'11%',fontFamily:"Inter"}}>S3(61-90)</th>
                             <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>M1(91-120)</th>
                             <th style={{  fontSize: '0.9vw',width:'10%',fontFamily:"Inter"}}>M2(121-180)</th>
                             <th style={{  fontSize: '0.9vw',width:'11%',fontFamily:"Inter"}}>X(Above-181)</th>
                             <th style={{  fontSize: '0.9vw',width:'11%',fontFamily:"Inter"}}>Total Portfolio</th>
                           
                         </tr>
                     </thead>
                     <tbody className='table-body'>
                     { monthlyPar?.parAmount?.slice(0,12).map((item, index) =>(
                     <tr key={index} className="table-row" style={{lineHeight:'1.3rem'}}>
                     <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.month_start_date?item.month_start_date:'-'}</td>
                     <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>
                           ₹{formatNumberWithCommas(
                            Math.round(item.total_loan_amount || 0) -
                            Math.round(item.s1_1_to_30_days || 0) -
                            Math.round(item.s2_31_to_60_days || 0) -
                            Math.round(item.s3_61_to_90_days || 0) -
                            Math.round (item.m1_91_to_120_days || 0) -
                            Math.round (item.m2_121_to_180_days || 0) -
                            Math.round(item.X_Above_181 || 0)
                          )}
                     </td>
                     <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item?.s1_1_to_30_days ? (<>₹{formatNumberWithCommas(item.s1_1_to_30_days)}</>) : "-"}</td>
                     <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item?.s2_31_to_60_days ? (<>₹{formatNumberWithCommas(item.s2_31_to_60_days)}</>) : "-"}</td>
                     <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.s3_61_to_90_days ?(<>₹{formatNumberWithCommas(item.s3_61_to_90_days)}</>) : "-"}</td>
                     <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.m1_91_to_120_days ? (<>₹{formatNumberWithCommas(item.m1_91_to_120_days)}</>) : "-"}</td>
                     <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.m2_121_to_180_days ? (<>₹{formatNumberWithCommas(item.m2_121_to_180_days)}</>) : '-'}</td>
                     <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.X_Above_181 ? (<>₹{formatNumberWithCommas(item.X_Above_181)}</>) : '-'}</td>
                     <td style={{ fontSize: '0.9vw',  width: '10em', fontFamily: "Inter", color: '#667085' }}>{item.total_loan_amount ? ( <>₹{formatNumberWithCommas(Math.round(item.total_loan_amount))}</>) : '-'}</td>
                    </tr>
             )) 
          }
          
             </tbody>
          
                 </table>
           
                 </div>
                  </>
                )}
    

 
    </div>
   </div>
  )
}

export default MonthlyPAR
