import React,{useState} from 'react'
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button
} from 'reactstrap'
import jwt_decode from 'jwt-decode'
import axiosInstance from '../../helpers/axios'

export default function AddMembers() {

  const UserType = {
    Admin: 'admin',
    LoanProcessor: 'loan_processor',
    User: 'user',
    InstituteUser: 'institute_user',
    CoLender: 'co_lender',
    Finance:'finance',
    Collection:'collection',
    InstituteAdmin:'institute_admin'
}
  const user=sessionStorage.getItem('user')
  const decode=jwt_decode(user)
  const [openRoles,setOpenRoles]=useState(false)
  const [selectedRole,setSelectedRole]=useState("Select Role")
  const [newName,setNewName]=useState(null)
  const [newEmail,setNewEmail]=useState(null)
  const [newMobile,setNewPhone]=useState(null)
  // const [new,setNewEmail]=useState(null)

  //get institute

    const [instituteList,setInstituteList]=useState(null)
    const [selectedInstitute,setSelectedInstituteName]=useState(null)
    const [selectedInstituteId,setSelectedInstituteId]=useState(null)


  const getInstituteInfo=(text)=>{
  const instituteInfoUrl=`${process.env.REACT_APP_BASE_URL}/institute/info/name?name=${text}`;
  if(text.length>=4)
  {
    axiosInstance.get(instituteInfoUrl,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res)=>{
      if(res.data.data==[])
      {
        setInstituteList(null)
        
      }
      else
      setInstituteList(res.data.data)

    })
  }
  else{
    setInstituteList(null)
   
  }
}



    
  
    const handleSearchInstituteDetails=(data)=>{
  
       // setCurrentInstituteId(data.id)
        document.getElementById('instituteNameField').value=data.brandName
        setInstituteList(null);
        setSelectedInstituteName(data.brandName)
        setSelectedInstituteId(data.id)
        
       
}

  const randomPassword=()=>{

    let randArr=[0,1,2,3,4,5,6,7,8,9,'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']

    let randPassword=''
    for(let i=0;i<8;i++)
    {
      randPassword+=randArr[Math.floor(Math.random()*randArr.length)]
    }
    return randPassword
  }

  const validateEmail = email => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  const [submitButton,setSubmitButton]=useState(false)
  const handleAddNewRole = () => {
    if (!newName || !newEmail || !newMobile) {
      window.alert("All fields (Name, Email, Phone) are required.");
      setSubmitButton(false);
      return;
    }
    if (selectedRole === "institute_user" && !selectedInstituteId) {
      window.alert("Please select an institute.");
      setSubmitButton(false);
      return;
    }
    setSubmitButton(true);
    if (validateEmail(newEmail)) {
      const urlAddNewRole = '/admin/create-user';
      const urlAddNewInstituteUser = '/admin/create-institute-user';
      const body = {
        name: newName,
        mobile: newMobile,
        emailId: newEmail,
        password: randomPassword(),
        role: selectedRole,
        instituteId: selectedInstituteId
          ? selectedInstituteId
          : decode?.role === "institute_admin"
          ? decode?.instituteId
          : " ",
      };
  
      if (decode?.role === "institute_admin") {
        axiosInstance
          .post(urlAddNewInstituteUser, body, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          })
          .then((res) => {
            window.alert(
              "Added Successfully \n Email is sent to the user for login instructions."
            );
            setSubmitButton(false);
          })
          .catch((err) => {
            console.log(err);
            window.alert(err?.response?.data?.message);
            setSubmitButton(false);
          });
      } else {
        axiosInstance
          .post(urlAddNewRole, body, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          })
          .then((res) => {
            window.alert(
              "Added Successfully \n Email is sent to the user for login instructions."
            );
            setSubmitButton(false);
          })
          .catch((err) => {
            window.alert(`${err?.response?.data?.message}`);
            setSubmitButton(false);
          });
      }
    } else {
      window.alert("Enter valid email!");
      setSubmitButton(false);
    }
  };
  

  return (
    <div style={{display:'block',margin:'3%',height:'100%'}}>
      <Dropdown isOpen={openRoles} toggle={()=>setOpenRoles(!openRoles)}>
        <DropdownToggle caret style={{backgroundColor: '#d32028',border:'none'}} >
           {selectedRole==="loan_processor"?"CRA":selectedRole==="institute_admin"?"Inst. Admin":selectedRole==="institute_user"?"Inst. User":selectedRole.toUpperCase()} 
        </DropdownToggle>{selectedRole!=="Select Role"?" ":<small> <i>Select a role to begin with</i></small>}
        <DropdownMenu>
          {decode?.role!=="institute_admin"&&decode?.role!=="institute_user"?<><DropdownItem onClick={(e)=>setSelectedRole(UserType.Admin)}>
                        Feemonk Admin
                      </DropdownItem>
                      <DropdownItem onClick={(e)=>setSelectedRole(UserType.Finance)}>
                        Finance
                      </DropdownItem>
                      <DropdownItem onClick={(e)=>setSelectedRole(UserType.LoanProcessor)}>
                        CRA
                      </DropdownItem>
                      <DropdownItem onClick={(e)=>setSelectedRole(UserType.InstituteUser)}>
                        Institute Admin
                      </DropdownItem>
                      <DropdownItem onClick={(e)=>setSelectedRole(UserType.Collection)}>
                        Collections
                      </DropdownItem></>
                      :decode?.role==="institute_admin"?<>
                      <DropdownItem onClick={(e)=>setSelectedRole(UserType.InstituteUser)}>
                       Institute User
                      </DropdownItem>
                      </>
                      :""
}
        </DropdownMenu>
      </Dropdown>
    
    
       { selectedRole!=="institute_user"&&selectedRole!=="Select Role"?
       <div style={{display:'block',padding:'1em',justifyContent:'space-between',backgroundColor:'#F8F8F8',boxShadow:'0px 0px 6px 0px rgb(0,0,0,0.3)',marginTop:'1em',borderRadius:'10px'}}>
        <h3 style={{textAlign:'center',}}>Add <strong style={{textDecoration:'underline'}}> {selectedRole==="loan_processor"?"CRA":selectedRole==="institute_admin"?"Inst. Admin":selectedRole==="institute_user"?"Inst. User":selectedRole.toUpperCase()}</strong> User</h3>
        <div style={{display:'flex',justifyContent:'space-evenly',margin:'0.5em',}}>
          <p style={{fontWeight:'bold',fontFamily:'Outfit'}}>Name</p>
          <input style={{borderRadius:'10px',border:'1px solid #DbDbDb',paddingLeft:'10px'}}  type="text" onChange={(e)=>setNewName(e.target.value)}/>
        </div>
        <div style={{display:'flex',justifyContent:'space-evenly',margin:'0.5em'}}>
          <p style={{fontWeight:'bold',fontFamily:'Outfit'}}>Email</p>
          <input style={{borderRadius:'10px',border:'1px solid #DbDbDb',paddingLeft:'10px'}} type="text" onChange={(e)=>setNewEmail(e.target.value)}/>
        </div>
        <div style={{display:'flex',justifyContent:'space-evenly',margin:'0.5em'}}>
          <p style={{fontWeight:'bold',fontFamily:'Outfit'}}>Phone</p>
          <input min="0" style={{borderRadius:'10px',border:'1px solid #DbDbDb',paddingLeft:'10px'}} type="number" onChange={(e)=>setNewPhone(e.target.value)}/>
        </div>
      <div style={{display:'flex',justifyContent:'center',marginTop:'2em'}}>
      <Button disabled={submitButton} onClick={()=>handleAddNewRole()} style={{backgroundColor:'#D32028',border:'none'}}>Submit</Button>
      </div>
        </div>
        :
        selectedRole!=="Select Role"&&selectedRole==="institute_user"?<div style={{display:'block',padding:'1em',justifyContent:'space-between',backgroundColor:'#F8F8F8',boxShadow:'0px 0px 6px 0px rgb(0,0,0,0.3)',marginTop:'1em',borderRadius:'10px'}}>
        <h3 style={{textAlign:'center',}}>Add <strong style={{textDecoration:'underline'}}> {selectedRole==="loan_processor"?"CRA":selectedRole==="institute_admin"?"Inst. Admin":selectedRole==="institute_user"?"Inst. User":selectedRole.toUpperCase()}</strong> User</h3>
        <div style={{display:'flex',justifyContent:'space-evenly',margin:'0.5em',}}>
          <p style={{fontWeight:'bold',fontFamily:'Outfit'}}>Institute</p>
          <div style={{display:"block"}}>
          <input id="instituteNameField" style={{borderRadius:'10px',border:'1px solid #DbDbDb',padding:'4px'}}  type="text" onChange={(e)=>getInstituteInfo(e.target.value)}/>
          <div style={{marginTop:'0.2em',position: 'absolute',zIndex:'999',backgroundColor: '#fff',width:'14em',borderRadius:'10px',border:'1px solid aliceBlue',}}>
           {
              instituteList?instituteList.map((item)=>{
              return(
                <div onClick={()=>handleSearchInstituteDetails(item)} className="instituteName" style={{cursor:'pointer',border:'1px solid #DbDbDB',borderRadius:'0.5em'}}>
                <p  style={{fontWeight:'600',paddingTop:'0.5em',paddingLeft:'0.5em'}}>{item.brandName}</p>
                </div>
              )
              }
              ):null

          }
         </div>
        </div>
        </div>
        <div style={{border:'1px dashed #000',width:'60%',marginLeft:'20%',boxShadow:'0px 0px 6px 0px rgb(0,0,0,0.4)'}}>

        </div>
        <div style={{display:'flex',justifyContent:'space-evenly',margin:'0.5em',marginTop:'2em'}}>
          <p style={{fontWeight:'bold',fontFamily:'Outfit'}}>Name</p>
          <input style={{borderRadius:'10px',border:'1px solid #DbDbDb',paddingLeft:'10px'}}  type="text" onChange={(e)=>setNewName(e.target.value)}/>
        </div>
        <div style={{display:'flex',justifyContent:'space-evenly',margin:'0.5em'}}>
          <p style={{fontWeight:'bold',fontFamily:'Outfit'}}>Email</p>
          <input style={{borderRadius:'10px',border:'1px solid #DbDbDb',paddingLeft:'10px'}} type="text" onChange={(e)=>setNewEmail(e.target.value)}/>
        </div>
        <div style={{display:'flex',justifyContent:'space-evenly',margin:'0.5em'}}>
          <p style={{fontWeight:'bold',fontFamily:'Outfit'}}>Phone</p>
          <input min="0" style={{borderRadius:'10px',border:'1px solid #DbDbDb',paddingLeft:'10px'}} type="number" onChange={(e)=>setNewPhone(e.target.value)}/>
        </div>
      <div style={{display:'flex',justifyContent:'center',marginTop:'2em'}}>
      <Button disabled={submitButton}  onClick={()=>handleAddNewRole()} style={{backgroundColor:'#D32028',border:'none'}}>Submit</Button>
      </div>
        </div>:" "}
    </div>
  )
}

