import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Alert,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Spinner,
} from 'reactstrap';

import jwt_decode from 'jwt-decode'
import Datetime from 'react-datetime';
import close from "../../../assets/images/close.png"
import action3 from "../../../assets/images/action3.png"
import { userActions } from '../../../actions/user.actions';
import Switch from 'react-bootstrap-switch';
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'

//import "../../assets/scss/_variables.scss"

export default function MoveProcessingStatus(ApplicantId) {
  //to refresh token
  const ApplicationStatusEnum = {
    InSubmission: 'In Submission',
    InReview: 'In Review',
    InSanction: 'In Sanction',
    InDisbursement: 'In Disbursement',
    Disbursed: 'Disbursed',
    Rejected: 'Rejected',
    Dropped: 'Dropped',
    OnHold:'On Hold'
  };
  const states=[
  "Select State",
  "JAMMU & KASHMIR",
  "HIMACHAL PRADESH",
  "PUNJAB",
  "CHANDIGARH",
  "UTTARANCHAL",
  "HARYANA",
  "DELHI",
  "RAJASTHAN",
  "UTTAR PRADESH",
  "BIHAR",
  "SIKKIM",
  "ARUNACHAL PRADESH",
  "NAGALAND",
  "MANIPUR",
  "MIZORAM",
  "TRIPURA",
  "MEGHALAYA",
  "ASSAM",
  "WEST BENGAL",
  "JHARKHAND",
  "ORISSA",
  "CHATTISGARH",
  "MADHYA PRADESH",
  "GUJARAT",
  "DAMAN & DIU",
  "DADRA & NAGAR HAVELI",
  "MAHARASHTRA",
  "ANDHRA PRADESH",
  "KARNATAKA",
  "GOA",
  "LAKSHADWEEP",
  "KERALA",
  "TAMIL NADU",
  "PONDICHERRY",
  "ANDAMAN AND NICOBAR ISLANDS",
  "TELANGANA",
  "LADAKH",
  "APO Address",
]
 const user = sessionStorage.getItem("user")
const decode=jwt_decode(user)


  
  const updateDroppedStatus = `/admin/application/update-status`;

  const urlApproveApiProd = `${process.env.REACT_APP_BASE_URL}/products/instituteId?instituteId=${ApplicantId.ApplicantId.instituteId}`;
  const urlApproveApiFund = `${process.env.REACT_APP_BASE_URL}/fund_source`;

  const [formStatusOpen, setStatusOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [productDropDownOpen, setProductDropDownOpen] = useState(false);
  const [fundDropDownOpen, setFundDropDownOpen] = useState(false);
  const [descriptionText, setDescriptionText] = useState('');
  const [status, setStatus] = useState(' Select Status');
  const [product, setProduct] = useState([]);
  const [prodArray, setProdArray] = useState([]);
  const [productName, setProductName] = useState('Product');
  const [fundSourceName, setFundSourceName] = useState('Fund Source');
  const [fundSource, setFundSource] = useState([]);
  const [fundSourceArray, setFundSourceArray] = useState([]);
  const [prodLoanAmount, setLoanAmount] = useState(null);
  const [prodEmi, setEMI] = useState(null);
  const [dateEmi, setEmiDate] = useState(null);
  const [dayEmi, setEmiDay] = useState(null);
  const [form, setForm] = useState(prodArray ? prodArray : null);
  const [formLoader, setFormLoader] = useState(false);
  const [coAppNumber, setCoAppNumber] = useState(false);
  const [currUserId, setUserId] = useState('');
  const [currApplicantId, setApplicantId] = useState('');
  const [coAppFName, setCoAppFName] = useState('');

  const [coAppEmail, setCoAppEmail] = useState('');
  const [relationshipStatus, setRelationshipStatus] = useState('Select Relation with Applicant');
  const [relationshipStatusDropdown, setRelationshipStatusDropdown] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('Hold On...');
  const [coAppAadhaar, setCoAppAadhaar] = useState(false);
  const [coAppAddress, setCoAppAddress] = useState(null);
  const [coAppDob, setCoAppDob] = useState(null);
  const [coAppCity, setCoAppCity] = useState("");
  const [coAppState, setCoAppState] = useState("");
  const [coAppPincode, setCoAppPincode] = useState("");
  const [coAppPAN, setCoAppPAN] = useState(false);
  const [aadhaarBackUpload, setAadhaarBackUpload] = useState(null);
  const [aadhaarFrontUpload, setAadhaarFrontUpload] = useState(null);
  const [panUpload, setPanUpload] = useState(null);
  const [aadhaarBackS3, setAadhaarBackS3] = useState(null);
  const [aadhaarFrontS3, setAadhaarFrontS3] = useState(null);
  const [panS3, setPanS3] = useState(null);
  const [alertMessage,setAlertMessage]=useState(null)
  const [runBothEmandate,setRunBothEmandateCheck]=useState(false)
  const [runEmandate,setRunEmandateCheck]=useState(false)
  const [mandateCheckOpen,setMandateCheckOpen]=useState(false)
  const [bothMandateCheckOpen,setBothMandateCheckOpen]=useState(false)
  const [flagForMainApplicant,setFlagForMainApplicant]=useState(false)
  
  const dispatch = useDispatch()
  const droppedstate = useSelector((state) => state.Droppedstatuschange?.res)
  const ruleStatus = ApplicantId.ApplicantId.ruleStatus

  const [ocrFormData,setOCRFormData]=useState(null);
const[ocrReadDone,setOCRReadDone]=useState(false)
const[ocrDetails,setOCRDetails]=useState(null)


const [ocrAadhaarFront,setOCRAadhaarFront]=useState(null)
const [ocrAadhaarBack,setOCRAadhaarBack]=useState(null)
const [ocrPan,setOCRPan]=useState(null)

const readOcr=()=>{
  console.log(ocrAadhaarBack,"OCR back")
  const readOCRUrl=`${process.env.REACT_APP_BASE_URL}/ocr/read`
  const data=new FormData();
  if(ocrAadhaarFront)
    data.append("aadhaarFront",ocrAadhaarFront);
  if(ocrAadhaarBack)
    data.append("aadhaarBack",ocrAadhaarBack);
  if(ocrPan)
    data.append("pan",ocrPan);

  axios.post(readOCRUrl,data)
  .then((res=>{
    
    axios.get(`${process.env.REACT_APP_BASE_URL}/ocr/data?id=${res.data.data.id}`)
    .then((resp)=>{
      setOCRReadDone(!ocrReadDone)
      setOCRFormData(resp.data.data)
    })
    .catch((err)=>alert("Fail to read OCR details"))
    setOCRDetails(res.data.data.id)
  }))
  .catch((err)=>alert("OCR read Failed!"));
}

const previewOcrAadhaarFront=(e)=>{
  setOCRAadhaarFront(e.target.files[0])
  const output = document.getElementById('ocrAadhaarFOutput');
    output.src = URL.createObjectURL(e.target.files[0]);
    output.onload = function() {
      URL.revokeObjectURL(output.src) // free memory
    }
  }
const previewOcrAadhaarBack=(e)=>{
  setOCRAadhaarBack(e.target.files[0])
  const output = document.getElementById('ocrAadhaarBOutput');
    output.src = URL.createObjectURL(e.target.files[0]);
    output.onload = function() {
      URL.revokeObjectURL(output.src) // free memory
    }
  }
const previewOcrPan=(e)=>{
  setOCRPan(e.target.files[0])
  const output = document.getElementById('ocrPanOutput');
    output.src = URL.createObjectURL(e.target.files[0]);
    output.onload = function() {
      URL.revokeObjectURL(output.src) // free memory
    }
  }
const handleMainApplicantSelectApplicant=()=>{
  document.getElementById("applicantCheck").checked=true
  document.getElementById("coapplicantCheck").checked=false
  setFlagForMainApplicant(document.getElementById("coapplicantCheck").checked)
  

    
}
const handleMainApplicantSelectCoApplicant=()=>{
   document.getElementById("applicantCheck").checked=false
  document.getElementById("coapplicantCheck").checked=true
  setFlagForMainApplicant(document.getElementById("coapplicantCheck").checked)

}

const handlemandateCheckbox=(value)=>{
     
  setRunEmandateCheck(value)
  if(value)
  setMandateCheckOpen(!mandateCheckOpen)
}
const handleRunEmandateBox=(value)=>{
    document.getElementById("runEmandate").checked=value
    document.getElementById("runBothEmandate").checked=false
 handlemandateCheckbox(value)
    
}
const handleBothMandateCheckbox=(value)=>{
  setRunBothEmandateCheck(value)
  if(value)
  setBothMandateCheckOpen(!bothMandateCheckOpen)
}
const handleRunBothEmandateBox=(value)=>{
    document.getElementById("runEmandate").checked=false
    document.getElementById("runBothEmandate").checked=value

   handleBothMandateCheckbox(value)
}



//get Emi
  const [backendPan,setBackendPan]=useState(null)
  const [backendAadhaarFront,setBackendAadhaarFront]=useState(null)
  const [backendAadhaarBack,setBackendAadhaarBack]=useState(null)
  const [emiData,setEmiData]=useState(null)
  const updateStatusUrl = `${process.env.REACT_APP_BASE_URL}/admin/application/${status==="Approve"?"approve":"assign-fund-source"}`;
  const AddCoApplicantUrl = `${process.env.REACT_APP_BASE_URL}/admin/application/add-coapplicant`;

  const popUpStatusForm = () => {
    setStatusOpen(true);
  };


  //get string validation
  const getStringContain=(str)=>{
    return (/\d/.test(str))
  }
  //get email validation
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };



//get s3 link of uploaded files
const handleAadhaarFrontFileUpload = (item) => {
    
    const data = new FormData();
    data.append('file', item);
    data.append('type', "aadhaar_front");

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/file/upload`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(
        (response) => {
         setBackendAadhaarFront(response.data.data.s3Url)
         document.getElementById("aadhaarFrontUploadButton").className="successFront"
         document.getElementById("aadhaarFrontUploadButton").innerText="Success"
        },
        (error) => {
          alert('Check Mandatory Fields and File format');
        },
      );
  };
const handleAadhaarBackFileUpload = (item) => {

    const data = new FormData();
    data.append('file', item);
    data.append('type', "aadhaar_back");

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/file/upload`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(
        (response) => {
         setBackendAadhaarBack(response.data.data.s3Url)
         document.getElementById("aadhaarBackUploadButton").className="successFront"
         document.getElementById("aadhaarBackUploadButton").innerText="Success"
        },
        (error) => {
          alert('Check Mandatory Fields and File format');
        },
      );
  };
const handlePanFileUpload = (item) => {

    const data = new FormData();
    data.append('file', item);
    data.append('type', "pan");

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/file/upload`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(
        (response) => {
         setBackendPan(response.data.data.s3Url)
         document.getElementById("PanUploadButton").className="successFront"
         document.getElementById("PanUploadButton").innerText="Success"
        },
        (error) => {
          alert('Check Mandatory Fields and File format');
        },
      );
  };

const [cibilcheck,setCibilCheck]=useState(false)
 
  const handleStatusChange = () => {
    if (status === 'Status') {
      alert('NO STATUS CHANGE !');
    } else if (status === 'Approve') {
      setFormLoader(!formLoader);
      setStatusOpen(!formStatusOpen);
      setConfirmationDialog(!confirmationDialog);
      const statusData = {
        applicationId: ApplicantId.ApplicantId.applicationId,
        productId: form.productId,
        loanAmount: prodLoanAmount,
        loanTenure: form.tenure,
        emi: prodEmi?prodEmi:emiData,
        emiFirstDate: dateEmi,
        dayOfEmi: dayEmi,
      };

      axios
        .post(`${updateStatusUrl}`, statusData, {
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-type': 'application/json',
          },
        })
        .then(
          (res) => {
            if (res.data.message === 'Successful') {
              setConfirmationMessage(`Approved Successfully`);
              setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
              setTimeout(() => setConfirmationDialog(false), 2000);
              setTimeout(() => window.location.reload(true), 500);
            }
          },
          (error) => {
            alert(' Unsuccessful!!! Check Fields/Format');
             setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
              setTimeout(() => setConfirmationDialog(false), 2000);
              setTimeout(() => window.location.reload(true), 1000);
          },
        );
        
    }else if(status==='Assign Fund Source')
    {
      const statusData = {
        applicationId: ApplicantId.ApplicantId.applicationId,
        fundCode: fundSourceName,
        productId: form.productId,
        loanAmount: prodLoanAmount,
        loanTenure: form.tenure,
        emi: prodEmi?prodEmi:emiData,
        emiFirstDate: dateEmi,
        dayOfEmi: dayEmi,
        rulesStatus:ruleStatus
      };

      axios
        .post(`${updateStatusUrl}`, statusData, {
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-type': 'application/json',
          },
        })
        .then(
          (res) => {
            if (res.data.message === 'Successful') {
              setConfirmationMessage(`Assigned Fund Source Successfully`);
              setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
              setTimeout(() => setConfirmationDialog(false), 2000);
              setTimeout(() => window.location.reload(true), 500);
            }
          },
          (error) => {
            alert(' Unsuccessful!!! Check Fields/Format');
             setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
              setTimeout(() => setConfirmationDialog(false), 2000);
              setTimeout(() => window.location.reload(true), 1000);
          },
        );
    } else if (status === 'Dropped') {
      
      const droppedData = {
        userId: ApplicantId.ApplicantId.userId,
        applicationId: ApplicantId.ApplicantId.applicationId,
        status: ApplicationStatusEnum.Dropped,
        notes: descriptionText,
          };
          if(droppedstate){
            if (droppedstate.message === 'Successful') {
              setFormLoader(!formLoader);
              setStatusOpen(!formStatusOpen);
              setConfirmationDialog(!confirmationDialog);
              setConfirmationMessage('Dropped Successfully');
              setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
              setTimeout(() => setConfirmationDialog(false), 2000);
              setTimeout(() => window.location.reload(true), 500);
            }else{
              setConfirmationMessage("error");
              setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
                setTimeout(() => setConfirmationDialog(false), 2750);
            }
          }else{
            dispatch(userActions.fetchDroppedstatuschange(updateDroppedStatus,droppedData, user),[])
          }
     
    } else if (status === 'Add Co-Applicant') {

      
          // setConfirmationDialog(!confirmationDialog);
        if(document.getElementById('coAppName').value.length>0&&document.getElementById('coAppDob').value.length>0&&document.getElementById('coAppNumber').value.length===10&&document.getElementById('coAppZip').value.length>0&&document.getElementById('coAppEmail').value)
        {
        setFormLoader(!formLoader);
        setStatusOpen(!formStatusOpen);
        setConfirmationDialog(!confirmationDialog);
        const statusData = {
          mandatePrimary: runEmandate?true:false,
            checkCibil: cibilcheck?true:false,
            aadhaarFront:backendAadhaarFront?backendAadhaarFront:ocrFormData?.aadhaarImage1,
            dateOfBirth:coAppDob?moment(coAppDob).format('YYYY-MM-DD'):ocrFormData?.dobPerAadhaar?moment(ocrFormData?.dobPerAadhaar).format('YYYY-MM-DD'):moment(ocrFormData?.dobPerPan).format('YYYY-MM-DD'),
            currentCity:coAppCity?coAppCity:ocrFormData?.city,
            currentState:coAppState?coAppState:ocrFormData?.state,
            currentPincode:coAppPincode?coAppPincode:ocrFormData?.pincode,
            aadhaarBack:backendAadhaarBack?backendAadhaarBack:ocrFormData.aadhaarImage2?ocrFormData.aadhaarImage2:null,
            panImage:backendPan?backendPan:ocrFormData?.panImage,
            address:coAppAddress?coAppAddress?.trim():ocrFormData?.address,
            panId: coAppPAN?coAppPAN?.trim():ocrFormData?.panId,
            aadhaarId: coAppAadhaar?coAppAadhaar?.trim():ocrFormData?.aadhaarId,
            applicationId: ApplicantId.ApplicantId.applicationId,
            name: coAppFName?coAppFName?.trim():ocrFormData?.fullNamePerPan,
            mobile: coAppNumber,
            email: coAppEmail?.trim(),
            ...(relationshipStatus !== 'Select Relation with Applicant' && { relation: relationshipStatus }),
            isCoapplicant:flagForMainApplicant,
            mandateForBoth:runBothEmandate,
            rulesStatus:ruleStatus
        };

        axios.post(`${AddCoApplicantUrl}`, statusData, {
            headers: {
              Authorization: `Bearer ${user}`,
              'Content-type': 'application/json',
            },
          })
          .then(
            (res) => {
              if (res.data.message === 'Successful') {
                setConfirmationMessage('Co Applicant Added Successfully');
                setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
                setTimeout(() => setConfirmationDialog(false), 2000);
                // setTimeout(() => window.location.reload(true), 500);
              }
            },
            (error) => {
              setConfirmationMessage(error?.response?.data?.message.map((item,key)=>{
                  return(
                    <p>{key+1}. {item}</p>
                  )
                }));
                setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
                 setTimeout(() => setConfirmationDialog(false), 8000);
                 setTimeout(() => window.location.reload(true), 8000);
             },
            
          );
        }
        else
        {
          setAlertMessage("Incomplete Information Please Check")
          setTimeout(() => {
            setAlertMessage(null)
          }, 3000);
        }
          
    } else if(status==="Rejected"){
      
      const rejectedData = {
        userId: ApplicantId.ApplicantId.userId,
        applicationId: ApplicantId.ApplicantId.applicationId,
        status: ApplicationStatusEnum.Rejected,
        notes: descriptionText,
      };
      if(droppedstate){
        if (droppedstate.message === 'Successful') {
          setFormLoader(!formLoader);
          setStatusOpen(!formStatusOpen);
          setConfirmationDialog(!confirmationDialog);
          setConfirmationMessage('Rejected Successfully');
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
          setTimeout(() => setConfirmationDialog(false), 2000);
          setTimeout(() => window.location.reload(true), 500);
        }else{
          setConfirmationMessage("error");
              setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
              setTimeout(() => setConfirmationDialog(false), 2750);
        }
      }else{
        dispatch(userActions.fetchDroppedstatuschange(updateDroppedStatus,rejectedData, user),[])
      }
      
    }
    else if(status==="On Hold"){
     
      const onholdData = {
        userId: ApplicantId.ApplicantId.userId,
        applicationId: ApplicantId.ApplicantId.applicationId,
        status: ApplicationStatusEnum.OnHold,
        notes: descriptionText,
      };
      if(droppedstate){
        if (droppedstate.message === 'Successful') {
          setFormLoader(!formLoader);
          setStatusOpen(!formStatusOpen);
          setConfirmationDialog(!confirmationDialog);
          setConfirmationMessage('Application is in On Hold Now ...');
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
          setTimeout(() => setConfirmationDialog(false), 2000);
          setTimeout(() => window.location.reload(true), 500);
        }else{
          setConfirmationMessage("error");
            setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
              setTimeout(() => setConfirmationDialog(false), 2750);
        }
      }else{
        dispatch(userActions.fetchDroppedstatuschange(updateDroppedStatus,onholdData, user),[])
      }
      
    }
   
  };
  useEffect(()=>{
    handleStatusChange();
  },[droppedstate])

  const handleApproveForm = (x, y, applId) => {
    
    setProductName(x.target.innerText);
      const urlgetEmi=`${process.env.REACT_APP_BASE_URL}/admin/application/emi?applicationId=${applId}&productId=${y.productId}`
    axios.get(urlgetEmi,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res)=>{
 
      setEmiData(res.data.data)
    document.getElementById("defaultEMI").defaultValue = res.data.data
  })

   setForm(y)
  };


  //set colender fund source
  const [colenderFundSource,setColenderFundSource]=useState(null)
  const getUserInfo=`${process.env.REACT_APP_BASE_URL}/admin/user-info`
const getColenderFundSource=()=>{

      axios.get(getUserInfo,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res)=>{
      if(res.data.data.colenderId)
      {
        axios.get(`${process.env.REACT_APP_BASE_URL}/fund_source/fund_code?fundCode=${res.data.data.colenderId}`,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
        .then((result)=>
        setColenderFundSource(result.data.data.fundCode))
      }
    })
  }
  const handleApproveApi = (x, y, z) => {
 
    
    setStatus(x.target.innerText);
    axios
      .get(urlApproveApiProd, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) =>{   
         //setting loan amount
        // setLoanAmount(z)
        // if(x.target.innerText==="Approve")
        // {

        //   document.getElementById("loanAmount").defaultValue=z;
        // }
        setProdArray(res.data.data)
      }
      );


    if(decode.role==='co_lender')
    {
      getColenderFundSource()
    }
    else{
axios
      .get(urlApproveApiFund, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => setFundSourceArray(res.data.data));
    }
        
  };
  const handleToggle = () => {
    setDropDownOpen(!dropDownOpen);
  };
  const handleRelationshipToggle = () => {
    setRelationshipStatusDropdown(!relationshipStatusDropdown);
  };
  const handleProductToggle = () => {
    setProductDropDownOpen(!productDropDownOpen);
  };
  const handleFundToggle = () => {
    setFundDropDownOpen(!fundDropDownOpen);
  };
  const handleDescriptionText = (text) => {
    setDescriptionText(text);
  };




  //upload Docs 
  let aadhaarFrontPreview='';
  aadhaarFrontPreview = aadhaarFrontUpload ? URL.createObjectURL(new Blob([aadhaarFrontUpload], { type: 'application/jpg' })) : '#';
  let aadhaarBackPreview='';
  aadhaarBackPreview = aadhaarBackUpload ? URL.createObjectURL(new Blob([aadhaarBackUpload], { type: 'application/jpg' })) : '#';
  let pancardPreview='';
  pancardPreview = panUpload ? URL.createObjectURL(new Blob([panUpload], { type: 'application/jpg' })) : '#';


  //handleForm Reset
const handleFormReset=()=>{
  setRelationshipStatus("Select Relation with Applicant");
  setAadhaarFrontUpload(null);
  setAadhaarBackUpload(null);
  setPanUpload(null);
  aadhaarBackPreview="";
  aadhaarFrontPreview="";
  pancardPreview="";
  
  setCoAppAadhaar(null);
  setCoAppEmail(null);
  setCoAppNumber(null);
  setCoAppPAN(null);
  setCoAppFName("");
  

}

const handleOnLoad=(e)=>{
setStatus(e.target.innerText)
handleFormReset()
}

const id = ApplicantId.ApplicantId.applicationId


const name = ApplicantId.ApplicantId.name



  
  return (
    <div>
      <img  onClick={() => popUpStatusForm()} style={{cursor:'pointer',width:'1.1vw'}} src={action3} alt="resend consent"/>
      <Modal isOpen={confirmationDialog}>
        <ModalHeader>Waiting For Confirmation</ModalHeader>
        <ModalBody>{confirmationMessage}</ModalBody>
      </Modal>
      <Modal style={{
        
        
         
        
       height:"100%",
       overflowY:"auto",
        width:"100%",
        borderRadius:"10px",
      }} isOpen={formStatusOpen} size="xl">
        <div style={{backgroundColor:"#E9E9E9",borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:'space-between',height:'60px',padding:"10px"}}>
                <p style={{fontFamily:"Inter-Medium",fontSize:'14px'}}>Update Status Here ( Process to <span style={{fontFamily:"Inter-Medium",color:'#D32028'}}>{status}</span> )</p>
                <p style={{fontFamily:"Inter-Medium",cursor: 'pointer'}} onClick={() => setStatusOpen(!formStatusOpen)}>
                  <img src={close}/>
                </p>
            </div>
            </div>
           
            <div style={{marginTop:'10px'}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",backgroundColor:'#FCF0F1',width:"100%",borderRadius:"10px"}}>
              <p style={{marginLeft:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}>Application ID <br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{id}</span></p>
               <p style={{marginRight:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}> Applicant Name<br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{name}</span></p>
            </div>
         
          
        </div>
       
        <ModalBody>
          <Form>
            <FormGroup>
            <p style={{lineHeight:'10px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Select Status*</p>
              <Dropdown isOpen={dropDownOpen} toggle={() => handleToggle()}>
                <DropdownToggle style={{lineHeight:'10px',textAlign:"left",height:'40px',width:'330px',backgroundColor:'white',color:'gray',fontFamily:"Inter-Medium",fontSize:'14px'}} >
                  {status}<span style={{ position: 'absolute',left:"300px", top: '50%', transform: 'translateY(-50%)' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                      <path fill="currentColor" d="M1.5 4.5l4.5 4.5 4.5-4.5z" />
                    </svg>
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}}onClick={(e) => handleApproveApi(e, ApplicantId.ApplicantId.instituteId,ApplicantId.ApplicantId.courseFees)}>
                  Approve
                  </DropdownItem>
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}}onClick={(e) => handleApproveApi(e, ApplicantId.ApplicantId.instituteId,ApplicantId.ApplicantId.courseFees)}>
                  Assign Fund Source
                  </DropdownItem>
                  <DropdownItem  style={{fontFamily:"Inter-Medium",fontSize:'14px'}}onClick={(e) => handleOnLoad(e)}>
                  Rejected
                  </DropdownItem>
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}} onClick={(e) => handleOnLoad(e)}>
                  Add Co-Applicant
                  </DropdownItem>
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}} onClick={(e) => handleOnLoad(e)}>
                  Dropped
                  </DropdownItem>
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}} onClick={(e) => handleOnLoad(e)}>
                  On Hold
                  </DropdownItem>
              
                </DropdownMenu>
              </Dropdown>

              {/* {
                status==='Request Co-Applicant'?
                <div style={{marginTop:'1em'}}>
                  <p style={{textAlign:'center',fontWeight:'600',backgroundColor:'aliceBlue',padding:'0.5em'}}>ON<span style={{color:'red'}}> SAVE AND SUBMIT </span><br/>A SMS WILL BE  SENT<br/>TO THE APPLICANT<p style={{textDecoration:'underline'}}> TO FILL
                    CO-APPLICANT DETAILS ! </p></p> 
                </div>:null

              } */}
             {status === 'Approve'? (
                <div style={{ display: 'flex',justifyContent: 'center',flexDirection: 'row', }}>
                  <div style={{ display: 'flex',}}>
                    <div style={{ display: 'flex', }}>
                      <p style={{backgroundColor:'#FEEDEE',padding:'1em',borderRadius:'10px',fontFamily:'Inter',textAlign:'center'}}>Click <strong>"Save and Submit"</strong> to approve the application</p>
                      {/* <Dropdown isOpen={fundDropDownOpen} toggle={() => handleFundToggle()}>
                        <DropdownToggle style={{border:"none", marginTop: '1em',fontFamily:"Inter-Medium",fontSize:'14px',backgroundColor:'#D32028' }} caret >
                          {decode.role==='co_lender'?colenderFundSource:fundSourceName}
                        </DropdownToggle>
                        {decode.role==='co_lender'?null:<DropdownMenu>
                          {fundSourceArray.map((item, idx) => {
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <DropdownItem
                                key={idx}
                                onClick={(e) => setFundSourceName(e.target.innerText)}
                              >
                                {item.fundCode}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>}
                      </Dropdown> */}
                      {/* <Dropdown
                        style={{ marginLeft: '4em' }}
                        isOpen={productDropDownOpen}
                        toggle={() => handleProductToggle()}
                      >
                        <DropdownToggle style={{border:"none", marginTop: '1em',fontFamily:"Inter-Medium",fontSize:'14px',backgroundColor:"#D32028" }} caret >
                          {productName}
                        </DropdownToggle>

                        <DropdownMenu>
                          {prodArray.map((item, idx) => {
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <>
                              {item.name==="SSSV090101"||item.name==="TSSV090200"?null:<DropdownItem
                                key={idx}
                                onClick={(e) => handleApproveForm(e, prodArray[idx],ApplicantId.ApplicantId.applicationId)}
                              >
                                {item.name}
                              </DropdownItem>}
                              </>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <p style={{ position: 'absolute',lineHeight:'5px', marginTop: '1.5em',fontFamily:'Inter-Medium',fontSize:'14px' }}>Tenure</p>
                    <Input
                      type="number"
                      id="inp1"
                      disabled
                      
                      value={form.tenure}
                      style={{
                        height: '30px',
                        width: '465px',
                        marginTop: '3em',
                        borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                      }}
                    />

                    <p style={{ position: 'absolute', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Loan Amount</p>
                    <Input
                      type="number"
                      id="loanAmount"
                      placeholder="Enter Loan Amount"
                      onChange={(e) => setLoanAmount(e.target.value)}
                      style={{
                        height: '30px',
                        width: '465px',
                        marginTop: '3em',
                        borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                      }}
                    />
                    <p style={{ position: 'absolute', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>EMI</p>
                    <Input
                      type="number"
                      disabled
                      id="defaultEMI"
                      placeholder="enter EMI"
                      style={{
                        height: '30px',
                        width: '465px',
                        marginTop: '3em',
                        borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                      }}
                      onChange={(e)=>setEMI(e.target.value)}
                    />

                    <p style={{ position: 'relative', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>First Date of EMI</p>
                    <Datetime
                      id="fromDate"
                      dateFormat="YYYY/MM/DD"
                      timeFormat={false}
                      onChange={(event) => setEmiDate(event.format('YYYY/MM/DD'))}
                      locale="en-gb"
                      inputProps={{ placeholder: 'Date',style:{fontFamily:'Inter-Medium',fontSize:'14px'} }}
                    />

                    <p style={{ position: 'relative', marginTop: '20px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Day of EMI</p>
                    <Input
                      type="number"
                      id="inp1"
                      placeholder="enter day(1-30)"
                      onChange={(e) => setEmiDay(e.target.value)}
                      style={{
                        height: '30px',
                        width: '465px',
                        marginTop: '2em',
                        borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'block',
                      marginLeft: '3em',
                      paddingLeft: '3em',
                      borderLeft: '1px dashed #333',fontFamily:'Inter-Medium',fontSize:'14px'
                    }}
                  >
                    <p style={{ position: 'absolute', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Facility Type</p>
                    <div style={{ display: 'flex' }}>
                      <Input
                        type="button"
                        disabled={true}
                        id="inp1"
                        value="Discounted Interest"
                        placeholder="enter text"
                        onClick={(e) => console.log(e)}
                        style={{
                          height: '30px',
                          width: '165px',
                          marginRight: '1em',
                          marginTop: '3em',
                          borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px',
                          color: 'black',
                          fontWeight: 'bold',
                          backgroundColor: form.facilityType === 1 ? '#7CB9E8' : '',
                        }}
                      />
                      <Input
                        type="button"
                        disabled={true}
                        id="inp1"
                        value="Standard Interest"
                        style={{
                          height: '30px',
                          width: '150px',
                          marginRight: '1em',
                          marginTop: '3em',
                          borderRadius: '5px',
                          fontWeight: 'bold',fontFamily:'Inter-Medium',fontSize:'14px',
                          color: 'black',
                          backgroundColor: form.facilityType === 2 ? '#7CB9E8' : '',
                        }}
                      />
                      <Input
                        type="button"
                        disabled={true}
                        id="inp1"
                        value="Hybrid Interest"
                        onClick={(e) => console.log(e)}
                        style={{
                          height: '30px',
                          width: '150px',
                          marginTop: '3em',
                          marginRight: '1em',
                          borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                        }}
                      />
                    </div>

                    <p style={{ position: 'absolute', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Interest</p>
                    <div style={{ display: 'flex' }}>
                      {' '}
                      <Input
                        type="button"
                        value={
                          form.discountedInterestPercentage !== 0
                            ? form.discountedInterestPercentage
                            : 'N/A'
                        }
                        id="inp1"
                        placeholder="enter text"
                        onClick={(e) => console.log(e)}
                        style={{
                          height: '30px',
                          width: '165px',
                          marginTop: '3em',
                          borderRadius: '5px',
                          fontWeight: 'bold',fontFamily:'Inter-Medium',fontSize:'14px',
                          color: 'black',
                          backgroundColor: form.discountedInterestType === 1 ? '#7CB9E8' : '',
                        }}
                      />
                      <Input
                        type="button"
                        id="inp1"
                        disabled={true}
                        value={
                          form.standardInterestPercentage !== 0
                            ? form.standardInterestPercentage
                            : 'N/A'
                        }
                        placeholder="enter text"
                        onClick={(e) => console.log(e)}
                        style={{
                          height: '30px',
                          width: '165px',
                          marginTop: '3em',
                          borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px',
                          fontWeight: 'bold',
                          color: 'black',
                          backgroundColor: form.standardInterestType === 1 ? '#7CB9E8' : '',
                        }}
                      />
                    </div>

                    <div style={{ display: 'flex' }}>
                      <p style={{ position: 'absolute', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Process Fee</p>
                      <span style={{ position: 'relative', marginTop: '30px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Flat</span>
                      <span style={{ position: 'relative', marginTop: '30px', marginLeft: '15px' ,fontFamily:'Inter-Medium',fontSize:'14px'}}>
                        Per(%)
                      </span>
                      <Input
                        type="radio"
                        id="inp1"
                        disabled
                        style={{
                          height: '20px',
                          width: '30px',
                          position: 'absolute',
                          marginTop: '4em',
                          borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px',
                        }}
                        checked={form.processingfeeType === 1 ? true : false}
                      />
                      <Input
                        type="radio"
                        id="inp1"
                        disabled
                        style={{
                          height: '20px',
                          width: '30px',
                          position: 'absolute',
                          marginTop: '4em',
                          marginLeft: '3em',fontFamily:'Inter-Medium',fontSize:'14px',
                          borderRadius: '5px',
                        }}
                        checked={form.processingfeeType === 2 ? true : false}
                      />
                      <p style={{ position: 'absolute', marginLeft: '9em', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>
                        Value
                      </p>
                      <Input
                        type="text"
                        id="inp1"
                        disabled
                        placeholder="value"
                        value={form.processingfeeValue}
                        style={{
                          height: '30px',
                          marginLeft: '1em',fontFamily:'Inter-Medium',fontSize:'14px',
                          width: '100px',
                          marginTop: '3.5em',
                          borderRadius: '5px',
                        }}
                      />

                      <p style={{ position: 'absolute', marginLeft: '16em', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>
                        GST applicable
                      </p>
                      <Input
                        type="checkbox"
                        id="inp1"
                        disabled
                        placeholder="enter text"
                        checked={form.processingfeeValueGST ? true : false}
                        style={{
                          height: '30px',
                          width: '50px',
                          marginLeft: '4em',fontFamily:'Inter-Medium',fontSize:'14px',
                          marginTop: '3.5em',
                          borderRadius: '5px',
                        }}
                      />
                      <p style={{ position: 'absolute', marginLeft: '27em', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>
                        Total Fee
                      </p>
                      <Input
                        type="text"
                        id="inp1"
                        disabled
                        placeholder="Total fee"
                        value={form.processingfeValueIncludingGST}
                        style={{
                          height: '30px',
                          width: '115px',
                          marginLeft: '3em',
                          marginTop: '3.5em',
                          borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                        }}
                      />
                    </div>

                    <p style={{ position: 'absolute', marginTop: '45px',fontFamily:'Inter-Medium',fontSize:'14px'}}>FLDG(Yes/No)</p>

                    <div style={{ marginLeft: '8em', marginTop: '3em' ,fontFamily:'Inter-Medium',fontSize:'14px'}}>
                      {' '}
                      <Switch disabled onText="YES" offText="NO" value={form.fldgApplicable} />
                      {'  '}{' '}
                    </div>
                    <div style={{ display: 'flex' }}>
                      <p style={{ position: 'absolute', marginTop: '10px',fontFamily:'Inter-Medium',fontSize:'14px' }}>Advance EMIs</p>
                      <Input
                        type="text"
                        id="inp1"
                        disabled
                        title="number between 0-3"
                        placeholder="number between 0-3"
                        value={form.advanceEmis}
                        style={{
                          height: '30px',
                          width: '100px',
                          marginTop: '3em',
                          borderRadius: '5px',fontFamily:'Inter-Medium',fontSize:'14px'
                        }}
                      />
                      <p style={{ position: 'absolute', marginTop: '10px', marginLeft: '13em',fontFamily:'Inter-Medium',fontSize:'14px' }}>
                        IRR
                      </p>
                      <Input
                        type="text"
                        id="inp1"
                        disabled
                        title="number between 0-3"
                        placeholder="number between 0-3"
                        value={form.irr}
                        style={{
                          height: '30px',
                          width: '100px',
                          marginTop: '3em',
                          marginLeft: '3em',fontFamily:'Inter-Medium',fontSize:'14px',
                          borderRadius: '5px',
                        }}
                      />
                    </div>
                    <p style={{ position: 'absolute', marginTop: '15px', fontWeight: '650',fontFamily:'Inter-Medium',fontSize:'14px' }}>
                      Disbursements
                    </p>
                    <div style={{ position: 'absolute', marginTop: '40px', display: 'flex',fontFamily:'Inter-Medium',fontSize:'14px' }}>
                      <div style={{ display: 'block' }}>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'14px'}}>Months:</p>{' '}
                        <ul
                          style={{
                            marginTop: '-55px',
                            marginLeft: '5em',
                            listStyle: 'none',
                            display: 'flex',
                            position: 'absolute',fontFamily:'Inter-Medium',fontSize:'14px'
                          }}
                        >
                          <li style={{ marginLeft: '3.5em' ,fontFamily:'Inter-Medium',fontSize:'14px'}}>{form.disbursementMonth1}</li>
                          <li style={{ marginLeft: '3.5em',fontFamily:'Inter-Medium',fontSize:'14px' }}>{form.disbursementMonth2}</li>
                          <li style={{ marginLeft: '3.5em',fontFamily:'Inter-Medium',fontSize:'14px' }}>{form.disbursementMonth3}</li>
                          <li style={{ marginLeft: '3.5em',fontFamily:'Inter-Medium',fontSize:'14px'}}>{form.disbursementMonth4}</li>
                        </ul>
                        <p style={{fontFamily:'Inter-Medium',fontSize:'14px'}}>Percentage:</p>{' '}
                        <ul
                          style={{
                            marginTop: '-55px',
                            marginLeft: '5em',
                            listStyle: 'none',
                            display: 'flex',
                            position: 'absolute',
                          }}
                        >
                          <li style={{ marginLeft: '3em',fontFamily:'Inter-Medium',fontSize:'14px' }}>{form.disbursementMonth1Percentage}</li>
                          <li style={{ marginLeft: '3em',fontFamily:'Inter-Medium',fontSize:'14px' }}>{form.disbursementMonth2Percentage}</li>
                          <li style={{ marginLeft: '3em',fontFamily:'Inter-Medium',fontSize:'14px' }}>{form.disbursementMonth3Percentage}</li>
                          <li style={{ marginLeft: '3em' ,fontFamily:'Inter-Medium',fontSize:'14px'}}>{form.disbursementMonth4Percentage}</li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {
                status==='Assign Fund Source'?
                <>
                  <Dropdown isOpen={fundDropDownOpen} toggle={() => handleFundToggle()}>
                        <DropdownToggle style={{border:"none", marginTop: '1em',fontFamily:"Inter-Medium",fontSize:'14px',backgroundColor:'#D32028' }} caret >
                          {decode.role==='co_lender'?colenderFundSource:fundSourceName}
                        </DropdownToggle>
                        {decode.role==='co_lender'?null:<DropdownMenu>
                          {fundSourceArray.map((item, idx) => {
                            return (
                              // eslint-disable-next-line react/no-array-index-key
                              <DropdownItem
                                key={idx}
                                onClick={(e) => setFundSourceName(e.target.innerText)}
                              >
                                {item.fundCode}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>}
                      </Dropdown>
                </>:
                (
                  <></>
                )
              }
 {status === 'Add Co-Applicant'?!ocrReadDone? <><div style={{display:'flex',justifyContent: 'space-evenly',marginTop:'1em'}}>
          <div style={{display:'block'}}>
          <p style={{fontFamily:'Inter-Medium',fontSize:'14px'}}>Upload Aadhaar Front</p>
          <Input className="ocrUpload"   onChange={(e)=>previewOcrAadhaarFront(e)} type="file"/>
          <p style={{textAlign:'center',fontFamily:'Inter-Medium',fontSize:'14px'}}>Preview</p>

          <img style={{border:'none',maxWidth:'18em',height:'15em'}} id="ocrAadhaarFOutput" alt=""/>
          </div>
          <div style={{display:'block'}}>
          <p style={{fontFamily:'Inter-Medium',fontSize:'14px'}}>Upload Aadhaar Back</p>
          
          <Input className="ocrUpload"   onChange={(e)=>previewOcrAadhaarBack(e)}type="file"/>
          <p style={{textAlign:'center',fontFamily:'Inter-Medium',fontSize:'14px'}}>Preview</p>

          <img style={{marginLeft:'1em',border:"0",maxWidth: '18em',height:'15em'}} id="ocrAadhaarBOutput" alt=""/>

          </div>

          <div style={{display:'block'}}>
          <p style={{fontFamily:'Inter-Medium',fontSize:'14px'}}>Upload PAN</p>

          <Input className="ocrUpload"   onChange={(e)=>previewOcrPan(e)}type="file"/>
          <p style={{textAlign:'center',fontFamily:'Inter-Medium',fontSize:'14px'}}>Preview</p>
          <img style={{marginLeft:'1em',border:'none',maxWidth:'18em',height:'15em'}} id="ocrPanOutput" alt=""/>

          </div>

         
          </div>
            <div style={{alignSelf: 'center',marginLeft:'40%'}}>
            <button onClick={()=>readOcr()} style={{border:'none',borderRadius:'10px',width:'15em',backgroundColor:"#D22129",color:'white',fontFamily:'Inter-Medium',fontSize:'14px'}}  type="button">Trigger Coapplicant OCR</button>
            </div></> :  <div style={{ display: 'flex' }}>
                  <div style={{ display: 'block' }}>
                   
                    <p style={{ marginTop: '1em', fontWeight: '700',fontFamily:'Inter-Medium',fontSize:'14px' }}>Application Id</p>
                    <Input
                      type="text"
                      id="inp9"
                      disabled
                      value={ApplicantId.ApplicantId.applicationId}
                      style={{
                        height: '30px',
                        width: '350px',
                        fontFamily:'Inter-Medium',fontSize:'14px',
                        borderRadius: '5px',
                      }}
                    />
                    <Dropdown
                      style={{ marginTop: '1em' }}
                      isOpen={relationshipStatusDropdown}
                      toggle={() => handleRelationshipToggle()}
                    >
                      <DropdownToggle style={{backgroundColor:'#d32028',fontFamily:'Inter-Medium',fontSize:'14px'}} caret>
                        {relationshipStatus}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem style={{fontFamily:'Inter-Medium',fontSize:'14px'}}   onClick={(e) => setRelationshipStatus(e.target.innerText)}>
                          Spouse
                        </DropdownItem>
                        <DropdownItem style={{fontFamily:'Inter-Medium',fontSize:'14px'}}  onClick={(e) => setRelationshipStatus(e.target.innerText)}>
                          Parent
                        </DropdownItem>
                        <DropdownItem style={{fontFamily:'Inter-Medium',fontSize:'14px'}}  onClick={(e) => setRelationshipStatus(e.target.innerText)}>
                          Guardian
                        </DropdownItem>
                        <DropdownItem style={{fontFamily:'Inter-Medium',fontSize:'14px'}}  onClick={(e) => setRelationshipStatus(e.target.innerText)}>
                          Friend
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                    <p style={{ marginTop: '1em', fontWeight: '700',fontFamily:'Inter-Medium',fontSize:'14px',width:'8em',lineHeight:'1.5em' }}> Co-Applicant Name</p>
                    <Input
                    defaultValue={ocrFormData?.fullNamePerPan}
                    valid={getStringContain(coAppFName)}
                    invalid={getStringContain(coAppFName)}
                      type="text"
                      id="coAppName"
                      placeholder="Co Applicant Name"
                      onChange={(e) => setCoAppFName(e.target.value)}
                      style={{
                        height: '30px',
                        width: '240px',fontFamily:'Inter-Medium',fontSize:'14px',
                        margin:'18px',
                        borderRadius: '5px',
                      }}
                    />
                </div>
                 <div style={{display:'flex',justifyContent:'space-between'}}>
                    <p style={{ marginTop: '1em', fontWeight: '700',fontFamily:'Inter-Medium',fontSize:'14px',width:'8em',lineHeight:'1.5em'}}>Co-Applicant DOB</p>
                    <Input
                      defaultValue={ocrFormData?.dobPerAadhaar?ocrFormData?.dobPerAadhaar:ocrFormData?.dobPerPan}
                      type="date"
                      id="coAppDob"
                      placeholder="Date of Birth"
                      onChange={(e) => setCoAppDob(e.target.value)}
                      style={{
                        height: '30px',
                        width: '200px',fontFamily:'Inter-Medium',fontSize:'14px',
                        margin:'18px',
                        borderRadius: '5px',
                      }}
                    />
                  </div>
                 <div style={{display:'flex',justifyContent:'space-between'}}>
                    <p style={{ marginTop: '1em', fontWeight: '700',fontFamily:'Inter-Medium',fontSize:'14px',width:'8em',lineHeight:'1.5em'}}>Co-Applicant Phone Number</p>
                    <Input
                    valid={coAppNumber?coAppNumber.length===10?true:false:""}
                     
                    
                      type="number"
                      id="coAppNumber"
                      placeholder="Phone number of Co Applicant"
                      onChange={(e) => setCoAppNumber(e.target.value)}
                      style={{
                        height: '30px',
                        width: '240px',fontFamily:'Inter-Medium',fontSize:'14px',
                        margin:'18px',
                        borderRadius: '5px',
                      }}
                    />
                  </div>
                      <div style={{display:'block',marginTop:'2em'}}>
                      <p style={{ marginTop: '0.5em', fontWeight: '700',fontFamily:'Inter-Medium',fontSize:'14px' }}>Upload Aadhaar (Front)</p>
                      <Input
                        type="file"
                        id="inp6"
                        disabled={ocrFormData?.aadhaarImage1?true:false}
                       
                        onChange={(e) => setAadhaarFrontUpload(e.target.files[0])}
                        style={{
                          height: '30px',
                          width: '300px',fontFamily:'Inter-Medium',fontSize:'14px',

                          borderRadius: '50px',
                        }}
                      />
                     { ocrFormData?.aadhaarImage1?<><img src={ocrFormData?.aadhaarImage1} alt="No File Uploaded" style={{borderRadius:'1em',backgroundColor:'#E8E8E8',margin:'15px',width:'20em',height:'10em',border:'1px dashed black',padding:'0.5em',}}/>
                     
                     </>
                     :
                     aadhaarFrontUpload?<><img src={aadhaarFrontPreview} alt="No File Uploaded" style={{borderRadius:'1em',backgroundColor:'#E8E8E8',margin:'15px',width:'20em',height:'10em',border:'1px dashed black',padding:'0.5em',}}/>
                     <Button id="aadhaarFrontUploadButton" className="infoFront" onClick={()=>handleAadhaarFrontFileUpload(aadhaarFrontUpload)}> Upload</Button></>: <p style={{color:'red',fontWeight:'550',marginTop:'-4em',fontFamily:'Inter-Medium',fontSize:'14px'}}>( Upload To See Preview )</p>}
                      </div>

                       <div style={{display:'block'}}>   
                      <p style={{ marginTop: '0.5em', fontWeight: '700',fontFamily:'Inter-Medium',fontSize:'14px' }}>Upload Aadhaar (Back Side)</p>
                      <Input
                        type="file"
                        id="inp8"
                         disabled={ocrFormData?.aadhaarImage2?true:false}
                        onChange={(e) => setAadhaarBackUpload(e.target.files[0])}
                        style={{
                          height: '30px',
                          width: '300px',fontFamily:'Inter-Medium',fontSize:'14px',

                          borderRadius: '50px',
                        }}
                      />
                       {ocrFormData?.aadhaarImage2?<><img src={ocrFormData?.aadhaarImage2}alt="No File Uploaded" style={{borderRadius:'1em',backgroundColor:'#E8E8E8',margin:'15px',border:'1px dashed black',padding:'0.5em',width:'20em',height:'10em',}}/></>
                       :
                        aadhaarBackUpload ?<>
                        <img src={aadhaarBackPreview} alt="No File Uploaded" style={{borderRadius:'1em',backgroundColor:'#E8E8E8',margin:'15px',border:'1px dashed black',padding:'0.5em',width:'20em',height:'10em',}}/><Button id="aadhaarBackUploadButton" className="infoBack" onClick={()=>handleAadhaarBackFileUpload(aadhaarBackUpload)}> Upload</Button></>:<p style={{padding:'5px',fontFamily:'Inter-Medium',fontSize:'14px'}}>Upload to see Preview</p>}

                        </div>
                  </div>
                  <div style={{ display: 'block', borderLeft:'1px dashed black',marginLeft: '4em',paddingLeft:'4em' }}>
                   <div style={{display:'flex',justifyContent:'space-between',}}>
                    <p style={{ marginTop: '1em', fontWeight: '700',fontFamily:'Inter-Medium',fontSize:'14px' }}>Co-Applicant Email</p>
                    <Input
                       valid={validateEmail(coAppEmail)}
                   
                      type="text"
                      id="coAppEmail"
                      placeholder="Enter Co Applicant Email"
                      onChange={(e) => setCoAppEmail(e.target.value)}
                      style={{
                      height: '30px',
                        width: '240px',margin:'18px',fontFamily:'Inter-Medium',fontSize:'14px',

                        borderRadius: '5px',
                      }}
                    />
                    </div>
                     <div style={{display:'flex',justifyContent:'space-between',width:'15em'}}>
                    <p style={{ marginTop: '1em', fontWeight: '700',fontFamily:'Inter-Medium',fontSize:'14px' }}>PAN</p>
                    <Input
                    valid={coAppPAN?coAppPAN.length===10:""}
                      defaultValue={ocrFormData?.panId}
                      type="text"
                      id="coAppPan"
                      placeholder="Enter PAN of Co Applicant"
                      onChange={(e) => setCoAppPAN(e.target.value)}
                      style={{
                        height: '30px',
                        width: '240px',margin:'18px',
                         textTransform: "uppercase",fontFamily:'Inter-Medium',fontSize:'14px',
                        borderRadius: '5px',
                      }}
                    />
                    </div>
                     <div style={{display:'flex',justifyContent:'space-between',width:'15em'}}>
                    <p style={{ marginTop: '1em', fontWeight: '700',fontFamily:'Inter-Medium',fontSize:'14px' }}>Aadhaar</p>
                    <Input
                      valid={coAppAadhaar?coAppAadhaar.length===12:""}
                      defaultValue={ocrFormData?.aadhaarId}
                      type="text"
                      id="inp5"
                      placeholder="Enter Aadhaar of Co Applicant"
                      onChange={(e) => setCoAppAadhaar(e.target.value)}
                      style={{
                      height: '30px',
                        width: '240px',margin:'18px',fontFamily:'Inter-Medium',fontSize:'14px',

                        borderRadius: '5px',
                      }}
                    />
                  </div>
                    <div
                      style={{ display: 'block', justifyContent: 'space-between', marginTop: '1em',fontFamily:'Inter-Medium',fontSize:'14px' }}
                    >
                    
                      
                       <div style={{display:'block'}}>
                      <p style={{ marginTop: '0.5em', fontWeight: '700' ,fontFamily:'Inter-Medium',fontSize:'14px'}}>Upload PAN </p>
                      <Input
                        type="file"
                        id="inp9"
                         disabled={ocrFormData?.panImage?true:false}
                        onChange={(e) => setPanUpload(e.target.files[0])}
                        style={{
                          height: '30px',
                          width: '300px',fontFamily:'Inter-Medium',fontSize:'14px',

                          borderRadius: '50px',
                        }}
                      />
                       {
                          
                        ocrFormData?.panImage?<><img src={ocrFormData?.panImage} alt="No File Uploaded" style={{borderRadius:'1em',backgroundColor:'#E8E8E8',margin:'15px',width:'20em',height:'10em',border:'1px dashed black',padding:'0.5em',}}/></>
                        :
                        
                         panUpload?<><img src={pancardPreview} alt="No File Uploaded" style={{borderRadius:'1em',backgroundColor:'#E8E8E8',margin:'15px',width:'20em',height:'10em',border:'1px dashed black',padding:'0.5em',}}/><Button id="PanUploadButton" className="infoBack"  onClick={()=>handlePanFileUpload(panUpload)}> Upload</Button></>:<p style={{padding:'5px',fontFamily:'Inter-Medium',fontSize:'14px'}}>Upload file to see preview</p>
                       }
                       </div>

                        <div style={{display:'flex',justifyContent:'space-between'}}>
                        <p style={{fontWeight:'bold',fontFamily:'Inter-Medium',fontSize:'14px',width:'8em'}}>Co-Applicant Address</p>
                         <Input
                            defaultValue={ocrFormData?.address}
                            type="text"
                            id="inp23"
                            placeholder="Enter Co Applicant Address"
                            onChange={(e) => setCoAppAddress(e.target.value)}
                            style={{
                              height: '80px',
                              width: '350px',fontFamily:'Inter-Medium',fontSize:'14px',
                              borderRadius: '5px',
                              }}
                    />
                      </div>
                        <div style={{display:'flex',justifyContent: 'space-between',width:'22em',marginTop:'1em'}}>
                      <strong> City</strong>

                        <Input
                            defaultValue={ocrFormData?.city}
                            type="text"
                            id="inp23"
                            placeholder="City"
                            onChange={(e) => setCoAppCity(e.target.value)}
                            style={{
                              height: '40px',
                              width: '150px',fontFamily:'Inter-Medium',fontSize:'14px',
                              borderRadius: '5px',
                              }}
                    />
                  </div>
                    <div style={{display:'flex',justifyContent: 'space-between',width:'22em',marginTop:'1em'}}>
                      <strong> State</strong>
                      <select onChange={(e) => setCoAppState(e.target.value)}
                        style={{
                          height: '40px',
                          width: '150px',fontFamily:'Inter-Medium',fontSize:'14px',
                          borderRadius: '5px',
                          }}>
                      {
                        states?.map((item)=>{
                          return(
                            <option name={item} value={item}>{item}</option>
                          )
                        })
                      }

                      </select>
                        {/* <Input
                            defaultValue={ocrFormData?.state}
                            type="text"
                            id="inp23"
                            placeholder="State"
                            onChange={(e) => setCoAppState(e.target.value)}
                            style={{
                              height: '40px',
                              width: '150px',fontFamily:'Inter-Medium',fontSize:'14px',
                              borderRadius: '5px',
                              }}
                    /> */}
                  </div>
                  
                  <div style={{display:'flex',justifyContent: 'space-between',width:'22em',marginTop:'1em'}}>
                   <strong> ZIP</strong>
                         <Input
                            defaultValue={ocrFormData?.pincode}
                            type="text"
                            id="coAppZip"
                            placeholder="Pincode"
                            onChange={(e) => setCoAppPincode(e.target.value)}
                            style={{
                              height: '40px',
                              width: '150px',fontFamily:'Inter-Medium',fontSize:'14px',
                              borderRadius: '5px',
                              }}
                    />
                  </div>
                    </div>
                        <div style={{display:'flex',backgroundColor:'#E0E0E0',borderRadius:'1em',marginTop:'1em',justifyContent:'space-around'}}>
                         {/* <div style={{display:'block'}}>
                       <p style={{fontWeight:'600',marginTop:'0.9em',fontFamily:'Inter-Medium',fontSize:'14px'}}>Run CIBIL</p> <input onChange={(e)=>setCibilCheck(e.target.checked)} style={{borderRadius:'2em',marginTop:'-1em',width:'5em',height:'2em'}} type="checkbox"/>
                       </div> */}
                       <div>
                     <p  style={{fontWeight:'600',marginTop:'0.9em',fontFamily:'Inter-Medium',fontSize:'14px'}}> Run Emandate</p><input id="runEmandate" onChange={(e)=>handleRunEmandateBox(e.target.checked)} style={{borderRadius:'2em',marginTop:'-1em',marginLeft
                     :'2em',width:'5em',height:'2em'}} type="checkbox"/>
                     </div>
                     <div>
                     <p  style={{fontWeight:'600',marginTop:'0.9em',fontFamily:'Inter-Medium',fontSize:'14px'}}> Run Emandate For Both </p><input id="runBothEmandate"  onClick={(e)=>handleRunBothEmandateBox(e.target.checked)} style={{borderRadius:'2em',marginTop:'-1em',marginLeft
                     :'4em',width:'5em',height:'2em'}} type="checkbox"/>
                     </div>
                       </div>
                  </div>
               
              

       <Modal isOpen={mandateCheckOpen}>
        <ModalHeader>Confirmation Message</ModalHeader>
            <ModalBody>This Action will make <u>Co Applicant</u> as the  <strong>Main Applicant</strong>
        

      
        </ModalBody>
        <ModalFooter style={{backgroundColor:'#E0E0E0',color:'black',fontWeight:'600',paddingRight:'48%'}} onClick={()=>setMandateCheckOpen(!mandateCheckOpen)}>Close</ModalFooter>
      </Modal>
       <Modal isOpen={bothMandateCheckOpen}>
        <ModalHeader>Confirmation Message</ModalHeader>
        <ModalBody>Choose whom to be the <strong>Main Applicant</strong>
        

        <div style={{display:'flex',justifyContent: 'space-around',marginBottom:'1em'}}>
          <div style={{display:'block',}}>
          <p style={{fontWeight:'600',marginTop:'1em'}}>Applicant</p><input 
          id="applicantCheck"style={{width:'3em',height:'2em'}}name="mainApplicant" type="checkbox" onClick={(e)=>handleMainApplicantSelectApplicant()}/>
          </div>
          <div style={{display:'block'}}>
          <p style={{fontWeight:'600',marginTop:'1em'}}>Co Applicant</p><input id="coapplicantCheck"name="mainApplicant" style={{width:'3em',height:'2em'}} type="checkbox" onChange={(e)=>handleMainApplicantSelectCoApplicant()}/>
          </div>
        </div>
        </ModalBody>
        <ModalFooter style={{backgroundColor:'#E0E0E0',color:'black',fontWeight:'600',paddingRight:'48%'}} onClick={()=>setBothMandateCheckOpen(!bothMandateCheckOpen)}>Close</ModalFooter>
      </Modal> 
    </div>:null}
            {status === 'Rejected' ? (
                <>
                <p style={{  marginTop: '2em',fontFamily:"Inter-Medium",fontSize:'14px',lineHeight:'10px' }}>Remarks:</p>
                  <input
                    type="text"
                    id="description"
                    placeholder="Write Description Here"
                    style={{ height: '100px', width: '1000px',borderStyle:"solid",borderWidth:"1px", borderRadius: '5px',fontFamily:"Inter-Medium",fontSize:'14px' }}

                    onChange={(e) => handleDescriptionText(e.target.value)}
                  />
                </>
              ) : (
                <></>
              )}
             {status === 'Dropped' ? (
                <>
                <p style={{  marginTop: '2em',fontFamily:"Inter-Medium",fontSize:'14px',lineHeight:'10px' }}>Remarks:</p>
                  <input
                    type="text"
                    id="description"
                    placeholder="Write Description Here"
                    style={{ height: '100px', width: '1000px',borderStyle:"solid",borderWidth:"1px", borderRadius: '5px',fontFamily:"Inter-Medium",fontSize:'14px' }}
                    onChange={(e) => handleDescriptionText(e.target.value)}
                  />
                </>
              ) : (
                <></>
              )}
              {status === 'On Hold' ? (
                <>
                <p style={{  marginTop: '2em',fontFamily:"Inter-Medium",fontSize:'14px',lineHeight:'10px' }}>Remarks:</p>
                  <input
                    type="text"
                    id="description"
                    placeholder="Write Description Here"
                    style={{ height: '100px', width: '1000px',borderStyle:"solid",borderWidth:"1px", borderRadius: '5px',fontFamily:"Inter-Medium",fontSize:'14px' }}
                    onChange={(e) => handleDescriptionText(e.target.value)}
                  />
                </>
              ) : (
                <></>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter style={{display:"flex",justifyContent:"center",backgroundColor:"#E9E9E9"}}>
          
          <Button style={{backgroundColor:"#D32028",color:"white",fontFamily:"Inter-Medium",fontSize:"13px",border:"solid",borderRadius:"5px",borderWidth:"1px",height:"30px"}} disabled={formLoader} onClick={() => handleStatusChange()}>
            Save and Submit
          </Button>
          {alertMessage?<p style={{ backgroundColor:'#fff0ce',padding:'5px',borderRadius:'10px',color:'red',fontWeight:'bold'}}>{alertMessage}</p>:""}
        </ModalFooter>
      </Modal>

       <Modal isOpen={mandateCheckOpen}>
        <ModalHeader>Confirmation Message</ModalHeader>
            <ModalBody>This Action will make <u>Co Applicant</u> as the  <strong>Main Applicant</strong>
        

      
        </ModalBody>
        <ModalFooter style={{backgroundColor:'#E0E0E0',color:'black',fontWeight:'600',paddingRight:'48%'}} onClick={()=>setMandateCheckOpen(!mandateCheckOpen)}>Close</ModalFooter>
      </Modal>
       <Modal isOpen={bothMandateCheckOpen}>
        <ModalHeader>Confirmation Message</ModalHeader>
        <ModalBody>Choose whom to be the <strong>Main Applicant</strong>
        

        <div style={{display:'flex',justifyContent: 'space-around',marginBottom:'1em'}}>
          <div style={{display:'block',}}>
          <p style={{fontWeight:'600',marginTop:'1em'}}>Applicant</p><input 
          id="applicantCheck"style={{width:'3em',height:'2em'}}name="mainApplicant" type="checkbox" onClick={(e)=>handleMainApplicantSelectApplicant()}/>
          </div>
          <div style={{display:'block'}}>
          <p style={{fontWeight:'600',marginTop:'1em'}}>Co Applicant</p><input id="coapplicantCheck"name="mainApplicant" style={{width:'3em',height:'2em'}} type="checkbox" onChange={(e)=>handleMainApplicantSelectCoApplicant()}/>
          </div>
        </div>
        </ModalBody>
        <ModalFooter style={{backgroundColor:'#E0E0E0',color:'black',fontWeight:'600',paddingRight:'48%'}} onClick={()=>setBothMandateCheckOpen(!bothMandateCheckOpen)}>Close</ModalFooter>
      </Modal> 
    </div>
  );
}


