import { userConstants } from './../constants/user.constants';

let user = sessionStorage.getItem('user');
const initialState = user ? { user, isLoading: false } : {};

export function Datefilter(state = initialState, action) {
  
  console.log("datesssssssfilter", action)
  switch (action.type) {
    case userConstants.DATEFILTER_REQUEST:
      return {
        user: action.user,
        isLoading : true

      };
    case userConstants.DATEFILTER_SUCCESS:
      const {res} = action
        console.log("dateAction", action)
      return {
        res,
        isLoading : false
      };
    case userConstants.DATEFILTER_FAILURE:
      return {
        isLoading : false
      };
    default:
      return state
  }
}