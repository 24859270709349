import React from "react";
import { Homestyle } from "./Style";
import Typography from "@mui/material/Typography";
import  {Cookies} from 'react-cookie';
import { List, ListItem, Box, Divider } from "@mui/material";
import {useNavigate} from 'react-router-dom';
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Setting from "../../assets/images/svg/settings.svg";

export default function Dropdownnav() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate=useNavigate();
  const cookies = new Cookies();

  const handleGotoProfile=()=>{
    navigate("/myProfile")
  }

  const handleLogout = () => {
    cookies.remove('jwt_token');
    sessionStorage.clear();
    navigate('/')
    window.location.reload();

  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Homestyle>
      <div>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L7 7L13 1"
              stroke="#101828"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          sx={{
            "& .MuiPaper-root": {
              top: "65px !important ",
              left: "unset !important ",
              width: "259px",
              right: "0px !important",
            },
          }}
        >
           
        

          

          <MenuItem onClick={()=>handleLogout()}>
            <List 
              sx={{
                display: "inline-flex",
                "& .MuiListItem-root": {
                  padding: "unset",
                },
              }}
            >
              <ListItem >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H7.5M13.3333 14.1667L17.5 10M17.5 10L13.3333 5.83333M17.5 10H7.5"
                    stroke="#D32028"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ListItem>
              <ListItem  sx={{ paddingLeft: "10px !important " }}>
                <Typography
                  variant="body1"
                  component="div"
                  fontFamily="Inter-Medium"
                  color="warning.main"
                >
                  Logout
                </Typography>
              </ListItem>
            </List>
          </MenuItem>
        </Menu>
      </div>
    </Homestyle>
  );
}
