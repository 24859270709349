import React, { useEffect, useState, useCallback } from 'react';
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import dagre from 'dagre';
import axiosInstance from '../../helpers/axios';
import 'reactflow/dist/style.css';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 250;
const nodeHeight = 50;

const getLayoutedElements = (nodes, edges, direction = 'LR') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node, index) => {
    const nodeWithPosition = dagreGraph.node(node.id);
   
    if (node.id === 'item-cibil') {
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 1, 
        y: nodeWithPosition.y - nodeHeight / 3,
      };
    } 
    else if (node.id.startsWith('item-')) {
      node.position = {
        x: nodeWithPosition.x, 
        y: nodeWithPosition.y,
      };
    }
    else if (node.id === 'final-output') {
      const lastFlagNode = nodes.find(n => n.id.startsWith('item-') && nodes.indexOf(n) === index - 1);
      node.position = {
        x: nodeWithPosition.x + nodeWidth / 1, 
        y: nodeWithPosition.y + nodeHeight / 3,
      };
    } else {
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 1,
        y: nodeWithPosition.y - nodeHeight / 3,
      };
    }
  
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';
  
    return node;
  });
  

  return { nodes, edges };
};

const LayoutFlow = ({
  appId,
  userId
}) => {
  const user = sessionStorage.getItem("user")
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  useEffect(() => {
    const url = `/go-rules/get-result?applicationId=${appId}&userId=${userId}`;
    axiosInstance
      .get(url, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }).then((res)=>{
        console.log("res",res.data.data)
        const data = res.data.data;
        const { nodes, edges } = processApiResponse(data);
        const layoutedElements = getLayoutedElements(nodes, edges);
        setNodes(layoutedElements.nodes);
        setEdges(layoutedElements.edges);
      })
    
  }, []);

  const processApiResponse = (data) => {
    let nodes = [];
    let edges = [];
  
    data.forEach((item, index) => {
      const sourceNodeId = `source-${index}`;
      
      nodes.push({
        id: sourceNodeId,
        data: { label: `${item.source}` },
        position: { x: 0, y: index * 300 }, 
        draggable: false,
        style: {
          lineHeight: '1.5',
          height: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '120px',
        },
      });
  
      const flagNodeIds = [];
      let flagIndex = 0;
  
      Object.keys(item.result.result.flag).forEach((key) => {
        const flagNodeId = `flag-${index}-${key}`;
        flagNodeIds.push(flagNodeId);
  
        nodes.push({
          id: flagNodeId,
          data: { label: `${key}` },
          position: { x: 300, y: index * 300 + flagIndex * 80 },
          draggable: false,
          style: {
            lineHeight: '1.5',
            height: '60px',
            display: 'flex',
            width: '200px',
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: item.result.result.flag[key] === "green" ? "#12B76A" : item.result.result.flag[key] === "orange" ? "#F8A31F" : "#D22129",
            color: item.result.result.flag[key] === "green" ? "#12B76A" : item.result.result.flag[key] === "orange" ? "#F8A31F" : "#D22129",
            backgroundColor: item.result.result.flag[key] === "green" ? "#ECFDF3" : item.result.result.flag[key] === "orange" ? "#FFF6E8" : "#FEEAEB",
            fontWeight: '800',
          },
        });
  
        edges.push({ id: `e-${sourceNodeId}-${flagNodeId}`, source: sourceNodeId, target: flagNodeId });
  
        flagIndex++;
      });
  
      const outputNodeId = `output-${index}`;
      nodes.push({
        id: outputNodeId,
        data: { label: `Overall` },
        position: { x: 600, y: index * 300 + 80 },
        draggable: false,
        style: {
          lineHeight: '1.5',
          height: '80px',
          display: 'flex',
          width: '250px',
          alignItems: 'center',
          justifyContent: 'center',
          borderColor: item.result.result.overall === "green" ? "#12B76A" : item.result.result.overall === "orange" ? "#F8A31F" : "#D22129",
          color: item.result.result.overall === "green" ? "#12B76A" : item.result.result.overall === "orange" ? "#F8A31F" : "#D22129",
          backgroundColor: item.result.result.overall === "green" ? "#ECFDF3" : item.result.result.overall === "orange" ? "#FFF6E8" : "#FEEAEB",
          fontWeight: '800',
        },
      });
  
      flagNodeIds.forEach((flagNodeId) => {
        edges.push({ id: `e-${flagNodeId}-${outputNodeId}`, source: flagNodeId, target: outputNodeId });
      });
    });
  
    return { nodes, edges };
  };
  
 const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, type: ConnectionLineType.SmoothStep, animated: true }, eds)
      ),
    []
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>  
      <div style={{ width: '100%', height: '100vh' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          connectionLineType={ConnectionLineType.SmoothStep}
          fitView
          proOptions={{ hideAttribution: true }}
        />
      </div>
    </div>
  );
};

export default LayoutFlow;
