import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Alert,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Spinner,
} from 'reactstrap';

import jwt_decode from 'jwt-decode'
import dropdown from "../../../assets/images/dropdown.png"
import Datetime from 'react-datetime';
import action3 from "../../../assets/images/action3.png"
import Switch from 'react-bootstrap-switch';
import close from "../../../assets/images/close.png"
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import 'react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'

import {userActions} from '../../../actions/user.actions';

export default function MoveDroppedStatus(ApplicantId) {
  //to refresh token
  const ApplicationStatusEnum = {
    InSubmission: 'In Submission',
    InReviewAgain:'In Review Again',
    InReview: 'In Review',
    InProcess:'In Process',
    InSanction: 'In Sanction',
    InDisbursement: 'In Disbursement',
    Disbursed: 'Disbursed',
    Rejected: 'Rejected',
    Dropped: 'Dropped',
    OnHold:'On Hold'
  };
const user = sessionStorage.getItem("user")
const decode=jwt_decode(user)
const droppedstate = useSelector((state) => state.Droppedstatuschange?.res)
const dispatch = useDispatch();

const id = ApplicantId.ApplicantId.applicationId

const name = ApplicantId.ApplicantId.name
  


  const [formStatusOpen, setStatusOpen] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [productDropDownOpen, setProductDropDownOpen] = useState(false);
  const [fundDropDownOpen, setFundDropDownOpen] = useState(false);
  const [descriptionText, setDescriptionText] = useState('');
  const [status, setStatus] = useState(' Select Status');

 
  const [formLoader, setFormLoader] = useState(false);
  
  const [relationshipStatus, setRelationshipStatus] = useState('Select Relation with Applicant');
  const [relationshipStatusDropdown, setRelationshipStatusDropdown] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('Hold On...');



    
 
  const [loanId, setLoanId] = useState('');

  const [disbursedUTR, setDisbursedUTR] = useState('');

  

  //get string validation
  const getStringContain=(str)=>{
    return (/\d/.test(str))
  }
  //get email validation
  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };


  const updateDroppedStatus = `/admin/application/update-status`;
  const handleStatusChange = () => {
    if (status === 'Status') {
      alert('NO STATUS CHANGE !');
    } 
    else if(status==="In Review"){
     
      const statusData = {
        userId: ApplicantId.ApplicantId.userId,
        applicationId: ApplicantId.ApplicantId.applicationId,
        status: ApplicationStatusEnum.InReviewAgain,
        notes: descriptionText,
      };
      if(droppedstate){
        if (droppedstate?.message === 'Successful') {
          setFormLoader(!formLoader);
          setStatusOpen(!formStatusOpen);
          setConfirmationDialog(!confirmationDialog);
          setConfirmationMessage('Dropped Successfully');
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
          setTimeout(() => setConfirmationDialog(false), 2000);
          setTimeout(() => window.location.reload(true), 2000);
        }else{
          setConfirmationMessage("error");
             setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
              setTimeout(() => setConfirmationDialog(false), 2750);
        }
      }else{
        dispatch(userActions.fetchDroppedstatuschange(updateDroppedStatus,statusData, user),[])
      }
      
    }
    else if (status === 'In Process') {
      
       const reviewData = {
        userId: ApplicantId.ApplicantId.userId,
        applicationId: ApplicantId.ApplicantId.applicationId,
        status: ApplicationStatusEnum.InProcess,
        notes: descriptionText,
      };
      if(droppedstate){
        if (droppedstate.message === 'Successful') {
          setFormLoader(!formLoader);
          setStatusOpen(!formStatusOpen);
          setConfirmationDialog(!confirmationDialog);
          setConfirmationMessage('Moved To In Process Successfully');
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2000);
          setTimeout(() => setConfirmationDialog(false), 2000);
          setTimeout(() => window.location.reload(true), 500);
        }else{
          setConfirmationMessage("error");
          setTimeout(() => setConfirmationDialog(!confirmationDialog), 2500);
          setTimeout(() => setConfirmationDialog(false), 2750);
        }
      }else{
        dispatch(userActions.fetchDroppedstatuschange(updateDroppedStatus,reviewData, user),[])

      }
    }
   
  
   
  };
const popUpStatusForm = () => {
    setStatusOpen(true);
    
  };
useEffect(()=>{
  handleStatusChange()
},[droppedstate])
  
  
  const handleToggle = () => {
    setDropDownOpen(!dropDownOpen);
  };

  const handleDescriptionText = (text) => {
    setDescriptionText(text);
  };


  
  return (
    <div>
            <img  onClick={() => popUpStatusForm()} style={{cursor:'pointer',width:'18px'}} src={action3} alt="resend consent"/>
      <Modal  isOpen={confirmationDialog}>
        <ModalHeader>Waiting For Confirmation</ModalHeader>
        <ModalBody>{confirmationMessage}</ModalBody>
      </Modal>
      {formStatusOpen && (
        <>
        <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }}
      ></div>
        
      <Modal 
      style={{
        
        width:"500px",
        
        top:"10%",
       
        
        borderRadius:"10px",
      }}
      isOpen={formStatusOpen} size="xl">
         <div style={{backgroundColor:"#E9E9E9",borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:'space-between',height:'60px',padding:"10px"}}>
                <p style={{fontFamily:"Inter-Medium",fontSize:'14px'}}>Update Status Here (Dropped to <span style={{fontFamily:"Inter-Medium",color:'#D32028'}}>{status}</span> )</p>
                <p style={{fontFamily:"Inter-Medium",cursor: 'pointer'}} onClick={() => setStatusOpen(!formStatusOpen)}>
                  <img src={close}/>
                </p>
            </div>
            </div>
           
            <div style={{marginTop:'10px'}}>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",backgroundColor:'#FCF0F1',width:"100%",borderRadius:"10px"}}>
              <p style={{marginLeft:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}>Application ID <br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{id}</span></p>
               <p style={{marginRight:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}> Applicant Name<br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{name}</span></p>
            </div>
         
          
        </div>
        <ModalBody>
          <Form>
            <FormGroup>
            <p style={{lineHeight:'10px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Select Status*</p>
              <Dropdown isOpen={dropDownOpen} toggle={() => handleToggle()}>
              <DropdownToggle style={{lineHeight:'10px',textAlign:"left",height:'40px',width:'330px',backgroundColor:'white',color:'gray',fontFamily:"Inter-Medium",fontSize:'14px'}} >
                  {status}<span style={{ position: 'absolute', left:"300px", top: '50%', transform: 'translateY(-50%)' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                      <path fill="currentColor" d="M1.5 4.5l4.5 4.5 4.5-4.5z" />
                    </svg>
                  </span>
                </DropdownToggle>
                <DropdownMenu >
                
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}} onClick={(e) => setStatus(e.target.innerText)}>
                    In Review
                  </DropdownItem>
                  <DropdownItem style={{fontFamily:"Inter-Medium",fontSize:'14px'}} onClick={(e) => setStatus(e.target.innerText)}>
                    In Process
                  </DropdownItem>
                 
                </DropdownMenu>
              </Dropdown>

             
             
              <p style={{marginTop:"20px",lineHeight:'1px',fontFamily:"Inter-Medium",fontSize:'14px'}}>Remarks</p>
              <input
                type="text"
                id="description"
                placeholder="Write Description Here"
                style={{borderRadius:"5px",borderStyle:"solid",borderWidth:"1px", height: '40px', width: '330px',fontFamily:"Inter-Medium",fontSize:'14px' }}
                onChange={(e) => handleDescriptionText(e.target.value)}
              />
            
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter style={{display:"flex",flexDirection:"row",justifyContent:"center",backgroundColor:"#E9E9E9",height:'60px'}}>

         
          <Button style={{backgroundColor:"#D32028",color:"white",fontFamily:"Inter-Medium",fontSize:"13px",border:"solid",borderRadius:"5px",borderWidth:"1px",height:"30px"}} disabled={formLoader} onClick={() => handleStatusChange()}>
            Make Changes
          </Button>
        </ModalFooter>
      </Modal>
      </>
      )}
    </div>
  );
}

