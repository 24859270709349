import { Outlet, Navigate } from 'react-router-dom'
import Cookie from 'js-cookie'

const PrivateRoutes = () => {
    const token = sessionStorage.getItem('user')
    if (token === undefined) {
      return <Navigate to="/" />
    }
    return <Outlet/>
  }


export default PrivateRoutes


  
