import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import { useState, useEffect,useCallback  } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Label, FormGroup, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FormLabel from '@mui/material/FormLabel';
import search from "../../assets/images/search.png"
import repayment from '../../assets/images/repayment.png'
import closeddot from "../../assets/images/closedDot.png"
import pendingdot from "../../assets/images/pendingDot.png"
import activedot from "../../assets/images/activeDot.png"
import initiateddot from "../../assets/images/initiatedDot.png"
import clear from "../../assets/images/clear.png"
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import calender from "../../assets/images/calender.png"
import {Row,Col,Input} from "reactstrap"
import jwt_decode from 'jwt-decode';
import axiosInstance from '../../helpers/axios';
import {useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import PaginationTable from '../status/PaginationTable';
import Datetime from 'react-datetime';
import copy from "../../assets/images/copy.png"
import plus from "../../assets/images/plus.png"
import download from "../../assets/images/download.png"
import "./Repayments.css"
import { event } from 'jquery';
import { userActions } from '../../actions/user.actions'
import EmiDetails from "../../components/Emis/EmiDetails"


const RepaymentTable = ()=>{
  const state = useSelector((state) => state?.Repayments?.res);

  const dispatch = useDispatch();
  const [totalFetchRows, setTotalFetchRows] = useState(null);
  const [currentPage, setcurrentPage] = useState(1);
  const [rowsPerPage, setrowsPerPage] = useState(15);
  const [loanId, setloanId] = useState(null);
  const [searchText, setSearchText] = useState("")
  const [customerName, setCUstomerName] = useState("")
  const [mobile, setMobile] = useState("")
  const [selectedValue, setSelectedValue] = useState("all");
  const [isLoading, setIsLoading] = useState(false); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emiAmount,setEmiAmount] = useState(null)
  const [emiId,setEmiid] = useState(null)
  const [remarks,setRemarks] = useState(sessionStorage.getItem("finstaId"))
  const [paymentLink,setPaymentLink] = useState(null)
  const [showPaymentLinkModal, setShowPaymentLinkModal] = useState(false);
  const [status,setStatus] = useState("")
  const [paymentMode,setMode] = useState("")


  const user = sessionStorage.getItem('user');
  
  const decode=jwt_decode(user)
    const [tableData, setTableData] = useState([]);
  
    const [paidfilterDateFrom, setPaidFilterDateFrom] = useState("");
    const [paidfilterDateTo, setPaidFilterDateTo] = useState("");
    const [duefilterDateFrom, setDueFilterDateFrom] = useState("");
    const [duefilterDateTo, setDueFilterDateTo] = useState("");
    const valid = (current) => {
      return current.isAfter(paidfilterDateFrom);
      
    };
    const valid1 = (current) => {
      return current.isAfter(duefilterDateFrom);
      
    };
    const clearDateFilter = () => {
      const url = `/loan-repayment/all?perPage=${rowsPerPage}&pageNo=${currentPage}`
      window.location.reload()
      setPaidFilterDateFrom("");
      setPaidFilterDateTo("");
      setDueFilterDateFrom("");
      setDueFilterDateTo("");
      axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
  
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    };
    
    useEffect(() => {
      setIsLoading(true)
      const url = `/loan-repayment/all?perPage=${rowsPerPage}&pageNo=${currentPage}`

      if(searchText === "" && paidfilterDateFrom === '' && paidfilterDateTo ==='' && duefilterDateFrom === '' && duefilterDateTo === '' && status === "" && paymentMode==="" && customerName ==="" && mobile ==="")
      axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {

        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
     
  }, []);

    const handleModeChange = (event)=>{
      setIsLoading(true);

      const paymentMode = event.target.value
      let queryParams = {}
      if(mobile){
        queryParams.mobile = mobile
      }
      if(customerName){
        queryParams.name = customerName
      }
      if(status){
        queryParams.status = status
      }
      if(duefilterDateFrom && duefilterDateTo){
        queryParams.fromDueDate = duefilterDateFrom;
        queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
      }
      if(paidfilterDateFrom && paidfilterDateTo){
        queryParams.fromPaidDate = paidfilterDateFrom;
        queryParams.toPaidDate = paidfilterDateTo+" "+"23:59:59"
      }
      if(id){
        queryParams.instituteId = id
      }
      if(loanId){
        queryParams.loanId = loanId
      }
      if(rowsPerPage && currentPage){
        queryParams.perPage = rowsPerPage;
        queryParams.pageNo = 1
      }
      if(paymentMode){
        queryParams.paymentMode = paymentMode
      }
    
      
      axiosInstance.get('/loan-repayment/all',{
        params:queryParams
      }, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
    
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });

    }

    const handleStatusChange = (event) => {
      setIsLoading(true);

      const status = event.target.value
     
      let queryParams = {}
      if(mobile){
        queryParams.mobile = mobile
      }
      if(customerName){
        queryParams.name = customerName
      }
      if(status){
        queryParams.status = status
      }
      if(duefilterDateFrom && duefilterDateTo){
        queryParams.fromDueDate = duefilterDateFrom;
        queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
      }
      if(paidfilterDateFrom && paidfilterDateTo){
        queryParams.fromPaidDate = paidfilterDateFrom;
        queryParams.toPaidDate = paidfilterDateTo+" "+"23:59:59"
      }
      if(id){
        queryParams.instituteId = id
      }
      if(loanId){
        queryParams.loanId = loanId
      }
      if(rowsPerPage && currentPage){
        queryParams.perPage = rowsPerPage;
        queryParams.pageNo = 1
      }
      if(paymentMode){
        queryParams.paymentMode = paymentMode
      }
    
      
      axiosInstance.get('/loan-repayment/all',{
        params:queryParams
      }, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
    
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    };
    const handleDateFilterApi = (paidfilterDateFrom,paidfilterDateTo,duefilterDateFrom,duefilterDateTo) => {
      setIsLoading(true);
      if (paidfilterDateFrom && !paidfilterDateTo) {
        alert('Enter Date Range to Filter');
        setIsLoading(false); 
        return; 
      }
      if (duefilterDateFrom && !duefilterDateTo) {
        alert('Enter Due Date Range to Filter');
        setIsLoading(false);
        return;
      }
      setPaidFilterDateFrom(paidfilterDateFrom);
      setPaidFilterDateTo(paidfilterDateTo);
      setDueFilterDateFrom(duefilterDateFrom);
      setDueFilterDateTo(duefilterDateTo);
      let queryParams = {}
      if(mobile){
        queryParams.mobile = mobile
      }
      if(customerName){
        queryParams.name = customerName
      }
      if(status){
        queryParams.status = status
      }
      if(duefilterDateFrom && duefilterDateTo){
        queryParams.fromDueDate = duefilterDateFrom;
        queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
      }
      if(paidfilterDateFrom && paidfilterDateTo){
        queryParams.fromPaidDate = paidfilterDateFrom;
        queryParams.toPaidDate = paidfilterDateTo+" "+"23:59:59"
      }
      if(id){
        queryParams.instituteId = id
      }
      if(loanId){
        queryParams.loanId = loanId
      }
      if(rowsPerPage && currentPage){
        queryParams.perPage = rowsPerPage;
        queryParams.pageNo = 1
      }
      if(paymentMode){
        queryParams.paymentMode = paymentMode
      }
    
      
      axiosInstance.get('/loan-repayment/all',{
        params:queryParams
      }, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
    
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
        
      
    };
  


    const paginate = (pageNumber) => {
      let queryParams = {}
      if(mobile){
        queryParams.mobile = mobile
      }
      if(customerName){
        queryParams.name = customerName
      }
      if(status){
        queryParams.status = status
      }
      if(duefilterDateFrom && duefilterDateTo){
        queryParams.fromDueDate = duefilterDateFrom;
        queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
      }
      if(paidfilterDateFrom && paidfilterDateTo){
        queryParams.fromPaidDate = paidfilterDateFrom;
        queryParams.toPaidDate = paidfilterDateTo+" "+"23:59:59"
      }
      if(id){
        queryParams.instituteId = id
      }
      if(loanId){
        queryParams.loanId = loanId
      }
      if(rowsPerPage && currentPage){
        queryParams.perPage = rowsPerPage;
        queryParams.pageNo = pageNumber
      }
      if(paymentMode){
        queryParams.paymentMode = paymentMode
      }
    
      
      axiosInstance.get('/loan-repayment/all',{
        params:queryParams
      }, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
    
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    };
    function formatNumberWithCommas(number) {
      return number.toLocaleString('en-IN'); 
    }
    const [id,setId]=useState(null)
    const getInsituteInfo=(text)=>{
    
      const urlGetInstitute=`/institute/info/name?name=${text}`;
        
      if(text.length>=4)
      {
        axiosInstance.get(urlGetInstitute,{
          headers:{
            Authorization:`Bearer ${user}`
          }
        }).then((res) => {
          console.log("text",res?.data?.data[0]?.id)
          setId(res?.data?.data[0]?.id)
        }).catch((error)=>{console.log(error)})
    
     
      }
   
    }
    
    const handleInputChange = (event) => {
      setIsLoading(true)
      setSearchText(event.target.value)
      let queryParams = {}
      if(mobile){
        queryParams.mobile = mobile
      }
      if(customerName){
        queryParams.name = customerName
      }
      if(status){
        queryParams.status = status
      }
      if(duefilterDateFrom && duefilterDateTo){
        queryParams.fromDueDate = duefilterDateFrom;
        queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
      }
      if(paidfilterDateFrom && paidfilterDateTo){
        queryParams.fromPaidDate = paidfilterDateFrom;
        queryParams.toPaidDate = paidfilterDateTo+" "+"23:59:59"
      }
      if(id){
        queryParams.instituteId = id
      }
      if(loanId){
        queryParams.loanId = loanId
      }
      if(rowsPerPage && currentPage){
        queryParams.perPage = rowsPerPage;
        queryParams.pageNo = 1
      }
      if(paymentMode){
        queryParams.paymentMode = paymentMode
      }
    
      
      axiosInstance.get('/loan-repayment/all',{
        params:queryParams
      }, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
    
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
  };

  

  const handleInputCustomerChange = (event) => {
    setIsLoading(true)

    setCUstomerName(event.target.value)
    if(event.target.value.length >=4){
      let queryParams = {}
      if(mobile){
        queryParams.mobile = mobile
      }
      if(customerName){
        queryParams.name = customerName
      }
      if(status){
        queryParams.status = status
      }
      if(duefilterDateFrom && duefilterDateTo){
        queryParams.fromDueDate = duefilterDateFrom;
        queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
      }
      if(paidfilterDateFrom && paidfilterDateTo){
        queryParams.fromPaidDate = paidfilterDateFrom;
        queryParams.toPaidDate = paidfilterDateTo+" "+"23:59:59"
      }
      if(id){
        queryParams.instituteId = id
      }
      if(loanId){
        queryParams.loanId = loanId
      }
      if(rowsPerPage && currentPage){
        queryParams.perPage = rowsPerPage;
        queryParams.pageNo = 1
      }
      if(paymentMode){
        queryParams.paymentMode = paymentMode
      }
    
      
      axiosInstance.get('/loan-repayment/all',{
        params:queryParams
      }, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
    console.log("data",res?.data?.data?.data)
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }else{
      const url = `/loan-repayment/all?perPage=${rowsPerPage}&pageNo=${currentPage}`
      axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
  
        setTableData(res?.data?.data?.data);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
    }
   
};
const handleInputMobileChange = (event) => {
  setIsLoading(true)
  setMobile(event.target.value)
  if(event.target.value.length >=10){
    let queryParams = {}
    if(mobile){
      queryParams.mobile = mobile
    }
    if(customerName){
      queryParams.name = customerName
    }
    if(status){
      queryParams.status = status
    }
    if(duefilterDateFrom && duefilterDateTo){
      queryParams.fromDueDate = duefilterDateFrom;
      queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
    }
    if(paidfilterDateFrom && paidfilterDateTo){
      queryParams.fromPaidDate = paidfilterDateFrom;
      queryParams.toPaidDate = paidfilterDateTo+" "+"23:59:59"
    }
    if(id){
      queryParams.instituteId = id
    }
    if(loanId){
      queryParams.loanId = loanId
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = rowsPerPage;
      queryParams.pageNo = 1
    }
    if(paymentMode){
      queryParams.paymentMode = paymentMode
    }
  
    
    axiosInstance.get('/loan-repayment/all',{
      params:queryParams
    }, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
  console.log("data",res?.data?.data?.data)
      setTableData(res?.data?.data?.data);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
  }else{
    const url = `/loan-repayment/all?perPage=${rowsPerPage}&pageNo=${currentPage}`
    axiosInstance.get(url, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {

      setTableData(res?.data?.data?.data);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
  }
 
};

  function formatDate(inputDate) {
    const dateParts = inputDate.split('/');
    if (dateParts.length === 3) {
      const [day, month, year] = dateParts;
      const dateObject = new Date(`${year}-${month}-${day}`);
      
    
      const formattedDate = dateObject.toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
      });
  
      return formattedDate;
    } else {
      return inputDate;
    }
  }
  const [finstaLoanId,setFinstLoanId] = useState("")
  const [reasonCode,setReasonCode]=useState("2")
  const [discountAmount, setDiscountAmount] = useState("");
  const [selectedEMIs, setSelectedEMIs] = useState([]);
  const [totalAmount, setTotalAmount] = useState(null);
  const [repaymentTable,setRepaymentTable] = useState([])
  const [whatsappChecked, setWhatsappChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);

  
  const handleWhatsappCheckboxChange = () => {
    setWhatsappChecked(!whatsappChecked);
  };

  const handleEmailCheckboxChange = () => {
    setEmailChecked(!emailChecked);
  };

  const toggleModal = (item) => {
    setIsModalOpen(!isModalOpen);
    setEmiAmount(item?.emiAmount);
    setloanId(item?.loanId)
    setFinstLoanId(item?.finstaLoanId)
    sessionStorage.setItem("finstaId",item?.finstaLoanId)
    setEmiid(item?.emiId)

    if (!isModalOpen) {
    setClicked(false)
    setTotalAmount(null);
    setReasonCode("2");
    setDiscountAmount("");
    setRemarks(sessionStorage.getItem("finstaId"));
    setSelectedEMIs([]);
    setWhatsappChecked(false)
    setEmailChecked(false)
      const url = `/emis/overdue?loanId=${item?.loanId}`;
      axiosInstance.get(url, {
        headers: {
          Authorization: `Bearer ${user}`
        }
      }).then((res) => {
        setRepaymentTable(res?.data?.data);
      });
    }
 

  };
const  togglePaymentLinkModal = ()=>{
  setShowPaymentLinkModal(!showPaymentLinkModal)
}

const [link,setLink] = useState("")
const [showModeModal, setShowModeModal] = useState(false);


const toggleModeModal = (item)=>{
  console.log("item",item)
  setShowModeModal(!showModeModal)
  setLink(item?.paymentLink)
}
console.log("link",link)

const [charges,setCharges] = useState(null)

const handleCheckboxChange = (emiId, emiAmount,penalty,bounceCharges) => {
  console.log("type",typeof(emiAmount))
  let updatedSelectedEMIs;
  let updatedTotalAmount;
  let updatedTotalPenalty = 0;
  let updatedTotalBounceCharges = 0;

  if (selectedEMIs.includes(emiId)) {
   
    updatedSelectedEMIs = selectedEMIs.filter((id) => id !== emiId);
    updatedTotalAmount = totalAmount - emiAmount;
  } else {
    
    updatedSelectedEMIs = [...selectedEMIs, emiId];
    updatedTotalAmount = totalAmount + emiAmount;
    
  }
  updatedSelectedEMIs.forEach((selectedEmiId) => {
    const selectedItem = repaymentTable.find(item => item.emiId === selectedEmiId);
    if (selectedItem) {
      updatedTotalPenalty += selectedItem.penalty;
      updatedTotalBounceCharges += selectedItem.bounceCharges || 0; 
    }
  });
  setSelectedEMIs(updatedSelectedEMIs);
  setTotalAmount(updatedTotalAmount);
  setCharges(updatedTotalPenalty+updatedTotalBounceCharges)
};
const [clicked, setClicked] = useState(false);
const generatelink = ()=>{

  if(discountAmount > charges){
    alert("Discount Amount Exceeded maximum")
  }
  setClicked(true); 
      const data = {
        loanId:loanId,
        amount:totalAmount,
        reason:reasonCode,
        emiIds:selectedEMIs,
        whatsappNotification: 1,
        emailNotification: 1,
        discount:discountAmount,
        adjustment: 0,
        remarks:remarks,
      };
      const createUrl = `${process.env.REACT_APP_BASE_URL}/loan-repayment/payment-link`;
  
      axiosInstance.post(createUrl,data,{
        headers:{
          Authorization:`Bearer ${user}`
      }
      }).then((res)=>{
        console.log("paymentlink",res?.data?.data)
        setPaymentLink(res?.data?.data)
        setShowPaymentLinkModal(true);
        setClicked(false)
      }).catch((error)=>{console.log(error)
      alert(error?.response?.data?.message)
    setClicked(false)})
    
    
  }
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    alert('Payment link copied to clipboard!');
    // setTimeout(() => window.location.reload(true), 500);
  };
  const copyToClipboard1 = () => {
    navigator.clipboard.writeText(paymentLink);
    alert('Payment link copied to clipboard!');
    // setTimeout(() => window.location.reload(true), 500);
  };
  const [expandedRows, setExpandedRows] = useState([]);
  const [isOpen, setIsOpen] = useState(Array(tableData?.length).fill(false));

  useEffect(() => {
    setIsOpen(Array(tableData?.length).fill(false));
  }, [tableData]);
  const toggleRow = (index,item) => {
    const updatedIsOpen = [...isOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setIsOpen(updatedIsOpen);
    
  };
 
  const handleChange = (event) => {
    setIsLoading(true)
    setrowsPerPage(event.target.value);
    let queryParams = {}
    if(mobile){
      queryParams.mobile = mobile
    }
    if(customerName){
      queryParams.name = customerName
    }
    if(status){
      queryParams.status = status
    }
    if(duefilterDateFrom && duefilterDateTo){
      queryParams.fromDueDate = duefilterDateFrom;
      queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
    }
    if(paidfilterDateFrom && paidfilterDateTo){
      queryParams.fromPaidDate = paidfilterDateFrom;
      queryParams.toPaidDate = paidfilterDateTo+" "+"23:59:59"
    }
    if(id){
      queryParams.instituteId = id
    }
    if(loanId){
      queryParams.loanId = loanId
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = event.target.value;
      queryParams.pageNo = 1
    }
    if(paymentMode){
      queryParams.paymentMode = paymentMode
    }

    axiosInstance.get('/loan-repayment/all',{
      params:queryParams
    }, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {

      setTableData(res?.data?.data?.data);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
  };
  
  const navigate = useNavigate();


  const handleUser = (event) => {
    console.log("event",event)

    const Id = event.userId;
    sessionStorage.setItem('repaymentsUserId',Id)
    sessionStorage.setItem('repaymentsAppId',event.applicationId)
    const urlProfile = `/summary/user?id=${Id}&applicationId=${event.applicationId}&status=Disbursed`;
    const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/user?id=${Id}&applicationId=${event.applicationId}&status=Disbursed`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `${process.env.REACT_APP_BASE_URL}/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user));
      const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  
    dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));


    window.open(`/status/disbursed/profile`,"_blank")
    
  };
  const handleDownloadList=()=>{
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
    const formattedDate = `${day}-${month}-${year}`;
    document.getElementById("downloadButton").disabled=true;
    document.getElementById("downloadButton").innerText="Wait....";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "130px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    // document.getElementById("downloadButton").style.marginLeft="150px";
    document.getElementById("downloadButton").style.backgroundImage = "none"


    const downloadTemplateUrl=`${process.env.REACT_APP_BASE_URL}/excel/repayment-details}`
    let queryParams = {}
    if(mobile){
      queryParams.mobile = mobile
    }
    if(customerName){
      queryParams.name = customerName
    }
    if(status){
      queryParams.status = status
    }
    if(duefilterDateFrom && duefilterDateTo){
      queryParams.fromDueDate = duefilterDateFrom;
      queryParams.toDueDate = duefilterDateTo+" "+"23:59:59"
    }
    if(paidfilterDateFrom && paidfilterDateTo){
      queryParams.fromPaidDate = paidfilterDateFrom;
      queryParams.toPaidDate = paidfilterDateTo+" "+"23:59:59"
    }
    if(id){
      queryParams.instituteId = id
    }
    if(loanId){
      queryParams.loanId = loanId
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = 300;
      queryParams.pageNo = 1
    }
    if(paymentMode){
      queryParams.paymentMode = paymentMode
    }
    axiosInstance.get("/excel/repayment-details", {params:queryParams,
      responseType:'arraybuffer',
      headers:{
        Authorization:`Bearer ${user}`

    }
  }).then((res)=>{
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 2000);
    const blob = new Blob([res.data], { type: "application/xlsx", });
    const url= window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href =url
    link.download = `${formattedDate}_${status}.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
    
  ).catch((error)=>{
    alert("Error Downloading")
    document.getElementById("downloadButton").innerText="Error..";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      // document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='20px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='15px 10px'
    }, 1000);
  })
  } 

  const handleLoanProfile = (loanId,finstaLoanId)=>{
    sessionStorage.setItem("loanid",loanId)
    sessionStorage.setItem("legacyLoanId",finstaLoanId)
    const urlUsers = `/loan-repayment/profile?loanId=${sessionStorage.getItem("loanid")}`;
    const callDetailsUrl = `/call-history/details?loanId=${sessionStorage.getItem("loanid")}`;
    const urlGetReminder=`/call-history/reminder-details?loanId=${sessionStorage.getItem("loanid")}`
    const emihistoryUrl=`/emis/for-loan?loanId=${sessionStorage.getItem("loanid")}`

    dispatch(userActions.fetchCallDetails(callDetailsUrl, user),[])
    dispatch(userActions.fetchLoanDetails(urlUsers, user),[])
    dispatch(userActions.getReminder(urlGetReminder))
    dispatch(userActions.getEmiHistory(emihistoryUrl,user))
  
    const newWindow = window.open("/Loanprofile", "_blank");      
  }

    const [isOpen1,setIsOpen1]=useState(true)

    return(
        <div style={{display:'flex', flexDirection:'row',width:"100%",backgroundColor:'white',minHeight:'100vh'}}>
        <Sidebar isOpenSidebar={isOpen1} handler={setIsOpen1}/>
        <div style={{width:isOpen1?'78%':'98%', overflow:"auto",marginLeft:isOpen1?"21%":'0%',}}>
                <Topbar/>
                <div >
                <div style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                    <div>
                    <h1 style={{fontFamily:'Inter-Medium',fontSize:'1vw',fontWeight:'500',color:'#D32028',marginTop:'10px'}}>Repayments</h1>
                    <FormLabel id="demo-row-radio-buttons-group-label" style={{marginTop:'10px',fontFamily:'Inter-Medium'}}>Filters</FormLabel>
                    </div>
                     <div style={{backgroundColor:'#D22129',display:'flex',alignItems:'center',width:'130px',height:'34px',justifyContent:'space-around',borderRadius:'5px',cursor:'pointer'}} onClick={clearDateFilter}>
                      <img src={clear} style={{height:'18px'}}/>
                      <div style={{color:'white',display:'flex',alignItems:'center'}}>Clear Filter</div>
                    </div>
                    
                  </div>

      <div style={{display:'flex',justifyContent:'space-between',width:'100%',marginTop:'10px',marginBottom:'20px'}}>
          
          <div >
          <div style={{ height: '23px',fontFamily:'Inter-Medium'  , position: 'relative', display: 'flex', alignItems: 'center' ,marginLeft:'3px'}}>
          <Input
                type="text"
                placeholder="Enter Institute Name"
                onChange={(event) => {
                  getInsituteInfo(event.target.value);
                  handleInputChange(event);
                }}
                style={{
                  fontSize: '14px',
                  paddingLeft: '26px',
                  height: '23px',
                  fontFamily: 'Inter-Medium',
                  backgroundImage: `url(${search})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '5px center',
                  backgroundSize: '15px 15px',
                  paddingRight: '5px', 
                  borderColor:'#cccccc',
                  borderStyle:'solid',
                  borderRadius:"5px",
                  borderWidth:'1px',
                  height:'34px',
                  marginTop:'11px',width:'310px'
                }}
              />
        </div>
        
          </div>
        
        
          <div  >
            <div style={{display:'flex'}}>

            <div style={{ height: '7px',width:'9.5em' }}>
            <Datetime
                closeOnSelect="false"
                id="fromDate"
                dateFormat="DD MMM YYYY"
                timeFormat={false}
                onChange={(event) =>{ 
                  if (event && event.format) {
                  setPaidFilterDateFrom(event.format('DD MMM YYYY'));
                }else{
                  setPaidFilterDateFrom('');
                  clearDateFilter()
                }
                }}

                locale="en-gb"
                inputProps={{ placeholder: 'Paid Date From',style:{fontFamily:'Inter-Medium', backgroundImage: `url(${calender})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '5px center',
                backgroundSize: '15px 15px', paddingLeft: '30px',fontSize:"14px"} }}
              />
            </div>
            <div style={{ height: '7px',width:'9.5em',marginLeft:'10px'}}>
            <Datetime
              closeOnSelect="false"
                id="toDate"
                dateFormat="DD MMM YYYY"
                timeFormat={false}
                locale="en-gb"
                onChange={(event) => {
                  if (event && event.format) {
                    setPaidFilterDateTo(event.format('DD MMM YYYY'));
                  } else {
                    setPaidFilterDateTo(''); 
                    clearDateFilter()
                  }
                }}

                inputProps={{ placeholder: 'Paid Date To',style:{fontFamily:'Inter-Medium', backgroundImage: `url(${calender})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '5px center',
                backgroundSize: '15px 15px', paddingLeft: '30px',fontSize:"14px"} }}
              />
            </div>
            </div>
           
          </div>
          
          <div  >
            <div style={{display:'flex'}}>
            <div style={{ height: '7px',width:'9.5em'}}>
            <Datetime
                    closeOnSelect="false"
                    id="fromDate"
                    dateFormat="DD MMM YYYY"
                    timeFormat={false}
                    onChange={(event) =>{ 
                      if (event && event.format) {
                      setDueFilterDateFrom(event.format('DD MMM YYYY'));
                    }else{
                      setDueFilterDateFrom('');
                      clearDateFilter()
                    }
                    }}

                    locale="en-gb"
                    inputProps={{ placeholder: 'Due Date From',style:{fontFamily:'Inter-Medium', backgroundImage: `url(${calender})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '5px center',
                    backgroundSize: '15px 15px', paddingLeft: '30px',fontSize:"14px"} }}
                  />
            </div>
            <div style={{ height: '7px',width:'9.5em' ,marginLeft:'10px'}}>
            <Datetime
                  closeOnSelect="false"
                    id="toDate"
                    dateFormat="DD MMM YYYY"
                    timeFormat={false}
                    locale="en-gb"
                    onChange={(event) => {
                      if (event && event.format) {
                        setDueFilterDateTo(event.format('DD MMM YYYY'));
                      } else {
                        setDueFilterDateTo(''); 
                        clearDateFilter()
                      }
                    }}

                    inputProps={{ placeholder: 'Due Date To',style:{fontFamily:'Inter-Medium', backgroundImage: `url(${calender})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '5px center',
                    backgroundSize: '15px 15px', paddingLeft: '30px',fontSize:"14px"} }}
                  />
            </div>
            </div>
           
          </div>
       
          <div >
          <div > 
          <button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium",paddingLeft:'10px'}}
            
            onClick={() => handleDateFilterApi(paidfilterDateFrom,paidfilterDateTo,duefilterDateFrom,duefilterDateTo)}
            >
              Search Results
            </button>
            </div>
          </div>
          
       
      </div>
      <div style={{display:'flex',justifyContent:'space-between',width:'100%',marginTop:'10px',marginBottom:'20px'}}>
        
        <div style={{display:'flex'}}>
        <div style={{ height: '23px',fontFamily:'Inter-Medium'  , position: 'relative', display: 'flex', alignItems: 'center' ,marginLeft:'3px'}}>
        <Input
                type="text"
                placeholder="Customer Name"
                onChange={(event) => {
                  handleInputCustomerChange(event);
                }}
                style={{
                  fontSize: '14px',
                  paddingLeft: '26px',
                  height: '23px',
                  fontFamily: 'Inter-Medium',
                  backgroundImage: `url(${search})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '5px center',
                  backgroundSize: '15px 15px',
                  paddingRight: '5px', 
                  borderColor:'#cccccc',
                  borderStyle:'solid',
                  borderRadius:"5px",
                  borderWidth:'1px',
                  height:'34px',
                  marginTop:'11px',width:'150px'
                }}
              />
      </div>
      <div style={{ height: '23px',fontFamily:'Inter-Medium'  , position: 'relative', display: 'flex', alignItems: 'center' ,marginLeft:'10px'}}>
        <Input
                type="text"
                placeholder="Legacy Loan Id"
                onChange={(event) => {
                  handleInputCustomerChange(event);
                }}
                style={{
                  fontSize: '14px',
                  paddingLeft: '26px',
                  height: '23px',
                  fontFamily: 'Inter-Medium',
                  backgroundImage: `url(${search})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '5px center',
                  backgroundSize: '15px 15px',
                  paddingRight: '5px', 
                  borderColor:'#cccccc',
                  borderStyle:'solid',
                  borderRadius:"5px",
                  borderWidth:'1px',
                  height:'34px',
                  marginTop:'11px',width:'150px'
                }}
              />
      </div>
        </div>
      
      
        <div  >
          <div style={{display:'flex'}}>

          <div style={{ height: '7px',width:'9.5em' }}>
         
          <Input
                type="text"
                placeholder="Mobile"
                onChange={(event) => {
                  handleInputMobileChange(event)
                }}
                style={{
                  fontSize: '14px',
                  paddingLeft: '26px',
                  height: '23px',
                  fontFamily: 'Inter-Medium',
                  backgroundImage: `url(${search})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '5px center',
                  backgroundSize: '15px 15px',
                  paddingRight: '5px', 
                  borderColor:'#cccccc',
                  borderStyle:'solid',
                  borderRadius:"5px",
                  borderWidth:'1px',
                  height:'34px',
                
                }}
              />
          </div>
          <div style={{ height: '7px',width:'9.5em',marginLeft:'10px'}}>
          <Input type="select"  style={{color:'#667085',fontSize:'14px',fontFamily:'Inter-Medium'}} onChange={(e)=>{handleModeChange(e);setMode(e.target.value)}}>
            <option value="" >Mode of Paymt</option>
            <option value="1">E Mandate</option>
            <option value="2">Upi</option>
            <option value="3">Manual</option>
            <option value="4">Digio</option>

          </Input>
          </div>
          </div>
         
        </div>
           
        <div  >
          <div style={{display:'flex'}}>
          <div style={{ height: '7px',width:'9.5em'}}>
          <Input type="select" id="status" style={{color:'#667085',fontSize:'14px',fontFamily:'Inter-Medium'}} onChange={(e)=>{handleStatusChange(e);setStatus(e.target.value)}}>
            <option value="" >Status</option>
              <option value="1">Initiated</option>
              <option value="2">Created</option>
              <option value="3">Successful</option>
              <option value="4">Failed</option>
              <option value="5">Initiation Failed</option>
          </Input>
          </div>
          <div style={{ height: '7px',width:'9.5em' ,marginLeft:'10px'}}>
          <Input type="select" id="page" style={{color:'#667085',fontSize:'14px',fontFamily:'Inter-Medium'}} onChange={(event)=>handleChange(event)}>
            <option value="15" >Per Page 15</option>
            <option value="50">Per Page 50</option>
            <option value="100">Per Page 100</option>
            <option value="500">Per Page 500</option>

          </Input>
          </div>
          </div>
         
        </div>
     
        <div >
        <div > 
      
        <div style={{backgroundColor:'#D22129',display: 'flex', alignItems: 'center',width:'130px',height:'34px',borderStyle:'none',borderRadius:"5px",fontFamily:"Inter-Medium",paddingLeft:'10px',justifyContent:'space-around',cursor:'pointer'}}
       id="downloadButton"  onClick={() => handleDownloadList()} >
             
         <img src={download} style={{height:'20px',marginLeft:'8px'}}/> 
         <span style={{fontFamily:"Inter-Medium",color:'white',paddingRight:"15px"}}>Export</span>
        
          </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} toggle={toggleModal} backdrop="static" size="xl">
        <ModalHeader toggle={toggleModal}>Payment Link</ModalHeader>
        <ModalBody >
        <div className='tables2' >
                    <table hover  style={{width:'100%'}}>
                    <thead >
                    <tr style={{height:'30px',backgroundColor:'#F9FAFB'}}>
                    <th style={{ textAlign: 'left', fontFamily: 'Inter-Medium', fontSize: '0.9vw', color: '#667085', paddingLeft: '10px', borderTopLeftRadius: '8px' }}>
                      <span style={{ color: '#667085', fontFamily: 'Inter-Medium', fontSize: '0.9vw' }}>Select</span>
                    </th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',paddingLeft:'10px',borderTopLeftRadius:'8px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>EMI No</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',paddingLeft:'10px' }}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>EMI Amount</span></th>
                    {/* <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',width:'10em' }}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>InstName</span></th> */}
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085' ,paddingLeft:'10px'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw'}}>Repymt Due On</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Delay Charges</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Ovd Days</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Penalty</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Bounce Charges</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter-Medium',fontSize:'0.9vw' ,color:'#667085'}}><span style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Total Ovd</span></th>
                    </tr>
                    </thead>
                    {repaymentTable?.map((item,index) => (
                        <>
                      <tbody className='table-body1'> 
                      <tr >
                      <td style={{paddingLeft:'20px'}}>
                      <input
                        type="checkbox"
                        checked={selectedEMIs.includes(item?.emiId)}
                        onChange={() => handleCheckboxChange(item?.emiId, item?.overdueAmount,item?.penalty,item?.bounceCharges)}
                      />
                      </td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.emiNo}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.emiAmount}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.dueDate.substr(0, 10)}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.delayCharges}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.overdueDays}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.penalty}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.bounceCharges ? item?.bounceCharges : "-"}</span></td>
                      <td style={{color:'#667085',fontFamily:'Inter-Medium',fontSize:'0.9vw' }}><span style={{fontFamily:'Inter-Medium',fontSize:'0.9vw',color:'#667085',fontWeight:'500',paddingLeft:'20px'}}>	{item?.overdueAmount}</span></td>

                      </tr>
                      {index !== repaymentTable.length - 1 && (
        <tr>
          <td colSpan="12" style={{ borderTop: '1px solid #ccc' }}></td>
        </tr>
      )}    
               </tbody>
                  </>
                    ))}
                    
                   
                   </table>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                      <div style={{width:'100%'}}>
                      <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw',marginTop:'20px'}}>LoanId</p>
                      <InputGroup>
                          <Input value={finstaLoanId} disabled/>
                        </InputGroup>
                      </div>
                      <div style={{ width: '100%', marginLeft: '20px' }}>
                        <p style={{ lineHeight: '1em', fontFamily: 'Inter-Medium', fontSize: '0.9vw', marginTop: '20px' }}>Amount</p>
                        <InputGroup>
                        <Input 
                          value={totalAmount} 
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            setTotalAmount(isNaN(value) ? 0 : value);
                          }} 
                        />                        
                        </InputGroup>
                      </div>
                    </div>
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                      <div style={{width:'100%'}}>
                      <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw',marginTop:'30px'}}>Category</p>
                    <select onChange={(e)=>setReasonCode(e.target.value)} value={reasonCode}   style={{border:'1px solid #D0D0D0',width:'100%',borderRadius:'5px',padding:'8px'}}  >
                      <option value="1">Preclosure</option>
                      <option value="2">Overdue</option>
                      <option value="3">Single EMI payment</option>
                      <option value="4">Multiple EMIs</option>
                      <option value="5">Settlement</option>
                      <option value="6">Refund By Institute</option>
                    </select>
                      </div>
                      <div style={{width:'100%',marginLeft:'20px'}}>
                      <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw',marginTop:'30px'}}>Discount Amount</p>
                      <InputGroup>
                          <Input value={discountAmount} onChange={(e) => setDiscountAmount(e.target.value)}/>
                        </InputGroup>
                      </div>
                    </div>
                    
                      <p style={{lineHeight:'1em',fontFamily:'Inter-Medium',fontSize:'0.9vw',marginTop:'30px'}}>Remarks</p>
                    <InputGroup>
                        <Input onChange={(e)=>setRemarks(e.target.value)} value={remarks}/>
                      </InputGroup>
                      <div style={{display:'flex',alignItems:'center',justifyContent:'space-around'}}>
                     
                      <FormGroup check style={{marginTop:'10px'}}>
                        <Label check >
                          WhatsApp
                        </Label>
                        <Input type="checkbox" style={{marginTop:'12px'}} onChange={handleWhatsappCheckboxChange} checked={whatsappChecked}/>{' '}
                      </FormGroup>
                      <FormGroup check style={{marginTop:'10px'}}>
                        <Label check >
                          Email
                        </Label>
                        <Input type="checkbox" style={{marginTop:'12px'}} onChange={handleEmailCheckboxChange} checked={emailChecked}/>{' '}
                      </FormGroup>
                      </div>
          
          
        </ModalBody>
        <Button style={{backgroundColor:'#D32028',border:'none',width:'25%',display:'flex',justifyContent:'center',marginLeft:'35%',marginBottom:'15px',marginTop:'10px',whiteSpace:'nowrap',transform: clicked ? 'scale(1.1)' : 'none',
        transition: 'transform 0.3s ease',}} onClick={generatelink}>
            Generate Link
          </Button>
        </Modal>
        <Modal isOpen={showPaymentLinkModal} toggle={togglePaymentLinkModal} backdrop="static" style={{top:'10%'}}>
        <ModalHeader toggle={togglePaymentLinkModal}>Payment Link</ModalHeader>
        <ModalBody>
          <p style={{fontFamily:'Inter-Medium'}}> {paymentLink}{" "}
          <span onClick={copyToClipboard1} style={{ cursor: 'pointer' }}>
              <img src={copy} style={{height:'20px',width:'20px',marginLeft:'50px'}} title='copy to clipboard'/>
            </span>
          </p>
        </ModalBody>
      </Modal>
      <Modal isOpen={showModeModal} toggle={toggleModeModal} backdrop="static" style={{top:'10%'}}>
        <ModalHeader toggle={toggleModeModal}>Payment Link</ModalHeader>
        <ModalBody>
          <p style={{fontFamily:'Inter-Medium'}}> {link}{" "}
          <span onClick={copyToClipboard} style={{ cursor: 'pointer' }}>
              <img src={copy} style={{height:'20px',width:'20px',marginLeft:'50px'}} title='copy to clipboard'/>
            </span>
          </p>
        </ModalBody>
      </Modal>
                {/* <Input  style={{border:'1px solid #D0D5DD',width:'50%',height:'40px',borderRadius:'10px',marginTop:'20px',fontFamily: "Inter-Medium"}}type='text' placeholder='Enter at least 5 characters of Applicant Name or Phone number'/>  */}
                {isLoading ? (
                  <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%'}}>
                    <CircularProgress />
                  </Box>
                ):(
                  <>
                   <div className='tables' >
                
                <table hover  style={{width:'100%'}}>
                <thead className='table-heading'>
                    <tr style={{height:'30px',backgroundColor:'#F9FAFB'}}>
                    <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',paddingLeft:'20px',width:'12em',borderTopLeftRadius:'8px'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>Loan ID</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',width:'12em' }}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>CustName</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',width:'8em' }}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>Mobile</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw',color:'#667085',width:'7em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw'}}>Loan Amt.</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085',width:'5em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',whiteSpace:'nowrap'}}>EMI No.</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085',width:'7em' }}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Emi Amt.</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085',width:'7em'  }}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Paid Amt.</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085',width:'7em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Due Date</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085',width:'8em' }}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Paid Date</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085' ,width:'4em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',whiteSpace:'nowrap'}}>Mode</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085' ,width:'10em'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',whiteSpace:'nowrap',marginLeft:'10px'}}>Status</span></th>
                    <th style={{textAlign:'left',fontFamily:'Inter',fontSize:'0.9vw' ,color:'#667085',width:'8em' ,borderTopRightRadius:'8px'}}><span style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px'}}>Actions</span></th>
                    </tr>
                </thead>
                    {tableData?.map((item,index) => (
                        <>
                      <tbody className='table-body1'> 

                    <tr className='table-row' key={index} style={{margin:'50px'}}>
                        <td onClick={()=>handleLoanProfile(item?.loanId,item?.finstaLoanId)} style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',paddingLeft:'20px',maxWidth:'12em',cursor:'pointer' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',color:'#101828',fontWeight:'500',color:'#101828',fontWeight:'500'}}>{item.finstaLoanId}</span></td>
                        <td title={item.userName} style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',maxWidth:'12em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',color:'#667085',fontWeight:'500',lineHeight:'1.5em',display:'block',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',cursor:'pointer',color:'#101828',fontWeight:'500'}} onClick={() => handleUser(item)}>{item?.userName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span></td>
                        <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',maxWidth:'8em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',color:'#667085',fontWeight:'500',lineHeight:'1.5em'}}>{item.mobile?item.mobile:'-'}</span></td>
                        <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',maxWidth:'7em' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',color:'#667085',fontWeight:'500'}}>{item.loanAmount ? ( <>&#8377;{formatNumberWithCommas(item.loanAmount)}</>) : ( '-')}</span></td>
                        <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500' ,maxWidth:'5em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',color:'#667085',fontWeight:'500'}}>{item.emiNo?item.emiNo:'-'}</span></td>
                        <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',maxWidth:'7em' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',color:'#667085',fontWeight:'500'}}>{item.emiAmount ? ( <>&#8377;{formatNumberWithCommas(item.emiAmount)}</>) : ( '-')}</span></td>
                        <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',maxWidth:'7em' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',color:'#667085',fontWeight:'500'}}>{item.repaymentAmount ? ( <>&#8377;{formatNumberWithCommas(item.repaymentAmount)}</>) : ( '-')}</span></td>
                        <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',maxWidth:'7em' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',color:'#667085',fontWeight:'500'}}>{formatDate(item.emiDueDate)!=="Invalid date" ? formatDate(item.emiDueDate):'-'}</span></td>
                        <td style={{color:'#667085',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',maxWidth:'7em' }}><span style={{fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'500',color:'#667085',fontWeight:'500'}}>{formatDate(item.paidDate)!=="Invalid date" ? formatDate(item.paidDate) :'-'}</span></td>
                        <td style={{fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'400',maxWidth:'10em' }}>
                        <span>
                          {item.repaymentType ? (
                            item.repaymentType === 1? (
                              <div title='E Mandate' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'25px',width:'25px',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontWeight:'bold',padding:'5px',cursor:'pointer'}} onClick={()=>toggleModeModal(item)}>EM</div>
                            ) : item.repaymentType === 2 ? (
                              <div title='UPI' style={{fontSize:'12px',backgroundColor:'#F8A31F',borderStyle:'solid',borderRadius:'50%',border:'none',height:'25px',width:'25px',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontWeight:'bold',padding:'5px',cursor:'pointer'}} onClick={()=>toggleModeModal(item)}>U</div>
                            ) :item.repaymentType === 3 ? (
                              <div title='Manual' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'25px',width:'25px',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontWeight:'bold',padding:'5px',cursor:'pointer'}} onClick={()=>toggleModeModal(item)}>M</div>
                            ) :item.repaymentType === 4 ? (
                              <div title='Digio' style={{fontSize:'12px',backgroundColor:'#EDC7E2',borderStyle:'solid',borderRadius:'50%',border:'none',height:'25px',width:'25px',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',fontWeight:'bold',padding:'5px',cursor:'pointer'}} onClick={()=>toggleModeModal(item)}>D</div>
                            ) :
                             (
                              item.repaymentType
                            )
                          ) : (
                            "-"
                          )}
                          </span>
                         
                          </td>
                          <td style={{maxWidth:'7em'}}>
                            {item.status?
                            item.status == 1?<span style={{backgroundColor:'#EFE4FF',color:'#6E24E7', borderRadius:'1em',padding:'5px',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'400'}}><img style={{marginRight:'5px'}} src={initiateddot}/>Initiated</span>
                            :item.status==2?<span style={{backgroundColor:'#EFE4FF',color:'#6E24E7', borderRadius:'1em',padding:'5px',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'400'}}><img style={{marginRight:'5px'}} src={initiateddot}/>Created</span>
                            :item.status==3?<span style={{backgroundColor:'#ECFDF3',color:'#12B76A',borderRadius:'1em',padding:'5px',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'400'}}><img style={{marginRight:'5px'}} src={activedot}/>Successful</span>
                            :item.status==4?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'1em',padding:'5px',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'400'}}><img style={{marginRight:'5px'}} src={closeddot}/>Failed</span>
                            :item.status==5?<span style={{backgroundColor:'#FEEAEB',color:'#D22129',borderRadius:'1em',padding:'5px',fontFamily:'Inter',fontSize:'0.9vw',fontWeight:'400',whiteSpace:'nowrap'}}><img style={{marginRight:'5px'}} src={closeddot}/>Int.Failed</span>
                            :item.status
                            :'-'}
                          </td>
                          <td style={{color:'#667085',fontFamily:'Inter',fontSize:'1vw',maxWidth:'7em',display:'none' }}><span style={{fontFamily:'Inter',fontSize:'1vw',color:'#667085',fontWeight:'500'}}>{item.status?item.status:'-'}</span></td>
                        <td style={{maxWidth:'6em'}}>
                        <a title='whatsApp' href={`https://wa.me/+91${item.mobile}`} target="_blank" rel="noreferrer">
                        <WhatsAppIcon color="action" />
                        </a>
                          {/* <img style={{height:'22px',width:'24px',marginLeft:'5px',cursor:'pointer'}} src={repayment} onClick={()=>toggleModal(item)}/> */}
                          <img style={{height:'22px',width:'24px',marginLeft:'5px',cursor:'not-allowed'}} src={repayment} />
                          <img style={{height:'22px',width:'22px',marginLeft:'5px',cursor:'pointer',borderColor:'gray',borderWidth:'2px',borderStyle:'solid',borderRadius:'50%',padding:'4px'}} src={plus} onClick={() => toggleRow(index,item)}/> 
                          </td>
                    </tr>
                    {isOpen[index] && (
                          <tr>
                            <td colSpan="10">
                              <EmiDetails item={item} />
                            </td>
                          </tr>
                        )}  
              {index !== tableData.length - 1 && (
        <tr>
          <td colSpan="12" style={{ borderTop: '1px solid #ccc' }}></td>
        </tr>
      )}    
               </tbody>
                  </>
                    ))}

                </table>
            
            </div>
            {totalFetchRows ? ( totalFetchRows <= 10 ?(
               <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',fontWeight:'400',marginLeft:'10px',marginBottom:'5px' }}>
               Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{' '}
               records of {totalFetchRows}
             </div>
            ):( <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '0.9vw',fontWeight:'400',marginLeft:'10px',marginBottom:'5px' }}>
            Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{' '}
            records of {totalFetchRows}
          </div>)
             
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw',fontWeight:'400' }}>No Records</div>
            )}
            <PaginationTable
              startPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={totalFetchRows}
              paginate={paginate}
              handleChange={handleChange}
              searchText={searchText}
              customerName={customerName}
              status={status}
              paymentMode={paymentMode}
              duefilterDateFrom={duefilterDateFrom}
              paidfilterDateFrom={paidfilterDateFrom}
            />
                  </>
                )}
               
                
              
                </div>
            </div>
        </div>
    )
}
export default RepaymentTable;