import React from 'react'
import {useDispatch,useSelector} from 'react-redux';

import clock0 from '../../../assets/images/clock0.png'
import clock1 from '../../../assets/images/clock1.png'
import delete_rm from '../../../assets/images/delete_rm.png'
import {userActions} from '../../../actions/user.actions'
import dayjs from 'dayjs';
import 'moment-timezone';
import moment from 'moment';
import jwt_decode from 'jwt-decode'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
 
import Button from '@mui/material/Button';
 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
 
import DialogTitle from '@mui/material/DialogTitle';



export default function AddReminder({item,getReminderState}) {

  const user=sessionStorage.getItem('user')
  const decode=jwt_decode(user)
  const today = dayjs();
  const dispatch=useDispatch();
  


  const addReminderState=useSelector((state)=>state?.createReminder?.res?.data?.data)
  
  let currReminders=getReminderState?.filter(itm=>itm.callHistoryId===item.callHistoryId)

  function binarySearch(date,array){
     
    let low=0;
    let high=array?.length-1

    while(low<=high)
    {
      var mid=Math.floor((low+high)/2)
       
      if(array[mid]?.date===date)
      {
        return true;
      }
      else if(array[mid]?.date<date)
      {
        low=mid+1;
      }
      else 
      {
        high=mid-1;
      }
    }
    return false;


  }
  

    
   
    
   
    
  
  
  const [selectedDate, setSelectedDate] = React.useState(null);

  const parsedDate = moment(selectedDate);

   
  
  const[openModal,setOpenModal]=React.useState(null);

  async function addCallReminder(){
    
    
    console.log(moment(parsedDate?._i?.$d).subtract(5, 'hours').subtract(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),"selected Date")
    
    const urlCreateReminder='/call-history/create-reminder'
    const body={
        callHistoryId: item.callHistoryId,
        loanId: sessionStorage.getItem('loanid'),
        status: item.status,
        userId:decode.user_id,
        date: moment(parsedDate?._i?.$d).subtract(5, 'hours').subtract(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
        createdBy: decode.display_name,
    }
    if(selectedDate &&((moment(parsedDate?._i?.$d).format('YYYY-MM-DD HH:mm:ss')>moment(today?.$d).format('YYYY-MM-DD HH:mm:ss')) ))
    {
      console.log(body)
      dispatch(userActions.createReminder(urlCreateReminder,body,user))
      setOpenModal(!openModal)
      
      window.location.reload()
    }
    else
    {
      alert("Select Date and Time")
    }

  }


  
  const callDetailsUrl = `/call-history/details?loanId=${sessionStorage.getItem('loanid')}`
  const deleteReminder=()=>{
      
      const urlDeleteReminder=`/call-history/delete-reminder`
      const body={
        id:currReminders[0].id
      }

      dispatch(userActions.deleteReminder(urlDeleteReminder,body,user))
      
      dispatch(userActions.fetchCallDetails(callDetailsUrl, user),[])
      window.location.reload()

  }
 
  return (
    <div >
     <Dialog style={{marginTop:'-20%'}} fullWidth={true}   open={openModal} onClose={setOpenModal}>
        <DialogTitle style={{backgroundColor:'#F3F3F3'}}>Add Reminder</DialogTitle>
        <DialogContent style={{marginTop:'1em'}}>
           <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                            components={[
                            'DesktopDateTimePicker',
                            ]}
                        >
                            <DemoItem label="Select Date and Time" >
                            <DesktopDateTimePicker  defaultValue={today}
                            disablePast
                            value={selectedDate}
                            views={['year', 'month', 'day', 'hours', 'minutes']} 
                            onChange={(newDate) => setSelectedDate(newDate)}/>
                            </DemoItem>
                        </DemoContainer>
          </LocalizationProvider>

          
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setOpenModal()}>Cancel</Button>
          <Button onClick={()=>addCallReminder()}>Create Reminder</Button>
        </DialogActions>
      </Dialog>
   { item.callHistoryId!==currReminders?.[0]?.callHistoryId? <img style={{cursor:'pointer'}} onClick={()=>setOpenModal(!openModal)} src={clock0}/> : <><img  src={clock1}/> <img style={{marginLeft:'1em',cursor:'pointer'}} title="Delete Reminder" onClick={()=>deleteReminder()}src={delete_rm}/></>}
   
    
    
    </div>
  )
}
