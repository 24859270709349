import { userConstants } from './../constants/user.constants';

let user = sessionStorage.getItem('user');
const initialState = user ? { loggedIn: false, user, isLoading: false } : {};

//ckyc
export function ACTIVITY(state = initialState, action) {
  console.log("inside ACTIVITY function", action)
  switch (action.type) {
    case userConstants.ACTIVITY_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: action.user,
        isLoading : true

      };
    case userConstants.ACTIVITY_SUCCESS:
        console.log("Action", action)
      return {
      ...state,
        loggedIn: true,
        activityData: action,
        isLoading : false
      };
    case userConstants.ACTIVITY_FAILURE:
      return {
        ...state,
        isLoading : false
      };
    default:
      return state
  }
}

 