import React from 'react';
import {useDispatch,useSelector} from 'react-redux';
import { userActions } from '../../actions/user.actions';
import jwt_decode from 'jwt-decode'
import notifications0 from '../../assets/images/notification0.png'
import close from '../../assets/images/close.png'
import activeDot from '../../assets/images/activeDot.png'
import notificationDot from '../../assets/images/notificationDot.png'
import axiosInstance from '../../helpers/axios'
import dayjs from 'dayjs';
import moment from 'moment';
import notification from './notification.wav'

import './index.css'

export default function Notifications() {

  const user=sessionStorage.getItem('user')
  const today = dayjs();

  const decode=jwt_decode(user)
  const dispatch=useDispatch();
  const getReminderState=useSelector((state)=>state?.getReminder?.res?.data?.data)
  const notificationState=useSelector((state)=>state?.getNotifcations?.res?.data?.data)
  const [notificationList,setNotificationList]=React.useState(null)

  const urlGetNotifications=`/in-app-notifications/get-notifications?userId=${decode.user_id}`
 
  const [reminders,setReminders]=React.useState(null)
function binarySearch(date,array){
     
    let low=0;
    let high=array?.length-1

    while(low<=high)
    {
      var mid=Math.floor((low+high)/2)
       
      if(array[mid]?.date===date)
      {
        return true;
      }
      else if(array[mid]?.date<date)
      {
        low=mid+1;
      }
      else 
      {
        high=mid-1;
      }
    }
    return false;


  }
  

  const urlGetReminder=`/call-history/reminder-details?userId=${decode.user_id}`
  React.useEffect(() => {

     if(getReminderState)
    {
        setReminders(getReminderState)
      var trigger
      setInterval(() => {
        var now=new dayjs()
         trigger  = binarySearch(moment(now?.$d).format('YYYY-MM-DD HH:mm:ss'),getReminderState)
          if(trigger)
        {
          dispatch(userActions.getNotifications(urlGetNotifications))
            setTimeout(() => {
              const audio=new Audio(notification);
              audio.play();
            }, 2000);
            
        }
      
      }, 1000);
     
    }
    else
    {
        dispatch(userActions.getReminder(urlGetReminder))
    }
    
    
    if(notificationState)
    { 
      setNotificationList(notificationState?.details?.reverse())   
    }
    else
    {
      dispatch(userActions.getNotifications(urlGetNotifications))
    }
   
  }, [notificationState,getReminderState])
  
const [show,setShow]=React.useState(false)
const showNotifications=()=>{
  setShow(!show);
  if(!show)
  document.getElementById('notifications').className="showNotifications"
else
document.getElementById('notifications').className="notifications"
}

const handleReadUnread=(nItem)=>{
  if(nItem.read!==1)
  {
    setShow(true)
    const urlRead=`/in-app-notifications/update-notification?notificationId=${nItem.id}&read=1`
    
    axiosInstance.post(urlRead)
    .then((res)=>{
      dispatch(userActions.getNotifications(urlGetNotifications))
    })
    .catch((err)=>{
      window.alert("Minor Error in Notifications")
    })
  }
  

}

  return (
    <div onClick={()=>showNotifications()} id="notifications" className='notifications'>
      {!show?<><img  style={{paddingLeft:'6px',width:'1.8em'}} src={notifications0}/>{notificationState?.unReadCount?<h6 style={{marginTop:'-3em',marginLeft:'2em',position:'absolute',padding:'3px',borderRadius:'8em',backgroundColor:'#d32028',color:'white'}}>{notificationState?.unReadCount}</h6>:<></>}</>:<><img  style={{paddingLeft:'6px',width:'1.8em'}} src={notifications0}/></>}
     <div>
      <div className="notificationHeading" >
        <div style={{display:'flex',justifyContent: 'space-around',}}>
          <img style={{width:'22px',height:'25px',marginLeft:'8px',marginTop:'3px'}} src={notifications0}/><p style={{marginTop:'-3px',fontSize:'20px'}}> Notifications </p><p style={{marginLeft:'40%',marginTop:'-4px' }}> <img onClick={()=>showNotifications()} src={close}/>
        </p>
      </div>
      </div>
      <div style={{overflowY:'auto',height:'15em',}}>
     { notificationList?.map((item)=>{
     return(
      <div onClick={()=>handleReadUnread(item)} title={item?.message} style={{backgroundColor:item.read?"#FFF":"#F3F3F3",lineHeight:'1.2em', display:'flex',flexDirection: 'row', borderBottom:'2px dashed #DBDBDB',padding:'10px',paddingBottom:'0px',fontSize:'14px',}}><img src={activeDot} style={{marginRight:'10px',height:'8px',width:'8px',marginTop:'0.8em'}}/><p style={{paddingTop:'5px'}}>{item.message.substr(0,45)}...</p></div> 
     )
     }
     )
      }
      
      </div>
      <div style={{height:'1em'}}>
      </div>
    </div> 
    </div>
  )
}
