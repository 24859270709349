import React,{useState,useEffect} from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap'
import axiosInstance from '../../helpers/axios'
import deleteIcon from '../../assets/images/delete_icon.png'
import './roles.css'

export default function DeleteRole({deleteUser}) {

  const UserType = {
    Admin: 'admin',
    LoanProcessor: 'loan_processor',
    User: 'user',
    InstituteUser: 'institute_user',
    CoLender: 'co_lender',
    Finance:'finance',
    Collection:'collection',
    InstituteAdmin:'institute_admin'
}
  const user=sessionStorage.getItem('user')
  const [openDeleteRole,setDeleteRole]=useState(false)
  const [selectRoleOpen,setSelectRoleOpen]=useState(false)
  
  const [selectedRole,setSelectedRole]=useState("Role")
  const [updatedName,setUpdatedName]=useState(null)
  const [updatedMobile,setUpdatedMobile]=useState(null)
  const [updatedEmail,setUpdatedEmail]=useState(null)

  const deleteUserInfoUrl='/admin/delete-info'


  const handleDeleteInfo=()=>{

        const body={

          userId:deleteUser?.userId
        }
        axiosInstance.delete(deleteUserInfoUrl,{
          headers:{
            Authorization:`Bearer ${user}`
            
          },
          data:body
        })
        .then((res)=>{
          window.alert("Deleted User")
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        })
        .catch((err)=>{
          window.alert("Error While Deleting")
        })

    }
  


  return (
    <div>
      <Modal size="md" isOpen={openDeleteRole} toggle={()=>setDeleteRole(!openDeleteRole)}>
        <ModalHeader>Delete Role</ModalHeader>
        <ModalBody>
          <div style={{display:'block',padding:'1em',width:'100%'}}>
            <div style={{display:'flex',justifyContent:'space-evenly',}}>
              <p className="heading">{deleteUser?.name}</p>
            </div>
            <div style={{display:'flex',justifyContent:'space-evenly',}}>
              <p className="heading">{deleteUser?.mobile}</p>
            </div>
            <div style={{display:'flex',justifyContent:'space-evenly',}}>
              <p className="heading">{deleteUser?.emailId}</p>
            </div>
            
         
            {/* <div style={{display:'flex',justifyContent:'space-evenly',}}>
              <p className="heading">Institute</p>
              <input className="inputClass" type="text" placeholder="Update Institute" defaultValue={deleteUser?.emailId} onChange={(e)=>setUpdatedEmail(e.target.value)}/>
            </div> */}
          </div>

        </ModalBody>
        <ModalFooter style={{display:'flex',justifyContent: 'space-evenly',flexDirection: 'row',}}>
          <Button onClick={()=>setDeleteRole(false)}>Close</Button>
          <Button style={{border:'none',backgroundColor:'#d32028'}} onClick={()=>handleDeleteInfo()}>Delete</Button>
        </ModalFooter>

      </Modal>

      <img onClick={()=>setDeleteRole(!openDeleteRole)} style={{width:'1.4em',height:'1.4em'}} src={deleteIcon}/>
    </div>
  )
}
