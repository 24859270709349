
import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { PaginationItem } from '@mui/material';
import leftarrow from '../../../src/assets/images/leftarrow.png';
import rightarrow from '../../../src/assets/images/rightarrow.png';
import { RFC_2822 } from 'moment';


export default function PaginationTable({ startPage, rowsPerPage, totalRows, paginate,currStatus ,isActive,searchText,duefilterDateFrom,loanId,status,paymentMode,paidfilterDateFrom,filterDateFrom,handleChange,customerName}) {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalRows / rowsPerPage); i++) {
    pageNumbers.push(i);
  } 
  const [displayPage, setDisplayPage] = React.useState(startPage);
  
  useEffect(() => {
    setDisplayPage(startPage)
    
  }, [currStatus,isActive,searchText,duefilterDateFrom,loanId,status,paymentMode,paidfilterDateFrom,filterDateFrom,handleChange,customerName]);
  
  const handleFwdPageChange = (num) => {
    if (num !== Math.ceil(totalRows / rowsPerPage) && num < Math.ceil(totalRows / rowsPerPage)) {
      setDisplayPage(++num);
      paginate(num);
      sessionStorage.setItem("page", num);
  
    scrollToTop()
      
    }
  };
  function scrollToTop() {
    document.documentElement.scrollTop=0
    
  }
  const handleBackPageChange = (num) => {
    if (num - 1 !== 0) {
      setDisplayPage(--num);
      paginate(num);
      sessionStorage.setItem("page", num);
      
      scrollToTop()
    }
  };

  const handlePaginationChange = (event, value) => {
    setDisplayPage(value);
    paginate(value);
    sessionStorage.setItem("page", value);
      scrollToTop()

  };
  const activePageStyle = {
    color: '#D32028',
    backgroundColor: '#FFF2F3',
    border: 'none'
  };
 const inactivePageStyle = {
  border:'none'
 }
  return (
    <div style={{ display: 'flex', justifyContent: 'center' ,justifyContent:'space-between'}}>
        <div>
      <a 
      onClick={() => handleBackPageChange(displayPage)} href="#NavBar" className="page-link" >
        <button 
        style={{backgroundColor:"white",borderColor:"#D0D5DD",borderStyle:'solid',display:'flex',alignItems:"center",borderWidth:'1px',borderRadius:'10px',padding:'5px',height:'35px',width:'110px'}}>
         <span style={{marginRight:'10px',marginLeft:'10px',marginBottom:'3px'}}><img src={leftarrow} /> </span> <span style={{fontFamily:'Inter-Medium',fontSize:"14px"}}>Previous</span>
        </button>
      </a>
           </div>


      <Pagination hidePrevButton hideNextButton
        count={pageNumbers.length ? pageNumbers.length : 1}
        variant="outlined"
        shape="rounded"
        page={parseInt(displayPage)}
        onChange={handlePaginationChange}
        renderItem={(item) => (
          <PaginationItem 
            {...item}
            style={item.type === 'page' && item.page === (parseInt(displayPage)) ? activePageStyle : inactivePageStyle}
          />
        )}
      />
      <div>
      <a onClick={() => handleFwdPageChange(displayPage)} href="#NavBar"  className="page-link">
      <button style={{backgroundColor:"white",borderColor:"#D0D5DD",borderStyle:'solid',display:'flex',alignItems:"center",borderWidth:'1px',borderRadius:'10px',padding:'5px',height:'35px',width:'90px'}}>
      <span style={{marginLeft:'10px',fontFamily:'Inter-Medium',fontSize:"14px"}}>Next</span> <span style={{marginLeft:'10px',marginBottom:'3px'}}><img src={rightarrow} /> </span> 
        </button>
           </a>

      </div>

    </div>
  );
}