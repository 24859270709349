import React from 'react'
import Sidebar from "../Sidebar";
import { useState, useEffect  } from 'react';
import axiosInstance from '../../helpers/axios';
import Topbar from "../Topbar";
import DatePicker from "react-multi-date-picker";
import calender from "../../assets/images/calender.png"
import download from "../../assets/images/download.png"
import moment from 'moment';


export default function CollectionPerformance() {

  const [TableData, setTableData] = useState()
  const [data, setData] = useState()
  const [startDate,setStartDateFrom] = useState()
  const [endDate,setEndDateTo] = useState()

 

  const user = sessionStorage.getItem('user');


  useEffect(() => {
    // Get the start of the current month and the current date
    const startDateFrom = moment().startOf('month');
    const endDateTo = moment();

    // Format the dates to the desired format
    const formattedStartDate = startDateFrom.format('YYYY-MM-DD');
    const formattedEndDate = endDateTo.format('YYYY-MM-DD')+ " 23:59:59";

    console.log("Formatted Start Date:", formattedStartDate);
    console.log("Formatted End Date:", formattedEndDate);

    let queryParams = {};

    if (formattedStartDate && formattedEndDate) {
      queryParams.startDate = formattedStartDate;
      queryParams.endDate = formattedEndDate;
    }

    axiosInstance.get('/analytics/collection-performance', {
      headers: {
        Authorization: `Bearer ${user}`,
      },
      params: queryParams // Pass the query params here
    })
    .then((res) => {
      setData(res?.data?.emiDetails?.overdueamount);
      console.log(res?.data?.emiDetails?.overdueamount, 'data');
    })
    .catch((error) => {
      console.log(error);
    });

  }, []); 


  const fetchingData = ()=>{
    
    let queryParams = {}
    if(startDate && endDate){
      const formattedStartDate = moment(startDate, 'DD MMM YYYY').format('YYYY-MM-DD');
      const formattedEndDate = moment(endDate, 'DD MMM YYYY').format('YYYY-MM-DD') + " 23:59:59";

    queryParams.startDate = formattedStartDate;
    queryParams.endDate = formattedEndDate;
    }
    


   

    axiosInstance.get('/analytics/collection-performance', {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {

      setTableData(res?.data?.emiDetails?.overdueamount);
      console.log(setTableData)
     
     
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
   }


   function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-IN'); 
  }


  const [isOpen,setIsOpen]=useState(true) 

  const handleDownloadList=()=>{
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
    const formattedDate = `${day}-${month}-${year}`;
    document.getElementById("downloadButton").disabled=true;
    document.getElementById("downloadButton").innerText="Wait....";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "130px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    // document.getElementById("downloadButton").style.marginLeft="150px";
    document.getElementById("downloadButton").style.backgroundImage = "none"


    const downloadTemplateUrl=`${process.env.REACT_APP_BASE_URL}/excel/IrrCountInRange `
    
    let queryParams = {}
    if(startDate && endDate){
      const formattedStartDate = moment(startDate, 'DD MMM YYYY').format('YYYY-MM-DD');
      const formattedEndDate = moment(endDate, 'DD MMM YYYY').format('YYYY-MM-DD') + " 23:59:59";

    queryParams.startDate = formattedStartDate;
    queryParams.endDate = formattedEndDate;

  

    }

    const startDateFrom = moment().startOf('month');
    const endDateTo = moment();

    if (startDateFrom && endDateTo){
      const formattedStartDate = startDateFrom.format('YYYY-MM-DD');
      const formattedEndDate = endDateTo.format('YYYY-MM-DD')+ " 23:59:59";

    queryParams.startDate = formattedStartDate;
    queryParams.endDate = formattedEndDate;
    }
     


    
    axiosInstance.get("/excel/OneMonthOverdue", {
      params: queryParams,
      responseType: 'arraybuffer',
      headers: {
          Authorization: `Bearer ${user}`
      }
  }).then((res)=>{
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 2000);
    const blob = new Blob([res.data], { type: "application/xlsx", });
    const url= window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href =url
    link.download = `${formattedDate}.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
    
  ).catch((error)=>{
    alert("Error Downloading")
    document.getElementById("downloadButton").innerText="Error..";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      // document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "130px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      // document.getElementById("downloadButton").style.marginLeft= "30px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      // document.getElementById("downloadButton").style.backgroundPositionY  ='20px' 
      document.getElementById("downloadButton").style.backgroundSize  ='20px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='15px 10px'
    }, 1000);
  })
  } 







  return (
    
    <div style={{display:'flex',width:"100%",backgroundColor:'white',height:'130%'}}>
    <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
    <div style={{width:isOpen?'80%':'100%',marginLeft:isOpen?"20%":'0%',padding:'10px'}}>
    <Topbar/>
    <p style={{textAlign:"center",fontFamily:'Inter-Medium',color:'#667085',fontSize:'25px'}}>CollectionPerformance </p>

   


    <div style={{ display: 'flex' }}>
     
              <div style={{ height:'30px',width:'15em', borderRadius: '20px',marginRight:"60px",marginTop:'-2px'}}>
              <DatePicker
                style={{ height:'33px', BorderRadius: '5px',padding: '2px', backgroundRepeat: 'no-repeat',
                backgroundPosition: '5px center',
                backgroundSize: '15px 15px', backgroundImage: `url(${calender})`, paddingLeft: '30px',fontSize:"14px", border:'1px solid #ccc',fontWeight:"normal", border:'1px solid #ccc'}}
                placeholder="Select date range"
                allowClear={true}
                format="DD MMM YYYY"
                onChange={(dates, dateStrings) => {
                  console.log("dateStrings:", dateStrings);
                
                    
                  setStartDateFrom(dateStrings?.validatedValue[0])
                  setEndDateTo(dateStrings?.validatedValue[1])
              }}
                range
                />
                </div>
               
                <div > 
           <button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter-Medium",paddingLeft:'10px'}}
             onClick={() => fetchingData()}
             >
               Search Results
             </button>
             </div>
         
             <div style={{marginLeft:'40px'}}>
             <div style={{backgroundColor:'#D22129',display: 'flex', alignItems: 'center',width:'130px',height:'34px',borderStyle:'none',borderRadius:"5px",fontFamily:"Inter-Medium",paddingLeft:'10px',justifyContent:'space-around',cursor:'pointer',marginRight:'20px'}}
               id="downloadButton"   onClick={() => handleDownloadList()} >
             
         <img src={download} style={{height:'20px',marginLeft:'8px'}}/> 
         <span style={{fontFamily:"Inter-Medium",color:'white',paddingRight:"15px"}}>Export</span>
        
          </div></div>

            
     </div>
    
    
        <table className='tables' style={{marginRight:'10px'}}>
          <thead className='table-heading'>
            <tr>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '8px%', fontFamily: "Inter-Medium", paddingLeft: '10px' }}>Buckets</th>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Collected</th>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '15px%', fontFamily: "Inter-Medium" }}>Total count</th>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Collected_amt</th>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Total_amt</th>
              <th style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10px%', fontFamily: "Inter-Medium" }}>Percentage</th>
            </tr>
          </thead>
          
          <tbody className='table-body'>
            {startDate && endDate ?
            TableData?.map((item, index) => (
              <tr className="table-row" key={index} style={{ lineHeight:"25px"}}>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085', paddingLeft: '10px' }}>{item.bucket}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item.collected}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item.Total_count}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>₹{formatNumberWithCommas(Math.round(item.Collected_amt))}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>₹{formatNumberWithCommas(Math.round(item.Total_amt))}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>  {`${((item.Collected_amt / (item.Total_amt)) * 100).toFixed(2)}%`}</td>
              </tr>
            )):
            data?.map((item, index) => (
              <tr className="table-row" key={index} style={{ lineHeight:"25px"}}>
                  <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085', paddingLeft: '10px' }}>{item.bucket}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item.collected}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>{item.Total_count}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}> ₹{formatNumberWithCommas(Math.round(item.Collected_amt))}</td>
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>₹{formatNumberWithCommas(Math.round(item.Total_amt))}</td>                
                <td style={{ fontSize: '0.9vw', cursor: 'pointer', width: '10em', fontFamily: "Inter-Medium", color: '#667085' }}>  {`${((item.Collected_amt / ( item.Total_amt)) * 100).toFixed(2)}%`}</td>
              </tr> 
            ))}
          </tbody>
        </table>

       
       
</div>
</div>
  )
}
