import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import { userActions } from "../../../actions/user.actions";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Button,
  Input,
} from "reactstrap";
import axiosInstance from '../../../helpers/axios'
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import successful from "../../../assets/images/successful.png";
import attention from "../../../assets/images/attention.png";
import credit5 from "../../../assets/images/Credit5.png";
import debit5 from "../../../assets/images/debit5.png";
import faceLive from "../../../assets/images/facelive.png";
import passed from "../../../assets/images/passed.png";
import fraud from "../../../assets/images/fraud.png";
import documentImage from "../../../assets/images/documentSample.png";
import fraudCheck from "../../../assets/images/fraudCheck.png";
import uploadPdf from "../../../assets/images/uploadPdf.svg";
import uploadZip from "../../../assets/images/uploadZip.svg";
import frame from "../../../assets/images/Frame.png";
import averageEod from "../../../assets/images/averageEod.png";
import ViewFile from "../Files/viewFile.js";
import {
  Chart as ChartJS,
  LinearScale,
  ArcElement,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";

import { Chart, Bar, Doughnut } from "react-chartjs-2";
import "./index.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

ChartJS.register(
  ArcElement,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export default function IncomeProfile({
  appId,
  userId,
  kycDetails,
  bankVerificationDetails,
  digilockerDetails,
  panDetails,
  profileDetails,
}) {
  const [pdfUploadStatus, setPdfUploadStatus] = useState("");
  const navigate=useNavigate()
  const user = sessionStorage.getItem("user");
  const dispatch = useDispatch();
  const uploadState = useSelector(
    (state) => state?.POSTINCOMEPDF?.postIncomePdfData?.res?.data?.data
  );
  const incomeProfileState = useSelector(
    (state) => state?.GETINCOMEPROFILE?.getIncomePdfData
  );
  const getFoirState = useSelector(
    (state) => state?.GETFOIRDETAILS?.getFoirDetails
  );

  const [incomeProfileData, setIncomeProfileData] = useState(null);
  const [openRemarks, setOpenAddRemarks] = useState(false);
  const [remarks, setRemarks] = useState(null);
  const [income, setIncome] = useState(null);
  const [rent, setRent] = useState(null);
  const [emi, setEmi] = useState(null);
  const [courseFeesFoir, setCourseFeesFoir] = useState(null);
  const [tenureFoir, setTenureFoir] = useState(null);
  const [feemonkEmi, setFeemonkEmi] = useState(null);
  const [openAddFoir, setOpenAddFoir] = useState(false);

  const [foirRefData, setFoirRefData] = useState(null);

  const getFoirRefUrl = `admin/application/additional-info?applicationId=${appId}`;

  const urlIncomeProfile = `/bank-statement-analysis/finbox/admin-analytics-data?userId=${userId}&applicationId=${appId}`;


  

  

  React.useEffect(() => {
    if (incomeProfileState) {
      console.log(incomeProfileState, "fetched here");
      setIncomeProfileData(incomeProfileState?.res?.data?.data);
    } else {
      dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile, user));
    }

    if (getFoirState) {
      setFoirRefData(getFoirState?.res?.data?.data);
    } else {
      dispatch(userActions.getFoirDetails(getFoirRefUrl, user));
    }

    
    
  }, [incomeProfileState, getFoirState]);

  //upload pdf
  const ref = React.useRef(null);
  const handleOpenUpload = () => {
    ref.current.click();
  };

  const [pdf, setPdf] = useState(null);

  const [openModal, setOpenModal] = React.useState(null);
  const [modalType, setModalType] = React.useState(null);

  const [pdfPassword, setPdfPassword] = React.useState("");
  const [zipPassword, setZipPassword] = React.useState("");

  const handleUploadPdf = (event) => {
    // const urlPostIncomePdf = "/bank-statement-analysis/upload";

    // const data = new FormData();
    // data.append("file", event.target.files[0]);
    // data.append("type", "pdf");
    // data.append("userId", userId);

    // dispatch(userActions.postIncomePdf(urlPostIncomePdf, data, user));
    setPdf(event.target.files[0]);
  };

  const handlePdfFormSubmit = () => {
    const urlPostIncomePdf = "/bank-statement-analysis/finbox/upload";

    const data = new FormData();
    data.append("file", pdf);
    data.append("type", "pdf");
    data.append("userId", userId);
    data.append("password", pdfPassword);
    data.append("applicationId", appId);
    data.append("Feemonk", "source");
    dispatch(userActions.postIncomePdf(urlPostIncomePdf, data, user));
    setOpenModal(false);
    setPdfPassword("");
    
    setTimeout(() => {
      window.location.reload()
      
      
    }, 1000);
  };

  const handleZipFormSubmit = () => {
    const urlPostIncomePdf = "/bank-statement-analysis/upload/zip";

    const data = new FormData();
    data.append("file", pdf);
    data.append("type", "pdf");
    data.append("userId", userId);
    data.append("password", pdfPassword);
    data.append("zipPassword", zipPassword);

    dispatch(userActions.postIncomePdf(urlPostIncomePdf, data, user));
    setOpenModal(false);
    setPdfPassword("");
    setZipPassword("");
  };

  let labelsEOD = [];

  incomeProfileData?.eod?.map((item, idx) => {
    labelsEOD.push(item.averageBalance);
  });

  const optionsVerticalBar = {
    responsive: true,
    maintainAspectRatio: true,

    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,

        text: "Average EOD",
      },
    },
  };
  let labels = [];
  incomeProfileData?.eod?.map((item) => {
    labels.push(`${item.month} / ${item.year}`);
  });
  const dataVertical = {
    labels,

    datasets: [
      {
        data: labelsEOD,
        backgroundColor: (color) => {
          let colors = color.raw >(Math.round((profileDetails?.courseFees /profileDetails?.loanTenure) *100) / 100) ? "#23BDC0" : "red";
          return colors;
        },
      },
    ],
  };

  let summaryData = [];
  let cashDepositsSum=0;
  for(let i=0;i<incomeProfileData?.summary?.cashDeposits?.length;i++)
  {
    cashDepositsSum+=incomeProfileData?.summary?.cashDeposits[i]?.amount
  }
  summaryData.push(cashDepositsSum)

  let salaryCreditSum=0;
  for(let i=0;i<incomeProfileData?.summary?.salaryCredits?.length;i++)
  {
    salaryCreditSum+=incomeProfileData?.summary?.salaryCredits[i]?.amount
  }
  summaryData.push(salaryCreditSum)

  let loanCreditsSum=0;
  for(let i=0;i<incomeProfileData?.summary?.loanCredits?.length;i++)
  {
    loanCreditsSum+=incomeProfileData?.summary?.loanCredits[i]?.emiAmount
  }
  summaryData.push(loanCreditsSum)

  let chequeDepositsSum=0;
  for(let i=0;i<incomeProfileData?.summary?.chequeDeposits?.length;i++)
  {
    chequeDepositsSum+=incomeProfileData?.summary?.chequeDeposits[i]?.amount
  }
  summaryData.push(chequeDepositsSum)


  const dataDoughnut = {
    labels: [
      "Cash Deposits",
      "Cheque Deposits",
      "Loan Credits",
      "Salary Credits",
    ],

    datasets: [
      {
        label: "Amount",
        data: summaryData,
        backgroundColor: ["#579AFD", "#FA4B53", "#FE825B", "#23BDC0"],

        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: true,

    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      title: {
        display: true,
      },
    },
  };

  let footerCredit = [];

  incomeProfileData?.top5Credit?.map((item) => {
    footerCredit.push(`${item.paymentMode} - ${item.subcategory}`);
  });
  let footerDebit = [];

  incomeProfileData?.top5Debit?.map((item) => {
    footerDebit.push(`${item.paymentMode} - ${item.subcategory}`);
  });

  const optionsDebit = {
    maintainAspectRatio: true,

    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          beforeTitle: function (context) {
            return `${footerDebit[context[0].dataIndex]}`;
          },
        },
      },
      title: {
        display: true,
      },
    },
  };
  const optionsCredit = {
    maintainAspectRatio: true,

    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          beforeTitle: function (context) {
            return `${footerCredit[context[0].dataIndex]}`;
          },
        },
      },
      title: {
        display: true,
      },
    },
  };
  const optionsFoir = {
    maintainAspectRatio: true,
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    scales: {
      B: {
        type: "linear",
        position: "right",
        ticks: { beginAtZero: true },
        grid: { display: false },
      },
      A: {
        position: "left",
        ticks: { beginAtZero: true },
        grid: { display: false },
      },
      x: { ticks: { beginAtZero: true } },
    },
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
      },
    },
  };

  let month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let top5CreditLabels = [];
  incomeProfileData?.top5Credit?.map((item) => {
    top5CreditLabels.push(
      `${
        month[parseInt(item.date.substr(5, 7))]
      } ${item.date.substr(0, 4)}`
    );
  });
  let top5Amount = [];
  incomeProfileData?.top5Credit?.map((item) => {
    top5Amount.push(item.amount);
  });

  const dataBarCredit = {
    labels: top5CreditLabels,
    datasets: [
      {
        label: "Amount",
        data: top5Amount,
        backgroundColor: "#23BDC0",
      },
    ],
  };
  let top5DebitLabels = [];
  incomeProfileData?.top5Credit?.map((item) => {
    top5DebitLabels.push(
      `${
        month[parseInt(item.date.substr(5, 7))]
      } ${item.date.substr(0, 4)}`
    );
  });
  let top5DebitAmount = [];
  incomeProfileData?.top5Credit?.map((item) => {
    top5DebitAmount.push(item.amount);
  });

  const dataBarDebit = {
    labels: top5DebitLabels,
    datasets: [
      {
        label: "Amount",
        data: top5DebitAmount,
        backgroundColor: "#FE825B",
      },
    ],
  };

  let foirPercentage = [];
  incomeProfileData?.foir?.map((item) => {
    foirPercentage.push(
      Math.round((item.totalDebitAmount / item.totalCreditAmount) * 100)
    );
  });
  let dataOutflow = [];
  incomeProfileData?.foir?.map((item) => {
    dataOutflow.push(Math.round(item.totalDebitAmount));
  });
  let dataInflow = [];
  incomeProfileData?.foir?.map((item) => {
    dataInflow.push(Math.round(item.totalCreditAmount));
  });
  const dataFoir = {
    labels,
    datasets: [
      {
        yAxisID: "B",

        type: "line",
        label: "Foir %",
        borderColor: "rgb(255, 99, 132)",
        borderWidth: 2,
        fill: false,
        data: foirPercentage,
      },
      {
        yAxisID: "A",
        type: "bar",
        label: "Outflow",
        backgroundColor: "#FE825B",
        data: dataOutflow,
        borderColor: "white",
        borderWidth: 2,
      },
      {
        yAxisID: "A",
        type: "bar",
        label: "Inflow",
        backgroundColor: "#23BDC0",
        data: dataInflow,
      },
    ],
  };
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const postFoirRefUrl = "/admin/application/additional-info";
  const handleSubmitFoir = () => {
    const foirKey = document.getElementById("foirValue").value;
    if (foirKey && emi && income && rent) {
      const data = {
        applicationId: appId,
        userId: userId,
        emiPerMonth: emi ? emi : 0,
        incomePerMonth: income ? income : 0,
        rentPerMonth: rent ? rent : 0,
        foir: foirKey ? foirKey : 0,
      };

      dispatch(userActions.postFoirDetails(postFoirRefUrl, data, user));
      dispatch(userActions.getFoirDetails(getFoirRefUrl, user));
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
      setOpenAddFoir(!openAddFoir);
    } else alert("Fill All Details");
  };

  const handleCloseFoir = () => {
    document.getElementById("feemonkemi").value = 0;
    document.getElementById("foirValue").value = 0;
    setOpenAddFoir(!openAddFoir);
    setOpenAddRemarks(!openRemarks);
  };

  // const handleChangeFMCourse=(e)=>{

  //   setCourseFeesFoir(e.target.value)
  //   document.getElementById("feemonkemi").value=e.target.value/tenureFoir
  //   setFeemonkEmi(document.getElementById("feemonkemi").value);
  // }
  const handleChangeFMTenure = (e) => {
    document.getElementById("feemonkemi").value =
      Math.round(
        (profileDetails?.courseFees / e.target.value) * 100
      ) / 100;

    setFeemonkEmi(document.getElementById("feemonkemi").value);
  };

  return (
    <div style={{ display: "block" }}>
      <Dialog
        style={{ marginTop: "-20%" }}
        fullWidth={true}
        open={openModal}
        onClose={setOpenModal}
      >
        <DialogTitle style={{ backgroundColor: "#F3F3F3" }}>
          Upload Files
        </DialogTitle>
        <DialogContent style={{ marginTop: "1em" }}>
          {/* <p>Upload {modalType === "PDF" ? "PDF File" : "Zip File"}</p> */}
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <img
              onClick={() => handleOpenUpload()}
              style={{ cursor: "pointer" }}
              src={modalType === "PDF" ? uploadPdf : uploadZip}
            /> <small style={{color:"green",fontWeight:'bold'}}>{pdf?" File Uploaded ":null}</small>
            <input
              onChange={(e) => handleUploadPdf(e)}
              ref={ref}
              style={{ display: "none" }}
              type="file"
            />
          </div>
          <Form>
            <FormGroup>
              {modalType === "ZIP" && (
                <>
                  Zip File Password
                  <Input
                    style={{ marginBottom: "1em" }}
                    onChange={(e) => setZipPassword(e.target.value)}
                    type="password"
                    placeholder="Zip File Password"
                    value={zipPassword}
                  />
                </>
              )}
              PDF File Password
              <Input
                style={{ marginBottom: "1em" }}
                onChange={(e) => setPdfPassword(e.target.value)}
                type="password"
                placeholder="PDF File Password"
                value={pdfPassword}
              />
            </FormGroup>
          </Form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setOpenModal(); setPdf(null)}}>Cancel</Button>
          <Button
            onClick={() => {
              if (modalType === "ZIP") {
                handleZipFormSubmit();
              } else {
                handlePdfFormSubmit();
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Modal size="md" isOpen={openAddFoir}>
        <ModalHeader style={{ backgroundColor: "#F0F0F0" }}>
          <span style={{ fontWeight: "bold" }}>Add FOIR</span>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              Income
              <Input
                style={{ marginBottom: "1em" }}
                onChange={(e) => setIncome(e.target.value)}
                type="number"
                placeholder="&#8377;"
              />
              Rent
              <Input
                style={{ marginBottom: "1em" }}
                onChange={(e) => setRent(e.target.value)}
                type="number"
                placeholder="&#8377;"
              />
              EMI
              <Input
                style={{ marginBottom: "1em" }}
                onChange={(e) => setEmi(e.target.value)}
                type="number"
                placeholder="&#8377;"
              />
              <p>Tenure</p>
               <Input
               defaultValue={profileDetails?.tenure}
                style={{ marginBottom: "1em" }}
                onChange={(e) => handleChangeFMTenure(e)}
                type="number"
                
              />
              {/* <Input onChange={(e)=>handleChangeFMTenure(e)} type="number" placeholder="Tenure"/> */}
              Feemonk EMI
              {/* <Input onChange={(e)=>handleChangeFMCourse(e)} type="number" placeholder="Course Fees"/> */}
              <Input
                style={{ marginBottom: "1em" }}
                defaultValue={
                  Math.round(
                    (profileDetails?.courseFees / profileDetails?.loanTenure) *
                      100
                  ) / 100
                }
                id="feemonkemi"
                disabled
                type="number"
                placeholder="Feemonk EMI"
              />
              {/* <div onClick={()=>setOpenAddRemarks(!openRemarks)} style={{display:'flex',cursor:'pointer ',width:'10em'}}>
                <p title="Remarks" style={{fontSize:'16px',margin:'10px',background:'#0096FF',width:'1.5em',height:'1.5em',marginTop:'10px',color:'white',fontWeight:'600',textAlign:'center',borderRadius:'1em'}} >!</p><span style={{marginTop:'0.8em'}}><small>Add Remarks</small></span> 
              </div>
              {
                openRemarks?<Input style={{marginBottom:'1em'}} onChange={(e)=>setRemarks(e.target.value)} type="text" placeholder="Remarks"/>:null
              } */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  backgroundColor: "#FCF0F1",
                  padding: "1em",
                  borderRadius: "10px",
                }}
              >
                <p style={{ color: "#d32028", marginTop: "10px" }}>
                  Calculated FOIR
                </p>
                <Input
                  style={{ width: "15em" }}
                  id="foirValue"
                  disabled
                  value={Math.round(
                    ((parseFloat(rent?rent:0) +
                      parseFloat(emi?emi:0) +
                      parseFloat(feemonkEmi?feemonkEmi:0)) /
                      parseFloat(income?income:0)) *
                      100
                  )}
                  type="number"
                  placeholder="Calculated FOIR"
                />
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleCloseFoir()}>Close Box</Button>
          <Button
            style={{ backgroundColor: "#d32028", border: "none" }}
            onClick={() => handleSubmitFoir()}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      <div
        style={{
          display: "flex",
          paddingTop: "1em",
          marginTop: "2em",
          justifyContent: "space-around",
          backgroundColor: "#FFF3F3",
          border: "1px solid #D32028",
          borderRadius: "1em",
        }}
      >
        <div style={{ display: "block", lineHeight: "1em" }}>
          <p>Current Rent</p>
          <p style={{ fontWeight: "bold" }}>
            &#8377;{foirRefData?.rentPerMonth}
          </p>
        </div>
        <div style={{ display: "block", lineHeight: "1em" }}>
          <p>Current Income</p>
          <p style={{ fontWeight: "bold" }}>
            &#8377;{foirRefData?.incomePerMonth}
          </p>
        </div>
        <div style={{ display: "block", lineHeight: "1em" }}>
          <p>Current EMI</p>
          <p style={{ fontWeight: "bold" }}>
            &#8377;{foirRefData?.emiPerMonth}
          </p>
        </div>
        <div style={{ display: "block", lineHeight: "1em" }}>
          <p>Feemonk EMI</p>
          <p style={{ fontWeight: "bold" }}>
            &#8377;
            {foirRefData?.feemonkEmi
              ? foirRefData?.feemonkEmi
              : Math.round(
                  (profileDetails?.courseFees / profileDetails?.loanTenure) *
                    100
                ) / 100}
          </p>
        </div>
        <div style={{ display: "block", lineHeight: "1em" }}>
          <p>FOIR</p>
          <p style={{ fontWeight: "bold" }}>{foirRefData?.foir} %</p>
        </div>

        <div style={{ display: "block", lineHeight: "1em" }}>
          <Button
            onClick={() => setOpenAddFoir(!openAddFoir)}
            style={{ backgroundColor: "#D32028", border: "none" }}
          >
            Edit FOIR
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          // justifyContent: "space-between",
          marginTop: "1em",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          {/* <span
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: "Outfit",
              color: !checked ? "#D32028" : "#B0B0B0",
            }}
          >
            PDF View 
          </span> */}
          {/* <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <span
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              fontFamily: "Outfit",
              color: checked ? "#D32028" : "#B0B0B0",
            }}
          >
            Account Aggregator
          </span> */}
        </div>
        {/* {incomeProfileData?.nameMatchScore ? <div style={{marginRight:'10%'}}><p style={{fontFamily:'Inter-Medium',fontWeight:'500'}}>NameMatch Score  {incomeProfileData?.nameMatchScore.toFixed(2)}</p></div>:null} */}
       {incomeProfileData?.nameMatchScore ? <Button  style={{fontSize:'13px',backgroundColor:'#FFF',border:'1px solid #DbDbDb',color:'black',fontWeight:'bold',marginRight:'10%'}}>NameMatch Score : <strong>{incomeProfileData?.nameMatchScore?.toFixed(2)}%</strong></Button> : null} 

        {incomeProfileData?.analysisExcelReport?<div style={{display:'flex',justifyContent: 'center',marginRight:'10%'}}>
          <Button onClick={()=>window.open(incomeProfileData?.analysisExcelReport)} style={{fontSize:'13px',backgroundColor:'#FFF',border:'1px solid #DbDbDb',color:'green',fontWeight:'bold'}}>Download Bank Analysis Excel Report</Button>

        </div>:null}
        {incomeProfileData?.bankConnectScore?<div style={{display:'flex',justifyContent: 'center',marginRight:'10%'}}>
          <Button title="Bank Statement Score by Finbox" style={{fontSize:'13px',backgroundColor:'#FFF',border:'1px solid #DbDbDb',color:'blue',fontWeight:'bold'}}>Finbox Score <strong>{incomeProfileData?.bankConnectScore}</strong></Button>

        </div>:null}
        <div
          style={{
            display: "flex",
          }}
        >
          <div>
            <img
              // onClick={() => handleOpenUpload()}
              onClick={() => {
                setOpenModal(true);
                setModalType("PDF");
              }}
              style={{ cursor: "pointer" }}
              src={uploadPdf}
            />
          </div>
          <div>
            <img
              // onClick={() => handleOpenUpload()}
              onClick={() => {
                setOpenModal(true);
                setModalType("ZIP");
              }}
              style={{ cursor: "pointer" }}
              src={uploadZip}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "2em",
          marginLeft: "1em",
        }}
      >
        <div
          style={{
            borderRadius: "1em",
            width: "50%",
            margin: "1em",
            boxShadow: "0px 0px 2px 2px  #f0f0f0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1em",
                width: "100%",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  fontFamily: "Outfit",
                }}
              >
                <img style={{ marginRight: "0.5em" }} src={averageEod} />
                Average EOD Balance
              </p>
            </div>
          </div>
          <div style={{ padding: "1em", paddingLeft: "2em", marginTop: "2em" }}>
            <Bar
              redraw={true}
              options={optionsVerticalBar}
              data={dataVertical}
            />
          </div>
        </div>
        <div
          style={{
            border: "1px solid #AEBBD7",
            borderRadius: "1em",
            width: "50%",
            margin: "1em",
            backgroundColor: "#F6F9FF",
            boxShadow: "0px 0px 2px 2px  #f0f0f0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1em",
                width: "100%",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  fontFamily: "Outfit",
                }}
              >
                <img style={{ marginRight: "0.5em" }} src={fraudCheck} />
                Fraud Checks
              </p>
            </div>
          </div>
          <div style={{ overflowY: "scroll" }}>
            <div style={{ padding: "1em", borderBottom: "1px solid #D0D0D0" }}>
              <p
                style={{
                  color: "#d32028",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginLeft: "2em",
                }}
              >
                Identified Fraud Indicators
              </p>
              {incomeProfileData?.flags ? (
                incomeProfileData?.flags?.bounceDetected ? (
                  <p style={{ marginLeft: "2em" }}>
                    <img style={{ marginRight: "1em" }} src={fraud} />
                    Bounce Detected
                  </p>
                ) : (
                  <p style={{ marginLeft: "3em" }}>None</p>
                )
              ) : null}
              {incomeProfileData?.flags?.chequeBounceDetected ? (
                <p style={{ marginLeft: "2em", color: "#d32028" }}>
                  <img style={{ marginRight: "1em" }} src={fraud} />
                  Cheque Bounce Detected
                </p>
              ) : null}
              {incomeProfileData?.flags?.bounceDetected ? (
                <p style={{ marginLeft: "2em" }}>
                  <img style={{ marginRight: "1em" }} src={fraud} />
                  Bank Penalties Detected
                </p>
              ) : null}
              {incomeProfileData?.flags?.bounceDetected ? (
                <p style={{ marginLeft: "2em" }}>
                  <img style={{ marginRight: "1em" }} src={fraud} />
                  Reversal Detected
                </p>
              ) : null}
            </div>
            <div style={{ padding: "1em", height: "10em" }}>
              <p
                style={{
                  color: "#4bb543",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginLeft: "2em",
                }}
              >
                Passed Fraud Indicators
              </p>
              {incomeProfileData?.flags ? (
                incomeProfileData?.flags?.bounceDetected === false ? (
                  <p style={{ marginLeft: "3em" }}>
                    <img style={{ marginRight: "1em" }} src={passed} />
                    No Bounce Detected
                  </p>
                ) : null
              ) : (
                <p style={{}}>No Indicators Found</p>
              )}
              {incomeProfileData?.flags?.chequeBounceDetected === false ? (
                <p style={{ marginLeft: "3em" }}>
                  <img style={{ marginRight: "1em" }} src={passed} />
                  No Cheque Bounce Detected
                </p>
              ) : null}
              {incomeProfileData?.flags?.bankPenaltiesDetected === false ? (
                <p style={{ marginLeft: "3em" }}>
                  <img style={{ marginRight: "1em" }} src={passed} />
                  No Bank Penalties Detected
                </p>
              ) : null}
              {incomeProfileData?.flags?.reversalDetected === false ? (
                <p style={{ marginLeft: "3em" }}>
                  <img style={{ marginRight: "1em" }} src={passed} />
                  No Reversal Detected
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "2em",
          marginLeft: "1em",
        }}
      >
        <div
          style={{
            borderRadius: "1em",
            width: "50%",
            margin: "1em",
            boxShadow: "0px 0px 2px 2px  #f0f0f0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1em",
                width: "100%",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  fontFamily: "Outfit",
                }}
              >
                <img style={{ marginRight: "0.5em" }} src={credit5} />
                Top 5 Credit Transactions
              </p>
            </div>
          </div>
          <div style={{ margin: "1em" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Bar redraw={true} options={optionsCredit} data={dataBarCredit} />
            </div>
          </div>
        </div>
        <div
          style={{
            borderRadius: "1em",
            width: "50%",
            margin: "1em",
            boxShadow: "0px 0px 2px 2px  #f0f0f0",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "1em",
                width: "100%",
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  fontFamily: "Outfit",
                }}
              >
                <img style={{ marginRight: "0.5em" }} src={debit5} />
                Top 5 Debit Transaction
              </p>
            </div>
          </div>
          <div style={{ margin: "1em" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Bar redraw={true} options={optionsDebit} data={dataBarDebit} />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          borderRadius: "1em",
          width: "95%",
          marginLeft: "2em",
          boxShadow: "0px 0px 2px 2px  #f0f0f0",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1em",
              width: "100%",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: "Outfit",
              }}
            >
              <img style={{ marginRight: "0.5em" }} src={frame} />
              FOIR Summary
            </p>
          </div>
        </div>
        <div style={{ padding: "1em" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1em",
            }}
          >
            <Chart
              redraw={true}
              options={optionsFoir}
              type="bar"
              data={dataFoir}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          borderRadius: "1em",
          width: "95%",
          marginLeft: "2em",
          boxShadow: "0px 0px 2px 2px  #f0f0f0",
          marginTop: "2em",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1em",
              width: "100%",
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                fontFamily: "Outfit",
              }}
            >
              <img style={{ marginRight: "0.5em" }} src={frame} />
              Income Summary
            </p>
          </div>
        </div>
        <div style={{ padding: "1em" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1em",
              paddingLeft: "5em",
              paddingRight: "5em",
            }}
          >
            <div style={{ width: "20em", height: "20em" }}>
              <Doughnut redraw={true} data={dataDoughnut} />
            </div>
            <div style={{ display: "block" }}>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div
                  style={{
                    display: "block",
                    width: "12em",
                    padding: "10px",
                    height: "7em",
                    marginRight: "1em",
                    boxShadow: "0px 0px 2px 2px #f0f0f0",
                    borderRadius: "10px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: "Outfit",
                    }}
                  >
                    Cash Deposits
                  </p>
                   &#8377;  {summaryData[0]}
                </div>
                <div
                  style={{
                    display: "block",
                    width: "12em",
                    marginLeft: "1em",
                    padding: "10px",
                    height: "7em",
                    boxShadow: "0px 0px 2px 2px #f0f0f0",
                    borderRadius: "10px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: "Outfit",
                    }}
                  >
                    Salary Credits
                  </p>
                  <p style={{ marginTop: "-1em" }}>
                    &#8377;   {summaryData[3]?summaryData[3]:0}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginTop: "1em",
                }}
              >
                <div
                  style={{
                    display: "block",
                    width: "12em",
                    padding: "10px",
                    height: "7em",
                    marginRight: "1em",
                    boxShadow: "0px 0px 2px 2px #f0f0f0",
                    borderRadius: "10px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: "Outfit",
                    }}
                  >
                    Cheque Deposits
                  </p>
                    &#8377;   {summaryData[1]}

                </div>
                <div
                  style={{
                    display: "block",
                    width: "12em",
                    marginLeft: "1em",
                    padding: "10px",
                    height: "7em",
                    boxShadow: "0px 0px 2px 2px #f0f0f0",
                    borderRadius: "10px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: "Outfit",
                    }}
                  >
                    Loan Credits
                  </p>
                  <p style={{ marginTop: "-1em" }}>
                    &#8377;{" "}
                    {summaryData[2]}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
